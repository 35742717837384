import React from 'react';
import "./style.scss";


function Component({ data, collapsedHeight, state, refList, toggleReadMore })
{
    return <div className="display-panel">
        {
                data.map((rowData, i) =>
                <div
                    className="row"
                    key={'row-' + i}>
                    <div className="name">{rowData.name}</div>
                    {
                        rowData.data.map((value, _i) => {
                            let style = {
                                color: rowData.fontColor[_i],
                                textAlign: rowData.textAlign,
                                flex: 1,
                                overflow: 'hidden'
                            };
                
                            if (state.collapsedStatusList[i]) {
                                style.height = collapsedHeight;
                            }

                            const html =
                                value +
                                (rowData != null ? ' ' + rowData.unit : '');

                            return (
                                <div
                                    className="value"
                                    key={'value-' + i + '-' + _i}
                                >
                                    <div
                                        className={state.collapsedStatusList[i] ? 'collapsed' : ''}
                                        // className={'collapsed'}
                                        ref={ref => refList[i] = ref}
                                        style={style}
                                        dangerouslySetInnerHTML={{ __html: html }}>
                                    </div>
                                    {
                                        state.heightList[i] > collapsedHeight &&
                                        <div
                                            className="read-more blue"
                                            data-rowindex={i}
                                            onClick={toggleReadMore}
                                        >
                                            {state.collapsedStatusList[i] ? '展開 ▼' : '收合 ▲'}
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            )
        }
    </div>
}

export default Component;