import Pagination from "../ui";
import { useSelector } from "react-redux";
import { updatePanelData, getPanelData } from "Redux/actionCreators/dashboard"
import React, { useEffect } from "react";
// import _ from "lodash";
import { getData } from "Util/table"
import {  useRouteMatch} from "react-router-dom"
/**
 * @memberof module:PaginationTable
 * @param {object} panel - 由dashboard api來的面板資料
 * @param {number} rowHeight - 行高
 * @param {object} parameters - 參數
 * @description 處理分頁列表的資料，第一次元件monted之後，由getPanelData取該元件所屬資料，並在取得資料後由afterGotPanelData處理。
 * @returns {ReactElement}
 */

function Container(props)
{
    // 每頁顯示數量
    const displayLength = props.displayLength ? props.displayLength : 10;
    let panelHeight = props.rowHeight * props.panel.height + 20 * (props.panel.height - 1)
    - 30 - 10;
    let height = panelHeight - 9 * 2 - 30 - 16;
    const headerHeight = 32; // table header height: 32
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === props.panel.id))
    // 元件資料
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === props.panel.id))
    
    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })
    
    //換頁
    const onChangePage = (page) => {
        updatePanelData(props.panel.id, {
            page: page 
        })
    }
    
    useEffect(() => {
        const parameters = {}
        if(match)
        {
            parameters.stock_code = match.params.id;
            parameters.strategy = match.params.strategyId;
        }
        getPanelData(props.panel, parameters)
        /*  eslint-disable-next-line */
    },[props.panel])
    
    return panelData ? <Pagination 
        id={props.panel.id}
        rawData = {panelData.rawData.main.data}
        height={height}
        headerHeight={headerHeight}
        data={getData(panelData.rawData.main.data, panelData.sortColumn, panelData.sortType, Math.floor((height - headerHeight) / 46), pagination?.page)}
        columns={panelData.rawData.main.columns}
        sortColumn={panelData.sortColumn}
        sortType={panelData.sortType}
        displayLength={displayLength}
        // sortColumnData={sortColumnData}
        onChangePage={onChangePage}
        page={panelData.page}
    />: null
}

export default Container;