import Component from "./component";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import { TOGGLE_STRATEGY_SIDEBAR } from "Redux/actionTypes/app"
import { fetchStrategyScore } from "Redux/actionCreators/strategy"
import { isEqual } from "lodash";
// import { SET_SIDEBAR_STRATEGY_SCORE_ID } from "Redux/actionTyps/strategy"

const deepEqual = (left, right) => {
    if(!isEqual(left, right))
    {
        return false;
    } else {
        return true;
    }
}

function Container()
{
    const dispatch = useDispatch()
    const history = useHistory()
    const strategyList = useSelector(store => store.strategy.list, deepEqual);
    const strategyScoreList = useSelector(store => store.strategy.strategyScoreList, deepEqual);
    const sidebarStrategyScoreId = useSelector(store => store.strategy.sidebarStrategyScoreId, shallowEqual);
    const strategySidebarVisibility = useSelector(store => store.app.strategySidebarVisibility, shallowEqual);
    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            // '/stockAnalysis/:id/:analysisId',
            // '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })
    const strategyId = match?.params.strategyId;
    const location = useLocation()

    // 投資策略側欄開關
    const togglePanel = useCallback(() => {
        dispatch({
            type: TOGGLE_STRATEGY_SIDEBAR,
            strategySidebarVisibility: !strategySidebarVisibility
        })
        /* eslint-disable-next-line */
    },[strategySidebarVisibility])
    // 選策略

    const onStrategyChange = (id) => {
        // 若在策略的頁面, 則進行連動轉換。
        if (match) {
            let analysisId =
                match.params.analysisId ?
                    match.params.analysisId : '2';
            let analysisPath =
                '/stockAnalysis/' + match.params.id + '/' +
                analysisId + '/' + id;

            const prevPath = '/stockAnalysis/' + match.params.id + '/' +
            analysisId + '/' + match.params.strategyId;
                const location = { pathname: analysisPath, prevPath: prevPath };
            history.push(location);
        }

        fetchStrategyScore(id);
    }
    // 取得策略的股票
    const getScoreList = () => {
        const scoreListId =  match ? match.params.strategyId*1: sidebarStrategyScoreId*1
        const strategyScoreStockList = strategyScoreList.find(o => o.id === scoreListId);
        
        if(strategyScoreStockList)
        {
            return strategyScoreStockList.stocks
        } else {
            return []
        }
        
    }
    useEffect(() => {
        if(strategyId)
        {
            fetchStrategyScore(strategyId);        
        } else {
            if(sidebarStrategyScoreId)
            {
                fetchStrategyScore(sidebarStrategyScoreId);       
            }
        }
    /* eslint-disable-next-line */
    },[strategyId, sidebarStrategyScoreId])

    let strategyNameList =
        strategyList
            .filter(x => x.status !== 0)
            .map(strategy => ({ value: strategy.id, label: strategy.name }));

    return <Component
        location={location}
        // id={sidebarStrategyScoreId}
        id={match ? match.params.strategyId* 1: sidebarStrategyScoreId*1 }
        scoreList = {getScoreList()}
        match={match}
        visibility={strategySidebarVisibility}
        strategyNameList={strategyNameList}
        onVisibilityChange={togglePanel}
        onStrategyChange={onStrategyChange}
        show={location.pathname === "/login" || location.pathname === '/tutorial' ? false : true}
        // strategyList={strategyList}
    />
}


function areEqual(prevProps, nextProps)
{
    if(!isEqual(prevProps, nextProps))
    {
        return false
    } else {
        return true;
    }

}

export default React.memo(Container, areEqual);

// export default Container;