const cols = 3

export default class DashboardUtil {
    static getDefaultLayout(singles) {
        if (!singles) { return []; }

        let layout = [];

        let x = 0;
        let y = 0;

        singles.forEach(panel => {
            if (x + panel.width > cols) {
                x = 0;
                y += 1;
            }

            layout.push({
                i: panel.id,
                x: x,
                y: y,
                w: panel.width,
                h: panel.height
            });

            x += panel.width;
        });

        return layout;
    }
}