import React from "react";
import "./style.scss";
import {  Router } from "react-router-dom";
import Footer from './component/footer';
import Header from './component/header';
import Sidebar from "Components/sidebar";
import NeedLoginModal from './component/needLoginModal';
import Notice from 'Components/notice'
// import Login from "Page/loginPage";
import StrategySidebar from 'Components/strategySidebar/index';
import QuestionnaireModal from 'Components/questionnaireModal';
// import { Route } from "react-router-dom";

// import { cookies } from "Https/fetch"
import BackgroundLogin from 'Image/background-login.jpg';

import history from "Src/history"

export default function Layout({ Routes, showBackground }){
	const routesStyle =
		showBackground ?
			{
				background: "url(" + BackgroundLogin + ")",
				backgroundSize: 'cover'
			} :
			{};
	
	// window.cookies = cookies
	return <div className = "app app__layout">
		<Router history={history}>
			<Header />
			<div className="app__layout__wrapper">
				<div className="app__layout__sidebar">
					<Sidebar />
				</div>
				<div className="app__layout__routes" style={routesStyle}>
					<div>
						<Routes />
						<Footer />
					</div>
				</div>
				 <div className="app__layout__strategy_sidebar">
					<StrategySidebar />
				</div>
			</div>
		</Router>
		<Notice />
		<NeedLoginModal />
		<QuestionnaireModal />
	</div>;
}