import React from 'react';

import ReactUtil from './reactUtil';
import { Operator } from './constant/operator';
import { getIndexUnit } from 'Util/getIndexUnit';

var _ = require('lodash');

export default class IndexUtil {
    // Check index validity
    static checkIndex(index) {
        if (!index) { return false; }

        // Check value
        if (!index.value || index.value.value == null) { return false; }

        // Check range
        if (
            !index.range || index.range.value == null ||
            (index.range.input && index.range.customValue == null)
        ) {
            return false;
        }

        // Check data type
        if (
            (index.range.data_types && index.range.data_types.length !== 0) &&
            (
                !index.dataType || index.dataType.value == null ||
                (index.dataType.input && index.dataType.customValue == null)
            )
        ) {
            return false;
        }

        // Check time
        if (
            !index.time || index.time.value == null ||
            (index.time.value !== 'latest' && index.time.customValue == null)
        ) {
            return false;
        }

        // Check adjustment
        if (
            index.adjust && index.adjust.type !== 'none' &&
            index.adjust.value == null
        ) {
            return false;
        }

        return true;
    }

    static checkIndexRule(rule) {
        if (!rule || !this.checkIndex(rule.index)) { return false; }

        // Check operator
        if (
            rule.operator == null ||
            !Operator.find(operator => rule.operator === operator.value)
        ) {
            return false;
        }

        // Check value
        if (rule.operator.includes('Index') && !this.checkIndex(rule.index2)) {
            return false;
        }

        if (
            rule.operator === 'between' &&
            (rule.rangeFrom == null || rule.rangeTo == null)
        ) {
            return false;
        }

        if (
            !rule.operator.includes('Index') &&
            rule.operator !== 'between' &&
            !rule.operator.toLowerCase().includes('asc') &&
            !rule.operator.toLowerCase().includes('desc') &&
            rule.value == null
        ) {
            return false;
        }

        return true;
    }

    static checkIndexScore(rule) {
        return this.checkIndexRule(rule) && rule.score != null;
    }

    static getIndexJSX(index, suppressRangeLabel, isTableUse) {
        if (!index || !index.value) return '';

        let time = '';
        let unit = '';

        let rangeList = [];
        if (index.value && index.value.ranges) {
            index.value.ranges.forEach(category =>
                category.children.forEach(range => rangeList.push(range))
            );
        }

        let rangeObject =
            index.range && index.range.value ?
                rangeList.find(x => x.value === index.range.value) : {};

        // Use typeof to skip processing the time object in create table page
        if (
            index.time && index.time.value !== 'latest' &&
            index.time.customValue && typeof index.time.customValue !== 'object'
        ) {
            time = index.time.customValue;
            unit =
                rangeObject.unit ?
                    <>
                        <span>{rangeObject.unit + '前'}</span>
                        <span className="opacity-40">&nbsp;的&nbsp;</span>
                    </> :
                    '';
        }

        let range = '';

        if (rangeObject && rangeObject.input) {
            // Skip returning range text if range_date exists
            // (the columns in the index modal in TablePage)
            if (!index.range_date) {
                let customRange =
                    index.range.customValue ? index.range.customValue : 'N';
                range =
                    <>
                        {'近' + customRange + rangeObject.unit}
                        {
                            !isTableUse ?
                                <span className="opacity-40">
                                    &nbsp;之&nbsp;
                                </span> :
                                <br />
                        }
                    </>;
            }
        } else if (
            rangeObject && rangeObject.label && !suppressRangeLabel &&
            !isTableUse
        ) {
            range =
                <>
                    {rangeObject.label}
                    <span className="opacity-40">&nbsp;的&nbsp;</span>
                </>;
        }

        let name = index.value.label ? index.value.label : '';
        let dataType = '';

        if (rangeObject && rangeObject.data_types && index.dataType) {
            let dataTypeObject =
                rangeObject.data_types.find(
                    x => x.value === index.dataType.value);

            if (
                dataTypeObject && dataTypeObject.input &&
                index.dataType.customValue
            ) {
                dataType =
                    <>
                        &nbsp;
                        {
                            '有' + index.dataType.customValue +
                            rangeObject.unit + '皆...'
                        }
                    </>;
            } else if (
                index.range && index.range.value !== 'latest' &&
                dataTypeObject && dataTypeObject.label
            ) {
                dataType =
                    <>
                        {
                            !isTableUse ?
                                <span className="opacity-40">&nbsp;的&nbsp;</span> :
                                <br />
                        }
                        {dataTypeObject.label}
                    </>;
            }
        }

        let adjust = '';
        if (
            index.adjust && index.adjust.type !== 'none' &&
            !isNaN(index.adjust.value)
        ) {
            switch (index.adjust.type) {
                case 'percent':
                    adjust = 
                        <>
                            <span className="opacity-40">&nbsp;的&nbsp;</span>
                            {index.adjust.value}%
                        </>;
                    break;
                case 'value':
                    let sign =
                        index.adjust.value >= 0 ? ' 加 ' : ' 減 ';
                    adjust = sign + Math.abs(index.adjust.value);
                    break;
                default:
            }
        }

        return <>{time}{unit}{range}{name}{dataType}{adjust}</>;
    }

    static getIndexText(index) {
        
        // console.log('123');
        return ReactUtil.getNodeText(IndexUtil.getIndexJSX(index));
    }

    static getIndexRuleJSX(rule) {
        if (!IndexUtil.checkIndexRule(rule)) { return null; }

        let operator =
            Operator.find(operator => rule.operator === operator.value);
        let operatorName = operator ? operator.label : '';

        return (
            <>
                <div className="index">
                    {IndexUtil.getIndexJSX(rule.index)}
                </div>
                <div className="operator">
                    {operatorName}
                </div>
                <div className="operand">
                    {this.getOperand(rule)}
                </div>
            </>
        );
    }

    static getOperand(rule) {
        let unit = 
            rule.operator && rule.operator.startsWith('rank') ?
                '%' : getIndexUnit(rule.index);

        switch (rule.operator) {
            case 'greater':
            case 'equal':
            case 'less':
            case 'greaterEqual':
            case 'lessEqual':
            case 'rankGreater':
            case 'rankExcludeGreater':
            case 'rankGreaterEqual':
            case 'rankExcludeGreaterEqual':
            case 'rankLess':
            case 'rankExcludeLess':
            case 'rankLessEqual':
            case 'rankExcludeLessEqual':
                return rule.value + unit;
            case 'greaterIndex':
            case 'equalIndex':
            case 'lessIndex':
            case 'greaterEqualIndex':
            case 'lessEqualIndex':
                return IndexUtil.getIndexJSX(rule.index2);
            case 'between':
                return rule.rangeFrom + ' ~ ' + rule.rangeTo + unit;
            default:
                return '';
        }
    }

    static getIndexRuleScore(rule) {
        let className = rule.score === rule.total ? 'score full' : 'score';

        return (
            <>
                {this.getIndexRuleJSX(rule)}
                <div className={className}>
                    {this.renderScore(rule)}
                </div>
            </>
        );
    }

    static renderScore(rule) {
        return (
            <>
                <div className="value">{rule.score}</div>
                <div className="split">/</div>
                <div className="total">{rule.total}</div>
            </>
        );
    }

    static getGraphIndexText_origin(index) {
        if (!index || !index.value) return '';

        let rangeObject =
            index.value && index.value.ranges ?
                index.value.ranges.find(x => x.value === index.range.value) :
                {};

        let name = index.value.label ? index.value.label : '';
        let unit =
            rangeObject && rangeObject.unit ?
                ' (' + rangeObject.unit + ')' : '';

        return name + unit;
    }

    static getTableHeaderIndexText(indexData) {
        function getTimeStr(time) {
            let str = '';

            if (time.year) {
                str += time.year;
            }

            if (!time.quarter && !time.month && !time.day) {
                str += '年';
            }

            if (time.quarter) {
                str += '/Q' + time.quarter;
            }

            if (time.month) {
                str += '/' + time.month;
            }

            if (time.day) {
                str += '/' + time.day;
            }

            return str;
        }

        let timeStr =
            indexData.index.range_date && indexData.index.range_date.from ?
                <>
                    {getTimeStr(indexData.index.range_date.from)}<br />
                    ~{getTimeStr(indexData.index.range_date.to)}
                </> :
                getTimeStr(indexData.date);

        let unit = getIndexUnit(indexData.index);

        return (
            <>
                {timeStr}< br />
                {IndexUtil.getIndexJSX(indexData.index, true, true)}
                {
                    unit &&
                    <><br />({unit})</>
                }
            </>
        );
    }

    static getGraphIndexText(index) {
        if (!index || !index.value) return '';

        let name = index.value.label ? index.value.label: "";

        let ranges = [];
        if(index.value && index.value.ranges)
        {
            // Merge the single-value and multi-value ranges
            index.value.ranges.forEach(category =>
                category.children.forEach(range => ranges.push(range))
            )
        }

        let rangeObject = ranges.find(x => x.value === index.range.value)
        let unit = "";

        if(rangeObject)
        {
            if(rangeObject.unit)
            {
                unit += rangeObject.unit;
            }

            if(rangeObject.value.includes("cumulative"))
            {
                unit += '累計'
            }
        }

        return name + (unit !== '' ? ' (' + unit + ')': '');
    }

    static getIndexFromLibrary(code, indexLibrary) {
        for (let i = 0; i < indexLibrary.length; i++) {
            let category = indexLibrary[i];
            let index = category.children.find(index => index.value === code);

            if (index) {
                return index;
            }
        }

        return null;
    }

    static convertCodeInListToIndex(list, indexLibrary) {
        if (!list) { return []; }

        return list.map(row => {
            let newRow = _.cloneDeep(row);

            if (row.index && row.index.value) {
                newRow.index = this.restoreIndex(row.index, indexLibrary);
            }

            if (row.index2) {
                newRow.index2 = this.restoreIndex(row.index2, indexLibrary);
            }

            return newRow;
        });
    }

    static restoreIndex(index, indexLibrary) {
        if (!index || !index.value) { return null; }

        let newIndex = _.cloneDeep(index);
        let value = this.getIndexFromLibrary(index.value, indexLibrary);

        newIndex.value = value;

        if (value && index.range && index.range.value) {
            // Single value
            newIndex.range =
                value.ranges[0].children
                    .find(x => x.value === index.range.value);

            // Multi-value
            if (!newIndex.range) {
                newIndex.range =
                    _.cloneDeep(
                        value.ranges[1].children
                            .find(x => x.value === index.range.value));
            }

            // Custom value
            if (index.range.customValue) {
                newIndex.range.customValue = index.range.customValue;
            }
        }

        if (value && newIndex.range && index.dataType && index.dataType.value) {
            newIndex.dataType =
                newIndex.range.data_types.find(x =>
                    x.value === index.dataType.value);

            if (index.dataType.customValue) {
                newIndex.dataType.customValue = index.dataType.customValue;
            }
        }

        return newIndex;
    }

    static convertIndexInListToCode(list) {
        // Strip the information other than index's code before sending it to
        // server
        //
        // Convert the format of index in the list from
        // {
        //     value: { value: 'raw01234', ranges: [...], label: 'xxx', ...},
        //     range: {...},
        //     time: {...},
        //     dataType: {...}
        // }
        // to
        // { value: 'raw01234', range: {...}, time: {...}, dataType: {...}}

        // If the index is invalid, return null
        return list.map(row => {
            let { index, index2 } = row;

            let newRow = { ...row };
            newRow.index = this.convertIndex(index);

            if (newRow.operator.includes('Index')) {
                newRow.index2 = this.convertIndex(index2);
            }

            return newRow;
        });
    }

    static convertIndex(index) {
        if (index && index.value && typeof index.value.value === 'string') {
            let newIndex = { ...index };
            newIndex.value = index.value.value;

            if (index.range) {
                newIndex.range = { value: index.range.value };

                if (index.range.customValue) {
                    newIndex.range.customValue = index.range.customValue;
                }
            }

            if (index.dataType) {
                newIndex.dataType = { value: index.dataType.value };

                if (index.dataType.customValue) {
                    newIndex.dataType.customValue = index.dataType.customValue;
                }
            }

            return newIndex;
        }

        return null;
    }
}