import React from 'react';
import { Button, Modal } from 'rsuite';

import './style.scss';

const Component = ({ show, onHide, onConfirm }) => {
    return (
        <Modal
            show={show}
            className="tutorial-hint-modal"
            onHide={onHide}>
            <Modal.Header>
                <Modal.Title>這裡有一些秘訣</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <img src={require('Image/tutorial/hint.jpg')} alt="" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onConfirm}>立即查看</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;