export const tabList = [
    {
      "id": 47,
      "name": "投資策略",
      "latest": [
        "6214",
        "5209",
        "3570",
        "5410",
        "6112"
      ],
      "backtest": [],
      "new": [],
      "removed": []
    },
    {
      "id": 374,
      "name": "追蹤股",
      "latest": [
        "5483",
        "2458",
        "6147",
        "2449",
        "8150",
        "6257",
        "6488",
        "2379",
        "5347",
        "8299",
        "2337",
        "6239",
        "3532",
        "2441",
        "6732"
      ],
      "backtest": [],
      "new": [],
      "removed": []
    },
    {
      "id": 377,
      "name": "全台股",
      "latest": [
        "6147",
        "2451",
        "6462",
        "6261",
        "3556"
      ],
      "backtest": [],
      "new": [],
      "removed": []
    }
]
// [
//     {
//       "name": "投資策略",
//       "id": 2,
//       "items": [
//         "6214",
//         "5209",
//         "3570"
//       ]
//     },
//     {
//       "name": "追蹤股",
//       "id": 56,
//       "items": []
//     },
//     {
//       "name": "全台股",
//       "id": 151,
//       "items": []
//     },
//   ];

  
export const columnList = [
    {
        "id": "stock_code",
        "type": "text",
        "name": "股票名稱",
        "width": 100
    },
    {
        "id": "ror",
        "type": "text",
        "name": "2019年 EPS(元)",
        "width": 100
    },
    {
        "id": "cost",
        "type": "text",
        "name": "2020年 EPS(元)",
      "width": 100
    },
    {
        "id": "DailyPriceChange_Percent",
      "type": "text",
      "name": "較上次月平均預估EPS",
      "width": 126
    },
    {
        "id": "weight",
        "type": "text",
        "name": "2021年 EPS(元)",
      "width": 85
    },
    {
      "id": "DailyClose",
      "type": "text",
      "name": "較上次月平均預估EPS",
      "width": 126
    },
    {
        "id": "Score",
        "type": "text",
        "name": "預估更新日期",
        "width": 120
    },
    {
        "id": "DailyClose1",
        "type": "text",
        "name": "預測家數",
        "width": 100
    },
]

export const processedData = [
    {
      "stock_code": {
        "value": "6214",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">6214</span> <span>精誠</span></div>",
        "link": "/stockAnalysis/6214",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 47,
          "value": 100,
          "text": "<div><span class=\"white\">2021/07/01</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 47,
          "value": 7.25,
          "text": "<div class=\"white\">7.25</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 47,
          "value": 88.4,
          "text": "<div class=\"white\">7.25</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 47,
          "value": 20,
          "text": "<div><span class=\"white\">預估7.25</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.55,
        "text": "<div><span class=\"red\">-</span></div>"
      },
      "DailyClose": {
        "value": 91.2,
        "text": "<div class=\"red\">▲ 調升7.25%</div>"
      },
      "DailyClose1": {
        "value": 91.2,
        "text": "<div><span class=\"white\">20</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "5209",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">5209</span> <span>新鼎</span></div>",
        "link": "/stockAnalysis/5209",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 47,
          "value": 100,
          "text": "<div><span class=\"white\">2021/07/01</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 47,
          "value": 0.31,
          "text": "<div class=\"white\">預估3.26</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 47,
          "value": 66.8,
          "text": "<div class=\"white\">預估3.26</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 47,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": -0.15,
        "text": "<div class=\"green\">▼ 調降3.26%</div>"
      },
      "DailyClose": {
        "value": 67.9,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 67.9,
        "text": "<div><span class=\"white\">5</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "3570",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">3570</span> <span>大塚</span></div>",
        "link": "/stockAnalysis/3570",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 47,
          "value": 100,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 47,
          "value": -0.17,
          "text": "<div class=\"white\">預估15.22</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 47,
          "value": 84.5,
          "text": "<div class=\"white\">預估15.22</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 47,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": -0.69,
        "text": "<div class=\"red\">▲ 調升15.22%</div>"
      },
      "DailyClose": {
        "value": 86.6,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 86.6,
        "text": "<div><span class=\"white\">26</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "5410",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">5410</span> <span>國眾</span></div>",
        "link": "/stockAnalysis/5410",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 47,
          "value": 80,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 47,
          "value": -0.49,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 47,
          "value": 24.05,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 47,
          "value": 20,
          "text": "<div><span class=\"white\">2.21</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.62,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 24.5,
        "text": "<div class=\"red\">▲ 調升2.21%</div>"
      },
      "DailyClose1": {
        "value": 24.5,
        "text": "<div><span class=\"white\">30</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "6112",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">6112</span> <span>聚碩</span></div>",
        "link": "/stockAnalysis/6112",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 47,
          "value": 70,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 47,
          "value": -2.46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 47,
          "value": 46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 47,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.12,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 42.35,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 42.35,
        "text": "<div><span class=\"white\">25</span></div>"
      }
    }
  ]
  
export const processedData2 = [
    {
      "stock_code": {
        "value": "6214",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">6214</span> <span>精誠</span></div>",
        "link": "/stockAnalysis/6214",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 374,
          "value": 100,
          "text": "<div><span class=\"white\">2021/07/01</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 374,
          "value": 7.25,
          "text": "<div class=\"white\">7.25</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 374,
          "value": 88.4,
          "text": "<div class=\"white\">7.25</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 374,
          "value": 20,
          "text": "<div><span class=\"white\">預估7.25</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.55,
        "text": "<div><span class=\"red\">-</span></div>"
      },
      "DailyClose": {
        "value": 91.2,
        "text": "<div class=\"red\">▲ 調升7.25%</div>"
      },
      "DailyClose1": {
        "value": 91.2,
        "text": "<div><span class=\"white\">20</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "5209",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">5209</span> <span>新鼎</span></div>",
        "link": "/stockAnalysis/5209",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 374,
          "value": 100,
          "text": "<div><span class=\"white\">2021/07/01</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 374,
          "value": 0.31,
          "text": "<div class=\"white\">預估3.26</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 374,
          "value": 66.8,
          "text": "<div class=\"white\">預估3.26</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 374,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": -0.15,
        "text": "<div class=\"green\">▼ 調降3.26%</div>"
      },
      "DailyClose": {
        "value": 67.9,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 67.9,
        "text": "<div><span class=\"white\">5</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "3570",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">3570</span> <span>大塚</span></div>",
        "link": "/stockAnalysis/3570",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 374,
          "value": 100,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 374,
          "value": -0.17,
          "text": "<div class=\"white\">預估15.22</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 374,
          "value": 84.5,
          "text": "<div class=\"white\">預估15.22</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 374,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": -0.69,
        "text": "<div class=\"red\">▲ 調升15.22%</div>"
      },
      "DailyClose": {
        "value": 86.6,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 86.6,
        "text": "<div><span class=\"white\">26</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "5410",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">5410</span> <span>國眾</span></div>",
        "link": "/stockAnalysis/5410",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 374,
          "value": 80,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 374,
          "value": -0.49,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 374,
          "value": 24.05,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 374,
          "value": 20,
          "text": "<div><span class=\"white\">2.21</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.62,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 24.5,
        "text": "<div class=\"red\">▲ 調升2.21%</div>"
      },
      "DailyClose1": {
        "value": 24.5,
        "text": "<div><span class=\"white\">30</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "6112",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">6112</span> <span>聚碩</span></div>",
        "link": "/stockAnalysis/6112",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 374,
          "value": 70,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 374,
          "value": -2.46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 374,
          "value": 46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 374,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.12,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 42.35,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 42.35,
        "text": "<div><span class=\"white\">25</span></div>"
      }
    }
  ]



export const processedData3 = [
    {
      "stock_code": {
        "value": "5410",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">5410</span> <span>國眾</span></div>",
        "link": "/stockAnalysis/5410",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 377,
          "value": 80,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 377,
          "value": -0.49,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 377,
          "value": 24.05,
          "text": "<div class=\"white\">2.21</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 377,
          "value": 20,
          "text": "<div><span class=\"white\">2.21</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.62,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 24.5,
        "text": "<div class=\"red\">▲ 調升2.21%</div>"
      },
      "DailyClose1": {
        "value": 24.5,
        "text": "<div><span class=\"white\">30</span></div>"
      }
    },
    {
      "stock_code": {
        "value": "6112",
        "tracked": true,
        "text": "<div><span class=\"smaller-font\">6112</span> <span>聚碩</span></div>",
        "link": "/stockAnalysis/6112",
        "linkTarget": "_self"
      },
      "Score": [
        {
          "tab_id": 377,
          "value": 70,
          "text": "<div><span class=\"white\">2021/06/29</span></div>"
        }
      ],
      "ror": [
        {
          "tab_id": 377,
          "value": -2.46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "cost": [
        {
          "tab_id": 377,
          "value": 46,
          "text": "<div class=\"white\">預估0.56</div>"
        }
      ],
      "weight": [
        {
          "tab_id": 377,
          "value": 20,
          "text": "<div><span class=\"white\">-</span></div>"
        }
    ],
    "DailyPriceChange_Percent": {
        "value": 0.12,
        "text": "<div class=\"red\">-</div>"
      },
      "DailyClose": {
        "value": 42.35,
        "text": "<div><span class=\"green\">-</span></div>"
      },
      "DailyClose1": {
        "value": 42.35,
        "text": "<div><span class=\"white\">25</span></div>"
      }
    }
  ]
  
export const data =  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    //
    processedData,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    // 374
    [...processedData2,...processedData2, ...processedData2],
    null,
    null,
    [...processedData3]
  ]