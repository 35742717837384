import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'Api/configs';
import { FETCH_CONFIGS, SAVE_CONFIGS } from './actionTypes/configs';
import { SET_CONFIGS, UPDATE_CONFIGS } from '../actionTypes/configs';

function* _fetchConfigs() {
    const result = yield call(() => Api('configs'));

    if (!result) { return; }

    yield put({ type: SET_CONFIGS, payload: result });
}

function* _saveConfigs(action) {
    const formData = new FormData();
    formData.append('name', action.payload.name);
    formData.append('data', action.payload.data);

    const result = yield call(() => Api('setConfigs', { formData }));

    if (!result) { return; }

    yield put({ type: UPDATE_CONFIGS, payload: result });
}

function* fetchConfigs() {
    yield takeLatest(FETCH_CONFIGS, _fetchConfigs);
}

function* saveConfigs() {
    yield takeLatest(SAVE_CONFIGS, _saveConfigs);
}

export default { fetchConfigs, saveConfigs };