import React, { useState } from 'react';
import Component from './component';
import { getData2 } from "Util/table";

function Container({
    columnList, 
    data,
    height,
    rowHeight,
    page,
    rowDataToContentsByColumnId,
    currentTab,
    headerHeight
})
{
    const [sort, setSort] = useState({
        sortColumn: '',
        sortType: ''
    })
    
    
    
    const onSortColumn = (sortColumn, sortType) => {
        setSort({
            sortColumn: sortColumn,
            sortType: sortType
        })
    }
    return <Component 
        className="table"
        // autoHeight={listAutoHeight}
        height={height}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        columnList={columnList}
        data={
            getData2(
                data,
                sort.sortColumn,
                sort.sortType,
                10,
                page
            )
        }
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={onSortColumn}
        rowDataToContentsByColumnId={rowDataToContentsByColumnId}
        currentTab={currentTab}
    />
}
export default Container;