import React from 'react';

import ListPanel from 'Components/listPanel';
import ValueHighlight from 'Components/valueHighlight';

import './style.scss';

const Component = props => {
    if (!props.data) { return <></>; }

    let rowCount = props.data?.data.length;
    let columnRowCount = Math.ceil(rowCount / 2);

    return (
        <div className="backtest-result-detail">
            <div className="highlight">
                {
                    props.data?.header.map(x =>
                        <ValueHighlight
                            highlight={x.highlight}
                            title={x.name}
                            value={x.data}
                            unit={x.unit} />
                    )
                }
            </div>
            <div className="detail">
                <div className="column">
                    <ListPanel
                        titleWidth={96}
                        data={props.data?.data.slice(0, columnRowCount)} />
                </div>
                <div className="column">
                    <ListPanel
                        titleWidth={144}
                        data={props.data?.data.slice(columnRowCount)} />
                </div>
            </div>
        </div>
    );
}

export default Component;