import React from 'react';
import { Button } from 'rsuite';

import ScoringProgressModal from 'Components/scoringProgressModal';
import StockResultPanel from 'Components/stockResultPanel';
import StrategyDesignStep from 'Components/strategyDesignStep';

import './style.scss';

const Component = ({
    id,
    showScoreResult,
    onPreviousStepClick,
    onBacktestClick,
    isScoringProgressModalOpen,
    scoringPercentage,
    scoringRemainTime,
    showStrategyOverview
}) => {
    return (
        <div className="strategy-score-page">
            <div className="page-title">投資策略設計工具</div>
            <hr />
            <div className="step-container">
                <StrategyDesignStep strategyId={id} scored={true} step={2} />
            </div>
            <div className="title">最新評分選股</div>
            <div className="block">
                {
                    showScoreResult &&
                    <StockResultPanel />
                }
            </div>
            <div className="button-container">
                <Button onClick={onPreviousStepClick}>上一步</Button>
                <Button onClick={onBacktestClick}>下一步：設定回測</Button>
            </div>
            <ScoringProgressModal
                isOpen={isScoringProgressModalOpen}
                percentage={scoringPercentage}
                remainTime={scoringRemainTime}
                showStrategyOverview={showStrategyOverview} />
        </div>
    );
};

export default Component;