import React from 'react';

import './style.scss';

const ListItem = (props) => {
    return (
        <div className="list-item">
            <div className="symbol">■</div>
            <div className="content">{props.children}</div>
        </div>
    );
}

export default ListItem;