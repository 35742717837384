import React, { Component } from 'react';
import {
    AutoComplete,
    Icon,
    InputGroup
} from 'rsuite';

import IndexLibraryModal from './indexLibraryModal';
import IndexUtil from '../indexUtil';

import './indexPicker.css';

var _ = require('lodash');

export default class IndexPicker extends Component {
    constructor(props) {
        super(props);

        this.indexLibrary = props.indexLibrary;

        let autoCompleteList = [];
        if (props && props.disableInput) {
            autoCompleteList =
                this.generateAutoCompleteList(this.props.indexLibrary);
        }

        let text = '';
        if (props && !props.clearOnSelect) {
            text =
                !this.props.useGraphText ?
                    IndexUtil.getIndexText(props.value) :
                    IndexUtil.getGraphIndexText(props.value);
        }

        this.state = {
            text: text,
            autoCompleteList: autoCompleteList,
            index: props.value,
            show: false
        };
    }

    componentDidUpdate() {
        if (!this.props) { return; }

        if (!_.isEqual(this.props.value, this.state.index)) {
            this.setState({
                text:
                    !this.props.useGraphText ?
                        IndexUtil.getIndexText(this.props.value) :
                        IndexUtil.getGraphIndexText(this.props.value),
                index: this.props.value
            });
        }

        if (!_.isEqual(this.indexLibrary, this.props.indexLibrary)) {
            this.indexLibrary = this.props.indexLibrary;

            if (this.props.disableInput) {
                let autoCompleteList =
                    this.generateAutoCompleteList(this.indexLibrary);

                this.setState({ autoCompleteList: autoCompleteList });
            }
        }
    }

    generateAutoCompleteList(indexLibrary) {
        // console.log('indexLibrary');
        // console.log(indexLibrary);
        // Loop through the index library
        let indexList = [];

        indexLibrary.forEach(category => {
            category.children.forEach(index => {
                let rangeList = [];

                // Merge the single-value and multi-value ranges
                index.ranges.forEach(category =>
                    category.children.forEach(range =>
                        rangeList.push(range)));

                rangeList.filter(range => !range.input).forEach(range => {
                    indexList.push({
                        dataType: {},
                        range: { value: range.value },
                        time: { value: 'latest' },
                        value: index,
                        keywords: index.keywords
                    });
                });
            });
        });
        // console.log(indexList);
        // Return AutoComplete required structure array
        return indexList.map((index, i) => {
            let name =
                !this.props.useGraphText ?
                    IndexUtil.getIndexText(index) :
                    IndexUtil.getGraphIndexText(index);
            return {
                value: name+i,
                label: name,
                index: index,
                keywords: index.keywords
            };
        });
    }

    onTextChange(value) {
        if (this.props && !this.props.disableInput) { return; }

        this.setState({ text: value });
    }

    onAutoCompleteSelect(item) {
        let index = item.index;

        this.setState({ index: index });

        if (this.props && this.props.clearOnSelect) {
            // Workaround: Have to set the text empty after AutoComplete has set
            // its value
            setTimeout(() => this.setState({ text: '' }));
        }

        if (!this.props.onChange) { return; }
        this.props.onChange(index);
    }

    filterBy(value, item) {
        return (
            item.label.includes(value) ||
            item.index.keywords.some(x => x.includes(value))
        ) && value !== "";
    }

    show() {
        this.setState({ show: true });
    }

    onIndexSelect(index) {
        let text = '';
        if (this.props && !this.props.clearOnSelect) {
            text =
                !this.props.useGraphText ?
                    IndexUtil.getIndexText(index) :
                    IndexUtil.getGraphIndexText(index);
        }

        this.setState({
            text: text,
            index: index,
            show: false
        });

        if (!this.props.onChange) { return; }
        this.props.onChange(index);
    }

    onIndexModalCancel() {
        this.setState({ show: false });
    }

    render() {
        // console.log('this.state.autoCompleteList');
        // console.log(this.state.autoCompleteList);
        let className = 'index-picker';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        return (
            <div className={className} style={this.props.style}>
                <InputGroup inside>
                    <AutoComplete
                        placeholder="請選擇指標"
                        data={this.state.autoCompleteList}
                        value={this.state.text}
                        onChange={value => this.onTextChange(value)}
                        onSelect={item => this.onAutoCompleteSelect(item)}
                        filterBy={(value, item) => this.filterBy(value, item)} />
                    <InputGroup.Button
                        className="index-button"
                        onClick={() => this.show()}>
                        <Icon icon="search" /> 瀏覽指標庫
                    </InputGroup.Button>
                </InputGroup>

                <IndexLibraryModal
                    hideTime={this.props.hideTime}
                    disableInput={this.props.disableInput}
                    excludeMultiValue={this.props.excludeMultiValue}
                    show={this.state.show}
                    data={this.indexLibrary}
                    selectedIndex={this.state.index}
                    onClose={index => this.onIndexSelect(index)}
                    onCancel={() => this.onIndexModalCancel()} />
            </div>
        );
    }
}