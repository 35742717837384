import React, { useEffect, useState } from "react"
import DashboardManagerModal from "../dashboardManagerModal";
import { useDispatch } from "react-redux";
import { DELETE_DASHBOARD } from "Redux/saga/actionTypes/dashboard"

/** 
 * @param {booloean} show - 是否視窗顯示
 * @param {number} id - 開啟時的 dashboard id
 * @param {object} singles - 可供使用的singles
 * @param {function} onHide - 關閉視窗
 * @param {function} onConfirm - 確認
 * @memberof module:DashboardManagerModal
 * @summary 戰情室管理員, 資料處理、非同步處理發動。
 * @description 以show開啟後先用props設定一次id跟layout，使之內容與dashboardPage選擇的一致。
 * @returns {ReactElement}
 */

function Container({
    show,
    dashboardList,
    id,
    singles,
    onHide,
    onConfirm,
}){
    const dispatch = useDispatch();
    const [state, setState] = useState({
        id: '',
        layout: []
    })

    useEffect(() => {
        const dashboard = dashboardList ? dashboardList.find(dashboard => dashboard.id === id) : null;
        const layout = dashboard ? dashboard.layout : [];
        setState({
            id: id,
            layout: layout
        })
    }, [dashboardList, id, show])

    /** 
     * selectDashboard
     * @param {object} e - event物件
     * @description 設定此元件狀態: 被選擇的dashboard 
     */
    const selectDashboard = e => {
        const id = Number(e.target.dataset.id);
        const dashboard =
            dashboardList.find(dashboard => dashboard.id === id);
        const layout = dashboard ? dashboard.layout : [];
        setState({ 
            ...state,
            id: id, layout: layout
        });
    }


    /**
     * @param {object} e - event物件
     * @description 刪除dashboard
     * @fires DELETE_DASHBOARD
     */
    const removeDashboard = e => {
        const id = e.currentTarget.dataset.id
        const name = e.currentTarget.dataset.name
        if (!window.confirm('確認刪除戰情室「' + name + '」?')) { return; }
        dispatch({ type: DELETE_DASHBOARD, id: id })
    }
    
    return <DashboardManagerModal 
        show={show}
        dashboardList={dashboardList}
        id={state.id}
        layout={state.layout}
        selectDashboard={selectDashboard}
        singles={singles}
        onHide={onHide}
        onConfirm={onConfirm}
        removeDashboard={removeDashboard}
    />
}

export default Container;