import React, { useEffect } from 'react';
import Component from "./component"
import { useSelector } from "react-redux";
import { getStockPrice, clearStockPrice } from "Redux/actionCreators/stock"
import './style.scss'
// import { deepEqual } from "Util/deepEqual"

function Container({ stockCode })
{
    // console.log('render-123');
    const stock = useSelector(store => store.stock.stockPrice)
    // const location = useLocation()
    useEffect(() => {
        getStockPrice(stockCode)
        return () => {
            clearStockPrice();
        }
        /* eslint-disable-next-line */
    },[stockCode])
    let color = '';
    let changeText =  ""
    let changePercentText = ""
    let priceChange = ""
    if(stock)
    {
        changeText = stock.DailyPriceChange.data.toString();
        changePercentText = stock.DailyPriceChange_Percent.data + '%';
        priceChange = parseFloat(stock.DailyPriceChange.data);
        if (priceChange > 0) {
            color = ' red';
            changeText = '▲' + changeText;
            changePercentText = '+' + changePercentText;
        } else if (priceChange < 0) {
            color = ' green';
            changeText = '▼' + changeText.substr(1); // Strip off the minus sign
        }
    }
    
    return <Component 
        stock={stock}
        color={color}
        changeText={changeText}
        changePercentText={changePercentText}
    />
}

function areEqual(prevProps, nextProps)
{
    if(
        prevProps.stockCode !== nextProps.stockCode
    )
    {
        return false
    }else {
        return  true
    }
}
export default React.memo(Container, areEqual);