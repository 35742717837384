import store from '../store'
import _ from "lodash"
import {
    TRACK_STOCK,
    ADD_FAVORITE_GROUP,
    FETCH_PANELDATA,
    REMOTE_DELETE_FAVORITE_GROUP,
    REMOTE_CLEAR_FAVORITE_GROUP,
    RENAME_FAVORITE_GROUP,
    FETCH_ANALYSIS_LIST,
    FETCH_STOCK_PRICE,
} from '../saga/actionTypes/stock';
import { CLEAR_STOCK_PRICE } from "../actionTypes/stock"

export function getAnalysisList(){
    store.dispatch({
        type: FETCH_ANALYSIS_LIST
    })
}

export function getStockPrice(stockCode){
    store.dispatch({
        type: FETCH_STOCK_PRICE,
        id: stockCode
    })
}

export function clearStockPrice(){
    store.dispatch({
        type: CLEAR_STOCK_PRICE
    })
}

export function track(groupId, stockId, tracking){
    store.dispatch({
        type: TRACK_STOCK,
        groupId: groupId,
        stockId: stockId,
        tracking: tracking
    })
}

export function addFavoriteGroup(name, afterGroupId=null){
    let formData = new FormData();
    formData.append('name', name);
    if (afterGroupId) { formData.append('after_group_id', afterGroupId); }
    store.dispatch({
        type: ADD_FAVORITE_GROUP,
        afterGroupId: afterGroupId,
        formData: formData
    })
}

export function deleteFavoriteGroup(id){
    store.dispatch({
        type: REMOTE_DELETE_FAVORITE_GROUP,
        id: id,
    })
}

export function renameFavoriteGroup(id, name) {
    store.dispatch({
        type: RENAME_FAVORITE_GROUP,
        id: id,
        name: name
    });
}

export function getPanelData(panel ,parameters)
{
    let params = _.cloneDeep(parameters);
    if (!params) { params = {}; }
    params.single = panel.id;
    store.dispatch({
        type: FETCH_PANELDATA,
        id: panel.id,
        params: {
            id: panel.model,
            single: panel.id
        }
    })    
}

export function clearFavoriteGroup(groupId, panelId){
    store.dispatch({
        type: REMOTE_CLEAR_FAVORITE_GROUP,
        groupId: groupId,
        panelId: panelId
    })
}