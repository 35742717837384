import React, { useState } from 'react';
import Layout from "../component";

import history from 'Src/history';

/* eslint-disable */
export default function Container({ Routes }) {
	const [showBackground, setShowBackground] =
		useState(history.location.pathname.startsWith('/login'));

	history.listen(location => {
		setShowBackground(location.pathname.startsWith('/login'));
	});

	return <Layout Routes={Routes} showBackground={showBackground} />;
}