import { singleConfigSeriesDataGather, configByIndexSeriesDataGather, configByStockSeriesDataGather } from "./data";
import { graphOption as defaultGraphOption } from "../constants";


const graphOptionPreProccess = (defaultGraphOption, state) => {
    let graphOption = { ...defaultGraphOption };
    graphOption.xAxis[0].floor = state.startDate ? state.startDate.getTime() : undefined;
    graphOption.xAxis[0].ceiling = state.endDate ? state.endDate.getTime() : undefined;
    if(state.graphStart !== 'normal')
    {
        graphOption.tooltip.pointFormat = '{series.name}: <b>{point.y}</b> ({point.change}%)'
    }
    return graphOption
}

export const graphConfigProccess = {
    one: (rawData, state) => {
        const graphOption = graphOptionPreProccess(defaultGraphOption, state);
        
        return singleConfigSeriesDataGather(rawData, state, graphOption);
    },
    byStock: (rawData, state) => {
        const graphOption = graphOptionPreProccess(defaultGraphOption, state);
        return configByStockSeriesDataGather(rawData, state, graphOption)
        
        
    },
    byIndex: (rawData, state) => {
        const graphOption = graphOptionPreProccess(defaultGraphOption, state);
        return configByIndexSeriesDataGather(rawData, state, graphOption)
    }
};