import React from 'react';

import './style.scss';

const Component = () => {
    return (
        <div className="change-log-page">
            <div className="page-title">更新日誌</div>
            <hr />
            <div>
                <div className="item">
                    <div className="date">2022/02/28</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">2.0.0版</div>
                        <div className="detail">
                            鉅亨易策略正式版上線<br />
                            增加：知名策略增加近期表現優秀排行、及精選策略<br />
                            增加：建立策略&gt;評分，增加各分數區段分佈<br />
                            增加：建立策略增加簡易教學<br />
                            優化：建立策略步驟流程頁面優化<br />
                            優化：失敗訊息提示優化<br />
                            以及其他細部調整.....
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="date">2022/02/20</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">1.0.5 BETA版</div>
                        <div className="detail">
                            增加：個人資訊、訂閱資訊顯示<br />
                            增加：分享回測報告時可隱藏篩選及評分條件<br />
                            修正：右欄股票清單排序修正<br />
                            修正：圖表大師指標顯示問題<br />
                            以及其他細部調整.....<br />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="date">2022/01/19</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">1.0.4 BETA版</div>
                        <div className="detail">
                            增加：我的策略/回測&gt;增加賺賠比<br />
                            增加：建立策略&gt;可回測單支個股報酬率<br />
                            增加：建立策略&gt;增加多種百分位排名指標<br />
                            增加：設定回測&gt;增加進階設定<br />
                            增加：設定回測&gt;可設定回測起始結束日期<br />
                            修正：數據大師&gt;加權指數顯示問題<br />
                            修正：建立策略&gt;股票清單排序功能修復<br />
                            優化：建立策略&gt;預設收盤價&gt;0<br />
                            優化：建立策略&gt;欄位輸入不完全檢查<br />
                            優化：我的策略/回測&gt;追蹤策略&gt;增加失敗時提示<br />
                            優化：右欄股票排序調整為，依回測優先買賣順序排序<br />
                            以及其他細部調整.....
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="date">2021/12/31</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">1.0.3 BETA版</div>
                        <div className="detail">
                            增加：建立策略&gt;評分運算時顯示進度條<br />
                            增加：建立策略&gt;增加回測期間顯示<br />
                            增加：建立策略&gt;增加多種百分位排名條件<br />
                            增加：建立策略&gt;增加進階運算，可乘以%數或加減<br />
                            修正：我的戰情室&gt;追蹤的策略&gt;買入後報酬率排序問題修正<br />
                            修正：加到我的最愛BUG<br />
                            優化：建立策略&gt;運算方法以顏色區別<br />
                            以及其他細部調整.....
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="date">2021/12/06</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">1.0.2 BETA版</div>
                        <div className="detail">
                            增加：使用秘訣頁面。<br />
                            增加：建立策略&gt;選擇指標&gt;增加第4步進階運算法。<br />
                            增加：知名策略&gt;策略年化報酬率、及策略累績報酬率。<br />
                            增加：儀表版&gt;追蹤的策略套件&gt;增加年化報酬率累績報酬率。<br />
                            修正：回測報告顯示上的BUG。<br />
                            修正：儀表版&gt;追蹤的策略套件&gt;修正換股日期顯示BUG。<br />
                            修正：漲跌顏色顯示BUG。<br />
                            修正：策略評分&gt;切換策略時會黑屏的BUG。<br />
                            修正：追蹤策略失敗的問題。<br />
                            優化：登入體驗。<br />
                            優化：評分運算時的操作流程。<br />
                            優化：回測報告顯示速度。<br />
                            優化：回測排隊功能。<br />
                            以及其他細部調整.....
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="date">2021/11/30</div>
                    <div className="dot" />
                    <div className="content">
                        <div className="version">1.0.1 BETA版</div>
                        <div className="detail">
                            鉅亨易策略BETA版正式上線
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Component;