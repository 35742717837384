import React from 'react';
import { Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

function Component({
    columnList,
    data,
    height,
    rowHeight,
    sortColumn,
    sortType,
    headerHeight,
    rowDataToContentsByColumnId,
    currentTab,
    onSortColumn
})
{
    return <Table
    // className="table"
    // autoHeight={listAutoHeight}
    height={height}
    headerHeight={headerHeight}
    rowHeight={rowHeight}
    data={data}
    sortColumn={sortColumn}
    sortType={sortType}
    onSortColumn={onSortColumn}
>
    {
        columnList.map((column, i) => {
            return <Column
                    key={column.id}
                    width={column.width}
                    sortable={true}
                    fixed={i === 0}
                    align={i === 0 ? 'left' : 'right'}
                    verticalAlign="middle"
                    >
                        <HeaderCell>
                            {
                                sortColumn === column.id ? <div className="blue">{column.name}</div> : column.name
                            }
                        </HeaderCell>
                        <Cell dataKey={column.id}>
                            {
                                rowData => {
                                    return rowDataToContentsByColumnId(column.id, rowData, currentTab)
                                }
                            }
                        </Cell>
                </Column>
        })
    }

</Table>
}
export default Component;