import DashboardChart from 'Components/dashboardChart/index';
import DisplayPanel from 'Components/displayPanel/index';
import DoubleTable from 'Components/doubleTable/index';
import FavoriteList from 'Components/favoriteList/index';
import IndexChart from 'Components/indexChart/index';
import KLineGraph from 'Components/kLineGraph/index';
import NeedLogInPanel from '../../panels/needLoginPanel';
import PaginationTable from 'Components/paginationTable/index';
import StrategyList from 'Components/dashboard/strategyList/index';
import StrategyScorePanel from 'Components/strategyScorePanel/index';
import TableChart from 'Components/tableChart';

// Mappings from panel type to component
export default {
    chart: DashboardChart,
    display: DisplayPanel,
    doubleTable: DoubleTable,
    favoriteList: FavoriteList,
    indexChart: IndexChart,
    needLogIn: NeedLogInPanel,
    score: StrategyScorePanel,
    stockChart: KLineGraph,
    strategyList: StrategyList,
    table: PaginationTable,
    tableChart: TableChart
};