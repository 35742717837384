import * as StrategyOverviewPageActions from '../actionTypes/strategy/strategyOverviewPage';
import store from '../store';



 /**
     * 打開命名策略視窗
     * @param {string} id - 策略id
     * @param {string} name - 選到的策略名稱
     */

 export function showRenameModal(e){
    const obj = {
        type: StrategyOverviewPageActions.SHOW_RENAME_MODAL,
        renameModalId: e.currentTarget.dataset.id,
        renameModalName: e.currentTarget.dataset.name,
    }
    store.dispatch(obj);
}