import moment from 'moment';
import ColorUtil from 'Src/colorUtil';
import { getXAxisFormatter, getYAxisList } from "Util/highchart"
import { graphOption, colorList } from "./constants"
import _ from 'lodash'

const getPeriodType = (chartData) => {
    let periodType = 'day';

    if (chartData.some(series => series.period_type === 'month')) {
        periodType = 'month';
    } else if (chartData.some(series => series.period_type === 'quarter')) {
        periodType = 'quarter';
    } else if (chartData.some(series => series.period_type === 'year')) {
        periodType = 'year';
    }

    return periodType;
}

export const getTableData = (panelData, periodTabs) => {

    if(panelData)
    {
        const chartData = panelData.rawData.main.data;
        let chart =
            periodTabs?.currentTab ?
                chartData[0].filter(o =>
                    o.showIn.includes(periodTabs.currentTab)) :
                chartData[0];

        let periodType = getPeriodType(chart);

         // Generate the date list
         let dateList = [];
         chart.forEach(x => dateList = [...dateList, ...Object.keys(x.data)]);
    
         dateList = [...new Set(dateList)];
         dateList = dateList.sort((a, b) => moment(a).diff(moment(b)));
    
         // Prepare series
         let seriesData =
             chart.map(x => ({ name: x.name + '(' + x.unit + ')', ...x.data }));
    
         let columnList =
             dateList.map(x =>
                 ({ id: x, name: getXAxisFormatter(periodType, x) }));
         columnList = [{ id: 'name', name: '' }, ...columnList];

        //  console.log('seriesData');
        //  console.log(seriesData);
        //  console.log('columnList');
        //  console.log(columnList);
         return {
             seriesData: seriesData,
             columnList: columnList
         }
    }
}





export const getChartData = (panelData, periodTabs, timeRangeTabs) => {
    // console.log('getChartdata');
    if(panelData)
    {
        const chartData = panelData.rawData.main.data;
        let chart =
            periodTabs?.currentTab ?
                chartData[0].filter(o =>
                    o.showIn.includes(periodTabs.currentTab)) :
                chartData[0];

        let periodType = getPeriodType(chart);

        chart.forEach(series => {
            if (!series.period_type || series.period_type === 'day') {
                return;
            }

            let dateList = Object.keys(series.data);
            let newData = {};

            dateList.forEach(date => {
                if (!series.period_type) { return; }

                let adjustedDate = moment(date).startOf(series.period_type);
                let adjustedDateStr = adjustedDate.format('YYYY-MM-DD');

                newData[adjustedDateStr] = series.data[date];
            });

            series.data = newData;
        });

        let colorIndex =
            chart.some(series => series.chart_type === 'column') ? 0 : 1;
        let option = _.cloneDeep(graphOption);
    
        if (chart[0] && chart[0].showText) {
            let chartName = chart[0].name;
    
            // Two line comparison
            if (
                chart.length === 2 &&
                chart[0].chart_type === 'line' &&
                chart[1].chart_type === 'line' && chart[1].dashLine
            ) {
                chartName = chart[0].name + ' vs ' + chart[1].name;
            }
    
            option.title.text = chartName;
            option.subtitle.text = chart[0].text;
            option.subtitle.style.color = chart[0].fontColor;
        }
    
        option.series = [];
        option.periodType = periodType;
        option.xAxis[0].labels.formatter = function () {
            return getXAxisFormatter(periodType, this.value);
        };
    
        let tickInterval = 0;
        const dayTotalMs = 1000 * 60 * 60 * 24;
        switch (periodType) {
            case 'year':
                tickInterval = dayTotalMs * 365;
                break;
            case 'quarter':
                tickInterval = dayTotalMs * 90;
                break;
            case 'month':
                tickInterval = dayTotalMs * 30;
                break;
            default:
                tickInterval = dayTotalMs;
        }
        option.xAxis[0].tickInterval = tickInterval;
    
        option.yAxis = getYAxisList(chart);
    
        if (chart[0] && chart[0].showLegend === false) {
            option.legend.enabled = false;
            option.chart.marginTop = 1;
        }
    
        chart.forEach(series => {
            let dateList = Object.keys(series.data);
            // Filter the date by time range picker's value
            if (timeRangeTabs && timeRangeTabs.currentTab !== 'all') {
                let splited = timeRangeTabs.currentTab.split('_');
                let num = parseInt(splited[0]);
                let startDate =
                    moment().startOf('day').subtract(num, splited[1]);
    
                dateList =
                    dateList.filter(date => moment(date).isAfter(startDate));
            }
    
            let seriesData =
                dateList.map(date => [Date.parse(date), series.data[date]]);
            let color = colorList[colorIndex % colorList.length];
    
            let seriesObject = {
                name: series.name,
                type: series.chart_type,
                data: seriesData,
                color: color,
                yAxis:
                    !(series.chart_option && series.chart_option.stack) ? series.unit : 0
            };
    
            if (
                series.chart_type === 'column' &&
                !(series.chart_option && series.chart_option.stack)
            ) {
                seriesObject.color = ColorUtil.hexToRgba(color, 0.4);
                seriesObject.borderColor = ColorUtil.hexToRgba(color, 0.9);
                seriesObject.borderWidth = 1;
            }
    
            if (series.chart_option && series.chart_option.stack) {
                seriesObject.stack = 0;
                seriesObject.stacking = 'normal';
            }
    
            if (series.dashLine) {
                seriesObject.dashStyle = 'dash';
            }
    
            option.series.push(seriesObject);
    
            if (series.showYAxis === undefined || series.showYAxis) {
                option.chart.marginRight = 100;
            }
    
            colorIndex++;
        });
        return option;
    }
}