import React from 'react';
import { Icon, Table } from 'rsuite';
import PerformanceGraph from 'Components/graph/performanceGraph';
import TrackStockToggle from 'Components/trackStockToggle/index';
import Subtitle from './components/subtitle.jsx'
import TabSelector from 'Components/tabSelector';
import './style/strategyList.css';
import './style/style.scss';
import Arrow from 'Image/svgIcon/arrow-1.svg'
import Empty from './components/empty'
import { isEqual } from "lodash"
import AppPagination from "Components/pagination";
const { Column, HeaderCell, Cell } = Table;
const headerHeight = 40;
const rowHeight = 36;
// const defaultDisplayLength = 6;

/**
 * @memberof module:StrategyList
 * @param {*} currentTab - 當前分頁 
 * @param {*} setCurrentTab - 設定當前頁面 
 * @param {array} tabList - 策略列表
 * @param {object} display - 元件上方當前策略資料 
 * @param {boolean} showGraph - 顯示圖表與否 
 * @param {function} onToggleGraphClick - 圖表開關
 * @param {number} page - 當前頁數 
 * @param {objecj} pages - 所有頁數
 * @param {function} onChangePage - 換頁 
 * @param {object} graphChartData - 圖表資料
 * @param {number} graphHeight - 圖表高度
 * @param {number} listHeight - 列表高度 
 * @param {number} listData - 列表資料 
 * @param {string} sortColumn - 作為排序標準的欄位 
 * @param {string} sortType - 排序方式(升降冪)
 * @param {function} sortColumnData - 排序方法函式
 * @param {array} columnList - 欄位
 * @description 介面
 * @returns {ReactElement}
 */

function StrategyList({
    id,
    currentTab,
    setCurrentTab,
    tabList,
    trackingStockLoaded,
    display,
    showGraph,
    onToggleGraphClick,
    graphChartData,
    graphHeight,
    listAutoHeight,
    listHeight,
    listData,
    sortColumn,
    sortType,
    sortColumnData,
    columnList,
    currentTabData,
    yearReturnRate,
    returnRateAcc,
    indexYearReturnRate,
    indexReturnRateAcc,
    backYear
})
{
    // console.log('tabList');
    // console.log(tabList);
    // console.log('listData');
    // console.log(listData);
    // console.log('columnList');
    // console.log(columnList);
    // 判斷有沒有追蹤
    return display && display.length ? <div className="strategy-list">
        <TabSelector
            enableDropdown
            tabs={tabList}
            currentTab={currentTab}
            onTabChange={setCurrentTab} />
        <div>
            <Subtitle display={display} tabId={currentTab} />
            <div className="strategy_list__switch">
                <button onClick={onToggleGraphClick}>
                    <div>
                        <Icon className={showGraph ? '' : 'open'} icon={Arrow} />
                    </div>
                    <div>
                        <span>
                            {showGraph ? '收合報酬率走勢' : '展開報酬率走勢'}
                        </span>
                    </div>
                </button>
            </div>
        </div>
        <div className = "strategy_list__contents">
            {
                // 等loading
                (trackingStockLoaded && currentTab) ? <div className="container">
                    <Table
                        className="table"
                        autoHeight={listAutoHeight}
                        height={listHeight}
                        headerHeight={headerHeight}
                        rowHeight={rowHeight}
                        data={listData}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={sortColumnData}
                    >
                    {
                        columnList.map((column, i) =>
                            <Column
                                key={column.id}
                                width={column.width}
                                sortable={true}
                                fixed={i === 0}
                                align={i === 0 ? 'left' : 'center'}
                                verticalAlign="middle"
                            >
                                <HeaderCell>
                                    {
                                        sortColumn === column.id ?
                                            <div className="blue">{column.name}</div> :
                                            column.name
                                    }
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        rowData => {
                                            let cellData =
                                                column.id !== 'Score' && column.id !== 'ScoreDate' &&
                                                    column.id !== 'ror' && column.id !== 'cost' && column.id !== 'weight' ?
                                                    rowData[column.id] :
                                                    rowData[column.id].find(row => row.tab_id === currentTab);
                                            let content =
                                                cellData.text ?
                                                    <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
                                                    cellData.value
                                            let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

                                            if (cellData.link) {
                                                content =
                                                    <a href={cellData.link}
                                                        target={target}
                                                        rel="noopener noreferrer">
                                                        {content}
                                                    </a>;
                                            }

                                            if (cellData.tracked !== undefined) {
                                                content =
                                                    <div style={{ display: 'flex' }}>
                                                        <TrackStockToggle 
                                                            id={cellData.value}
                                                        />
                                                        {content}
                                                    </div>;
                                            }

                                            return content;
                                        }
                                    }
                                </Cell>
                            </Column>
                        )
                    }
                </Table>
                {
                    showGraph && <div className="graph">
                        <PerformanceGraph
                            height={graphHeight}
                            data={graphChartData}
                            yearReturnRate={yearReturnRate}
                            returnRateAcc={returnRateAcc}
                            indexYearReturnRate={indexYearReturnRate}
                            indexReturnRateAcc={indexReturnRateAcc}
                            backYear={backYear}
                        />
                    </div>
                }
                </div>: null
            }
        </div>
        <div className = "strategy_list__pagination">
            <AppPagination
                id={id}
                list={currentTabData}
                maxButtons={10}
                displayLength={10}
            />
        </div>
    </div>: <Empty />
}


function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.tabList, nextProps.tabList) ||
        !isEqual(prevProps.listData, nextProps.listData) ||
        prevProps.showGraph !== nextProps.showGraph ||
        prevProps.currentTab !== nextProps.currentTab ||
        prevProps.page !== nextProps.page ||
        prevProps.sortType !== nextProps.sortType ||
        prevProps.sortColumn !== nextProps.sortColumn ||
        prevProps.trackingStockLoaded !== nextProps.trackingStockLoaded ||
        prevProps.yearReturnRate !== nextProps.yearReturnRate ||
        prevProps.returnRateAcc !== nextProps.returnRateAcc ||
        prevProps.backYear !== nextProps.backYear
    )
    {
        return false
    } else {
        return true
    }
}

export default React.memo(StrategyList, areEqual);