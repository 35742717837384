import React from 'react';

import Expandable from 'Components/expandable';
import ListPanel from 'Components/listPanel';
import PerformanceGraph from 'Components/graph/performanceGraph';
import ValueHighlight from 'Components/valueHighlight';

import './style.scss';

const Component = props => {
    if (!props.data) { return <></>; }

    let rowCount = props.data?.data.length;
    let columnRowCount = Math.ceil(rowCount / 2);

    return (
        <div className="return-rate-panel">
            <div className="highlight">
                {
                    props.data?.header.map(x =>
                        <ValueHighlight
                            highlight={x.highlight}
                            title={x.name}
                            value={x.data}
                            unit={x.unit} />
                    )
                }
            </div>
            <PerformanceGraph data={props.performanceData?.main} />
            <Expandable expandText="策略詳情 ▼" collapseText="策略詳情 ▲">
                <div className="detail">
                    <div className="column">
                        <ListPanel
                            titleWidth={96}
                            data={props.data?.data.slice(0, columnRowCount)} />
                    </div>
                    <div className="column">
                        <ListPanel
                            titleWidth={144}
                            data={props.data?.data.slice(columnRowCount)} />
                    </div>
                </div>
            </Expandable>
        </div>
    );
}

export default Component;