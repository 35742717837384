import React, { useEffect, useState } from 'react';
import API from 'Src/api';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './style.scss';
import { useHistory, useParams } from 'react-router';
// import { getStockPrice } from "Redux/actionCreators/stock"
import Component from "./component.jsx"
import { useSelector, shallowEqual } from 'react-redux';
import { deepEqual } from "Util/deepEqual" 
export default function Container()
{
    const sidebarStrategyScoreId = useSelector(store => store.strategy.sidebarStrategyScoreId, shallowEqual)
    const strategyList = useSelector(store => store.strategy.list, deepEqual)
    const history = useHistory()
    const { id, analysisId, strategyId } = useParams()
    const [state, setState] = useState({
        singles: [],
        layout: [],
        parameters: {
            stock_code: id ? id: '1101'
        }
    })

    // Use passed in strategy id first, then the strategy id from the sidebar
    // If both are unspecified, use the id of the first strategy in list
    let path =
        '/stockAnalysis/' + (id !== 'null' ? id : '1101') + '/' +
        (analysisId ? analysisId : '2');

    if (strategyId) {
        path += '/' + strategyId;
    } else if (sidebarStrategyScoreId) {
        path += '/' + sidebarStrategyScoreId;
    } else if (strategyList && strategyList.length !== 0) {
        path += '/' + strategyList[0].id;
    }

    if (history.location.pathname !== path) {
        history.replace(path);
    }

    const analysisListSet = useSelector(store => store.stock.analysisListSet);
    const refKeyTableSet = useSelector(store => store.sidebar.refKeyTableSet, deepEqual);
    const getList = async () => {
        if(analysisListSet && refKeyTableSet)
        {
            let dashboard = await API.getStockAnalysis(analysisId);
            setState({
                singles: dashboard ? dashboard.singles : [],
                layout: dashboard ? dashboard.layout : []
            });
        }
    }


    const onStrategySelect = (_strategyId) => {
        // _strategyId is a Number, so convert it before comparing
        if (strategyId === _strategyId.toString()) { return; }

        const prevPath =
            '/stockAnalysis/' + id + '/' + analysisId + '/' + strategyId;
        let path =
            '/stockAnalysis/' + id + '/' + analysisId + '/' + _strategyId;

        const location = { pathname: path, prevPath: prevPath };
        history.push(location);
    }

    useEffect(() => {
        // alert('change')
        getList()
        /* eslint-disable-next-line */
    },[analysisListSet, refKeyTableSet, analysisId, strategyId, id])
    
    return <Component
                strategyId={strategyId*1}
                stockCode={id}
                singles={state.singles}
                layout={state.layout}
                parameters={state.parameters}
                analysisId={analysisId}
                strategyList={strategyList}
                onStrategySelect={onStrategySelect}
            />
}