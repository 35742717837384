import React, { Component } from 'react';
import { Icon, Popover, Whisper } from 'rsuite';
import { AutoSizer, Grid } from 'react-virtualized';

import CellRangeRenderer from './table/cellRangeRenderer';
import history from '../history';
import IndexUtil from '../indexUtil';
import TrackStockToggle from './trackStockToggle';

import './stockHoldingsTable.scss';

var _ = require('lodash');

export default class StockHoldingsTable extends Component {
    state = {
        columnList: [],
        data: [],
        selectedStock: null,
        expandDetail: false
    };

    constructor(props) {
        super(props);

        if (!props.data) { return; }

        this.state = {
            columnList: props.data.columns,
            data: props.data.data
        }
    }

    componentDidUpdate() {
        if (!this.props.data) { return; }

        if (!_.isEqual(this.props.data.columns, this.state.columnList)) {
            this.setState({ columnList: this.props.data.columns });
        }

        if (!_.isEqual(this.props.data.data, this.state.data)) {
            this.setState({ data: this.props.data.data });
        }
    }

    setSelectedStock(stock) {
        this.setState({ selectedStock: stock });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        if (!this.props.onTrackingStockChange) { return; }
        this.props.onTrackingStockChange(groupId, stockId, tracked);
    }

    onStockNameClick(stockCode) {
        const location = { pathname: '/stockAnalysis/' + stockCode };
        history.push(location);
    }

    toggleExpandDetail() {
        this.setState({ expandDetail: !this.state.expandDetail });
    }

    renderCell(rowData, column, style) {
        if (!rowData[column.id] || !rowData[column.id].name) {
            return <div className="cell" style={style}>{rowData[column.id]}</div>;
        }

        let stock = rowData[column.id];

        let cellContent = stock.name + ' ';

        // Round ROR to 1 decimal
        let ror = Math.round(stock.ror * 10) / 10;
        if (ror > 0) {
            cellContent =
                <span className="red">{cellContent + '+' + ror + '%'}</span>;
        } else if (ror < 0) {
            cellContent =
                <span className="green">{cellContent + ror + '%'}</span>;
        } else {
            cellContent = <span>{cellContent + '0%'}</span>;
        }

        let className =
            _.isEqual(stock, this.state.selectedStock) ?
                'cell selected-stock' : 'cell';

        return (
            <div className={className} style={style}>
                <Whisper
                    preventOverflow
                    placement="auto"
                    trigger="click"
                    speaker={this.popover}
                    onClick={() => this.setSelectedStock(stock)}
                    onExit={() => this.setSelectedStock(null)}>
                    {cellContent}
                </Whisper>
            </div>
        );
    }

    renderSpeaker(stock) {
        if (!stock || !stock.code || !stock.name) { return <div />; }

        let className = 'popover-stock-holdings';
        if (this.state.expandDetail) {
            className += ' expand';
        }

        let priceDiff = stock.priceDiff;
        if (priceDiff > 0) {
            priceDiff = <span className="red">{'+' + priceDiff}元</span>;
        } else if (priceDiff < 0) {
            priceDiff = <span className="green">{priceDiff}元</span>;
        }

        // Round to 2 decimal places
        let ror = stock.ror;
        if (ror > 0) {
            ror = <span className="red">{'+' + ror}%</span>;
        } else if (ror < 0) {
            ror = <span className="green">{ror}%</span>;
        } else {
            ror = '0%';
        }

        let arrowIcon = !this.state.expandDetail ? 'angle-down' : 'angle-up';

        return (
            <Popover className={className}>
                <div className="row bottom-line">
                    <TrackStockToggle
                        inPopover
                        style={{ marginRight: 10 }}
                        id={stock.code} />
                    <span
                        className="stock-name"
                        onClick={() => this.onStockNameClick(stock.code)}>
                        {stock.code + ' ' + stock.name}
                    </span>
                </div>
                <div className="row bottom-line column-container">
                    <div className="column">
                        <div>買進時投資策略評分：{stock.score}</div>
                        <div>持有期間：{stock.period}</div>
                        <div>價差：{priceDiff}</div>
                        <div>報酬率：{ror}</div>
                    </div>
                    <div className="column">
                        <div>買進時間：{stock.buyTime}</div>
                        <div>買進價位：{stock.buyPrice}元</div>
                        <div>賣出時間：{stock.sellTime}</div>
                        <div>賣出價位：{stock.sellPrice}元</div>
                    </div>
                </div>
                {
                    stock.categoryList &&
                    <div
                        className="row"
                        onClick={() => this.toggleExpandDetail()}>
                        <span className="expand-text blue">
                            {!this.state.expandDetail ? '顯示' : '收合'}
                            買進時評分細節
                            <Icon icon={arrowIcon} size="lg" />
                        </span>
                    </div>
                }
                {
                    !stock.categoryList &&
                    <div className="row">(分享者將評分條件設為不公開)</div>
                }
                {
                    this.state.expandDetail &&
                    <div className="detail">
                        {this.renderCategoryList(stock.categoryList)}
                    </div>
                }
            </Popover>
        );
    };

    renderCategoryList(categoryList) {
        if (!categoryList) { return <div />; }

        return (
            categoryList.map((category, i) =>
                <div key={'category-' + i} className="category">
                    <div className="category-name">{category.name}</div>
                    {
                        category.rowList.map((rule, j) =>
                            <div
                                key={'category-' + i + '-row-' + j}
                                className="rule">
                                {IndexUtil.getIndexRuleScore(rule)}
                            </div>
                        )
                    }
                </div>
            )
        )
    }

    cellRenderer({rowIndex, isVisible, key, columnIndex, style}) {
        if (!isVisible) { return; }

        let column = this.state.columnList[columnIndex];

        if (rowIndex === 0) {
            let splitPos = column.id === 'row' ? 2 : 11;

            return (
                <div
                    key={key}
                    className="cell header-cell"
                    style={style}>
                    <div>{column.name.substring(0, splitPos)}</div>
                    <div>{column.name.substring(splitPos)}</div>
                </div>
            );
        } else {
            let data = this.state.data[rowIndex - 1];

            return this.renderCell(data, column, style);
        }
    }

    render() {
        this.popover = this.renderSpeaker(this.state.selectedStock);

        const headerRowHeight = 66;
        const rowHeight = 46;

        const height = headerRowHeight + rowHeight * this.state.data.length;
        const rowCount = this.state.data.length + 1; // Add header row

        const fullWidth =
            this.state.columnList
                .map(x => x.width)
                .reduce((previous, current) => previous + current, 0);

        return (
            <AutoSizer disableHeight>
                {
                    ({ width }) => {
                        // 8px: scroll bar's height
                        const finalHeight =
                            fullWidth > width ? height + 8 : height;

                        return (
                            <Grid
                                className="stock-holdings-table"
                                columnCount={this.state.columnList.length}
                                columnWidth={({ index }) => this.state.columnList[index].width}
                                estimatedColumnSize={120}
                                height={finalHeight}
                                width={width}
                                cellRangeRenderer={props => CellRangeRenderer(props, 0, 0)}
                                cellRenderer={props => this.cellRenderer(props)}
                                rowCount={rowCount}
                                rowHeight={({ index }) => index === 0 ? headerRowHeight : rowHeight}
                                estimatedRowSize={rowHeight} />
                        );
                    }
                }
            </AutoSizer>
        );
    }
}