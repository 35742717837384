//
// Index library modal for data table
//
import moment from 'moment';
import React, { Component } from 'react';
import {
    Button,
    Icon,
    Input,
    InputGroup,
    Modal,
    Radio,
    RadioGroup,
    Tree
} from 'rsuite';

import API from '../../api';
import IconDelete from '../../image/svgIcon/delete-white.svg';
import IndexUtil from '../../indexUtil';

import './indexLibraryModalTable.css';

var _ = require('lodash');

const defaultIndex = { time: { value: 'latest', customValue: { value: 1 } } };

const defaultState = {
    rangeList: [],
    dataTypeList: [],
    indexSearchText: '',
    expandIndexCategory: [],
    groupList: [[]], // Stores column information returned from server,
    isOldToNew: true,
    selectedGroupIndex: 0,
    columnSearchText: '',
    showExcessColumnLimitHint: false
};

const ColumnLimit = 10;

export default class IndexLibraryModalTable extends Component {
    indexList = [_.cloneDeep(defaultIndex)]; // Stores selected indices

    constructor(props) {
        super(props);

        this.state = defaultState;
        this.indexList =
            props.selectedIndexList && props.selectedIndexList.length !== 0 ?
                _.cloneDeep(props.selectedIndexList) :
                [_.cloneDeep(defaultIndex)];

        if (props.selectedIndexList && props.selectedIndexList.length !== 0) {
            this.state.groupList =
                props.selectedIndexList.map(async index =>
                    await API.getTableGroup(
                        index, props.dataDate, props.indexLibrary)
                );
        }
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            this.setState(defaultState);
            this.indexList =
                this.props.selectedIndexList &&
                    this.props.selectedIndexList.length !== 0 ?
                    _.cloneDeep(this.props.selectedIndexList) :
                    [_.cloneDeep(defaultIndex)];

            if (
                this.props.selectedIndexList &&
                this.props.selectedIndexList.length !== 0
            ) {
                let groupList =
                    await Promise.all(
                        this.props.selectedIndexList.map(async index =>
                            await API.getTableGroup(
                                index,
                                this.props.dataDate,
                                this.props.indexLibrary)
                        )
                    );

                this.setState({ groupList });
                this.selectGroup(
                    this.props.defaultIndex ? this.props.defaultIndex : 0);
            }
        }
    }

    // Return value when the modal is closed
    onClose() {
        if (!this.props.onClose) { return; }
        this.props.onClose(this.indexList);
    }

    checkIndexValidity(index) {
        if (
            !index || !index.value || !index.range || !index.time ||
            !index.time.customValue ||
            (index.range.data_types && !index.dataType)
        ) {
            return false;
        }

        // Check time
        if (
            index.time.value === 'specific' &&
            !this.checkCustomTime(index.range.value, index.time.customValue)
        ) {
            return false;
        }

        if (
            index.time.value === 'range' && (
                !index.time.customValue.from || !index.time.customValue.to ||
                !this.checkCustomTime(
                    index.range.value, index.time.customValue.from) ||
                !this.checkCustomTime(
                    index.range.value, index.time.customValue.to)
            )
        ) {
            return false;
        }

        return true;
    }

    checkCustomTime(rangeValue, time) {
        let neededKey = [];

        if (rangeValue.includes('year')) {
            neededKey = ['year'];
        } else if (
            rangeValue.includes('quarter') || rangeValue.includes('cumulative')
        ) {
            neededKey = ['year', 'quarter'];
        } else if (rangeValue.includes('month')) {
            neededKey = ['year', 'month'];
        } else if (rangeValue.includes('day')) {
            neededKey = ['year', 'month', 'day'];
        }

        for (let i = 0; i < neededKey.length; i++) {
            let key = neededKey[i];

            if (!time[key]) { return false; }
        }

        return true;
    }

    async updateColumn() {
        this.setState({ showExcessColumnLimitHint: false });

        let index = this.indexList[this.state.selectedGroupIndex];

        if (!this.checkIndexValidity(index)) {
            return;
        }

        API.getTableGroup(index, this.props.dataDate, this.props.indexLibrary)
            .then(result => {
                let groupList = _.cloneDeep(this.state.groupList);
                groupList[this.state.selectedGroupIndex] = result;

                this.setState({ groupList });
            });
    }

    // Step 1
    onIndexSearchTextChange(value) {
        this.setState({ indexSearchText: value });
    }

    onIndexSelect(data) {
        if (!data.ranges) {
            let expandIndexCategory =
                this.state.expandIndexCategory.includes(data.value) ?
                    [] : [data.value];

            // Handle category selection
            this.setState({
                expandIndexCategory,
                rangeList: [],
                dataTypeList: []
            });

            return;
        }

        let currentSelection = this.indexList[this.state.selectedGroupIndex];

        // Reset custom time when selected range is not in new range list
        if (
            !data.ranges.some(ranges =>
                ranges.children.some(range =>
                    currentSelection.range &&
                    range.value === currentSelection.range.value)
            )
        ) {
            if (currentSelection.time) {
                currentSelection.time.customValue =
                    this.getDefaultTimeCustomValue(currentSelection.time.value);
            }

            if (currentSelection.range) {
                delete currentSelection.range;
            }
        }

        currentSelection.value = data;

        this.updateColumn();
        this.setState({ rangeList: data.ranges });
    }

    // Step 2
    onRangeSelect(data) {
        if (data.children) { return; }

        let currentSelection = this.indexList[this.state.selectedGroupIndex];
        currentSelection.range = data;

        // Delete invalid data type selection
        if (
            currentSelection.dataType && (
                !currentSelection.range.data_types ||
                !currentSelection.range.data_types.some(x =>
                    x.value === currentSelection.dataType.value)
            )
        ) {
            delete currentSelection.dataType;
        }

        this.updateColumn();
        this.setState({
            dataTypeList:
                data.data_types ?
                    data.data_types.filter(dataType => !dataType.multivalue) :
                    []
        });
    }

    onRangeInputChange(value) {
        let currentSelection = this.indexList[this.state.selectedGroupIndex];
        currentSelection.range.customValue = value;

        this.updateColumn();
    }

    // Step 3
    onDataTypeSelect(data) {
        let currentSelection = this.indexList[this.state.selectedGroupIndex];
        currentSelection.dataType = data;

        this.updateColumn();
    }

    onDataTypeInputChange(value) {
        let currentSelection = this.indexList[this.state.selectedGroupIndex];
        currentSelection.dataType.customValue = value;

        this.updateColumn();
    }

    // Step 4
    setTimeOption(value) {
        let currentSelection = this.indexList[this.state.selectedGroupIndex];
        currentSelection.time = {
            value,
            customValue: this.getDefaultTimeCustomValue(value)
        };

        this.updateColumn();
    }

    setCustomTime(timeType, value, rangeType) {
        let currentSelection = this.indexList[this.state.selectedGroupIndex];

        if (!currentSelection.time.customValue) {
            currentSelection.time.customValue = {};
        }

        if (rangeType) {
            if (!currentSelection.time.customValue[rangeType]) {
                currentSelection.time.customValue[rangeType] = {};
            }

            currentSelection.time.customValue[rangeType][timeType] = value;
        } else {
            currentSelection.time.customValue[timeType] = value;
        }

        this.updateColumn();
    }

    getDefaultTimeCustomValue(time) {
        switch (time) {
            case 'latest': return { value: 1 };
            case 'before': return { before: 1, value: 1 };
            default: return {};
        }
    }

    renderTimeOption() {
        let index = this.indexList[this.state.selectedGroupIndex];
        let unit = index && index.range ? index.range.unit : '期';
        let time = index && index.time ? index.time.value : null;

        let optionLatest = '最新X' + unit;
        let optionSpecific = '指定某1' + unit;
        let optionBefore = 'Y' + unit + '前 的 最新X' + unit;

        return (
            <RadioGroup
                className="time-type-radio-group"
                value={time}
                onChange={value => this.setTimeOption(value)}>
                <Radio value='latest'>{optionLatest}</Radio>
                <Radio value="specific">{optionSpecific}</Radio>
                <Radio value='range'>指定某段期間</Radio>
                <Radio value='before'>{optionBefore}</Radio>
            </RadioGroup>
        );
    }

    // timeType: year, month, day, quarter
    // rangeType (optional): from, to
    renderTimeInputComponent(timeType, customValue, rangeType) {
        let inputComponent;

        if (rangeType) {
            let value =
                customValue && customValue[rangeType] ?
                    customValue[rangeType][timeType] :
                    '';

            inputComponent =
                <Input
                    value={value}
                    onChange={value => this.setCustomTime(timeType, value, rangeType)} />;
        } else {
            let value =
                customValue && customValue[timeType] ?
                    customValue[timeType] : '';

            inputComponent =
                <Input
                    value={value}
                    onChange={value => this.setCustomTime(timeType, value, rangeType)} />;
        }

        switch (timeType) {
            case 'year':
                return <>{inputComponent}{'年'}</>;
            case 'quarter':
                return <>{'第'}{inputComponent}{'季'}</>;
            case 'month':
                return <>{inputComponent}{'月'}</>;
            case 'day':
                return <>{inputComponent}{'日'}</>;
            default:
                return;
        }
    }

    renderTimeInputPart(timeType, customValue, rangeType) {
        let component = [
            this.renderTimeInputComponent('year', customValue, rangeType)
        ];

        if (timeType.includes('quarter') || timeType.includes('cumulative')) {
            component.push(
                ' ',
                this.renderTimeInputComponent('quarter', customValue, rangeType)
            );
        }

        if (timeType.includes('month') || timeType.includes('day')) {
            component.push(
                ' ',
                this.renderTimeInputComponent('month', customValue, rangeType)
            );
        }

        if (timeType.includes('day')) {
            component.push(
                ' ',
                this.renderTimeInputComponent('day', customValue, rangeType)
            );
        }

        return component;
    }

    renderTimeInput() {
        let index = this.indexList[this.state.selectedGroupIndex];

        if (!index || !index.range || !index.range.value || !index.time) {
            return '';
        }

        let unit = index.range.unit;

        switch (index.time.value) {
            case 'latest':
                return (
                    <>
                        最新
                        <Input
                            value={index.time.customValue ? index.time.customValue.value : ''}
                            onChange={value => this.setCustomTime('value', value)} />
                        {unit}
                    </>
                );
            case 'specific':
                return (
                    this.renderTimeInputPart(
                        index.range.value, index.time.customValue)
                );
            case 'range':
                let fromComponent =
                    this.renderTimeInputPart(
                        index.range.value, index.time.customValue, 'from');

                let toComponent =
                    this.renderTimeInputPart(
                        index.range.value, index.time.customValue, 'to');

                return fromComponent.concat(' ~ ').concat(toComponent);
            case 'before':
                return (
                    <>
                        <Input
                            value={index.time.customValue ? index.time.customValue.before : ''}
                            onChange={value => this.setCustomTime('before', value)} />
                        {unit + '前 的 最新'}
                        <Input
                            value={index.time.customValue ? index.time.customValue.value : ''}
                            onChange={value => this.setCustomTime('value', value)} />
                        {unit}
                    </>
                );
            default:
                return '';
        }
    }

    // Column list
    omColumnSearchTextChanged(value) {
        this.setState({ columnSearchText: value });
    }

    selectGroup(groupIndex) {
        // Expand selected index's category
        let selectedIndex = this.indexList[groupIndex];
        let category =
            this.props.indexLibrary.find(category =>
                category.children.some(index =>
                    index && selectedIndex && selectedIndex.value &&
                    index.value === selectedIndex.value.value
                )
            );

        // Set range list and data type list
        let rangeList =
            selectedIndex && selectedIndex.value && selectedIndex.value.ranges ?
                selectedIndex.value.ranges : [];

        let dataTypeList =
            selectedIndex && selectedIndex.range &&
                selectedIndex.range.data_types ?
                selectedIndex.range.data_types : [];

        this.setState({
            selectedGroupIndex: groupIndex,
            expandIndexCategory: category ? [category.value] : [],
            rangeList,
            dataTypeList,
            showExcessColumnLimitHint: false
        });
    }

    addGroup() {
        this.indexList.push(_.cloneDeep(defaultIndex));

        let groupList = _.cloneDeep(this.state.groupList);
        groupList.push([]);

        this.selectGroup(groupList.length - 1);

        // Clear the column search text to prevent new added group is not
        // visible in the list
        this.setState({ groupList, columnSearchText: '' });
    }

    clearGroupList() {
        this.indexList = [_.cloneDeep(defaultIndex)];

        this.setState({
            groupList: [[]],
            selectedGroupIndex: 0,
            showExcessColumnLimitHint: false
        });
    }

    orderGroupList() {
        // Merge array before reordering
        let list = [];

        for (let i = 0; i < this.indexList.length; i++) {
            list.push({
                index: this.indexList[i], group: this.state.groupList[i]
            });
        }

        // Reorder
        list.sort((a, b) => {
            let dateA = this.getTime(a.group);
            let dateB = this.getTime(b.group);

            if (!dateA) { dateA = moment().year(1900).startOf('year') };
            if (!dateB) { dateB = moment().year(1900).startOf('year') };

            return !this.state.isOldToNew ? dateA - dateB : dateB - dateA;
        });

        // Split back two arrays
        this.indexList = [];
        let groupList = [];

        list.forEach(obj => {
            this.indexList.push(obj.index);
            groupList.push(obj.group);
        });

        // Select first group
        this.selectGroup(0);

        this.setState({ groupList, isOldToNew: !this.state.isOldToNew });
    }

    getTime(columnList) {
        if (!columnList || !columnList[0]) { return null; }

        let firstColumn = columnList[0];

        if (!firstColumn || !firstColumn.date || !firstColumn.date.year) {
            return null;
        }

        let date = moment().year(firstColumn.date.year).startOf('year');

        if (firstColumn.date.quarter) {
            date.quarter(firstColumn.date.quarter);
        }

        if (firstColumn.date.month) {
            date.month(firstColumn.date.month - 1);
        }

        if (firstColumn.date.day) {
            date.date(firstColumn.date.day);
        }

        return date;
    }

    reverseGroupList() {
        this.indexList.reverse();

        let groupList = _.cloneDeep(this.state.groupList);
        groupList.reverse();

        this.selectGroup(0);

        this.setState({ groupList });
    }

    deleteGroup(groupIndex) {
        this.indexList.splice(groupIndex, 1);

        let groupList = _.cloneDeep(this.state.groupList);
        groupList.splice(groupIndex, 1);
        this.setState({ groupList });

        // If current selection exceeds group list's length, adjust it to the
        // last group
        if (groupList.length === this.state.selectedGroupIndex) {
            this.selectGroup(groupList.length - 2);
        }
    }

    onColumnSearchTextChange(value) {
        this.setState({ columnSearchText: value });
    }

    getTimeStr(time) {
        let str = '';

        if (time.year) {
            str += time.year + '年';
        }

        if (time.quarter) {
            str += '第' + time.quarter + '季';
        }

        if (time.month) {
            str += time.month + '月';
        }

        if (time.day) {
            str += time.day + '日';
        }

        return str;
    }

    renderColumn(column) {
        let content = '請選擇指標';

        if (column) {
            content =
                column.index.range_date && column.index.range_date.from ?
                    <>
                        {
                            this.getTimeStr(column.index.range_date.from) +
                            ' ~ ' +
                            this.getTimeStr(column.index.range_date.to)
                        }
                        <span className="opacity-40">&nbsp;的&nbsp;</span>
                        {IndexUtil.getIndexJSX(column.index, true)}
                    </> :
                    <>
                        {this.getTimeStr(column.date)}
                        <span className="opacity-40">&nbsp;的&nbsp;</span>
                        {IndexUtil.getIndexJSX(column.index, true)}
                    </>;
        }

        return (
            <div className="column">
                <div className="square-icon" />
                <div className="column-name">{content}</div>
            </div>
        );
    }

    renderGroupList() {
        let source =
            this.state.columnSearchText !== '' ?
                this.state.groupList.filter(group =>
                    group.some(column => {
                        let text =
                            this.getTimeStr(column.date) +
                            IndexUtil.getIndexText(column.index);

                        return text.includes(this.state.columnSearchText)
                    })
                ) :
                this.state.groupList;

        return source.map((group, i) => {
            let content =
                group && group.length !== 0 ?
                    group.map(column => this.renderColumn(column)) :
                    this.renderColumn();

            let className =
                i === this.state.selectedGroupIndex ?
                    'group clickable selected' : 'group clickable';

            return (
                <div
                    key={'group-' + i}
                    className={className}
                    onClick={() => this.selectGroup(i)}>
                    {content}
                    <div className="more">
                        <div
                            className="clickable"
                            onClick={() => this.deleteGroup(i)}>
                            <Icon icon={IconDelete} />
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        let selectedIndex = this.indexList[this.state.selectedGroupIndex];
        let selectedValue = selectedIndex ? selectedIndex.value : null;
        let selectedRange = selectedIndex ? selectedIndex.range : null;
        let selectedDataType = selectedIndex ? selectedIndex.dataType : null;

        return (
            <Modal
                className="index-library-modal-table"
                show={this.props.show}
                size="lg"
                onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>選擇指標</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div style={{ flex: 8 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 3 }}>
                                <div className="sub-text">第一步：選擇欲加入指標</div>
                                <div className="treeContainer">
                                    <InputGroup>
                                        <Input
                                            placeholder="搜尋指標"
                                            onChange={value => this.onIndexSearchTextChange(value)} />
                                        <InputGroup.Button>
                                            <Icon icon="search" />
                                        </InputGroup.Button>
                                    </InputGroup>
                                    <Tree
                                        data={this.props.indexLibrary}
                                        searchKeyword={this.state.indexSearchText}
                                        expandItemValues={this.state.expandIndexCategory}
                                        locale={{ noResultsText: '無選項' }}
                                        value={selectedValue ? selectedValue.value : null}
                                        onSelect={nodeData => this.onIndexSelect(nodeData)} />
                                </div>
                            </div>
                            <div style={{ flex: 2, marginLeft: 13, marginRight: 13 }}>
                                <div className="sub-text">第二步：運算單值或多值</div>
                                <div className="treeContainer">
                                    <Tree
                                        data={this.state.rangeList}
                                        locale={{ noResultsText: '無選項' }}
                                        expandItemValues={['singleValue', 'multiValue']}
                                        value={selectedRange ? selectedRange.value : null}
                                        onSelect={nodeData => this.onRangeSelect(nodeData)} />
                                    {
                                        selectedRange && selectedRange.input &&
                                        <div className="treeCustomInput">
                                            最近
                                            <Input
                                                value={selectedRange ? selectedRange.customValue : null}
                                                onChange={value => this.onRangeInputChange(value)} />
                                            {selectedRange ? selectedRange.unit : ''}...
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                !this.props.disableInput &&
                                <div style={{ flex: 2 }}>
                                    <div className="sub-text">
                                        第三步：算法 (只適用於多值)
                                    </div>
                                    <div className="treeContainer">
                                        <Tree
                                            className="single-layer"
                                            data={this.state.dataTypeList}
                                            locale={{ noResultsText: '無選項' }}
                                            value={selectedDataType ? selectedDataType.value : null}
                                            onSelect={nodeData => this.onDataTypeSelect(nodeData)} />
                                        {
                                            selectedDataType &&
                                            selectedDataType.value === 'include' &&
                                            <div className="treeCustomInput">
                                                有
                                                <Input
                                                    value={selectedDataType ? selectedDataType.customValue : ''}
                                                    onChange={value => this.onDataTypeInputChange(value)} />
                                                {selectedRange ? selectedRange.unit : ''}皆...
                                        </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ marginTop: 11 }}>
                            <span className="sub-text">
                                第四步：選擇欄位數
                            </span>
                            {this.renderTimeOption()}
                            <div className="time-type-div">
                                {this.renderTimeInput()}
                                {
                                    this.state.showExcessColumnLimitHint &&
                                    <div className="red">&nbsp;欄位數不得大於{ColumnLimit}</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 6, marginLeft: 25, display: 'flex', flexDirection: 'column' }}>
                        <div className="sub-text">
                            表格欄位(一行=一欄)
                        </div>
                        <div style={{ display: 'flex', marginLeft: -12, marginTop: -12, marginBottom: 4 }}>
                            <Button
                                appearance="link"
                                onClick={() => this.addGroup()}>
                                新增欄位
                            </Button>
                            <Button
                                appearance="link"
                                onClick={() => this.clearGroupList()}>
                                清空
                            </Button>
                            <Button
                                appearance="link"
                                onClick={() => this.orderGroupList()}>
                                時間由
                                {this.state.isOldToNew ? '新到舊' : '舊到新'}
                                排序
                            </Button>
                            <Button
                                appearance="link"
                                onClick={() => this.reverseGroupList()}>
                                反轉順序
                            </Button>
                        </div>
                        <div className="group-list">
                            <InputGroup>
                                <Input
                                    placeholder="搜尋指標或時間"
                                    onChange={value => this.onColumnSearchTextChange(value)} />
                                <InputGroup.Button>
                                    <Icon icon="search" />
                                </InputGroup.Button>
                            </InputGroup>
                            {this.renderGroupList()}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onClose()}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}