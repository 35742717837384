export const SET_INITIAL_INDEX_SCORE_LIST = "SET_INITIAL_INDEX_SCORE_LIST";
export const RESET_COPIED_STRATEGY_LOCAL = "RESET_COPIED_STRATEGY_LOCAL"
export const RESET_STRATEGY_DESIGN = "RESET_STRATEGY_DESIGN";
export const SET_STRATEGY_NAME = "SET_STRATEGY_NAME";
export const SET_STRATEGY_DESCRIPTION = "SET_STRATEGY_DESCRIPTION";
export const SET_DESIGN_STRATEGY = "SET_DESIGN_STRATEGY";
export const SET_STOCK_LIST = "SET_STOCK_LIST";
export const UPDATE_DESIGN_STRATEGY = "UPDATE_DESIGN_STRATEGY";
export const SET_INDUSTRY_FILTER = "SET_INDUSTRY_FILTER";
export const SET_CONSTRAINT_FILTER = "SET_CONSTRAINT_FILTER";
export const SET_INDEX_SCORELIST_AND_TOTALSCORE = "SET_INDEX_SCORELIST_AND_TOTALSCORE";
export const UPDATE_BACKTEST_SETTING = "UPDATE_BACKTEST_SETTING";