import React from 'react';
import { Button, Checkbox, Modal } from 'rsuite';

const Component = ({
    isOpen,
    onClose,
    showQuestionnaire,
    setDismiss
}) => {
    return (
        <Modal
            className="questionnaire-modal"
            show={isOpen}
            onEntered={() => setDismiss(true)}
            onHide={onClose}>
            <Modal.Header>
                <Modal.Title>邀請您填寫簡單的問卷</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                請協助我們改善使用體驗，或給我們更多建議。
                <Checkbox
                    className="dismiss-checkbox"
                    defaultChecked={true}
                    onChange={(_, checked) => setDismiss(checked)}>
                    下次不再顯示
                </Checkbox>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-grey" onClick={onClose}>取消</Button>
                <Button onClick={showQuestionnaire}>立即填寫</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;