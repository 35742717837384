import AddTabModal from "../addTabModal"
import React from "react";
import { useState } from "react";
import { addFavoriteGroup } from "Redux/actionCreators/stock"
import { useSelector } from "react-redux";

// 不顯示開關icon時, 由外部傳入的show控制開關。 若由自己的icon控制，則使用自己的state。
export default function Container({ show, onModalHide, addAfter, lastGroupId }){
    const title = addAfter ? '往下插入頁籤' : '插入頁籤';
    const [state, setState] = useState({
        show: false,
        name: '',
    })
    const trackingStock = useSelector(store => store.stock.trackingStock)
    const onNameChange = (name) => {
        setState({ 
            ...state,
            name: name 
        });
    }

    const showAddTabModal = (id) => {
        setState({
            ...state,
            show: true,

        })
    }

    const _onModalHide = () => {
        setState({
            ...state,
            show: false,
        })
    }
    const onExited = () => {
        setState({
            ...state,
            name: ''
        })
    }
    const onAddTab = () => {
        if (state.name === '') {
            alert('請輸入新頁籤名稱');
            return;
        }
        const lastId = lastGroupId ? lastGroupId : trackingStock[trackingStock.length - 1].id
        addFavoriteGroup(state.name, lastId);
        typeof show !== 'undefined' ? onModalHide() : _onModalHide()
    }
    const focusInput = (ref) => {
        if (!ref) { return; }
        ref.focus();
    }

    return <AddTabModal 
        title={title}
        name={state.name}
        show={typeof show !== 'undefined' ? show: state.show}
        showIcon={typeof show !== 'undefined' ? false: true}
        onNameChange={onNameChange}
        showAddTabModal={showAddTabModal}
        onModalHide={typeof show !== 'undefined' ? onModalHide: _onModalHide}
        onAddTab={onAddTab}
        focusInput={focusInput} 
        onExited={onExited}
    />
}