import React, { useEffect, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Component from './component';
import { getPanelData } from 'Redux/actionCreators/dashboard';
import { deepEqual } from 'Util/deepEqual';
import { FETCH_STRATEGY } from 'Redux/saga/actionTypes/strategy';

import './style.scss';

const Container = ({ panel }) => {
    const dispatch = useDispatch();
    
    const indexLibrary =
        useSelector(store => store.index.indexLibrary, deepEqual);
    const panelData =
        useSelector(store =>
            store.dashboard.panelDataList.find(o => o.id === panel.id),
            deepEqual);

    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    });

    useEffect(() => {
        let params = {};

        if (match) {
            params = {
                stock_code: match.params.id,
                strategy: match.params.strategyId
            };
        }

        getPanelData(panel, params);
        /* eslint-disable-next-line */
    }, []);

    // Get strategy
    const strategyId = match?.params.strategyId;
    const strategyDesign = useSelector(store => store.strategyDesign);

    useEffect(() => {
        if (!indexLibrary || !strategyId) { return; }

        dispatch({ type: FETCH_STRATEGY, id: strategyId });
        /* eslint-disable-next-line */
    }, [indexLibrary, strategyId]);

    const total = panelData?.rawData.main.display.data[0].value;
    const diff = panelData?.rawData.main.display.data[1].value;
    const inStockpool = panelData?.rawData.main.display.data[2].value;
    const frequency = panelData?.rawData.main.display.data[3].value;
    const lastUpdate = panelData?.rawData.main.scored_date;

    const [scores, setScores] = useState([]);

    useEffect(() => {
        // Processing...
        if (panelData) {
            let i = 0;
            let indexScoreList = cloneDeep( strategyDesign.indexScoreList);

            indexScoreList.forEach(category => {
                let score = 0;
                let total = 0;

                category.rowList.forEach(row => {
                    row.total = row.score;
                    row.score = panelData?.rawData.main.data.score_list[i];
                    row.status = row.score === row.total;
                    i++;

                    score += row.score;
                    total += row.total;
                })

                category.score = score;
                category.total = total;
            });

            setScores(indexScoreList);
        }
    }, [panelData, strategyDesign.indexScoreList]);

    return (
        panelData ?
            <Component
                total={total}
                diff={diff}
                inStockpool={inStockpool}
                industries={strategyDesign.industryFilter}
                selections={strategyDesign.constraintFilter}
                frequency={frequency}
                lastUpdate={lastUpdate}
                categoryList={scores} /> :
            null
    );
}

export default React.memo(Container, isEqual);