import React from 'react';
import { Button, Icon, Input } from 'rsuite';
import { isEqual } from 'lodash';

import IndexExpression from 'Components/indexExpression';

import IconFillScore from 'Image/svgIcon/fillScore.svg';
import ImageCopy from 'Image/svgIcon/copy.svg';
import ImageDelete from 'Image/svgIcon/delete.svg';

import './style.scss';

function RowList({
    rowList,
    deleteIndex,
    copyIndex,
    setIndex,
    setScore,
    i
}) {
    return rowList.map((_x, _i) => {
        return (
            <div className="index-row" key={_i}>
                <div className="icon-button-column">
                    <Button
                        data-blockindex={i}
                        data-aryindex={_i}
                        onClick={deleteIndex}>
                        <Icon icon={ImageDelete} />
                    </Button>
                </div>
                <div className="icon-button-column">
                    <Button
                        data-blockindex={i}
                        data-aryindex={_i}
                        onClick={copyIndex}>
                        <Icon icon={ImageCopy} />
                    </Button>
                </div>
                <IndexExpression
                    expression={_x}
                    onChange={index => setIndex(i, _i, index)} />
                <div className="score-column">
                    <span>符合條件得</span>
                    <Input
                        style={{ width: 44, marginLeft: 8, marginRight: 8 }}
                        value={_x.score ? _x.score : ''}
                        onChange={value => setScore(i, _i, value)} />
                    <span>分</span>
                </div>
            </div>
        );
    });
}

function Component({
    blockList,
    deleteBlock,
    copyBlock,
    setBlockName,
    deleteIndex,
    copyIndex,
    setIndex,
    setScore,
    addIndex,
    addBlock,
    totalScore,
    autoFillScore
}) {
    return (
        <div className="index-score-panel">
            <div className="index-score-panel__header">
                <span className="icon-button-column">刪除</span>
                <span className="icon-button-column">複製</span>
                <span className="index-column">分類/評分公式</span>
                <span className="score-column">權重/重要性分數</span>
            </div>
            <hr />
            {
                blockList.map((x, i) => {
                    return <div key={i} className="index-block">
                        <div>
                            <div className="icon-button-column">
                                <Button data-id={i} onClick={deleteBlock}>
                                    <Icon icon={ImageDelete} />
                                </Button>
                            </div>
                            <div className="icon-button-column">
                                <Button data-id={i} onClick={copyBlock}>
                                    <Icon icon={ImageCopy} />
                                </Button>
                            </div>
                            <div className="index-column">
                                <Input
                                    placeholder="類型名稱"
                                    value={x.name}
                                    name={i}
                                    onChange={setBlockName}
                                />
                            </div>
                        </div>
                        <RowList
                            rowList={x.rowList}
                            deleteIndex={deleteIndex}
                            copyIndex={copyIndex}
                            setIndex={setIndex}
                            setScore={setScore}
                            i={i} />
                        <Button
                            className="blue"
                            data-blockindex={i}
                            appearance="subtle"
                            style={{ marginTop: 19, marginBottom: 19 }}
                            onClick={addIndex}>
                            <Icon icon="plus-circle" /> 新增評分公式
                        </Button>
                        <hr />
                    </div>
                })
            }
            <div style={{ display: 'flex', marginTop: 14, marginLeft: 70 }}>
                <div className="index-column">
                    <Button
                        className="blue"
                        appearance="subtle"
                        onClick={() => addBlock()}>
                        <Icon icon="plus-circle" /> 新增分類
                    </Button>
                </div>
                <div className="score-column">
                    <span>總分 {totalScore} 分</span>
                    <Button
                        className="fill-score-button"
                        appearance='link'
                        onClick={autoFillScore}>
                        <Icon icon={IconFillScore} /> 自動填入分數
                    </Button>
                </div>
            </div>
        </div>
    );
}

function areEqual(prevProps, nextProps) {
    return (
        isEqual(prevProps.blockList, nextProps.blockList) &&
        isEqual(prevProps.indexLibrary, nextProps.indexLibrary)
    );
}

export default React.memo(Component, areEqual);