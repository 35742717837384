import React, { Component } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import {
    AutoComplete,
    Button,
    Icon,
    Input,
    InputGroup,
    Modal,
    SelectPicker,
    Tree
} from 'rsuite';

import AddStockGroupModal from './addStockGroupModal';
import API from '../../api';
import IconAdd from '../../image/svgIcon/dashboard/add.svg';
import IconCopy from '../../image/svgIcon/copy.svg';
import IconDelete from '../../image/svgIcon/dashboard/delete.svg';
import IndexExpression from 'Components/indexExpression';
import UploadPanel from './stockSelectionModal/uploadPanel';

import './stockSelectionModal.css';

var _ = require('lodash');

const modeList = [
    { label: '依類別、或全選', value: 'category' },
    { label: '手動加入股票', value: 'manual' },
    { label: '依競爭同業', value: 'sameIndustry' },
    { label: '依我的投資策略選股', value: 'strategy' },
    { label: '指標篩選', value: 'index' },
    { label: '上傳CSV . XLS清單', value: 'upload' }
];

const scoreOperatorList = [
    { label: '大於等於', value: 'greaterEqual' },
    { label: '小於等於', value: 'lessEqual' },
    { label: '等於', value: 'equal' },
    { label: '介於', value: 'between' }
]

const defaultState = {
    mode: 'category',
    groupSearchText: '',
    expandGroup: [],
    previewSearchText: '',
    previewList: [],
    selectionSearchText: '',
    selectionList: [],
    scoreOperator: 'greaterEqual',
    ruleList: [
        { index: null, operator: 'greater' }
    ],
    isRuleRunning: false,
    isRuleModified: true,
    showAddStockGroupModal: false
};

export default class StockSelectionModal extends Component {
    constructor(props) {
        super(props);

        this.state = _.cloneDeep(defaultState);

        if (props.selectedStockList) {
            this.state.selectionList = _.cloneDeep(props.selectedStockList);
        }

        if (props.strategyList && props.strategyList[0]) {
            this.state.strategyId = this.props.strategyList[0].id;
        }

        if (props.stockPool) {
            this.state.stockAutoCompleteList =
                props.stockPool.map(stock => (
                    {
                        label: stock.stock_code + ' ' + stock.stock_name,
                        value: stock.stock_code
                    }
                ));
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.stockPool, this.props.stockPool)) {
            this.setState({
                stockAutoCompleteList:
                    this.props.stockPool.map(stock => (
                        {
                            label: stock.stock_code + ' ' + stock.stock_name,
                            value: stock.stock_code
                        }
                    ))
            });
        }

        if (!_.isEqual(prevProps.stockGroups, this.props.stockGroups)) {
            this.forceUpdate();
        }

        if (!prevProps.show && this.props.show) {
            this.setState(defaultState);

            if (this.props.selectedStockList) {
                this.setState({
                    selectionList: _.cloneDeep(this.props.selectedStockList)
                });
            }

            if (this.props.strategyList && this.props.strategyList[0]) {
                this.setState({ strategyId: this.props.strategyList[0].id });
            }
        }
    }

    onModeChange(value) {
        this.setState({ mode: value });
    }

    // Group
    onGroupSearchTextChange(value) {
        this.setState({ groupSearchText: value });
    }

    onGroupSelect(group) {
        if (group.children) {
            let expandGroup =
                !this.state.expandGroup.includes(group.value) ?
                    [group.value] : [];

            this.setState({ expandGroup });
            return;
        }

        this.setState({
            selectedGroup: group.value,
            previewList: this.props.stockGroups.items[group.value]
        });
    }

    deleteStockGroup(id, name) {
        if (!window.confirm('是否要刪除自定義分類「' + name + '」?')) { return; }

        API.deleteStockGroup(id).then(result => {
            alert('刪除自定義分類' + (result ? '成功' : '失敗'));

            if (!result || !this.props.notifyRefreshStockGroup) { return; }
            this.props.notifyRefreshStockGroup();
        });
    }

    // Manual
    onManualSingleTextChange(value) {
        this.setState({ manualSingleText: value });
    }

    onManualSingleSelect(item) {
        if (this.state.previewList.includes(item.value)) { return; }

        let previewList = _.cloneDeep(this.state.previewList);
        previewList.push(item.value);

        this.setState({ previewList });
    }

    onManualSingleKeyUp(e) {
        const found = this.manualSingleAutoComplete.getFocusableMenuItems();

        // KeyCode 13 = Enter
        if (e.keyCode !== 13 || found.length !== 1) { return; }

        this.onManualSingleSelect(...found);
        this.manualSingleAutoComplete.close();
        this.setState({ manualSingleText: '' });
    }

    onManualMultipleTextChange(value) {
        this.setState({ manualMultipleText: value });
    }

    onManualMultipleSubmit() {
        if (!this.props.stockPool || !this.state.manualMultipleText) { return; }

        let tokenList =
            this.state.manualMultipleText.split(/\s/)
                .filter(token => token !== '');

        let previewList =
            this.props.stockPool
                .filter(stock =>
                    tokenList.some(token =>
                        stock.stock_code === token || stock.stock_name === token
                    )
                )
                .map(stock => stock.stock_code);

        this.setState({ previewList, manualMultipleText: '' });
    }

    // Same industry
    onSameIndustryTextChange(value) {
        if (!this.props.stockPool || !this.props.sameIndustry) { return; }

        let stock =
            this.props.stockPool.find(stock =>
                stock.stock_code === value || stock.stock_name === value);

        let stockIndustry =
            stock ?
                this.props.sameIndustry.find(x => x.id === stock.stock_code) :
                null;

        this.setState({
            previewList: stockIndustry ? stockIndustry.value : []
        });
    }

    // Strategy
    onStrategyIdChange(value) {
        this.setState(
            { strategyId: value }, () => this.updateScorePreviewList());
    }

    onScoreOperatorChange(value) {
        this.setState(
            { scoreOperator: value }, () => this.updateScorePreviewList());
    }

    onScoreFromChange(value) {
        this.setState(
            { scoreFrom: value }, () => this.updateScorePreviewList());
    }

    onScoreToChange(value) {
        this.setState({ scoreTo: value }, () => this.updateScorePreviewList());
    }

    onScoreChange(value) {
        this.setState({ score: value }, () => this.updateScorePreviewList());
    }

    updateScorePreviewList() {
        let score = {};

        if (this.state.scoreOperator === 'between') {
            score.rangeFrom = this.state.scoreFrom;
            score.rangeTo = this.state.scoreTo;
        } else {
            score.value = this.state.score;
        }

        API.getStrategyScoreStock(
            this.state.strategyId, 'range', this.state.scoreOperator, score
        )
            .then(result => this.setState({ previewList: result }));
    }

    // Index
    addRule() {
        let emptyRule = { index: null, operator: 'greater' };

        let list = [...this.state.ruleList, emptyRule];

        this.setState({ ruleList: list, isRuleModified: true });
    }

    copyRule(ruleIndex) {
        let ruleList = _.cloneDeep(this.state.ruleList);
        let rule = _.cloneDeep(ruleList[ruleIndex]);

        ruleList.push(rule);

        this.setState({ ruleList: ruleList, isRuleModified: true });
    }

    deleteRule(ruleIndex) {
        let list = [...this.state.ruleList];

        list.splice(ruleIndex, 1);

        this.setState({ ruleList: list, isRuleModified: true });
    }

    setRule(ruleIndex, value) {
        let list = _.cloneDeep(this.state.ruleList);
        list[ruleIndex] = value;

        this.setState({ ruleList: list, isRuleModified: true });
    }

    getIndexFilteredPreview() {
        this.setState({ isRuleRunning: true });

        API.getIndexFilteredStock(this.state.ruleList)
            .then(result =>
                this.setState({
                    previewList: result,
                    isRuleRunning: false,
                    isRuleModified: false
                })
            );
    }

    // CSV
    onCSVGetStocks(stocks) {
        this.setState({ previewList: stocks });
    }

    // Preview
    addAllStock() {
        let selectionList = _.cloneDeep(this.state.selectionList);

        this.state.previewList.forEach(code => {
            if (selectionList.includes(code)) { return; }
            selectionList.push(code);
        });

        selectionList.sort((a, b) => parseInt(a) - parseInt(b));

        this.setState({ selectionList });
    }

    clearPreviewList() {
        this.setState({ previewList: [] });
    }

    onPreviewSearchTextChange(value) {
        this.setState({ previewSearchText: value });
    }

    addStock(code) {
        if (this.state.selectionList.includes(code)) { return; }

        let selectionList = _.cloneDeep(this.state.selectionList);
        selectionList.push(code);
        selectionList.sort((a, b) => parseInt(a) - parseInt(b));

        this.setState({ selectionList });
    }

    removePreviewStock(code) {
        let previewList = this.state.previewList.filter(x => x !== code);
        this.setState({ previewList });
    }

    // Selection
    clearSelectionList() {
        this.setState({ selectionList: [] });
    }

    onSelectionSearchTextChange(value) {
        this.setState({ selectionSearchText: value });
    }

    removeSelectionStock(code) {
        this.setState({
            selectionList: this.state.selectionList.filter(x => x !== code)
        });
    }

    showAddStockGroupModal() {
        this.setState({ showAddStockGroupModal: true });
    }

    closeAddStockGroupModal(name, stocks) {
        if (name && stocks && stocks.length !== 0) {
            API.addStockGroup(name, stocks).then(result => {
                alert('新增自定義分類' + (result ? '成功' : '失敗'));

                if (!result || !this.props.notifyRefreshStockGroup) { return; }
                this.props.notifyRefreshStockGroup();
            });
        }

        this.setState({ showAddStockGroupModal: false });
    }

    // Modal
    onCancel() {
        if (!this.props.onCancel) { return; }
        this.props.onCancel();
    }

    onConfirmClick() {
        if (!this.props.onConfirmClick) { return; }
        this.props.onConfirmClick(this.state.selectionList);
    }

    // Render input panel
    renderInputPanel() {
        switch (this.state.mode) {
            case 'category':
                return this.renderCategoryPanel();
            case 'manual':
                return this.renderManualPanel();
            case 'sameIndustry':
                return this.renderSameIndustryPanel();
            case 'strategy':
                return this.renderStrategyPanel();
            case 'index':
                return this.renderIndexPanel();
            case 'upload':
                return (
                    <UploadPanel
                        stockPool={this.props.stockPool}
                        onGetStocks={stocks => this.onCSVGetStocks(stocks)} />
                );
            default:
                return;
        }
    }

    renderCategoryPanel() {
        return (
            <div className="group-list">
                <InputGroup>
                    <Input
                        placeholder="搜尋"
                        onChange={value => this.onGroupSearchTextChange(value)} />
                    <InputGroup.Button>
                        <Icon icon="search" />
                    </InputGroup.Button>
                </InputGroup>
                <Tree
                    data={
                        this.props.stockGroups ?
                            this.props.stockGroups.categories : []}
                    searchKeyword={this.state.groupSearchText}
                    expandItemValues={this.state.expandGroup}
                    locale={{ noResultsText: '無選項' }}
                    value={this.state.selectedGroup}
                    onSelect={nodeData => this.onGroupSelect(nodeData)}
                    renderTreeNode={nodeData => this.renderGroupTreeNode(nodeData)} />
            </div>
        );
    }

    renderGroupTreeNode(nodeData) {
        // Use id is int to check current node is custom stock group or not
        if (!Number.isInteger(nodeData.id)) {
            // Not custom stock group, return name directly
            return nodeData.label;
        }

        return (
            <div className="custom-stock-group">
                <div className="custom-group-name">{nodeData.label}</div>
                <Icon
                    icon={IconDelete}
                    onClick={() => this.deleteStockGroup(nodeData.id, nodeData.label)} />
            </div>
        );
    }

    renderManualPanel() {
        return (
            <>
                <div className="sub-text">輸入單支股票</div>
                <InputGroup>
                    <AutoComplete
                        ref={ref => this.manualSingleAutoComplete = ref}
                        placeholder="輸入代號/名稱，按Enter加入"
                        data={this.state.stockAutoCompleteList}
                        value={this.state.manualSingleText}
                        onChange={value => this.onManualSingleTextChange(value)}
                        onSelect={item => this.onManualSingleSelect(item)}
                        onKeyUp={e => this.onManualSingleKeyUp(e)} />
                    <InputGroup.Button>
                        <Icon icon={IconAdd} />
                    </InputGroup.Button>
                </InputGroup>
                <div className="sub-text multiple">輸入股票清單</div>
                <InputGroup className="multiple-input">
                    <Input
                        componentClass="textarea"
                        placeholder="可輸入多支股票，以空格或Enter分隔"
                        value={this.state.manualMultipleText}
                        onChange={value => this.onManualMultipleTextChange(value)} />
                    <InputGroup.Button onClick={() => this.onManualMultipleSubmit()}>
                        <Icon icon={IconAdd} />
                    </InputGroup.Button>
                </InputGroup>
            </>
        );
    }

    renderSameIndustryPanel() {
        return (
            <InputGroup>
                <Input
                    placeholder="輸入完整代號/名稱，搜尋其同業"
                    onChange={value => this.onSameIndustryTextChange(value)} />
                <InputGroup.Button>
                    <Icon icon="search" />
                </InputGroup.Button>
            </InputGroup>
        );
    }

    renderStrategyPanel() {
        let strategyList =
            this.props.strategyList ?
                this.props.strategyList.map(strategy =>
                    ({ label: strategy.name, value: strategy.id }))
                : [];

        return (
            <>
                <div className="row">
                    符合
                    <SelectPicker
                        className="strategy-picker"
                        data={strategyList}
                        searchable={false}
                        cleanable={false}
                        value={this.state.strategyId}
                        onChange={value => this.onStrategyIdChange(value)} />
                    選股
                </div>
                <div className="row">
                    而且
                    <SelectPicker
                        className="score-operator-picker"
                        data={scoreOperatorList}
                        searchable={false}
                        cleanable={false}
                        value={this.state.scoreOperator}
                        onChange={value => this.onScoreOperatorChange(value)} />
                    {
                        this.state.scoreOperator === 'between' &&
                        <div className="score-input-container">
                            <Input
                                className="score-input"
                                value={this.state.scoreFrom}
                                onChange={value => this.onScoreFromChange(value)} />
                            分 ~
                            <Input
                                className="score-input"
                                value={this.state.scoreTo}
                                onChange={value => this.onScoreToChange(value)} />
                            分
                        </div>
                    }
                    {
                        this.state.scoreOperator !== 'between' &&
                        <div className="score-input-container">
                            <Input
                                className="score-input"
                                value={this.state.score}
                                onChange={value => this.onScoreChange(value)} />
                            分
                        </div>
                    }
                </div>
            </>
        );
    }

    renderIndexPanel() {
        let ruleComponent =
            this.state.ruleList ?
                this.state.ruleList.map((rule, i) =>
                    <div className="rule-row" key={'row-' + i}>
                        <div className="button-column">
                            <Button onClick={() => this.deleteRule(i)}>
                                <Icon icon={IconDelete} />
                            </Button>
                        </div>
                        <div className="button-column">
                            <Button onClick={() => this.copyRule(i)}>
                                <Icon icon={IconCopy} />
                            </Button>
                        </div>
                        <IndexExpression
                            className="index-column"
                            useTwoRowLayout
                            expression={rule}
                            onChange={index => this.setRule(i, index)} />
                    </div>
                ) :
                null;

        return (
            <>
                {ruleComponent}
                <hr />
                <div className="index-button-container">
                    <div className="left-button">
                        <Button
                            appearance="link"
                            onClick={() => this.addRule()}>
                            <Icon icon="plus-circle" /> 新增篩選條件
                    </Button>
                    </div>
                    {
                        this.state.isRuleModified &&
                        !this.state.isRuleRunning &&
                        <Button
                            className="start-filter-button"
                            appearance="link"
                            onClick={() => this.getIndexFilteredPreview()}>
                            ▶ 開始篩選
                        </Button>
                    }
                    {
                        this.state.isRuleRunning &&
                        <div className="filter-status">運算中...</div>
                    }
                    {
                        !this.state.isRuleModified &&
                        !this.state.isRuleRunning &&
                        <div className="filter-status">篩選完成</div>
                    }
                </div>
            </>
        );
    }

    // Render list
    renderPreviewList() {
        return (
            <AutoSizer>
                {
                    ({ height, width }) =>
                        <List
                            height={height}
                            width={width}
                            rowCount={this.state.previewList.length}
                            rowHeight={33}
                            rowRenderer={
                                ({ key, index, style }) =>
                                    this.renderPreviewRow(key, index, style)
                            } />
                }
            </AutoSizer>
        );
    }

    renderPreviewRow(key, index, style) {
        if (!this.state.previewList || !this.props.stockPool) { return; }

        let code = this.state.previewList[index];
        let stock =
            this.props.stockPool.find(stock => stock.stock_code === code);

        if (!stock) { return null; }
        if (
            this.state.previewSearchText !== '' &&
            !stock.stock_code.includes(this.state.previewSearchText) &&
            !stock.stock_name.includes(this.state.previewSearchText)
        ) {
            return null;
        }

        return (
            <div key={key} className="stock" style={style}>
                <div
                    className="stock-name"
                    onClick={() => this.addStock(stock.stock_code)}>
                    {stock.stock_code + ' ' + stock.stock_name}
                </div>
                <Icon
                    icon={IconAdd}
                    onClick={() => this.addStock(stock.stock_code)} />
                <Icon
                    icon={IconDelete}
                    onClick={() => this.removePreviewStock(stock.stock_code)} />
            </div>
        );
    }

    renderSelectionList() {
        return (
            <AutoSizer>
                {
                    ({ height, width }) =>
                        <List
                            height={height}
                            width={width}
                            rowCount={this.state.selectionList.length}
                            rowHeight={33}
                            rowRenderer={
                                ({ key, index, style }) =>
                                    this.renderSelectionRow(key, index, style)
                            } />
                }
            </AutoSizer>
        );
    }

    renderSelectionRow(key, index, style) {
        if (!this.state.selectionList || !this.props.stockPool) { return; }

        let code = this.state.selectionList[index];
        let stock =
            this.props.stockPool.find(stock => stock.stock_code === code);

        if (!stock) { return null; }
        if (
            this.state.selectionSearchText !== '' &&
            !stock.stock_code.includes(this.state.selectionSearchText) &&
            !stock.stock_name.includes(this.state.selectionSearchText)
        ) {
            return null;
        }

        return (
            <div key={key} className="stock" style={style}>
                <div className="stock-name">
                    {stock.stock_code + ' ' + stock.stock_name}
                </div>
                <Icon
                    icon={IconDelete}
                    onClick={() => this.removeSelectionStock(stock.stock_code)} />
            </div>
        );
    }

    render() {
        return (
            <>
                <Modal
                    className="stock-selection-modal"
                    show={this.props.show}
                    onHide={() => this.props.onCancel()}>
                    <Modal.Header>
                        <Modal.Title>選擇股票</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <div className="group">
                            <div className="sub-text">選股方法</div>
                            <SelectPicker
                                className="mode-picker"
                                data={modeList}
                                searchable={false}
                                cleanable={false}
                                value={this.state.mode}
                                onChange={value => this.onModeChange(value)} />
                            <div className="input-panel">
                                {this.renderInputPanel()}
                            </div>
                        </div>
                        <div className="preview">
                            <div className="sub-text">預覽股票</div>
                            <div>數量：{this.state.previewList.length}支</div>
                            <div>
                                <Button
                                    className="add-all-button"
                                    appearance="link"
                                    onClick={() => this.addAllStock()}>
                                    <Icon icon="plus-circle" /> 全部加入股票池
                                </Button>
                                <Button
                                    className="clear-button"
                                    appearance="link"
                                    onClick={() => this.clearPreviewList()}>
                                    清空
                                </Button>
                            </div>
                            <div className="stock-container">
                                <InputGroup>
                                    <Input
                                        placeholder="搜尋"
                                        onChange={value => this.onPreviewSearchTextChange(value)} />
                                    <InputGroup.Button>
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <div className="stock-list">
                                    {this.renderPreviewList()}
                                </div>
                            </div>
                        </div>
                        <div className="selection">
                            <div className="sub-text">股票池</div>
                            <div>數量：{this.state.selectionList.length}支</div>
                            <div>
                                <Button
                                    className="clear-button"
                                    appearance="link"
                                    onClick={() => this.clearSelectionList()}>
                                    清空
                                </Button>
                                <Button
                                    className="add-stock-group-button"
                                    appearance="link"
                                    onClick={() => this.showAddStockGroupModal()}>
                                    儲存自定義分類
                                </Button>
                            </div>
                            <div className="stock-container">
                                <InputGroup>
                                    <Input
                                        placeholder="搜尋"
                                        onChange={value => this.onSelectionSearchTextChange(value)} />
                                    <InputGroup.Button>
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <div className="stock-list">
                                    {this.renderSelectionList()}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.onConfirmClick()}>
                            確定
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AddStockGroupModal
                    show={this.state.showAddStockGroupModal}
                    onCancel={() => this.closeAddStockGroupModal()}
                    onConfirmClick={name => this.closeAddStockGroupModal(name, this.state.selectionList)} />
            </>
        );
    }
}