import React, { useEffect } from 'react';
import TabList from '../tabList.jsx'
import '../style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { SET_A_TABLIST, 
    // REMOVE_A_TABLIST,
    CHANGE_TAB } from "Redux/actionTypes/tabList";
export default function Container({ id, data, onChangeCallBack, type, defaultTab=undefined })
{
    const tab = useSelector(store => store.tabList.list.find(o => o.id === id))
    const dispatch  = useDispatch()
    useEffect(() => {
        dispatch({
            type: SET_A_TABLIST,
            id: id,
            tabs: data,
            defaultTab: defaultTab
        })
        // return function()
        // {
        //     dispatch({
        //         type: REMOVE_A_TABLIST,
        //         id: id
        //     })
        // }
        /* eslint-disable-next-line */
    },[data, data.length])
    const handleOnChange = (value) => {
        if(typeof onChangeCallBack === "function")
        {
            onChangeCallBack()
        }
        dispatch({
            type: CHANGE_TAB,
            id: id,
            currentTab: value
        })
    }
    return  tab ? <TabList 
        list={tab.tabs}
        value={tab.currentTab}
        handleOnChange={handleOnChange}
        type={type}
    />: null
}