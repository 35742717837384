import React from 'react';

import Expandable from 'Components/expandable';
import ListPanel from 'Components/listPanel';
import StrategyDetail from './component/strategyDetail';
import StrategyRatings from './component/strategyRatings';

import './style.scss';

const Container = ({
    data,
    industries,
    selections,
    totalFiltered,
    scores,
    backtest
}) => {
    return (
        <div className="strategy-panel">
            <ListPanel titleWidth={96} data={data?.data} />
            <StrategyRatings ratings={data?.ratings} />
            <Expandable expandText="選股方法 ▼" collapseText="選股方法 ▲">
                <StrategyDetail
                    industries={industries}
                    selections={selections}
                    scores={scores}
                    totalFiltered={totalFiltered}
                    backtest={backtest} />
            </Expandable>
        </div>
    );
}

export default Container;