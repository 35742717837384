import { SET_INDEX_LIBRARY, SET_INDEX_DATA, CLEAR_INDEX_DATA, INSERT_INDEX_DATA, REMOVE_INDEX_DATA } from "../actionTypes/_index";

const initialState = {
    indexLibrary: null,
    data: []
}
export default function reducer(state=initialState, action)
{
    switch(action.type) {
        case SET_INDEX_LIBRARY: {
            return {
                ...state,
                indexLibrary: action.indexLibrary,
            }
        }
        case SET_INDEX_DATA: {
            return {
                ...state,
                data: action.data
            }
        }
        case INSERT_INDEX_DATA: {
    
            // 已存在的股票
            const duplicateStock =  action.data.filter(newStock => 
                state.data.findIndex(oldStock => oldStock.stock_code === newStock.stock_code) !== -1
            )
            // 非已存在的股票
            const nonDuplicateStock =  action.data.filter(newStock => 
                state.data.findIndex(oldStock => oldStock.stock_code === newStock.stock_code) === -1
            )
            // 重複股票的指標資料放一個currentStockData
            let newData = state.data.reduce((accumulator, currentStockData) => {
                const currentDuplicateStock =  duplicateStock.find(o => o.stock_code === currentStockData.stock_code)
                if(currentDuplicateStock)
                {
                    return [
                        ...accumulator,
                        {
                            ...currentStockData,
                            data: [
                                ...currentStockData.data,
                                ...currentDuplicateStock.data
                            ]
                        }
                    ]
                } else {
                    return [...accumulator, currentStockData]
                }
            },[])

            // 非重複的股票
            newData = [
                ...newData,
                ...nonDuplicateStock
            ]

            return {
                ...state,
                data: newData
            }
        }
        case CLEAR_INDEX_DATA: {
            return {
                ...state,
                data: []
            }
        }
        case REMOVE_INDEX_DATA: {
            if(action.by === "index")
            {
                const data = state.data.reduce((accumulator, current) => {
                    const newData =
                        current.data.filter(o =>
                            o.code !== action.index.value.value ||
                            o.range !== action.index.range.value);

                    if(newData.length === 0)
                    {
                        return accumulator
                    } else {
                        return [
                            ...accumulator,
                            {
                                ...current,
                                data: newData
                            }
                        ]
                    }
                },[])
                return {
                    ...state,
                    data: data
                }
            } else {
                const data = state.data.filter(o => o.stock_code !== action.stockCode)
                return {
                    ...state,
                    data: data
                }
            }
        }

        default: {
            return state
        }        
    }
};