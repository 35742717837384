import Fetch from '../fetch'

/**
 * @memberof module:Fetch
 * @description 策略api
 */

const strategyApis = {
    // 取得某個策略
    item: {
        method: "GET",
        uri: id => "/user/strategies/" + id
    },
    // 策略清單
    list: {
        method: "GET",
        uri: () => "/user/strategies/"
    },
    // 策略股票
    stockList: {
        method: "GET",
        uri:  id => '/data/stocks/' + id
    },
    // 分享的清單
    sharedList: {
        method: "GET",
        uri: () => "/strategies"
    },
    trackSharedStrategy: {
        method: 'POST',
        uri: id => '/strategies/' + id
    },
    // 重新命名策略
    rename: {
        method: "POST",
        uri: id => "/user/strategies/" + id
    },
    // 更新策略
    update: {
        method: "POST",
        uri: id => "/user/strategies/" + id
    },
    // 複製策略
    copy: {
        method: "POST",
        uri: id => `/user/strategies/${id}/duplicated`
    },
    resetCopiedStrategy: {
        method:  "POST",
        uri:  id =>  `/user/strategies/${id}/reset`
    },
    // 刪除策略
    delete: {
        method: "DELETE",
        uri: id => "/user/strategies/" + id
    },
    // 取消回測
    cancel: {
        method: "POST",
        uri: '/user/strategies/backtest/cancel'
    },
    // 策略股票評分
    strategyScore: {
        method: "GET",
        uri: id => '/data/strategies/' + id
    },
    // 評分
    startScore: {
        method: "POST",
        uri: () => '/user/strategies/score'
    }
}

/**
 * @memberof module:Fetch
 * @function StrategyApi
 * @description 發送策略相關request
 */

export default function StrategyApi(type, params = null)
{
    // console.log("params");
    // console.log(params);
    // console.log("type");
    // console.log(type);
    return Fetch.request(
        strategyApis[type].method,
        strategyApis[type].uri,
        params
    )
};
