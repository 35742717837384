import React from 'react';
import {
    Button,
    Icon,
    Modal
} from 'rsuite';

import IconAdd from 'Image/svgIcon/dashboard/add.svg';
import IconAddBlue from 'Image/svgIcon/dashboard/add-blue.svg';
import StockPicker from 'Components/stockPicker/index.js';

import './style.scss';

const Component = ({
    stockPool,
    show,
    showModal,
    onModalHide,
    onStockSelected
}) => {
    return (
        <>
            <Icon
                icon={IconAdd}
                size='lg'
                onClick={showModal} />
            <Modal
                className="add-stock-modal"
                show={show}
                onHide={onModalHide}>
                <Modal.Header>
                    <Modal.Title>新增股票</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <StockPicker
                        clearOnSelect
                        icon={IconAddBlue}
                        data={stockPool}
                        onSelect={onStockSelected} />
                    <div className="text">按Enter新增股票</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onModalHide}>
                        完成
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Component;