import React from 'react';
import { useHistory } from 'react-router-dom';
import Component from './component';

const Container = ({ strategyId, step, scored }) => {
    const history = useHistory();

    const showStep1 = () => {
        if (!strategyId) { return; }

        const path = '/strategy/' + strategyId;

        // Do not push the path into history if the page is current page
        if (history.location.pathname === path) { return; }

        history.push(path);
    };

    const showStep2 = () => {
        if (!strategyId || !scored) { return; }

        const path = '/strategyScore/' + strategyId;

        // Do not push the path into history if the page is current page
        if (history.location.pathname === path) { return; }

        history.push(path);
    };

    const showStep3 = () => {
        if (!strategyId || !scored) { return; }

        const path = '/backtest/' + strategyId;

        // Do not push the path into history if the page is current page
        if (history.location.pathname === path) { return; }

        history.push(path);
    };

    return (
        <Component
            scored={scored}
            step={step}
            showStep1={showStep1}
            showStep2={showStep2}
            showStep3={showStep3} />
    );
};

export default Container;