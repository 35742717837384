import React, { useEffect } from 'react';
import AlertBox from '../alertBox';
import Api from '../../api';
import Notifications from '../messageBox'
import { useSelector, useDispatch } from 'react-redux'
import {
    TOGGLE_MESSAGE_BOX,
    CHECK_ALERT,
    CLEAR_ALERT,
    SET_ALL_TASKS_READ,
    TOGGLE_ALERT_BOX
} from 'Redux/actionTypes/messages';
import { FETCH_NOTIFICATIONS } from 'Redux/saga/actionTypes/notification'
import { useHistory } from 'react-router';
// import Api from  '../../../api';
//訊息
export default function Message(){
    const history = useHistory()
    const dispatch = useDispatch()
    const display = useSelector(store => store.messages.display)
    const alerts = useSelector(store => store.messages.alerts)
    const toggleMessageBox = (e, showMessageBox = null) => {
        e.stopPropagation();

        let newStatus =
            showMessageBox === null ?
                !display.showMessageBox : showMessageBox;

        dispatch({ type: TOGGLE_MESSAGE_BOX, showMessageBox: newStatus });

        // Mark all tasks as read when closing the message box
        if (!newStatus && display.ifAlert) {
            setAllTasksRead();
        }

        dispatch({ type: CLEAR_ALERT });
        dispatch({ type: TOGGLE_ALERT_BOX, showAlertBox: false });
    }

    const setAllTasksRead = async () => {
        const result = await Api.allTasksRead();
        if (!result) { return; }

        dispatch({ type: SET_ALL_TASKS_READ });
        dispatch({ type: CHECK_ALERT });
    }

    useEffect(() => {
        const getMessage = async () => {
            dispatch({ type: FETCH_NOTIFICATIONS })
        }
        getMessage()
    },[dispatch])
    useEffect(() => {
        window.addEventListener('click', function(){
            dispatch({ type: TOGGLE_MESSAGE_BOX, showMessageBox: false });
        })
        history.listen((location, action) => {
            dispatch({
                type: TOGGLE_MESSAGE_BOX, showMessageBox: false
            })
        })
    },[dispatch, history])

    return <div className="message">
        <div className="message__icon" onClick={toggleMessageBox}>
            {
                display.ifAlert ?
                    <div className="message__icon-alert">
                        <img
                            src={process.env.PUBLIC_URL + '/img/notification-active.gif'}
                            alt='notification' />
                    </div> :
                    <div className="message__icon">
                        <img
                            src={process.env.PUBLIC_URL + '/img/notification.gif'}
                            alt='notification' />
                    </div>
            }
        </div>
        <div className = "message_arrow">
            {display.showMessageBox ? '▲' : '▼'}
        </div>
        <div className="messsage__container">
            <div className = "messsage__container__contents">
                <AlertBox alerts={alerts} show={display.showAlertBox}/>
                {
                    display.showMessageBox ? <Notifications />: null
                }
            </div>
        </div>
    </div>
}