import Component from "../component"
import React, { useRef, useState } from "react";
import { 
    // useParams, 
    useHistory, useLocation, useRouteMatch } from "react-router-dom";
import API from 'Src/api';
import { useSelector } from "react-redux";
import { StoreLink } from '../../../../../constant/link';

function Container()
{
    const history = useHistory();
    const avatarRef = useRef();
    const [isAvatarMenuOpened, setIsAvatarMenuOpened] = useState();
    const stockPool = useSelector(store => store.stock.pool)
    const profile = useSelector(store => store.profile);
    const strategySidebarVisibility = useSelector(store => store.app.strategySidebarVisibility);
    const location = useLocation()
    let match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })

    let pricingPlan;
    switch (profile.pricing_plan) {
        case 'free': pricingPlan = '免費版'; break;
        case 'basic': pricingPlan = '付費版'; break;
        case 'pro': pricingPlan = '進階付費版'; break;
        case 'enterprise': pricingPlan = '企業版'; break;
        default: pricingPlan = '';
    }

    // alert(match.params.analysisId)
    const onOpenAvatarMenu = () => {
        setIsAvatarMenuOpened(true)
    }
    const onCloseAvatarMenu = () => {
        setIsAvatarMenuOpened(false)
    }
    const closeAvatarMenu = () => {
        avatarRef.current.close()
    }

    const showProfile = () => {
        window.open(
            'https://www.cnyes.com/member/info',
            '_blank'
        );
    };

    const showStore = () => {
        window.open(StoreLink, '_blank');
    };

    const showSubscription = () => {
        window.open(
            'https://www.cnyes.com/member/purchase?tab=subscribe',
            '_blank'
        );
    };

    const logout = () => {
        if (window.confirm('確認登出?')) {
            API.revokeToken();
            alert('已成功登出');
            window.location.href = '/dashboard';
        }
    }

    const onStockSelected = (stock) => {
        let path = '/stockAnalysis/' + stock.stock_code;
        // alert(stock.stock_code)
        if(match)
        {
            const { analysisId, strategyId } = match.params
            if (analysisId && strategyId) {
                // alert(match.params.analysisId)
                path += '/' + analysisId + '/' + strategyId;
            } else if (analysisId) {
                path += '/' + analysisId;
            }
        }
        const location = { pathname: path }
        history.push(location);
    }

    // useEffect(() => {
       
    //     /* eslint-disable-next-line */
    // },[location.pathname])

    // return <>{match.params.analysisId}</>
    return <Component 
        profile={profile}
        pricingPlan={pricingPlan}
        avatarRef={avatarRef}
        onStockSelected={onStockSelected}
        closeAvatarMenu={closeAvatarMenu}
        isAvatarMenuOpened={isAvatarMenuOpened}
        onOpenAvatarMenu={onOpenAvatarMenu}
        onCloseAvatarMenu={onCloseAvatarMenu}
        showProfile={showProfile}
        showStore={showStore}
        showSubscription={showSubscription}
        logout={logout}
        stockPool={stockPool}
        strategySidebarVisibility={strategySidebarVisibility}
        showStockPicker={location.pathname === "/login" || location.pathname === '/tutorial' ? false : true}
        showLoginButton={location.pathname === "/login" ? false : true}
    />
}

export default Container;