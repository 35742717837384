import * as Actions from "../../actionTypes/strategy/strategyOverviewPage"
export const initialState = {
    showRenameModal: false,
    renameModalId: '',
    renameModalName: '',
    page: 1,
    sortColumn: 'updated_at',
    sortType: 'desc',
    displayLength: 10,
    expandId: '',
    // mode: "table"
};

export default function reducer(state=initialState, action){
    switch(action.type) {
        case Actions.SHOW_RENAME_MODAL:{
            return {
                ...state,
                showRenameModal: true,
                renameModalId: action.renameModalId,
                renameModalName: action.renameModalName
            }
        }
        case Actions.CLOSE_RENAME_MODAL:{
            return {
                ...state,
                showRenameModal: false,
                currentId: "",
                currentName: ""
            }
        }
        
        case Actions.CHANGE_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case Actions.SORT_COLUMN: {
            return {
                ...state,
                sortColumn: action.sortColumn,
                sortType: action.sortType
            };
        }
        case Actions.SET_DISPLAY_MODE: {
            return {
                ...state,
                mode: action.mode
            }
        }
        case Actions.SET_EXPAND_ID: {
            return {
                ...state,
                expandId: action.expandId
            }
        }
        default: {
            return state
        } 
    }
}