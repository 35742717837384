import React, { Component } from 'react';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import {
    CellMeasurer,
    CellMeasurerCache,
    Grid
} from 'react-virtualized';
import { Icon } from 'rsuite';

import CellRangeRenderer from './cellRangeRenderer';
import IconDelete from '../../image/svgIcon/table/delete-grey.svg';
import IconEdit from '../../image/svgIcon/table/edit-grey.svg';
import IconUnsort from '../../image/svgIcon/table/sort.svg';
import IconSortAsc from '../../image/svgIcon/table/sort-asc.svg';
import IconSortDesc from '../../image/svgIcon/table/sort-desc.svg';
import IconSortHandle from '../../image/svgIcon/table/sort-handle.svg';
import TrackStockToggle from '../trackStockToggle';
import IndexUtil from '../../indexUtil';

import './dataTable.css';

var _ = require('lodash');

const SortableGrid = SortableContainer(Grid);
const StockDragHandle =
    SortableHandle(() => <Icon className="clickable" icon={IconSortHandle} />);

export default class DataTable extends Component {
    _cache = new CellMeasurerCache({
        defaultHeight: 35,
        defaultWidth: 100,
        fixedWidth: true,
        keyMapper: (rowIndex, columnIndex) => {
            if (!this.props.transpose) {
                // Measure the heights of the fixed rows and the first value row
                return (
                    rowIndex < this.props.fixedRowCount ?
                        'column-' + columnIndex + '-row-' + rowIndex :
                        'value'
                );
            } else {
                // Measure the heights of the fixed columns and the first value
                // column
                return (
                    columnIndex < this.props.fixedColumnCount ?
                        'row-' + rowIndex + '-header-' + columnIndex :
                        'row-' + rowIndex + '-value'
                );
            }
        }
    });

    constructor(props) {
        super(props);

        this.state = {
            stockList: props.stockList ? props.stockList : [],
            trackingStock: props.trackingStock ? props.trackingStock : [],
            transpose: false,
            sortColumn: '', // sorted column name
            sortType: '', // asc, desc
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.stockList, this.props.stockList)) {
            this.setState({ stockList: this.props.stockList });
        }

        if (!_.isEqual(prevProps.trackingStock, this.props.trackingStock)) {
            this.setState({ trackingStock: this.props.trackingStock });
        }

        if (
            _.isEqual(prevProps.stockList, this.props.stockList) &&
            _.isEqual(prevProps.columnList, this.props.columnList) &&
            _.isEqual(prevProps.stockPool, this.props.stockPool) &&
            _.isEqual(prevProps.tableData, this.props.tableData) &&
            prevProps.width === this.props.width &&
            prevProps.height === this.props.height &&
            prevProps.fixedRowCount === this.props.fixedRowCount &&
            prevProps.fixedColumnCount === this.props.fixedColumnCount &&
            prevProps.transpose === this.props.transpose
        ) {
            return;
        }

        this._cache.clearAll();

        if (prevProps.transpose !== this.props.transpose) {
            // Force fix the column width
            this.forceUpdate();
        }
    }

    // Method
    editColumn(groupIndex) {
        if (!this.props.onColumnEdit) { return; }

        this.props.onColumnEdit(groupIndex);
    }

    sortColumn(columnId) {
        let data = _.cloneDeep(this.props.tableData.data);
        let sortColumn = columnId;
        let sortType = 'asc';

        // Use stockList to control row order
        if (this.state.sortColumn === columnId) {
            sortType = this.state.sortType === 'asc' ? 'desc' : 'asc';
        }

        data.sort((a, b) => {
            let valueA = a[sortColumn].value;
            let valueB = b[sortColumn].value;

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return (
                    sortType === 'asc' ?
                        valueA.localeCompare(valueB) :
                        valueB.localeCompare(valueA));
            } else {
                return sortType === 'asc' ? valueA - valueB : valueB - valueA;
            }
        });

        let stockList = data.map(data => data.stock_code.value);

        this.setState({ sortColumn, sortType, stockList });
    }

    deleteColumn(groupIndex) {
        if (!this.props.onColumnDelete) { return; }

        this.props.onColumnDelete(groupIndex);
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        if (!this.props.onTrackingStockChange) { return; }

        this.props.onTrackingStockChange(groupId, stockId, tracked);
    }

    onSortEnd(props) {
        if (!this.props.onSortEnd) { return; }

        this.props.onSortEnd(props);
    }

    // Render
    cellRendererNormal(props) {
        let { columnIndex, key, parent, rowIndex, style, isVisible } = props;
        let className;
        let content = '';

        if (!isVisible) { return; }

        // Get column
        let column = null;
        let groupIndex = -1;

        if (columnIndex === 0) {
            // "data-table-stock" is used for styling while dragging
            className = 'stock';

            if (rowIndex !== 0) {
                className += ' data-table-stock';
            }
        } else {
            let result = this.getColumn(columnIndex - 1);

            className = 'value';
            column = result.column;
            groupIndex = result.groupIndex;
        }

        if (rowIndex === 0) {
            // Render header
            if (columnIndex === 0) {
                content =
                    <>
                        <div className="serial">序列</div>
                        <div className="code">
                            {this.renderTableHeader({ id: 'stock_code' })}
                        </div>
                        <div className="name">
                            {this.renderTableHeader({ id: 'stock_name' })}
                        </div>
                    </>;
            } else if (column) {
                content = this.renderTableHeader(column, groupIndex);
            }
        } else {
            let stockCode = this.state.stockList[rowIndex - 1];
            let stock =
                this.props.stockPool.find(x => x.stock_code === stockCode);

            if (columnIndex === 0) {
                content =
                    <>
                        <div className="serial">
                            <div className="number">{rowIndex}</div>
                            <div className="buttons">
                                <Icon
                                    className="clickable"
                                    icon={IconDelete}
                                    onClick={() => this.removeStock(stockCode)} />
                                <StockDragHandle />
                            </div>
                        </div>
                        <div className="code">
                            <TrackStockToggle
                                id={stockCode}
                                trackingStock={this.state.trackingStock}
                                onTrackingStockChange={
                                    (groupId, stockId, tracked) =>
                                        this.onTrackingStockChange(
                                            groupId, stockId, tracked)} />
                            {stockCode}
                        </div>
                        <div className="name">
                            {stock ? stock.stock_name : ' '}
                        </div>
                    </>;
            } else {
                // Render value
                if (this.props.tableData && this.props.tableData.data) {
                    let row =
                        this.props.tableData.data.find(x =>
                            x.stock_code.value === stockCode);

                    content = row ? this.renderTableCell(row, column) : '';
                } else {
                    content = '';
                }
            }
        }

        if (columnIndex === 0 && rowIndex !== 0) {
            let Element =
                SortableElement(() => (
                    <CellMeasurer
                        cache={this._cache}
                        columnIndex={columnIndex}
                        key={key}
                        parent={parent}
                        rowIndex={rowIndex}>
                        <div className={className} style={{ ...style }}>
                            {content}
                        </div>
                    </CellMeasurer>
                ));

            return <Element index={rowIndex} key={rowIndex} />;
        } else {
            return (
                <CellMeasurer
                    cache={this._cache}
                    columnIndex={columnIndex}
                    key={key}
                    parent={parent}
                    rowIndex={rowIndex}>
                    <div className={className} style={{ ...style }}>
                        {content}
                    </div>
                </CellMeasurer>
            );
        }
    }

    cellRendererTranspose(props) {
        let { columnIndex, key, parent, rowIndex, style, isVisible } = props;
        let className;
        let content = '';

        if (!isVisible) { return; }

        // Get column
        let column = null;
        let groupIndex = -1;

        if (rowIndex === 0) {
            // "data-table-stock" is used for styling while dragging
            className = 'stock';

            if (rowIndex !== 0) {
                className += ' data-table-stock';
            }
        } else {
            let result = this.getColumn(rowIndex - 1);

            className = 'value';
            column = result.column;
            groupIndex = result.groupIndex;
        }

        if (columnIndex === 0) {
            if (rowIndex === 0) {
                content =
                    <>
                        <div className="serial">序列</div>
                        <div className="code">
                            {this.renderTableHeader({ id: 'stock_code' })}
                        </div>
                        <div className="name">
                            {this.renderTableHeader({ id: 'stock_name' })}
                        </div>
                    </>;
            } else {
                content = this.renderTableHeader(column, groupIndex);
            }
        } else {
            let stockCode = this.state.stockList[columnIndex - 1];
            let stock =
                this.props.stockPool.find(x => x.stock_code === stockCode);

            if (rowIndex === 0) {
                content =
                    <>
                        <div className="serial">
                            <div className="number">{columnIndex}</div>
                            <div className="buttons">
                                <Icon
                                    className="clickable"
                                    icon={IconDelete}
                                    onClick={() => this.removeStock(stockCode)} />
                            </div>
                        </div>
                        <div className="code">
                            <TrackStockToggle
                                id={stockCode}
                                trackingStock={this.state.trackingStock}
                                onTrackingStockChange={
                                    (groupId, stockId, tracked) =>
                                        this.onTrackingStockChange(
                                            groupId, stockId, tracked)} />
                            {stockCode}
                        </div>
                        <div className="name">
                            {stock ? stock.stock_name : ' '}
                        </div>
                    </>;
            } else {
                // Render value
                if (this.props.tableData && this.props.tableData.data) {
                    let row =
                        this.props.tableData.data.find(x =>
                            x.stock_code.value === stockCode);

                    content = row ? this.renderTableCell(row, column) : '';
                } else {
                    content = '';
                }
            }
        }

        return (
            <CellMeasurer
                cache={this._cache}
                columnIndex={columnIndex}
                key={key}
                parent={parent}
                rowIndex={rowIndex}>
                <div className={className} style={{ ...style }}>
                    {content}
                </div>
            </CellMeasurer>
        );
    }

    getColumn(index) {
        if (this.props.columnList) {
            let startIndex = 0;

            for (var i = 0; i < this.props.columnList.length; i++) {
                let columns = this.props.columnList[i];

                if (index < startIndex + columns.length) {
                    return { column: columns[index - startIndex], groupIndex: i };
                } else {
                    startIndex += columns.length;
                }
            };
        }

        return { column: null, groupIndex: -1 };
    }

    renderTableHeader(column, groupIndex) {
        let iconSort = IconUnsort;

        if (column.id === this.state.sortColumn) {
            iconSort =
                this.state.sortType === 'asc' ? IconSortAsc : IconSortDesc;
        }

        let headerName;
        if (groupIndex !== undefined) {
            headerName = IndexUtil.getTableHeaderIndexText(column);
        } else if (column.id === 'stock_code') {
            headerName = '代號';
        } else if (column.id === 'stock_name') {
            headerName = '股票名稱';
        }

        return (
            <>
                <div className="header-button-container">
                    {
                        groupIndex !== undefined &&
                        <Icon
                            icon={IconEdit}
                            onClick={() => this.editColumn(groupIndex)} />
                    }
                    <Icon
                        icon={iconSort}
                        onClick={() => this.sortColumn(column.id)} />
                    {
                        groupIndex !== undefined &&
                        <Icon
                            icon={IconDelete}
                            onClick={() => this.deleteColumn(groupIndex)} />
                    }
                </div>
                {headerName}
            </>
        );
    }

    renderTableCell(row, column) {
        if (column.id === 'stock_code' || column.id === 'stock_name') {
            return;
        }

        if (row[column.id].text) {
            return (
                <td>
                    <div dangerouslySetInnerHTML={
                        { __html: row[column.id].text }} />
                </td>
            );
        }

        return (
            row[column.id].value ?
                row[column.id].value.toLocaleString() : row[column.id].value
        );
    }

    getColumnWidth(index) {
        if (!this.props.transpose) {
            return index === 0 ? 235 : 120;
        } else {
            return index === 0 ? 120 : 97;
        }
    }

    render() {
        let className = 'data-table';

        if (this.props.transpose) {
            className += ' transpose';
        }

        let columnCount =
            this.props.columnList.reduce(
                (prev, current) => prev += current.length, 0
            ) + this.props.fixedColumnCount;

        let rowCount =
            this.state.stockList && this.props.fixedRowCount !== undefined ?
                this.state.stockList.length + this.props.fixedRowCount : 0;

        return (
            <SortableGrid
                useDragHandle
                className={className}
                columnCount={!this.props.transpose ? columnCount : rowCount}
                columnWidth={({ index }) => this.getColumnWidth(index)}
                deferredMeasurementCache={this._cache}
                height={this.props.height}
                overscanColumnCount={5}
                overscanRowCount={20}
                estimatedRowSize={!this.props.transpose ? 35 : 75}
                estimatedColumnSize={120}
                cellRenderer={props => !this.props.transpose ? this.cellRendererNormal(props) : this.cellRendererTranspose(props)}
                cellRangeRenderer={props => !this.props.transpose ? CellRangeRenderer(props, this.props.fixedRowCount, this.props.fixedColumnCount) : CellRangeRenderer(props, this.props.fixedColumnCount, this.props.fixedRowCount)}
                rowCount={!this.props.transpose ? rowCount : columnCount}
                rowHeight={this._cache.rowHeight}
                width={this.props.width}
                onSortEnd={props => this.onSortEnd(props)} />
        );
    }
}