const ServiceTerms = {
    title: '服務條款',
    content:
        '易策略有限公司(下稱本公司)係依據本服務條款提供鉅亨易策略網站(下稱本網站)相關服務。\n' +
        '\n' +
        '本服務條款包括(一)以下條款與規則；(二)本網站的隱私權政策；(三)免責聲明。\n' +
        '\n' +
        '請您務必審慎閱讀、充分理解本服務條款，否則您應停止使用本網站相關服務，當您繼續使用本網站之相關服務或註冊為本網站之會員（包含以第三方提供之身分驗證機制創立之帳號）時，即表示您已閱讀、了解且同意接受本服務條款之所有內容。\n' +
        '\n' +
        '本服務條款亦為您與本公司間契約關係之依據。\n' +
        '\n' +
        '本公司有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將會更新於本網站上，本公司不會另行通知，本公司建議您隨時注意本服務條款之修改或變更。\n' +
        '\n' +
        '您於本服務條款修改或變更後繼續使用本網站提供之服務時，視為您已閱讀、了解且同意本服務條款修改或變更後的內容。\n' +
        '\n' +
        '如您不同意前述內容，或不接受本服務條款的其他部分，請您立即終止使用本網站。\n' +
        '\n' +
        '您認知並同意，您必須遵守提供應用程式之平台業者（如Google、Facebook等提供者，下稱「平台業者」）之條款以使用本網站。\n' +
        '\n' +
        '您同意本公司依據平台業者或您所使用之身分驗證機制提供者與您或本公司間之條款，提供本公司所收集之某些資訊予該等平台業者與身分驗證機制提供者，或自該等平台業者與身分驗證機制提供者接收您的個人資訊。\n' +
        '\n' +
        '一 、本網站服務範圍\n' +
        '\n' +
        '本網站所提供之資訊服務包括但不限於以下幾點：\n' +
        '\n' +
        '(一)   動態新聞；\n' +
        '\n' +
        '(二)   投資研究統計；\n' +
        '\n' +
        '(三)   文章與投資策略分享；\n' +
        '\n' +
        '(四)   選股工具；\n' +
        '\n' +
        '(五)   個股資訊；\n' +
        '\n' +
        '(六)   財務熱圖；\n' +
        '\n' +
        '(七)   個股評價等工具。\n' +
        '\n' +
        '二、本網站之收費機制\n' +
        '\n' +
        '(一)   本網站針對不同的投資組合以及資訊內容，訂定不同的服務價格，您應依據本網站提供的服務價格購買本網站之服務。\n' +
        '\n' +
        '(二)   您同意使用本網站購買服務時，於本網站通知確認交易成立前，本網站仍有不接受您的訂購之權利。您向本網站發出訂購通知後，關於交易成立與否本網站將可在「訂單查詢」頁面確認。若因標的服務之交易條件有誤時(包括但不限於價錢、服務內容、圖片)，本網站得於您訂購後三日內取消該筆交易。\n' +
        '\n' +
        '(三)   如您以信用卡刷卡扣款，本公司會於交易成立後始向信用卡收單業務機構請求帳款。\n' +
        '\n' +
        '三、未成年人保護條款\n' +
        '\n' +
        '(一)   若您未滿二十歲，您須提出法定代理人或監護人之書面同意予本公司後，始得購買本網站之加值服務。\n' +
        '\n' +
        '(二)   若您未滿二十歲，您及您的法定代理人或監護人皆應閱讀、了解且同意本服務條款，始得開始使用本網站或註冊為本網站之會員。當您開始使用本網站之相關服務或註冊為本網站之會員，即表示您及您的法定代理人或監護人已閱讀、了解且同意接受本服務條款之所有內容。若您於本服務條款修改或變更後繼續使用本網站提供之服務時，視為您及您的法定代理人或監護人已閱讀、了解且同意本服務條款修改或變更後的內容。\n' +
        '\n' +
        '四、會員註冊義務\n' +
        '\n' +
        '(一)   為了能使用本服務，您同意以下事項：\n' +
        '\n' +
        '1. 您保證註冊時係提供正確、最新及完整的個人資料；\n' +
        '\n' +
        '2. 請勿重複註冊；\n' +
        '\n' +
        '3. 請勿以第三人名義或以虛偽姓名註冊；\n' +
        '\n' +
        '4. 維持並更新您個人資料，以確保相關資料的正確性、最新性及完整性。\n' +
        '\n' +
        '(二)   若您違反本條之前開規定或未依本網址之要求提供資料或欠缺必要的資料時，本公司有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。\n' +
        '\n' +
        '(三)   您同意，根據相關法律法規和/或相關監管機構要求，並出於對本網站的使用，本公司有權隨時進行任何或所有相關的身份、信用及其他方面的核查。您同意配合提供與該核查有關的所有資料。若您的帳號有涉及違法情事，本公司有權通過任何本公司認為適當的方式，來查核凍結或限制您的帳號。\n' +
        '\n' +
        '(四)   如您登錄資料不實或冒用他人名義，以致個人權利受有損害或有侵害他人之權利或有違反法令時，應自負相關之法律責任。如果本公司因您違反本條約定而受有損害，您應承擔本公司蒙受的任何及全部損失，本公司得向您請求損害賠償。\n' +
        '\n' +
        '五、會員帳號、密碼及安全\n' +
        '\n' +
        '(一)   您同意並保證您於本網站註冊之帳號及密碼，僅供您使用。\n' +
        '\n' +
        '(二)   您於本網站註冊帳號時，可以自行選擇用戶名稱及密碼，您必須對該相關資料進行保密。\n' +
        '\n' +
        '(三)   您應對個人帳號中的所有行為負責。\n' +
        '\n' +
        '(四)   您的密碼得依本網站提供之修改機制進行變更。本公司不得主動詢問您的密碼。\n' +
        '\n' +
        '(五)   您應隨時保持個人帳號中帳號個人資訊之正確性，如本公司無法依據您留存之帳號個人資料確認使用者身分，本公司有拒絕您依據條款要求服務之權利。\n' +
        '\n' +
        '(六)   如您發現帳號密碼不慎遺失或遭冒用，本公司於接獲您的通知後，應立即停止或凍結您於本網站之帳號。如您請求本公司提供被冒用其間之交易資訊相關資料，本公司應協同您進行查證。\n' +
        '\n' +
        '六、隱私權政策\n' +
        '\n' +
        '您的個人資料依照本網站之隱私權政策受到保護與規範，有關本網站隱私權保護，請詳閱本網站「隱私權政策」，以了解並保障您的權益。\n' +
        '\n' +
        '七、本公司之保密義務\n' +
        '\n' +
        '本公司因交易而知悉或持有您之交易紀錄及其他相關資料，應予保密，並不得為不當使用。本公司如違反前項規定致您受有損害者，應負賠償責任。\n' +
        '\n' +
        '八、限制責任\n' +
        '\n' +
        '(一)   本網站必須特別聲明：投資必有風險，您必須謹慎、理性地判讀各種市場資訊並為個人投資決策及其後果負起盈虧責任。\n' +
        '\n' +
        '(二)   本網站各項服務係依據公開資訊觀測站、台灣證券交易所與櫃檯買賣中心之資料直接呈現，或綜整；圖表化；工具化等方式呈現，已力求正確與即時。但不保證本網站的效能、速度與安全性，以及資訊內容完整、正確與可靠性等。其可能發生錯誤、遺漏、瑕疵、中斷與延遲等狀況，一切資料仍以公開資訊觀測站、台灣證券交易所與櫃檯買賣中心的資訊為準。如發生相關狀況，本公司不負擔相關之損害賠償及法律責任。\n' +
        '\n' +
        '(三)   本網站所提供之各項資訊服務，包括但不限於其他使用者或投資達人張貼的文章、評論及意見等，皆不具立即性、統一適用性，不應視為本公司的投資建議，亦不代表本公司立場。相關資訊的真實性、完整性、可信度有賴您自行審度，您務必多方蒐集資訊參考佐證，自行分析且獨立思考判斷，不可直接依賴本網站服務進行投資決策，相關風險應自行承擔。若因此發生直接或衍生之損失，本公司恕不負擔任何損害賠償及法律責任。\n' +
        '\n' +
        '(四)   此外，本公司之關係企業、相關管理人員及董事、以及所屬員工或代理人在不違反法令之限度內，對以下原因所引發之任何損害，不負賠償之責：\n' +
        '\n' +
        '1. 本網站服務之可能錯誤、延遲、遺漏、瑕疵、中斷或停止傳輸；\n' +
        '\n' +
        '2. 第三方駭客攻擊或藉由本網站服務傳輸惡意軟體、病毒、木馬或類似性質之程式；\n' +
        '\n' +
        '3. 本網站受託廣告刊登行為，或您自行對廣告商或贊助人所發生的任何交易或行為\n' +
        '\n' +
        '4. 本網站對提供服務之範圍與內容，以及相關免責聲明、服務條款、隱私權政策等規範所進行的任何異動之行為（ 例如：一部分或全部性的變更、修改、暫時停止服務、永久停止服務等異動行為；\n' +
        '\n' +
        '5. 本網站使用者違反中華民國法律及本網站各項服務條款、承諾、義務與注意事項之行為。\n' +
        '\n' +
        '九、本網站使用者之守法義務及承諾\n' +
        '\n' +
        '(一)   不論您是否為中華民國之使用者，您承諾遵守中華民國相關法規及一切使用網際網路之國際慣例，不得以任何非法目的或以任何非法方式使用本網站，亦不得利用本網站從事侵害他人權益或違法之行為，包括但不限於：\n' +
        '\n' +
        '1. 上載、張貼、公布或傳送任何違反公共秩序或善良風俗的文字、圖片或任何形式的檔案於本網站上；\n' +
        '\n' +
        '2. 侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、智慧財產權及其他權利；\n' +
        '\n' +
        '3. 違反依法律或契約所應負之保密義務；\n' +
        '\n' +
        '4. 冒用他人名義使用本網站；\n' +
        '\n' +
        '5. 上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料；\n' +
        '\n' +
        '6. 從事散布虛假不實之訊息；\n' +
        '\n' +
        '7. 濫發廣告訊息與違法之多層次傳銷訊息等；\n' +
        '\n' +
        '8. 偽造訊息來源或以任何方式干擾傳輸來源之認定；\n' +
        '\n' +
        '9. 其他不符本網站所提供的使用目的之行為或本公司認為不適當之行為。\n' +
        '\n' +
        '(二)   您若於本網站進行違法或侵害他人之行為，本公司將盡力配合相關執法單位進行調查。\n' +
        '\n' +
        '(三)   如果本公司因您違反本條約定而受有損害，您應承擔本公司蒙受的任何及全部損失，本公司得向您請求損害賠償。\n' +
        '\n' +
        '十、智慧財產權\n' +
        '\n' +
        '(一)   本網站上所有內容，包括但不限於著作、圖文資訊、網站架構及畫面配置、網頁設計等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權與著作權。任何人不得逕自使用、修改、重製、公開傳輸、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。\n' +
        '\n' +
        '(二)   若您欲引用或轉載本網站內容，必須依法取得本公司或其他權利人的事前書面同意，並註明來源與出處。\n' +
        '\n' +
        '(三)   若發現本網站之任何網頁內容或其他使用者使用服務時，已侵害您或他人的智慧財產權，請通知本公司，本公司將盡速查證排除相關侵害。\n' +
        '\n' +
        '(四)   您使用本服務而涉及侵權情事時，本公司有權暫停或終止您全部或部分服務。\n' +
        '\n' +
        '(五)   如果本公司因您違反本條約定而受有損害，您應承擔本公司蒙受的任何及全部損失，本公司得向您請求損害賠償。\n' +
        '\n' +
        '十一、不得為商業利用\n' +
        '\n' +
        '您同意不對本網站之任何部分或本網站之使用或存取，進行重製、拷貝、出售、交易、轉售或作任何商業目的之使用。如果本公司因您違反本條約定而受有損害，您應承擔本公司蒙受的任何及全部損失，本公司得向您請求損害賠償。\n' +
        '\n' +
        '十二、本網站之服務變更與終止\n' +
        '\n' +
        '(一)   您同意本公司得於本網站所提供之服務範圍內，視業務需要及實際情形，增減、變更或終止相關服務的項目或內容，且無需個別通知使用者。\n' +
        '\n' +
        '(二)   您同意本公司得於下列情形下暫停或終止本網站之運作：\n' +
        '\n' +
        '1. 本網站電子通信設備進行必要之保養、維護及進行相關施工時；\n' +
        '\n' +
        '2. 發生突發性之電子通信設備故障時；\n' +
        '\n' +
        '3. 本網站申請之電子通信服務被停止，無法提供服務時；\n' +
        '\n' +
        '4. 因天災、不可抗力之因素或其他不可歸責於本公司致使本網站無法提供服務時。\n' +
        '\n' +
        '十三、使用者對本公司之授權\n' +
        '\n' +
        '(一)   您同意在本網站內自行公開傳輸之資料、所分享之訊息與文章或提供之各種檔案，皆為合法、或具合法授權，且無涉個人隱私，並同意本網站蒐集、處理、保存、傳遞並合法授權使用該等資訊，或作成會員統計資料、或進行網路行為之相關研究與調查、或為任何商業性及非商業性用途、或為任何之合法使用等行為，以繼續精進本公司資訊服務內容。\n' +
        '\n' +
        '(二)   若您無合法獲得他人授權使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，請勿擅自將該資料上載、傳送、輸入或提供至本網站。\n' +
        '\n' +
        '(三)   您應保證您提供的任何資料未侵害任何第三人之智慧財產權，若本公司因您於本網站內自行公開傳輸之資料、所分享之訊息與文章或提供之各種檔案受到第三人主張權利，您應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。\n' +
        '\n' +
        '十四、退費政策\n' +
        '\n' +
        '(一)   您認知且了解依照通訊交易解除權合理例外情事適用準則，本網站提供之付費服務無消費者保護法第19條得於接受服務後七日內無條件解除契約並請求退費之適用。\n' +
        '\n' +
        '(二)   如您於購買本網站1年以上(包含1年)之服務後，依本條第一項終止契約時，本公司就您已繳之全部費用依下列方式退還餘額：\n' +
        '\n' +
        '1. 自開始使用付費服務之日起1個月內(包含1個月整)請求終止契約，本公司應退還__80__%之餘額。\n' +
        '\n' +
        '2. 自開始使用付費服務之日起1個月到3個月內(包含3個月整)請求終止契約，本公司應退還__50__%之餘額。\n' +
        '\n' +
        '3. 自開始使用付費服務之日起3個月到6個月內(包含6個月整)請求終止契約，本公司應退還__20__%之餘額。\n' +
        '\n' +
        '4. 自開始使用付費服務之日起6個月到9個月內(包含9個月整)請求終止契約，本公司應退還__5__%之餘額。\n' +
        '\n' +
        '5. 自開始使用付費服務之日起9個月後，本公司不予退費\n' +
        '\n' +
        '(三)   因不可抗力事件，致本網站無法繼續提供服務時，您及本公司之任何一方均得通知對方終止契約。契約終止後，本公司應按比例返還您相當於終止契約前尚未使用之本網站服務餘額。\n' +
        '\n' +
        '十五、準據法及管轄法院\n' +
        '\n' +
        '本服務條款之解釋與適用，以及與本服務條款有關會員與本公司間之爭議或糾紛，雙方同意本誠信原則解決之，如有訴訟之必要，應依照中華民國法律處理，同意以台灣台北地方法院為第一審管轄法院。'
};

export default ServiceTerms;