import * as Actions from "../../actionTypes/dashboard/dashboard";

const initialState = {
    activePanelList: [],
    layout: [],
    panelDataList: []
}
// activePanelList: 顯示在畫面上的panel
export default function reducer(state=initialState ,action){
    switch(action.type) {
        case Actions.INSERT_PANEL_DATA: {
            if(state.panelDataList.find(o => o.id === action.id))
            {
                const newPanelDataList =  state.panelDataList.map(o => {
                    if(o.id === action.id)
                    {
                        return {
                            ...o,
                            id: action.id,
                            rawData: {
                                ...action.data,
                            }
                        }
                    } else {
                        return o
                    }
                })
                return {
                    ...state,
                    panelDataList: newPanelDataList
                }
            } else {
                // console.log('re');
                return {
                    ...state,
                    panelDataList: [
                        ...state.panelDataList,
                        {
                            id: action.id,
                            rawData: action.data
                        }
                    ]
                } 
            }
        }
        case Actions.INSERT_ACTIVEPANELLIST: {
            return {
                ...state,
                activePanelList: [
                    ...state.activePanelList,
                    action.activePanel
                ]
            };
        }
        case Actions.UPDATE_PANEL_DATA: {
            const newPanelDataList = state.panelDataList.map((o) => {
                if(o.id !== action.id)
                {
                    return o
                } else {
                    return {
                        ...o,
                        ...action.properties
                    }
                }
            })
            return {
                ...state,
                panelDataList: newPanelDataList
            };
        }
        case Actions.UPDATE_DASHBOARD_LAYOUT: {
            return {
                ...state,
                layout: action.layout
            }
        }
        case Actions.REPLACE_ACTIVEPANELLIST: {
            return {
                ...state,
                activePanelList: action.activePanelList
            }
        }
        case Actions.CLEAR_ACTIVEPANELLIST: {
            return {
                ...state,
                activePanelList: []
            }
        }
        case Actions.CLEAR_DASHBOARD_LAYOUT: {
            return {
                ...state,
                layout: []
            }
        }
        case Actions.CLEAR_DASHBOARD: {
            return {
                ...state,
                activePanelList: [],
                layout: [],
                panelDataList: []
            }
        }
        case Actions.CLEAR_FAVORITE_GROUP: {
            const targetPanel = state.panelDataList.find(o => o.id === action.panelId)
            const targetTabIndex = targetPanel.rawData.main.tab.findIndex(o => o.id === action.groupId)
            targetPanel.rawData.main.tab[targetTabIndex] = {
                ...targetPanel.rawData.main.tab[targetTabIndex],
                items: []
            }
            const newTargetPanel = {
                ...targetPanel
            };
            const newPanelDataList = state.panelDataList.map(o => {
                if(o.id === action.panelId)
                {
                    return newTargetPanel;
                } else {
                    return o;
                }
            })
            return {
                ...state,
                panelDataList: newPanelDataList
            }
        }
        default: {
            return state;
        }
    }
}