import Component from "./component"
import React from 'react';
import moment from 'moment';

import API from 'Src/api';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deepEqual } from "Util/deepEqual"
import { FETCH_STRATEGY } from "Redux/saga/actionTypes/strategy"
import { FETCH_BACKTEST_PREVIEW, START_BACKTEST } from "Redux/saga/actionTypes/backtest";
import { CLEAR_PREVIEW } from "Redux/actionTypes/backtest";
import { UPDATE_BACKTEST_SETTING } from "Redux/actionTypes/strategy/strategyDesign"
import { showNotice } from 'Redux/actionCreators/notice';

function Container()
{
    const { id } = useParams();
    const history = useHistory();

    const indexLibrary = useSelector(store => store.index.indexLibrary, deepEqual);
    const backtestSetting = useSelector(store => store.strategyDesign.backtestSetting, deepEqual)
    const preview = useSelector(store => store.backtest.preview, deepEqual)
    const trackingStock = useSelector(store => store.stock.trackingStock);
    const dispatch = useDispatch();
    // const history = useHistory();
    const onBacktestChange = (setting) => {
        dispatch({
            type: UPDATE_BACKTEST_SETTING,
            setting: setting
        })
        // setState({ ...state, backtestSetting: setting });
    }

    const checkBacktestDate = () => {
        if (
            !backtestSetting.backtestDate ||
            backtestSetting.timespan === 'custom'
        ) {
            return true;
        }

        if (moment(backtestSetting.backtestDate).isAfter(moment())) {
            showNotice(
                '請將結束日往前調整',
                '回測結束日不可晚於今日，請將結束日往前調整'
            );

            return false;
        }

        return true;
    }

    const onPreviousStepClick = () => {
        history.push('/strategyScore/' + id);
    }

    const onPreviewClick = () => {
        // Check backtest date
        if (!checkBacktestDate()) { return; }

        const _backtestSetting = API.prepareBacktestSetting(backtestSetting);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('backtest', JSON.stringify(_backtestSetting));

        dispatch({
            type: FETCH_BACKTEST_PREVIEW,
            payload: {
                id: id,
                formData: formData
            }
        })
    }

    const onBacktestClick = () => {
        // Check backtest date
        if (!checkBacktestDate()) { return; }

        let _backtestSetting = API.prepareBacktestSetting(backtestSetting);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('backtest', JSON.stringify(_backtestSetting));
        dispatch({
            type: START_BACKTEST,
            payload: {
                id: id,
                formData: formData
            }
        })
    }

    useEffect(() => {
        if(indexLibrary)
        {
            dispatch({
                type: FETCH_STRATEGY,
                id: id
            });
        }
        return () => {
            dispatch({
                type: CLEAR_PREVIEW
            })
        }
    /* eslint-disable-next-line */
    },[indexLibrary])
    return <Component
        id={id}
        onBacktestClick={onBacktestClick}
        backtestSetting={backtestSetting}
        onPreviousStepClick={onPreviousStepClick}
        onBacktestChange={onBacktestChange}
        onPreviewClick={onPreviewClick}
        preview={preview}
        trackingStock={trackingStock}
    />
}

export default Container;