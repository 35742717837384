import Fetch from '../fetch';
/**
 * @constant
 * @memberof module:Fetch
 * @description Profile API
 */
const profileApi = {
    profile: {
        method: 'GET',
        uri: () => '/user/profile'
    },
    plan: {
        method: 'GET',
        uri: () => '/user/plan'
    }
}

/**
 * @memberof module:Fetch
 * @function BackTesetApi
 * @description 發送回測相關request
 */
export default function IndexApi(type, params = null) {
    return Fetch.request(
        profileApi[type].method,
        profileApi[type].uri,
        params
    )
};