export const INSERT_SELECTED_STOCK = "INSERT_SELECTED_STOCK";
export const REMOVE_SELECTED_STOCK = "REMOVE_SELECTED_STOCK";
export const INSERT_SELECTED_INDEX = "INSERT_SELECTED_INDEX";
export const REMOVE_SELECTED_INDEX = "REMOVE_SELECTED_INDEX";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_LINE_VISIBLE = "SET_LINE_VISIBLE";
export const SET_GRAPH_START = "SET_GRAPH_START";
export const SET_GRAPH_TYPE = "SET_GRAPH_TYPE";
export const SET_STOCK_VISIBLE = "SET_STOCK_VISIBLE";
export const SET_INDEX_VISIBLE = "SET_INDEX_VISIBLE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const RESET_GRAPH = "RESET_GRAPH";