import React, { useState } from 'react';
import { Icon } from 'rsuite';

import './style.scss';

const Container = ({ items, onHide }) => {
    const [page, setPage] = useState(1);

    if (items == null) { return <></>; }

    let item = items[page - 1];

    if (item == null) { return <></>; }

    const previousPage = () => {
        if (page === 1) { return; }

        setPage(page - 1);
    };

    const nextPage = () => {
        if (page === items.length) { return; }

        setPage(page + 1);
    };

    return (
        <div className="tutorial-modal" onClick={onHide}>
            <div className="container" onClick={event => event.stopPropagation()}>
                <Icon className="close" icon="close" onClick={onHide} />
                <div className="image">
                    <div className="left">
                        {
                            items.length > 1 &&
                            <Icon
                                className={page === 1 ? 'disabled' : ''}
                                icon="angle-left"
                                size="5x"
                                onClick={previousPage} />
                        }
                    </div>
                    <img src={item.image} alt="" />
                    <div className="right">
                        {
                            items.length > 1 &&
                            <Icon
                                className={page === items.length ? 'disabled' : ''}
                                icon="angle-right"
                                size="5x"
                                onClick={nextPage} />
                        }
                    </div>
                </div>
                <div className="image-title">{item.title}</div>
                <div className="content">{item.content}</div>
            </div>
        </div>
    );
};

export default Container;