import moment from 'moment';
import React, { Component } from 'react';
import { Button, Icon } from 'rsuite';
import MetaTags from 'react-meta-tags';
import { cookies } from "Https/fetch";
import API from '../api';
import ProfileApi from 'Api/profile';
import './loginPage.css';
import MobileAlertModal from 'Components/mobileAlertModal';
import showFreeVersionNotice from '../utils/showFreeVersionNotice';

import LogoCnyes from 'Image/svgIcon/logo-cnyes.svg';
import LogoLarge from 'Image/svgIcon/logo-large.svg';
import { isMobile } from 'react-device-detect';

// const cookies = new Cookies();

export default class LoginPage extends Component {
    state = {
        isMobileAlertModalOpen: false
    };

    async componentDidMount() {
        let url = new URL(window.location.href);
        let code = url.searchParams.get('code');

        if (code) {
            let result = await API.getCnyesAccessToken(code);

            this.handleExchangedToken(result?.access_token);
        }

        this.initFacebookSDK();
    }

    initFacebookSDK() {
        // Load Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '662608717716774',
                cookie: true,
                xfbml: true,
                version: 'v12.0'
            });
        };

        // Load the SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id))
                return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/zh_TW/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    setEmail(value) {
        this.setState({ email: value });
    }

    setPassword(value) {
        this.setState({ password: value });
    }

    handleFacebookLogin() {
        window.FB.login(
            response =>
                this.handleResponse({ tokenDetail: response.authResponse }),
            { scope: 'email' }
        );
    }

    async handleResponse(data) {
        // Exchange token with server
        let result =
            await API.exchangeAccessToken(data?.tokenDetail?.accessToken);

        this.handleExchangedToken(result?.access_token);
    }

    handleError(error) {
        console.log(error);
        alert('登入失敗');
    }

    handleUALogin() {
        let domain = window.location.protocol + '//' + window.location.host;
        let timestamp = moment().unix();
        window.location.href = process.env.REACT_APP_API_HOST + '/oauth/api/v1/authorize?client_id=3&redirect_uri=' + domain + '/login&response_type=code&scope=&state=' + timestamp;
    }

    handleCnyesLogin() {
        const host =
            process.env.REACT_APP_USE_CNYES_OAUTH_DEV !== 'yes' ?
                process.env.REACT_APP_CNYES_OAUTH_HOST :
                process.env.REACT_APP_CNYES_OAUTH_HOST_DEV;
        const clientId =
            process.env.REACT_APP_USE_CNYES_OAUTH_DEV !== 'yes' ?
                process.env.REACT_APP_CNYES_OAUTH_CLIENT_ID :
                process.env.REACT_APP_CNYES_OAUTH_CLIENT_ID_DEV;
        let domain = window.location.protocol + '//' + window.location.host;
        let timestamp = moment().unix();

        window.location.href =
            host + '/oauth/api/v1/authorize?client_id=' + clientId +
            '&redirect_uri=' + domain +
            '/login&response_type=code&scope=user:email user:name user:subscription client:products&state=' +
            timestamp;
    }

    async handleExchangedToken(token) {
        if (!token) {
            alert('登入失敗');
            return;
        }

        cookies.set('accessToken', token, { path: '/' });

        const result = await ProfileApi('plan');
        const pricingPlan = result?.pricing_plan;

        if (
            isMobile && (
                !cookies.get('dismissMobileAlertModal') ||
                cookies.get('dismissMobileAlertModal') === 'false'
            )
        ) {
            this.setState({ isMobileAlertModalOpen: true });
        } else if (pricingPlan === 'free') {
            showFreeVersionNotice();
        } else {
            window.location.href = '/dashboard';
        }
    }

    onMobileAlertModalClose() {
        this.setState({ isMobileAlertModalOpen: false });
        window.location.href = '/dashboard';
    }

    render() {
        let url = new URL(window.location.href);
        let isLoginInProgress = url.searchParams.get('code');

        return (
            <div className="login-page">
                <MetaTags>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </MetaTags>
                <div className="login-container">
                    <Icon className="logo-large" icon={LogoLarge} />
                    <div className="split">
                        <div className="split-line left" />
                        <span>使用 帳密 登入</span>
                        <div className="split-line right" />
                    </div>
                    {
                        !isLoginInProgress &&
                        <div className="buttons">
                            {
                                process.env.REACT_APP_HIDE_TEST_LOGIN_BUTTONS === 'no' &&
                                <>
                                    {/*<div>
                                        <Button
                                            className="login-button"
                                            onClick={() => this.handleFacebookLogin()}>
                                            使用 Facebook 登入
                                        </Button>
                                    </div>*/}
                                    <div>
                                        <Button
                                            className="login-button"
                                            onClick={() => this.handleUALogin()}>
                                            使用 帳密 登入
                                        </Button>
                                    </div>
                                </>
                            }
                            {/*<div>
                                <Button
                                    className="login-button"
                                    onClick={() => this.handleCnyesLogin()}>
                                    立即登入 / 註冊
                                </Button>
                            </div>*/}
                        </div>
                    }
                    {
                        isLoginInProgress &&
                        <div className="loading">登入中...</div>
                    }
                </div>
                <MobileAlertModal
                    isOpen={this.state.isMobileAlertModalOpen}
                    onClose={() => this.onMobileAlertModalClose()} />
            </div>
        );
    }
}