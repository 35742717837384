import { isEqual } from "lodash"

export const deepEqual = (left, right) => {
    if(!isEqual(left, right))
    {
        // console.log(left);
        // console.log(right);
        // console.log(left);
        // console.log(right);
        return false;
    } else {
        // console.log('same');
        // console.log(left);
        // console.log(right);
        return true;
    }
}