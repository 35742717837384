import React, { useEffect, useRef, useState } from 'react';
import Component from "./component"
import "./style.scss";
import { getPanelData } from "Redux/actionCreators/dashboard" 
import { useRouteMatch } from 'react-router';
import { useSelector } from "react-redux";

function Container({ panel, rowHeight, parameters })
{
    const refList = useRef([])
    const collapsedHeight = 290;    
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id))
    const [state, setState] = useState({
        collapsedStatusList: [],
        heightList: [],
        // refList: []
    })
    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })
    const updateReadMore = () => {
        const collapsedStatusList = [];
        // const refList = []
        const heightList = [];
        refList.current.forEach((ref, i) => {
            if (!ref) { return; }

            heightList[i] = ref.clientHeight;
            collapsedStatusList[i] = heightList[i] > collapsedHeight;
        });

        setState({ 
            collapsedStatusList: collapsedStatusList,
            heightList: heightList,
        });
    }
    

    const toggleReadMore = (e) => {
        const rowIndex = e.currentTarget.dataset.rowindex
        let collapsedStatusList = [
            ...state.collapsedStatusList
        ]
        collapsedStatusList[rowIndex] = !collapsedStatusList[rowIndex];
        setState({
            ...state, 
            collapsedStatusList: collapsedStatusList 
        });
    }




    useEffect(() => {
        if (!match) { return; }

        getPanelData(panel, {
            stock_code: match.params.id,
            strategy: match.params.strategyId,
            single: panel.id
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    window.refList = refList
    useEffect(() => {
        updateReadMore()
    },[panelData])
    return panelData ? <Component 
        data={panelData.rawData.main.data}
        collapsedHeight={collapsedHeight}
        state={state}
        refList={refList.current}
        toggleReadMore={toggleReadMore}
    />: null
}

export default Container;