import React, { Component } from 'react';
import { Table, Pagination } from 'rsuite';

import TrackStockToggle from './trackStockToggle';

var _ = require('lodash');
const { Column, HeaderCell, Cell } = Table;

export default class PaginationTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            sortColumn:
                this.props.sortColumn ? this.props.sortColumn : undefined,
            sortType: this.props.sortType ? this.props.sortType : undefined
        };

        if (!props) { return; }

        this.state.columnList = props.columns;
        this.state.displayLength =
            this.props.displayLength ? this.props.displayLength : 10;
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.columns, this.props.columns) ||
            !_.isEqual(prevProps.data, this.props.data) ||
            !_.isEqual(prevProps.displayLength, this.props.displayLength)
        ) {
            this.setState({ page: 1 });
        }

        if (!_.isEqual(prevProps.trackingStock, this.props.trackingStock)) {
            this.forceUpdate();
        }
    }

    sortColumnData(sortColumn, sortType) {
        this.setState({ sortColumn: sortColumn, sortType: sortType });
    }

    getData() {
        const { sortColumn, sortType } = this.state;

        let data;

        if (sortColumn && sortType) {
            // If sorting by daily price change, sort stocks hit price limit and
            // normal stocks separately
            if (sortColumn === 'DailyPriceChange_Percent') {
                let priceLimitStocks =
                    this.props.data.filter(x => x.stock_code.uplimit);
                let normalStocks =
                    this.props.data.filter(x => !x.stock_code.uplimit);

                priceLimitStocks.sort((a, b) =>
                    this.sortFunction(a, b, sortColumn, sortType));
                normalStocks.sort((a, b) =>
                    this.sortFunction(a, b, sortColumn, sortType));

                data = priceLimitStocks.concat(normalStocks);
            } else {
                data = _.cloneDeep(this.props.data);
                data.sort((a, b) =>
                    this.sortFunction(a, b, sortColumn, sortType));
            }
        } else {
            data = _.cloneDeep(this.props.data);
        }

        let start = this.state.displayLength * (this.state.page - 1);
        let end = start + this.state.displayLength;

        return data.slice(start, end);
    }

    sortFunction(a, b, sortColumn, sortType) {
        let x = a[sortColumn].value !== null ?
            a[sortColumn].value : a[sortColumn];
        let y = b[sortColumn].value !== null ?
            b[sortColumn].value : b[sortColumn];

        if (typeof x === 'string' || typeof y === 'string') {
            return (
                sortType === 'asc' ?
                    ('' + x).localeCompare(y) : ('' + y).localeCompare(x)
            );
        }

        return sortType === 'asc' ? x - y : y - x;
    }

    onChangePage(page) {
        this.setState({ page: page });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        if (!this.props.onTrackingStockChange) { return; }
        this.props.onTrackingStockChange(groupId, stockId, tracked);
    }

    render() {
        return (
            <div>
                <Table
                    autoHeight={this.props.height ? false : true}
                    height={this.props.height}
                    rowHeight={this.props.rowHeight ? this.props.rowHeight : undefined}
                    headerHeight={this.props.headerHeight ? this.props.headerHeight : undefined}
                    data={this.getData()}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    onSortColumn={(sortColumn, sortType) => this.sortColumnData(sortColumn, sortType)}>
                    {
                        this.state.columnList.map(column =>
                            <Column
                                key={column.id}
                                width={column.width}
                                sortable={column.sortable}>
                                <HeaderCell>
                                    {
                                        this.state.sortColumn === column.id ?
                                            <div className="blue">{column.name}</div> :
                                            column.name
                                    }
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        rowData => {
                                            let cellData = rowData[column.id];

                                            if (Array.isArray(cellData[column.id])) {
                                                return cellData[column.id].join('、');
                                            }

                                            let content =
                                                cellData.text ?
                                                    <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
                                                    cellData.value
                                            let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

                                            if (cellData.link) {
                                                content =
                                                    <a href={cellData.link}
                                                        target={target}
                                                        rel="noopener noreferrer">
                                                        {content}
                                                    </a>;
                                            }

                                            if (cellData.tracked !== undefined) {
                                                content =
                                                    <div style={{ display: 'flex' }}>
                                                        <TrackStockToggle
                                                            style={{ marginRight: 10 }}
                                                            id={cellData.value}
                                                            trackingStock={this.props.trackingStock}
                                                            onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                                                        {content}
                                                    </div>;
                                            }

                                            return content;
                                        }
                                    }
                                </Cell>
                            </Column>
                        )
                    }
                </Table>
                <div style={{ textAlign: 'center', marginTop: 16, height: 30 }}>
                    <Pagination
                        first prev next last ellipsis
                        pages={Math.ceil(this.props.data.length / this.state.displayLength)}
                        maxButtons={10}
                        activePage={this.state.page}
                        onSelect={page => this.onChangePage(page)} />
                </div>
            </div>
        );
    }
}