import history from "Src/history"
import { cookies } from "Https/fetch"
import { showNotice } from 'Redux/actionCreators/notice';
import showUpgradeNotice from '../../utils/showUpgradeNotice';
import { StoreLink } from '../../constant/link';

/**
 * @alias ResponsetErrorHandlings
 * @memberof module:Fetch
 * @description 回應狀態碼與狀態相應動作
 */
const resErrHandlings = {
    timeout: function() {
        alert("timeout");
    },
    TypeError: () => {
        //alert("TypeError: NetworkError when attempting to fetch resource.");
    },
    AbortError: () => {
        showNotice('通知', '網路連線逾時');
        // console.log('timeout');
        // alert("timeout");
    },
    E400001 : () => {
        cookies.remove('accessToken', { path: '/' });
        history.replace('/login');
    },
    E410001: data => {
        showNotice('通知', '已達使用上限' + data.use_limit + '個');
    },
    E410002: data => {
        showNotice(
            '已達上限', '己達免費使用上限' + data.use_limit + '次，請明天再來');
    },
    E410003: data => {
        showNotice(
            '儀表板追蹤策略數己達上限',
            '儀表板追蹤策略數己達上限' + data.use_limit + '組',
            '我知道了'
        );
    },
    E410004: data => {
        showNotice(
            '追蹤策略數己達上限',
            '追蹤策略總數己達上限' + data.use_limit + '組 (總數為知名策略+我的策略合計)',
            '我知道了'
        );
    },
    E410005: () => {
        showNotice(
            '此為付費版功能',
            '升級訂閱方案，以同時追蹤多組策略持股，哪個策略近期表現最佳立刻知道',
            '我知道了',
            StoreLink
        );
    },
    E410006: data => {
        if (data.use_limit === 0) {
            showUpgradeNotice();
        } else {
            showNotice(
                '策略數量己達上限',
                '請移除不常用的策略再新增 (上限' + data.use_limit + '組)',
                '我知道了'
            );
        }
    },
    E410007: data => {
        if (data.use_limit === 0) {
            showUpgradeNotice();
        } else {
            showNotice(
                '評分次數用完囉',
                '使用次數己達上限' + data.use_limit + '次，請明日再試',
                '我知道了'
            );
        }
    },
    E410008: data => {
        if (data.use_limit === 0) {
            showUpgradeNotice();
        } else {
            showNotice(
                '回測次數用完囉',
                '使用次數己達上限' + data.use_limit + '次，請明日再試',
                '我知道了'
            );
        }
    },
    E410602: () => {
        showNotice('請先回測再追蹤', '需先進行回測，才能在戰情室追蹤此策略');
    },
    E410701: () => {
        showNotice('通知', '此股票已在清單中');
    },
    E410702: () => {
        showNotice('通知', '此股票未在清單中');
    }
    // 暫定
    // E400002: () => {
    //     store.dispatch({
    //         type: SHOW_NOTICE, title: '通知', content: '複製策略失敗'
    //     })
    //     showNotice('通知', '複製策略失敗')
    // }
};

export default resErrHandlings;