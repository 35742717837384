import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export default function Graph({options})
{
    return <HighchartsReact
        options={options}
        highcharts={Highcharts}
    />
};