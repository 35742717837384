import IndexUtil from 'Src/indexUtil';
import moment from 'moment';

export function prepareFormData(
    {id, name, description, industryFilter, constraintFilter, scoreList,
    backtest, published, customized, pathname}
) {
    const formData = new FormData();

    if (id) { formData.append('id', id); }
    formData.append('name', name);
    if (description) { formData.append('description', description); }
    if (industryFilter) { formData.append('industries', JSON.stringify(industryFilter)); }
    if (constraintFilter) {
        let convertedConstraintFilter = {
            include:
                IndexUtil.convertIndexInListToCode(constraintFilter.include),
            exclude:
                IndexUtil.convertIndexInListToCode(constraintFilter.exclude)
        };
        formData.append('selections', JSON.stringify(convertedConstraintFilter));
    }
    if (scoreList) {
        let indexScoreList = scoreList.map(block => {
            let newBlock = { ...block };
            newBlock.rowList = IndexUtil.convertIndexInListToCode(block.rowList);

            return newBlock;
        });

        formData.append('scores', JSON.stringify(indexScoreList));
    }
    if (backtest) {
        let backtestSetting = {
            funds: backtest.fund,
            buy_rule: backtest.buyRule,
            change_frequency: backtest.changeFrequency,
            buy_priority: backtest.buyPriority,
            score_limit: backtest.scoreLimit,
            buy_nodailylimit: backtest.noDailyLimit,
            transaction_fee: backtest.fee
        };

        if (backtest.backtestDate) {
            backtestSetting.backtest_date =
                moment(backtest.backtestDate).format('YYYY-MM-DD');
        }

        formData.append('backtest', JSON.stringify(backtestSetting));
    }
    if (published) { formData.append('published', published); }
    if (customized) { formData.append('customized', customized); }
    if (pathname) { formData.append('pathname', pathname); }

    return formData;
}