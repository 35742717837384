import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../api';
import { READ_EMAIL, SET_ALL_EMAILS_READ, CHECK_ALERT } from '../../../redux/actionTypes/messages'
import Anouncement from '../../../image/svgIcon/message/mail_announcement.svg'
import Article from '../../../image/svgIcon/message/mail_article.svg'
import Mail from '../../../image/svgIcon/message/mail_message.svg'
import { Icon } from 'rsuite';

import { Link } from "react-router-dom"
import PerfectScrollbar from 'react-perfect-scrollbar'
const icons = {
    inMail: Mail,
    announcement: Anouncement,
    article: Article,
    system_announcement: Anouncement,
    system_message: Mail, 
}

export default function MailBox({ emails })
{
    const dispatch = useDispatch()
    const notifications = useSelector(store => store.messages.notifications)
    const notificationMessage = notifications.filter((o) => {
        return  o.category === 'message';
    })
    // console.log(notificationMessage);
    // const notificationMessage = notifications.filter((o) => {
    //     return  o.category === 'message';
    // })
    const setAllEmailsRead = async () => {
        const result =  await Api.allEmailsRead()
        if(result)
        {
            dispatch({
                type: SET_ALL_EMAILS_READ
            })
            dispatch({ type: CHECK_ALERT })
        }
    }
    const readEmail = async (id) => {
        console.log(id);
        const result = await Api.readEmail(id)
        if(result) {
            dispatch({  type: READ_EMAIL, id: id} )
            dispatch({ type: CHECK_ALERT })
            // dispatch({ type: TOGGLE_MESSAGE_BOX, showMessageBox: false });
        }
    }
    return <PerfectScrollbar style={ notificationMessage.length > 10 ? { height: "420px", overflowY: 'scroll'}: null}>
        <div className="messageBox_content_email">
            <div className="messageBox_read_control messageBox_read_email_control">
                <button type="button" onClick={setAllEmailsRead}>
                    全部設為已讀
                </button>
            </div>
            {
                notificationMessage ? notificationMessage.map((o, i) => {
                    return <Link key={i} className = {o.read ?  "messageBox_content_email_row": "messageBox_content_email_row messageBox_content_email_row-unread"} onClick={() => {readEmail(o.id)}}>
                        <div>
                            <div className = "messageBox_content_email_row_title">
                                <Icon icon={icons[o.type]}/>
                            </div>
                            <div className = "messageBox_content_email_row_contents">
                                <div>{o.msg}</div>
                                <div>{o.created_at}</div>
                            </div>
                        </div>
                    </Link>
                }): '讀取中'
            }
        </div>
    </PerfectScrollbar>
};