import React from 'react';
import { Button, Modal } from 'rsuite';

import Bar from 'Components/bar';
import formatDuration from 'Util/formatDuration';

import './style.scss';

const Component = ({
    isOpen, percentage, remainTime, showStrategyOverview
}) => {
    const percentageStr = percentage != null ? percentage + '%' : '-%';
    const remainTimeStr = formatDuration(remainTime);

    return (
        <Modal
            className="scoring-progress-modal"
            show={isOpen}>
            <Modal.Header closeButton={false}>
                <Modal.Title>評分排程已啟動</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <div className="content">
                    雲端主機將依序為所有使用者運算。<br />
                    請留在此頁等待、或返回上頁繼續使用其他功能。
                </div>
                <Bar value={percentage} total={100} />
                <div className="progress-text">
                    <div className="percent">{percentageStr}</div>
                    <div className="time">{remainTimeStr}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={showStrategyOverview}>
                    返回 我的策略/回測
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;