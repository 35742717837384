import React from "react";
import {
    Icon,
    Table
} from 'rsuite';
import IconDeleteWhite from 'Image/svgIcon/delete-white.svg';
import { isEqual } from "lodash";
import TabSelector from 'Components/tabSelector';
import AppPagination from "Components/pagination";

const { Column, HeaderCell, Cell } = Table;

function FavoriteList({
    currentTab,
    tabList,
    columnList,
    tableHeight,
    currentPageData,
    currentTabData,
    sortColumn,
    sortType,
    sortColumnData,
    removeStock,
    onChangeTabCallBack,
    id
})
{
    return <div className="favorite-list">
            <TabSelector
                enableDropdown
                tabs={tabList}
                currentTab={currentTab}
                onTabChange={onChangeTabCallBack} />
        <div className = "favorite__list__contents">
            {
                <Table
                    height={tableHeight}
                    headerHeight={40}
                    rowHeight={48}
                    data={currentPageData}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={sortColumnData}
                >
                    {
                        columnList.map((column, i) =>
                            <Column
                                key={column.id}
                                width={column.width}
                                sortable={true}
                                fixed={i === 0}
                                align={i === 0 ? 'left' : 'center'}
                                verticalAlign="middle">
                                <HeaderCell>
                                    {
                                        sortColumn === column.id ?
                                            <div className="blue">{column.name}</div> :
                                            column.name
                                    }
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        rowData => {
                                            let cellData = rowData[column.id];
                                            let content =
                                                cellData.text ?
                                                    <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
                                                    cellData.value
                                            let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

                                            if (cellData.link) {
                                                content =
                                                    <a href={cellData.link}
                                                        target={target}
                                                        rel="noopener noreferrer">
                                                        {content}
                                                    </a>;
                                            }

                                            if (column.id === 'stock_code') {
                                                return (
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            className="clickable delete-icon"
                                                            data-currenttab={currentTab}
                                                            data-stockid={cellData.value}
                                                            onClick={removeStock}>
                                                            <Icon icon={IconDeleteWhite} />
                                                        </div>
                                                        {content}
                                                    </div>
                                                );
                                            }

                                            return content;
                                        }
                                    }
                                </Cell>
                            </Column>
                        )
                    }
                </Table>
            }
        </div>
        <div className = "favorite__list__pagination">
            <AppPagination
                id={id}
                list={currentTabData}
                maxButtons={10}
                displayLength={10}
            />
        </div>
    </div>
};

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps, nextProps)
        // !isEqual(prevProps.currentTab, nextProps.currentTab) ||
        // !isEqual(prevProps.tabList, nextProps.tabList) ||
        // !isEqual(prevProps.pages, nextProps.pages) ||
        // !isEqual(prevProps.columnList, nextProps.columnList) ||
        // !isEqual(prevProps.tableData, nextProps.tableData) ||
        // prevProps.sortColumn !==  nextProps.sortColumn ||
        // prevProps.sortType !==  nextProps.sortType
    )
    {
        return false;
    } else {
        return true;
    }
    // return false
}
export default React.memo(FavoriteList, areEqual);
// export default hot(FavoriteList)