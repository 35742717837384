import React, { Component } from "react";
import RGL from "react-grid-layout";
import { withResizeDetector } from 'react-resize-detector';

import PreviewGrid from "./previewGrid";

var _ = require('lodash');

const ReactGridLayout = withResizeDetector(RGL);

export default class PreviewDashboard extends Component {
    static defaultProps = {
        cols: 3
    };

    constructor(props) {
        super(props);

        this.state = {
            layout: props.layout ? props.layout : [],
            activePanelList:
                this.getActivePanelList(props.singles, props.layout)
        };
    }

    componentDidMount() {
        let layout = this.props.layout ? this.props.layout : [];
        let activePanelList =
            this.getActivePanelList(this.props.singles, this.props.layout);

        this.setState({ layout, activePanelList });
    };

    async componentDidUpdate(prevProps) {
        if (!this.props) { return; }

        if (
            !_.isEqual(prevProps.singles, this.props.singles) ||
            !_.isEqual(prevProps.layout, this.props.layout)
        ) {
            let layout = this.state.layout;

            if (
                this.props.layout &&
                !_.isEqual(this.props.layout, this.state.layout)
            ) {
                layout = this.props.layout;
            } else if (!this.props.layout) {
                layout = [];
            }

            let activePanelList =
                this.getActivePanelList(this.props.singles, layout);

            this.setState({ activePanelList, layout });
        }
    }

    getActivePanelList(singles, layout) {
        if (!singles || !layout) { return []; }

        let activePanelList =
            singles.filter(x => layout.some(y => x.id === y.i));

        return activePanelList;
    }

    onLayoutChange(layout) {
        if (!this.props.onLayoutChange) { return; }
        this.props.onLayoutChange(layout);
    }

    removeGrid(id) {
        this.onLayoutChange(this.props.layout.filter(x => x.i !== id));
    }

    renderActivePanel() {
        return (
            this.state.activePanelList.map(x =>
                PreviewGrid.create(
                    x.id,
                    x.name,
                    () => this.removeGrid(x.id),
                    this.props.enableEdit)
            )
        );
    }

    render() {
        return (
            <ReactGridLayout
                className="preview-dashboard"
                layout={this.state.layout}
                cols={this.props.cols}
                rowHeight={1}
                autoSize={false}
                isResizable={false}
                isDraggable={this.props.enableEdit}
                compactType="vertical"
                style={{ marginLeft: -5, marginRight: -5, marginTop: -5 }}
                margin={[5, 5]}
                draggableHandle=".grid"
                onLayoutChange={layout => this.onLayoutChange(layout)}>
                {this.renderActivePanel()}
            </ReactGridLayout>
        );
    }
}