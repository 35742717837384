import React, { Component } from 'react';
import { Button } from 'rsuite';

import API from 'Src/api';
import BacktestDetailPanel from 'Components/backtestResult/backtestDetailPanel';
import CopyLinkModalButton from 'Components/backtestResult/copyLinkModalButton';
import GraphPanel from 'Components/backtestResult/graphPanel';
import history from 'Src/history';
import PerformanceGraph from 'Components/graph/performanceGraph';
import ProfitLossRatioGraph from 'Components/graph/profitLossRatioGraph';
import RiskReturnGraph from 'Components/graph/riskReturnGraph';
import ShowPageModal from 'Components/showPageModal';
import StockHoldingsTable from 'Components/stockHoldingsTable';
import StrategyPanel from 'Components/backtestResult/strategyPanel';
import { showNotice } from 'Redux/actionCreators/notice';

var _ = require('lodash');

export default class BacktestResultPage extends Component {
    state = { showCopyLinkModal: false };

    async componentDidMount() {
        if (this.props.trackingStock) {
            this.setState({ trackingStock: this.props.trackingStock });
        }

        if (this.props.indexLibrary) {
            this.updateBacktestResult(this.props.indexLibrary);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trackingStock !== this.props.trackingStock) {
            this.setState({ trackingStock: this.props.trackingStock });
        }

        if (prevProps.indexLibrary !== this.props.indexLibrary) {
            this.updateBacktestResult(this.props.indexLibrary);
        }
    }

    async updateBacktestResult(indexLibrary) {
        if (!indexLibrary) { return; }

        let result = await API.getBacktestResult(this.props.id, indexLibrary);

        if (!result) {
            // E420001
            showNotice(
                '無法查看回測報告', '分享者己刪除、或不再分享該策略，故無法查看');
            return;
        }

        this.setState({ backtestResult: result });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        let trackingStock = _.cloneDeep(this.state.trackingStock);
        let group = trackingStock.find(group => group.id === groupId);

        if (!group) { return; }

        if (tracked) {
            group.items.push(stockId);
        } else {
            group.items = group.items.filter(x => x !== stockId);
        }

        this.setState({ trackingStock });
    }

    async copyStrategy() {
        if (this.state.backtestResult?.shared === 1) {
            this.props.copyStrategy();
        } else {
            showNotice('無法複製策略', '因分享者將條件設為不公開');
        }
    }

    editStrategy() {
        history.push('/strategy/' + this.state.backtestResult?.id);
    }

    // Copy link modal
    setCopyLinkModalShow(show) {
        this.setState({ showCopyLinkModal: show });
    }

    async onShareBacktestSettingsChange(checked) {
        if (await API.shareBacktestSettings(this.props.id, checked)) {
            this.setState({
                backtestResult: {
                    ...this.state.backtestResult,
                    shared: checked ? 1 : 0
                }
            });
        } else {
            const msg = (checked ? '' : '取消') + '分享回測設定失敗';
            showNotice('通知', msg);
        }
    }

    render() {
        let link =
            window.location.protocol + '//' + window.location.host + 
            '/backtestResult/' + this.state.backtestResult?.pathname;

        return (
            <div className="backtest-result">
                <div style={{ display: 'flex', marginBottom: 7 }}>
                    <div className="page-title">投資策略回測系統</div>
                    {
                        this.state.backtestResult &&
                        <div className="page-buttons">
                            {
                                !this.state.backtestResult.owned &&
                                <Button onClick={() => this.copyStrategy()}>
                                    複製策略
                                </Button>
                            }
                            {
                                this.state.backtestResult.owned &&
                                <Button
                                    onClick={() => this.editStrategy()}>
                                    修改策略
                                </Button>
                            }
                            <CopyLinkModalButton
                                buttonText="分享回測報告"
                                modalTitle="分享回測報告"
                                show={this.state.showCopyLinkModal}
                                link={link}
                                shareBacktestSettings={this.state.backtestResult.shared === 1}
                                onClick={() => this.setCopyLinkModalShow(true)}
                                onHide={() => this.setCopyLinkModalShow(false)}
                                onShareBacktestSettingsChange={checked => this.onShareBacktestSettingsChange(checked)} />
                        </div>
                    }
                </div>
                <hr />
                <div className="title">策略名片</div>
                <div className="block">
                    <StrategyPanel
                        industries={this.state.backtestResult?.industries}
                        selections={this.state.backtestResult?.selections}
                        totalFiltered={this.state.backtestResult?.total_filtered}
                        scores={this.state.backtestResult?.scores}
                        backtest={this.state.backtestResult?.backtest}
                        data={this.state.backtestResult ? this.state.backtestResult.data[0].display : null} />
                </div>
                <div className="title">回測結果</div>
                <div className="block">
                    <BacktestDetailPanel
                        data={this.state.backtestResult ? this.state.backtestResult.data[1].display : null} />
                </div>
                <div className="title">回測報表</div>
                <div className="block">
                    <GraphPanel
                        component={PerformanceGraph}
                        data={this.state.backtestResult ? this.state.backtestResult.data[2] : null} />
                    <GraphPanel
                        component={ProfitLossRatioGraph}
                        data={this.state.backtestResult ? this.state.backtestResult.data[3] : null} />
                    <GraphPanel
                        component={RiskReturnGraph}
                        data={this.state.backtestResult ? this.state.backtestResult.data[4] : null} />
                </div>
                <div className="title">本策略過去選出的股票</div>
                <div className="block" style={{height: '100%'}}>
                    <StockHoldingsTable
                        data={this.state.backtestResult ? this.state.backtestResult.data[5].main : null}
                        trackingStock={this.state.trackingStock}
                        onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                </div>

                <ShowPageModal
                    isOpen={this.props.isCopyModalOpen}
                    title="複製策略成功"
                    text="可前往『我的策略/回測』，查看或修改"
                    closeModal={this.props.closeCopyModal}
                    showPage={this.props.showStrategyOverviewPage} />
            </div>
        );
    }
}