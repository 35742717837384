import React from 'react';
import { tabList } from  "./constant"
import TabList from "Components/tabListGeneral"
import "./style.scss"
import { hot } from "react-hot-loader/root"
// import StockPicker from 'Components/stockPicker/index.js';
import AppPagination from "Components/pagination";
import { columnList } from "./constant"
import { rowDataToContentsByColumnId } from "./table";
import AppTable from "Components/appTable"


function Component({ onSearchChange, currentTab, data, pagination, onChangeTabCallBack, searchText})
{   
    const page = pagination ? pagination.page : 1;
    // console.log('data');
    // console.log(data);
    return <div className="grid eps">
    <div className="grid-title">
        <span className="grid-title-text">預估EPS更新快報</span>
        {/* {this.renderButton(buttonList)} */}
    </div>
    <div className="grid-content">
        <div className="eps__forcast">
            <div className="grid-tab">
                <TabList
                    id={'eps'}
                    data={tabList}
                    type="tab"
                    onChangeCallBack={onChangeTabCallBack}
                />
            </div>
            <div className="eps__forcast__list__filter">
                <div class="rs-input-group rs-input-group-inside">
                    <div class="rs-auto-complete">
                        <input placeholder="搜尋股票/代號" type="text" class="rs-input" value={searchText} onChange={onSearchChange}/>
                    </div>
                    <span class="rs-input-group-addon">
                        <i class="rs-icon rs-icon-search"></i>
                    </span>
                </div>
                {/* <StockPicker 
                    clearOnSelect
                    data={stockPool}
                    onSelect={onStockSelected}
                /> */}
            </div>
            <div className="eps__forcast__list__contents">
                {currentTab && pagination ? <AppTable 
                    data={data}
                    height={39*11 + 12}
                    columnList={columnList}
                    rowHeight={39}
                    currentTab={currentTab}
                    page={page}
                    rowDataToContentsByColumnId={rowDataToContentsByColumnId}
                />: null
                }
            </div>
            <div className="eps__forcast__list__pagination">
                {
                    currentTab ? <AppPagination
                    id={'eps'}
                    list={data}
                    // list={currentTabData}
                    maxButtons={10}
                    displayLength={10}
                />: null
            }
            </div>
        </div>
    </div>
    </div>
}



export default hot(Component);
// function areEqual(prevProps, nextProps)
// {
    // return false;
    // if(

    // )
    // {
    //     return  true
    // }else {
    //     return false
    // }
// }

// export default React.memo(Component, areEqual);