import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
} from 'rsuite';

import './tableNameModal.css';

export default class TableNameModal extends Component {
    state = {
        name: ''
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            this.setState({ name: '' });
        }
    }

    onNameChange(value) {
        this.setState({ name: value });
    }

    onConfirmClick() {
        if (!this.props.onConfirmClick) { return; }
        this.props.onConfirmClick(this.props.mode, this.state.name);
    }

    render() {
        let title;
        
        switch(this.props.mode) {
            case 'new':
                title = '為新表格命名';
                break;
            case 'copy':
                title = '複製表格';
                break;
            case 'rename':
                title = '重新命名表格';
                break;
            default:
                title = '';
        }

        return (
            <Modal
                className="table-name-modal"
                show={this.props.show}
                onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <span>表格名稱</span>
                        <Input
                            className="name-input"
                            value={this.state.name}
                            onChange={value => this.onNameChange(value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onConfirmClick()}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}