import React from "react";
import "./style.scss";
import { colorList } from './constants'
import AppPagination from "Components/pagination";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import StockScoreTable from 'Components/stockScoreTable/index';
// import StockScoreTable from 'Components/stockScoreTable';
import { isEqual } from "lodash";

function Component({
    distributionData, summaryData, startIndex, componentId, displayLength,
    stockList, industryGraphOption, distributionGraphOption, trackingStock
}) {
    // console.log('stockList');
    // console.log(stockList);
    return <div className="stock-result-panel stock_result_panel">
        <div className="stock_result_panel__categories">
            <div className="stock_result_panel__categories__table">
                <div>評分分布</div>
                <div>
                    <div>評分區間</div>
                    <div>支數</div>
                    <div>比例(%)</div>
                </div>
                <div className="stock_result_panel__categories__unit">
                    {
                        distributionData.map((x, i) =>
                            <div key={i}>
                                <hr />
                                <div>
                                    <div>
                                        <div 
                                            className="stock_result_panel__categories__colorblock"
                                            style={{ backgroundColor: colorList[i % colorList.length] }} />
                                        {x.name}
                                    </div>
                                    <div>{x.count}</div>
                                    <div>{x.percentage.toFixed(2) + '%'}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={distributionGraphOption} />
        </div>
        <div className = "stock_result_panel__categories">
            <div className = "stock_result_panel__categories__table">
                <div>產業比重</div>
                <div>
                    <div>產業別</div>
                    <div>支數</div>
                    <div>比例(%)</div>
                </div>
                <div className = "stock_result_panel__categories__unit">
                    {
                        summaryData.map((x, i) =>
                            <div key={i}>
                                <hr />
                                <div>
                                    <div>
                                        <div className = "stock_result_panel__categories__colorblock" style={{backgroundColor: colorList[(startIndex + i) % colorList.length]}} />
                                        {x.name}
                                    </div>
                                    <div>{x.count}</div>
                                    <div>{x.percentage.toFixed(2) + '%'}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{textAlign: 'center'}}>
                    <AppPagination
                        id={componentId}
                        list={stockList.industrySummary}
                        maxButtons={10}
                        displayLength={displayLength}
                    />
                    {/* <Pagination
                        first prev next last ellipsis
                        pages={pageCount}
                        maxButtons={10}
                        activePage={this.state.page}
                        onSelect={page => this.onChangePage(page)} 
                    /> */}
                </div>
            </div>   
            <HighchartsReact
                // ref={ref => this.pieChart = ref}
                highcharts={Highcharts}
                options={industryGraphOption}
            />
        </div>
        <StockScoreTable
            columnList={stockList.stockColumnList}
            data={stockList.stockData}
            trackingStock={trackingStock}
            // onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} 
        />
</div>
}
// summaryData, startIndex, componentId, displayLength, stockList, graphOption, trackingStock
function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.trackingStock, nextProps.trackingStock) ||
        !isEqual(prevProps.graphOption, nextProps.graphOption) ||
        !isEqual(prevProps.stockList, nextProps.stockList) ||
        !isEqual(prevProps.summaryData, nextProps.summaryData) ||
        prevProps.componentId !== nextProps.componentId ||
        prevProps.startIndex !== nextProps.startIndex
    )
    {
        return false
    } else {
        return true
    }
}


export default React.memo(Component, areEqual);
// export default Component;