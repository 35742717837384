import _ from "lodash";
export const getData = (rawData, sortColumn, sortType, displayLength, page) => {
    // const { sortColumn, sortType } = this.state;

    if (!rawData) { return []; }

    let data = _.cloneDeep(rawData);

    if (sortColumn && sortType) {
        data = data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            if (typeof x === 'string' || typeof y === 'string') {
                return (
                    sortType === 'asc' ?
                        ('' + x).localeCompare(y) :
                        ('' + y).localeCompare(x)
                );
            }

            return sortType === 'asc' ? x - y : y - x;
        });
    }

    let begin = (page - 1) * displayLength;
    let end = begin + displayLength;

    return data.slice(begin, end);
};
export const getData2 = (rawData, sortColumn, sortType, displayLength, page) => {
 
    if (!rawData) { return []; }

    let data = _.cloneDeep(rawData);

    if (sortColumn && sortType) {
        data = data.sort((a, b) => {
            // let x = a[sortColumn];
            // let y = b[sortColumn];
            let x = '';
            let y = '';
            if(Array.isArray(a[sortColumn]))
            {
                x = a[sortColumn][0] ? a[sortColumn][0].value : '';
                y = b[sortColumn][0] ? b[sortColumn][0].value : '';
            } else {
                x = a[sortColumn].value;
                y = b[sortColumn].value;
            }
            if (typeof x === 'string' || typeof y === 'string') {
                return (
                    sortType === 'asc' ?
                        ('' + x).localeCompare(y) :
                        ('' + y).localeCompare(x)
                );
            }

            return sortType === 'asc' ? x - y : y - x;
        });
    }

    let begin = (page - 1) * displayLength;
    let end = begin + displayLength;
    // console.log('rawData');
    // console.log(rawData);
    // console.log(data);
    // console.log(data.slice(begin, end));
    return data.slice(begin, end);
    // return []
};


// const getData1 = (name) => { 
//     if(!panelData){return}
//     if(!panelData.tableData){return}
//     const { sortColumn, sortType } = panelData;

//     let source = _.cloneDeep(panelData.tableData[name]);
//     if (!source) { return; }

//     let data = source[panelData.displayType];
    
//     if (sortColumn && sortType) {
//         data = data.sort((a, b) => {
//             let x = '';
//             let y = '';
//             if(Array.isArray(a[sortColumn]))
//             {
//                 x = a[sortColumn][0] ? a[sortColumn][0].value : '';
//                 y = b[sortColumn][0] ? b[sortColumn][0].value : '';
//             } else {
//                 x = a[sortColumn].value;
//                 y = b[sortColumn].value;
//             }

//             if (typeof x === 'string' || typeof y === 'string') {
//                 return (
//                     sortType === 'asc' ?
//                         ('' + x).localeCompare(y) :
//                         ('' + y).localeCompare(x)
//                 );
//             }

//             return sortType === 'asc' ? x - y : y - x;
//         });
//     }

//     let start = panelData.displayLength * (panelData.page - 1);
//     let end = start + panelData.displayLength;
//     return data.slice(start, end);
// }