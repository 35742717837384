import React from 'react';

import './style.scss';

export default function ArrowText(props) {
    return (
        <div className="arrow-text">
            <div className="text">{props.value}</div>
        </div>
    );
}