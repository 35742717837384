import * as QuestionnaireModal from '../actionTypes/questionnaireModal';

const initialSate = {
    isOpen: false
};

const questionnaireModal = (state = initialSate, action) => {
    switch (action.type) {
        case QuestionnaireModal.SHOW_QUESTIONNAIRE_MODAL: {
            return {
                isOpen: true
            };
        }
        case QuestionnaireModal.CLOSE_QUESTIONNAIRE_MODAL: {
            return {
                isOpen: false
            };
        }
        default: {
            return state;
        }
    }
};

export default questionnaireModal;