const style = {
    cursor: 'move',
    fontSize: 13, color: 'rgba(255, 255, 255, 0.7)'
};

const ItemTypes = {
    COLUMN: 'column',
    ROW: 'row'
};

const DisplayLengthList = [
    { label: '顯示10檔', value: 10 },
    { label: '顯示20檔', value: 20 },
    { label: '顯示30檔', value: 30 },
    { label: '顯示50檔', value: 50 },
    { label: '顯示100檔', value: 100 }
];

export {
    style, ItemTypes, DisplayLengthList
}