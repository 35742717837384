import React from 'react';
import TrackStockToggle from 'Components/trackStockToggle/index';
import { isEqual } from "lodash"
function Component({ stock, color, changeText, changePercentText })
{
    return <div className="stock-info">
        {
            stock ? <>
                <TrackStockToggle
                    id={stock.code} 
                />
                {stock.code + ' ' + stock.name}
                <span className={'daily-close' + color}>
                    {stock.DailyClose.data}
                </span>
                <span className={'daily-price-change' + color}>
                    {changeText}
                </span>
                <span className={'daily-price-change-percent' + color}>
                    {changePercentText}
                </span>
                <span className={'date'}>
                    {'(' + stock.data_date.data + '收盤價)'}
                </span>
            </>:null
        }
    </div>
}

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.stock, nextProps.stock)
    )
    {
        return false
    }else {
        return  true
    }
}
export default React.memo(Component, areEqual);