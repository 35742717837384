import React, { Component } from 'react';
import { SelectPicker } from 'rsuite';

import './strategySelector.css';

var _ = require('lodash');

export default class StrategySelector extends Component {
    strategyRefList = [];

    state = { id: '' };

    componentDidMount() {
        if (
            (
                !this.props.strategyList || !this.props.strategyList[0] ||
                !this.props.strategyList[0].id
            ) &&
            !this.props.strategyId
        ) {
            return;
        }

        let strategyId =
            this.props.strategyId ?
                this.props.strategyId : this.props.strategyList[0].id;

        this.setState({ id: strategyId });

        if (this.strategyRefList[strategyId]) {
            this.strategyRefList[strategyId].scrollIntoView();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.strategyId !== this.props.strategyId &&
            this.props.strategyId
        ) {
            this.onStrategySelect(this.props.strategyId);
        }

        if (
            _.isEqual(prevProps.strategyList, this.props.strategyList) ||
            !this.props.strategyList || !this.props.strategyList[0] ||
            !this.props.strategyList[0].id
        ) {
            return;
        }

        let strategyId =
            this.props.strategyId ?
                this.props.strategyId : this.props.strategyList[0].id;
        this.onStrategySelect(strategyId);
    }

    onStrategySelect(id) {
        this.setState({ id: id });

        if (this.strategyRefList[id]) {
            this.strategyRefList[id].scrollIntoView();
        }

        if (id !== this.props.id && this.props.onStrategySelect) {
            this.props.onStrategySelect(id);
        }
    }

    onWheel(event) {
        event.preventDefault();

        var container = document.getElementById('strategy-list');
        container.scrollTo({ left: container.scrollLeft + event.deltaY });
    }

    renderStrategyList() {
        if (!this.props.strategyList) { return; }

        return this.props.strategyList.map(strategy => {
            let className = 'strategy-name';

            if (strategy.id === this.state.id) {
                className += ' selected';
            }

            return (
                <div
                    key={'strategy-' + strategy.id}
                    ref={ref => this.strategyRefList[strategy.id] = ref}
                    className={className}
                    onClick={() => this.onStrategySelect(strategy.id)}>
                    {strategy.name}
                </div>
            );
        });
    }

    render() {
        let selectorClassName = 'strategy-selector';
        if (this.state.isExpanded) {
            selectorClassName += ' expanded';
        }

        return (
            <div className={selectorClassName}>
                <div className="row">
                    <div
                        id="strategy-list"
                        onWheel={event => this.onWheel(event)}>
                        {this.renderStrategyList()}
                    </div>
                    <div className="picker">
                        <SelectPicker
                            data={this.props.strategyList}
                            labelKey="name"
                            valueKey="id"
                            placeholder="策略選單"
                            searchable={false}
                            cleanable={false}
                            value={null}
                            onChange={value => this.onStrategySelect(value)} />
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}