import React from 'react';
import { Icon } from 'rsuite';
import { isEqual } from 'lodash';

import Graph from '../graph/index';
import IconAdd from 'Image/svgIcon/dashboard/add.svg';

import './style.scss';

const Card = ({
    data, recentMonth, recentMonthGain, recentMonthGainColor, diff, diffColor,
    yearReturnRate, showBacktestResult, trackStrategy
}) => {
    const valueFormatter = value => {
        if ((!value && value !== 0) || value === '') {
            return '-';
        } else if (value > 0) {
            return '+' + value + '%';
        } else {
            return value + '%';
        }
    };

    let buyRuleStr =
        data.backtest_buy_rule === '自訂權重買進' ?
            data.backtest_buy_rule :
            (data.backtest_buy_rule.substr(0, 2) + '買進');

    buyRuleStr += '前' + data.buy_rank + '名';

    return (
        <div className="strategy-card">
            <div
                className="title clickable"
                onClick={() => showBacktestResult(data.id)}>
                {data.name}
            </div>
            <div className="graph">
                <div className="highlight recent-month-gain">
                    <div className="text">近{recentMonth}月漲幅</div>
                    <div className={'value ' + recentMonthGainColor}>
                        {valueFormatter(recentMonthGain)}
                    </div>
                </div>
                <div className="highlight diff">
                    <div className="text">跑贏大盤</div>
                    <div className={'value ' + diffColor}>
                        {valueFormatter(diff)}
                    </div>
                </div>
                {data.returns && <Graph data={data.returns.data} />}
            </div>
            <div
                className="content clickable"
                onClick={() => showBacktestResult(data.id)}>
                <div>
                    <div>年化報酬率</div>
                    <div>
                        {
                            !yearReturnRate && yearReturnRate !== 0 ?
                                '-' : yearReturnRate + '%'
                        }
                    </div>
                </div>
                <div>
                    <div>個股勝率</div>
                    <div>{data.win_rate ? data.win_rate + '%' : null}</div>
                </div>
                <div>
                    <div>買進策略</div>
                    <div>{buyRuleStr}</div>
                </div>
                <div>
                    <div>換股頻率</div>
                    <div>{data.backtest_change_frequency}</div>
                </div>
            </div>
            <div className="action">
                <div>
                    <button
                        className={data.tracked ? "active" : null}
                        data-id={data.id}
                        data-tracked={data.tracked}
                        onClick={trackStrategy}>
                        {
                            data.tracked ?
                                '追蹤中' : <><Icon icon={IconAdd} />追蹤</>
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Card, isEqual);