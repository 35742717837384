import { SET_BACKTEST_PREVIEW, CLEAR_PREVIEW } from "../actionTypes/backtest"
const initialState = {
    preview: null
}

export default function reducer(state=initialState, action)
{
    switch(action.type) {
        case SET_BACKTEST_PREVIEW: {
            return {
                ...state,
                preview: action.preview
            }
        }
        case CLEAR_PREVIEW: {
            return {
                ...state,
                preview: null
            }
        }
        default: {
            return state;
        }
    }
} ;