import * as NeedLoginModal from '../actionTypes/needLoginModal';

const initialSate = {
    show: false
};

const needLoginModal = (state = initialSate, action) => {
    switch (action.type) {
        case NeedLoginModal.SHOW_NEED_LOGIN_MODAL: {
            return {
                show: true
            };
        }
        case NeedLoginModal.CLOSE_NEED_LOGIN_MODAL: {
            return {
                show: false
            };
        }
        default: {
            return state;
        }
    }
};

export default needLoginModal;