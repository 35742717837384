import Component1 from './component';
import React, { useCallback, useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import PriceIndicator from 'highcharts/modules/price-indicator';
import IndexUtil from 'Src/indexUtil';
import { graphConfigProccess } from "./processforGraph/main"
import './style.scss';
import 'Page/highcharts.css';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_INDEX_DATA } from "Redux/saga/actionTypes/index"
import { CLEAR_INDEX_DATA, REMOVE_INDEX_DATA } from "Redux/actionTypes/_index"
import { RESET_GRAPH } from "Redux/actionTypes/graph"
import * as Actions from "Redux/actionTypes/graph";
// import { REMOVE_INDEX_DATA } from "Redux/actionTypes/_index"
// import { deepEqual } from "Util/deepEqual";
PriceIndicator(Highcharts);

// graph: graph state
function Container()
{
    const _dispatch = useDispatch();
    const dispatch = useCallback(_dispatch, [])
    const stockPool = useSelector(store => store.stock.pool);
    const index = useSelector(store => store.index);
    const graph = useSelector(store => store.graph)
    const chartRefList = useRef([]);
    useEffect(() => {
        setDateRange(graph.dateRange)
        return () => {
            dispatch({ type: RESET_GRAPH })
            dispatch({ type: CLEAR_INDEX_DATA})
        }
    /* eslint-disable-next-line */
    },[])

    useEffect(() => {
        updateData()
        /* eslint-disable-next-line */
    }, [graph.lineList.length])

    const updateData = () => {
        if (
            graph.selectedStock.length === 0 ||
            graph.selectedIndex.length === 0
        ) {
            dispatch({ type: CLEAR_INDEX_DATA })
        } else {

            if(
                graph.selectedStock.length > graph.originSeletectStock.length ||
                graph.selectedIndex.length > graph.originSeletectIndex.length
            )
            {
                const newSelectedStock = graph.selectedStock.length === graph.originSeletectStock.length ? graph.selectedStock:
                graph.selectedStock.filter(stock => {
                    return !graph.originSeletectStock.some(originStock => stock.stock_code === originStock.stock_code)
                })

                const newSelectedIndex =
                    graph.selectedIndex.length ===
                        graph.originSeletectIndex.length ?
                        graph.selectedIndex :
                        graph.selectedIndex.filter(index =>
                            !graph.originSeletectIndex.some(originIndex =>
                                index.value.value === originIndex.value.value &&
                                index.range.value === originIndex.range.value)
                        );
                
                const convertedStockList = newSelectedStock.map(x => x.stock_code);
                const convertedIndexList = newSelectedIndex.map(x => IndexUtil.convertIndex(x));
                
    
    
                let formData = new FormData();
                formData.append('stocks', JSON.stringify(convertedStockList));
                formData.append('indice', JSON.stringify(convertedIndexList));
                dispatch({
                    type: FETCH_INDEX_DATA,
                    formData: formData
                })
            }
        }
    }

    const deleteStock = useCallback((index, stockCode) => {
        dispatch({
            type: Actions.REMOVE_SELECTED_STOCK,
            index: index
        })
        dispatch({
            type: REMOVE_INDEX_DATA,
            by: "stock",
            stockCode: stockCode
        })
    },[dispatch]);

    const deleteIndex = useCallback((index) => {
        // console.log('index');
        // console.log(index);
        dispatch({
            type: Actions.REMOVE_SELECTED_INDEX,
            index: index
        })
        dispatch({
            type: REMOVE_INDEX_DATA,
            by: "index",
            index: index
        })
    },[dispatch])

    const onStockSelected = useCallback((stock) => {
        dispatch({
            type: Actions.INSERT_SELECTED_STOCK,
            stock: stock
        })
    },[dispatch])

    const onIndexSelected = useCallback((index) => {
        dispatch({
            type: Actions.INSERT_SELECTED_INDEX,
            index: index
        })
    },[dispatch])

    const setStockVisible = useCallback((index, value) => {
        dispatch({
            type:  Actions.SET_STOCK_VISIBLE,
            index: index,
            value:  value
        })
    },[dispatch])

    const setIndexVisible = useCallback((index, value) => {
        dispatch({
            type: Actions.SET_INDEX_VISIBLE,
            index: index,
            value: value
        })
    },[dispatch])

    
    const getChartOptions = () => {
        const options = graphConfigProccess[graph.graphType](index.data, graph);
        // console.log("options");
        // console.log(options);
        return options;
    }

    
    const setLineVisible = (index, value) => {
        dispatch({
            type: Actions.SET_LINE_VISIBLE,
            visible: value,
            index: index
        })
    }

    
    const setGraphStart = useCallback((value) => {
        dispatch({
            type: Actions.SET_GRAPH_START,
            graphStart: value,
        })
    },[dispatch])

    
    
   
    const setGraphType = useCallback((value) => {
        dispatch({
            type: Actions.SET_GRAPH_TYPE,
            graphType: value
        })
    },[dispatch])

    
    const setDateRange = useCallback((option) => {
        dispatch({
            type: Actions.SET_DATE_RANGE,
            option: option
        })
    },[dispatch])

    

    const setStartDate = useCallback((date) => {
        dispatch({
            type: Actions.SET_START_DATE,
            date: date
        })
    },[dispatch])
        
    const setEndDate = useCallback((date) => {
        dispatch({
            type: Actions.SET_END_DATE,
            date: date
        })
    },[dispatch])
    return <Component1
        state={graph}
        stockPool={stockPool}
        selectedStock={graph.selectedStock}
        chartRefList={chartRefList.current}
        onStockSelected={onStockSelected}
        setLineVisible={setLineVisible}
        deleteStock={deleteStock}
        setStockVisible={setStockVisible}
        setIndexVisible={setIndexVisible}
        indexLibrary={index.indexLibrary}
        onIndexSelected={onIndexSelected}
        selectedIndex={graph.selectedIndex}
        deleteIndex={deleteIndex}
        graphStart={graph.graphStart}
        graphType={graph.graphType}
        setGraphStart={setGraphStart}
        setGraphType={setGraphType}
        lineList={graph.lineList}
        setDateRange={setDateRange}
        chartOptions={getChartOptions()}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
    />
}

// function areEqual(prevProps, nextProps)
// {
//     if(
        
//     )
//     {
//         return false
//     }else {
//         return  true
//     }
// }


export default React.memo(Container/*, areEqual*/);