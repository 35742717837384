import React from 'react';
import {
    Icon,
    Popover,
    Whisper
} from 'rsuite';

import IconHelp from 'Image/svgIcon/help.svg';

import './style.scss';

const infoSpeaker = info => {
    let split = info.split('\n');

    return (
        <Popover className="strategy-rating-single-popup">
            {split.map(x => <>{x}<br /></>)}
        </Popover>
    )
};

export default function Component(props) {
    let color;

    switch (props.rating) {
        case 'A++':
            color = 'purple';
            break;
        case 'A+':
            color = 'red';
            break;
        case 'A':
            color = 'orange';
            break;
        case 'B':
            color = 'blue';
            break;
        case 'C':
            color = 'green';
            break;
        default:
            color = '';
    }

    let rating =
        props.rating.length > 1 ?
            <>{props.rating[0]}<sup>{props.rating.substring(1)}</sup></> :
            props.rating;

    return (
        <div className="strategy-rating-single">
            <div className="name">
                {props.name}
                {
                    props.info &&
                    <Whisper trigger="hover" speaker={infoSpeaker(props.info)}>
                        <Icon className="clickable" icon={IconHelp} />
                    </Whisper>
                }
            </div>
            <div className={'rating ' + color}>{rating}</div>
        </div>
    );
}