import React from "react";
import Dashboard from 'Components/dashboard/dashboard';
import StrategySelector from 'Components/strategySelector';

import StockInfo from 'Components/stockInfo'
function Component({singles, layout, parameters, analysisId, strategyId, strategyList, renderStockHeader, color, stockCode, onStrategySelect})
{
    return <div className="stock-analysis-page">
        {
            analysisId === '2' &&
            <StrategySelector
                strategyId={strategyId}
                strategyList={strategyList}
                onStrategySelect={onStrategySelect}
            />
        }
        <StockInfo 
            stockCode={stockCode}
        />
        <hr />
        <Dashboard
            singles={singles}
            layout={layout}
            enableEdit={false}
            parameters={parameters}
        />
    </div>
}

export default Component;