import React from "react";

function Component()
{
    return <div
        id="chartContainer"
        className={'TVChartContainer'}
    ></div>
}

export default Component;