export const UPDATE_DASHBOARD_LIST = "UPDATE_DASHBOARD_LIST";
export const UPDATE_SELECTED_DASHBOARD = "UPDATE_SELECTED_DASHBOARD";
export const UPDATE_ACTIVEPANELLIST = 'UPDATE_ACTIVEPANELLIST';
export const SHOW_LAYOUTMODAL = "TOGGLE_LAYOUTMODAL";
export const HIDE_LAYOUTMODEL = "HIDE_LAYOUTMODEL";
export const SHOW_CREATEDASHBOARDMODAL = "SHOW_CREATEDASHBOARDMODAL";
export const SHOW_DASHBOARDMODAL = 'SHOW_DASHBOARDMODAL';
export const UPDATE_AVAILABLE_SINGLES = "UPDATE_AVAILABLE_SINGLES";
export const AFTER_SAVE_DASHBOARD_REMOTE = 'AFTER_SAVE_DASHBOARD_REMOTE';
export const AFTER_CREATE_DASHBOARD = 'AFTER_CREATE_DASHBOARD';
export const CLOSE_DASHBOARDMODAL = "CLOSE_DASHBOARDMODAL";
export const AFTER_REMOVE_DASHBOARD = "AFTER_REMOVER_DASHBOARD";