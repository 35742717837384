import React from 'react';
import { Icon } from 'rsuite';

import SvgStep1 from 'Image/svgIcon/strategyDesign/step-1.svg';
import SvgStep2 from 'Image/svgIcon/strategyDesign/step-2.svg';
import SvgStep3 from 'Image/svgIcon/strategyDesign/step-3.svg';

import './style.scss';

const Component = ({ scored, step, showStep1, showStep2, showStep3 }) => {
    var classNameStep1 = 'step step-1 active';
    var classNameStep2 = 'step step-2';
    var classNameStep3 = 'step step-3';

    if (scored) {
        classNameStep2 += ' active';
        classNameStep3 += ' active';
    }

    if (step === 1) {
        classNameStep1 += ' current';
    }

    if (step === 2) {
        classNameStep2 += ' current';
    }

    if (step === 3) {
        classNameStep3 += ' current';
    }

    return (
        <div className="strategy-design-step">
            <div className={classNameStep1} onClick={showStep1}>
                <div className="icon">
                    <div className="number">1</div>
                    <Icon icon={SvgStep1} />
                </div>
                <div className="text">設計策略</div>
            </div>
            <div className="line" />
            <div className={classNameStep2} onClick={showStep2}>
                <div className="icon">
                    <div className="number">2</div>
                    <Icon icon={SvgStep2} />
                </div>
                <div className="text">看評分結果</div>
            </div>
            <div className="line" />
            <div className={classNameStep3} onClick={showStep3}>
                <div className="icon">
                    <div className="number">3</div>
                    <Icon icon={SvgStep3} />
                </div>
                <div className="text">設定回測</div>
            </div>
        </div>
    );
};

export default Component;