import React from "react";
import StrategyCard from "../strategyCard"
import { colorDecidedByValue } from "Util/colorDecider"
import { colorRangeMapping } from '../../../container/constants/colorRange'
import { isRunning } from "Util/isRunning"
import * as StrategyActions from "Redux/saga/actionTypes/strategy";
import store from  "Redux/store"
import history from "Src/history"
import { SHOW_RENAME_MODAL } from "Redux/actionTypes/strategy/strategyOverviewPage";
// import { isEqual } from "lodash";
/**
 * 分享策略
 * @param {string} id - 策略id
 */

const publishStrategy = e => {
    const id = Number(e.currentTarget.dataset.id);
    const published = JSON.parse(e.currentTarget.dataset.published)
    store.dispatch({type: StrategyActions.PUBLISH_STRATEGY, id: id, published: !published})
}

/**
 * 追蹤策略
 * @param {string} id - 策略id
 */

const trackStrategy = (e) => {
    const id = Number(e.currentTarget.dataset.id);
    const tracked = JSON.parse(e.currentTarget.dataset.tracked)
    store.dispatch({type: StrategyActions.TRACK_STRATEGY, id: id, tracked: !tracked})
}

/**
 * 複製策略
 * @param {string} id - 策略id
 */

const copyStrategy = e => {
    store.dispatch({
        type: StrategyActions.COPY_STRATEGY,
        id: e.currentTarget.dataset.id
    })
}

/**
 * 刪除策略策略
 * @param {string} id - 策略id
 */

const deleteStrategy = (eventKey, event) => {
    const confirmResult = window.confirm('確認刪除此策略?');
    if (!confirmResult) { return; }
    store.dispatch({type: StrategyActions.DELETE_STRATE, id: event.currentTarget.dataset.id })
}

/**
 * 跳轉回測頁面
 * @param {string} id - 策略id
 */

const doBacktest = (eventKey, event) => {
    const location = { pathname: '/backtest/' + event.currentTarget.dataset.id };
    history.push(location);
}

/**
 * 跳轉回測結果
 * @param {string} id - 策略id
 */

const showBacktestResult = (eventKey, event) => {
    const location = { pathname: '/backtestResult/' + event.currentTarget.dataset.id };
    history.push(location);
}

/**
 * 打開重新命名視窗
 * @param {string} id - 策略id
 */

const showRenameModal = (eventKey, event) => {
    // console.log(event.currentTarget.dataset.id, event.currentTarget.dataset.name);
    store.dispatch({
        type: SHOW_RENAME_MODAL,
        renameModalId: event.currentTarget.dataset.id,
        renameModalName: event.currentTarget.dataset.name,
    })
}


/**
 * rerender判斷
 * @param {string} id - 策略id
 */

//  function areEqual(prevProps, nextProps) {
//     if(isEqual(prevProps.data, nextProps.data))
//     {
//         return true
//     } else {
//         return false
//     }
// }


export default function Container({ data })
{
    const { status } = data;
    const scored =
        status != null && status !== 0 && status !== 1 && status !== 10 &&
        status !== 11 && status !== 15;

    const showScoreResult = (_eventKey, event) => {
        if (!scored) { return; }

        history.push('/strategyScore/' + event.currentTarget.dataset.id);
    };

    return <StrategyCard
        data={data}
        isRunning={isRunning(data.status) ? 'running': null }
        yearReturnRateColor={colorDecidedByValue(data.year_return_rate, colorRangeMapping)}
        yearReturnRate={data.year_return_rate ? data.year_return_rate : 0}
        trackStrategy={trackStrategy}
        publishStrategy={publishStrategy}
        copyStrategy={copyStrategy}
        showRenameModal={showRenameModal}
        deleteStrategy={deleteStrategy}
        scored={scored}
        showScoreResult={showScoreResult}
        doBacktest={doBacktest}
        showBacktestResult={showBacktestResult}
    />
};

// export default React.memo(Container, areEqual);