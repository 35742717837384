import React from 'react';

import './style.scss';
import Component1 from "./component";
import _  from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { deepEqual } from "Util/deepEqual"
import { UPDATE_DESIGN_STRATEGY } from "Redux/actionTypes/strategy/strategyDesign"

function Container()
{
    const indexScoreList = useSelector(store =>  store.strategyDesign.indexScoreList, deepEqual);
    const dispatch = useDispatch()
    const updateStrategyDesign = (payload) => {
        dispatch({
            type: UPDATE_DESIGN_STRATEGY,
            payload: payload
        })
    }
    
    const deleteBlock = (e) => {
        const blockIndex = e.currentTarget.dataset.id;
        let list = [...indexScoreList];
        list.splice(blockIndex, 1);
        // this.setState({ blockList: list }, () => this.onChange());
        updateStrategyDesign({ indexScoreList: list })
    }

    const copyBlock = (e) => {
        const blockIndex = e.currentTarget.dataset.id;
        let blockList = _.cloneDeep(indexScoreList);
        let block = _.cloneDeep(blockList[blockIndex]);

        blockList.push(block);
        updateStrategyDesign({ indexScoreList: blockList })
        // this.setState({ blockList: blockList }, () => this.onChange());
    }

    const setBlockName = (value, e) => {
        const blockIndex = e.currentTarget.name;
        let list = _.cloneDeep(indexScoreList);
        list[blockIndex].name = value;
        // this.setState({ blockList: list }, () => this.onChange());
        updateStrategyDesign({ indexScoreList: list })
    }

    const deleteIndex = (e) => {
        const blockindex = e.currentTarget.dataset.blockindex;
        const aryindex = e.currentTarget.dataset.aryindex;
        let blockList = _.cloneDeep(indexScoreList);
        let rowList = blockList[blockindex].rowList;
        rowList.splice(aryindex, 1);
        updateStrategyDesign({ indexScoreList: blockList })
        // this.setState({ blockList: blockList }, () => this.onChange());
    }

    const copyIndex = (e) => {
        const blockindex = e.currentTarget.dataset.blockindex;
        const aryindex = e.currentTarget.dataset.aryindex;
        const blockList =  _.cloneDeep(indexScoreList);
        const rowList = blockList[blockindex].rowList;
        const row = _.cloneDeep(rowList[aryindex]);
        rowList.push(row);
        updateStrategyDesign({ indexScoreList: blockList })
    }

    const setIndex = (blockIndex, aryIndex, expression) => {
        let blockList = _.cloneDeep(indexScoreList);
        blockList[blockIndex].rowList[aryIndex] = expression;

        updateStrategyDesign({ indexScoreList: blockList });
    }
    
    const setScore = (blockIndex, aryIndex, value) => {
        let blockList = _.cloneDeep(indexScoreList);
        let index = blockList[blockIndex].rowList[aryIndex];

        let newScore = parseInt(value);
        if (!newScore) { newScore = null; }

        index.score = newScore;
        updateStrategyDesign({ indexScoreList: blockList })
        // this.setState({ blockList: blockList }, () => this.onChange());
    }

    const addIndex = (e) => {
        const blockIndex = e.currentTarget.dataset.blockindex
        const blockList =  _.cloneDeep(indexScoreList);

        let emptyScore = { index: null, operator: 'greater', score: null };
        blockList[blockIndex].rowList =
            [...blockList[blockIndex].rowList, emptyScore];
        updateStrategyDesign({ indexScoreList: blockList  })
        // this.setState({ blockList: blockList }, () => this.onChange());
    }

    const addBlock = () => {
        let newBlockNumber = indexScoreList.length + 1;
        let emptyBlock = {
            name: '分類' + newBlockNumber,
            rowList: [
                { index: null, operator: 'greater', score: null }
            ]
        };

        let list = [...indexScoreList, emptyBlock];
        updateStrategyDesign({ indexScoreList: list  })
        // this.setState({ blockList: list }, () => this.onChange());
    }

    const autoFillScore = () => {
        if (indexScoreList.length === 0) {
            return;
        }

        // Distribute score to the rows
        const blockList =  _.cloneDeep(indexScoreList);
        let nonEmptyBlockList =
            blockList.filter(block =>
                block.rowList && block.rowList.length !== 0);
        let blockScore = Math.floor(100 / nonEmptyBlockList.length);

        nonEmptyBlockList.forEach(block => {
            let rowScore = Math.floor(blockScore / block.rowList.length);
            block.rowList.forEach(row => row.score = rowScore);
        });

        // Calculate the remaining score
        let remainingScore = 100;
        nonEmptyBlockList.forEach(block =>
            block.rowList.forEach(row => remainingScore -= row.score)
        );

        // Add the remaining score to the first row score
        nonEmptyBlockList[0].rowList[0].score += remainingScore;
        updateStrategyDesign({ indexScoreList: blockList })
        // this.setState({ blockList }, () => this.onChange());
    }

    const getTotalScore = () => {
        let totalScore = 0;

        indexScoreList.forEach(block =>
            block.rowList.forEach(index => {
                if (!index.score) { return; }

                totalScore += index.score;
            })
        );

        return totalScore
    }

    return <Component1
        blockList={indexScoreList}
        deleteBlock={deleteBlock}
        copyBlock={copyBlock}
        setBlockName={setBlockName}
        deleteIndex={deleteIndex}
        copyIndex={copyIndex}
        setIndex={setIndex}
        setScore={setScore}
        addIndex={addIndex}
        addBlock={addBlock}
        totalScore={getTotalScore()}
        autoFillScore={autoFillScore}
    />
}

export default Container;