import React, { useState } from 'react';
import {
    Button,
    Input,
    Modal
} from 'rsuite';

import './style.scss';

const Component = ({ currentName, show, onModalHide, onConfirm }) => {
    let [text, setText] = useState('');

    return (
        <Modal
            className="rename-favorite-modal"
            onShow={() => setText('')}
            show={show}
            onHide={onModalHide}>
            <Modal.Header>
                <Modal.Title>重新命名頁籤</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <div className="subtle">輸入新名稱</div>
                <Input
                    placeholder={currentName}
                    value={text}
                    onChange={value => setText(value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onConfirm(text)}>確定</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;