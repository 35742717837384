import IndexUtil from 'Src/indexUtil';
import _ from "lodash"
import moment from 'moment';

export const  getStrategyObject  =  (data, indexLibrary) => {
    // Industry list
    let industryFilter = JSON.parse(data.industries);

    // Constraint list
    let constraintFilter = JSON.parse(data.selections);

    if (constraintFilter) {
        constraintFilter.include =
            IndexUtil.convertCodeInListToIndex(
                constraintFilter.include, indexLibrary);
        constraintFilter.exclude =
            IndexUtil.convertCodeInListToIndex(
                constraintFilter.exclude, indexLibrary);
    }

    // Score list
    let scoreList = JSON.parse(data.scores);
    scoreList = scoreList.map(block => {
        if (!block.rowList) { return block; }

        let newBlock = _.cloneDeep(block);
        newBlock.rowList =
            IndexUtil.convertCodeInListToIndex(newBlock.rowList, indexLibrary);

        return newBlock;
    });

    // Backtest
    let backtest = JSON.parse(data.backtest);
    let backtestSetting = {
        fund: backtest.funds,
        buyRule: backtest.buy_rule,
        buyRank: backtest.buy_rank,
        timespan: backtest.timespan,
        changeFrequency: backtest.change_frequency,
        buyPriority: backtest.buy_priority,
        scoreLimit: backtest.score_limit,
        noDailyLimit: backtest.buy_nodailylimit,
        fee: backtest.transaction_fee,
        backtestDate:
            backtest.backtest_date ?
                moment(backtest.backtest_date).valueOf() : null
    };

    if (backtestSetting.buyRule === 'custom') {
        backtestSetting.customWeight = backtest.weight;
    }

    if (backtestSetting.timespan === 'custom') {
        backtestSetting.timespanStart = backtest.timespan_start;
        backtestSetting.timespanEnd = backtest.timespan_end;
    }

    // result
    return {
        id: data.id,
        strategyName: data.name,
        strategyDescription: data.description,
        industryFilter: industryFilter,
        constraintFilter: constraintFilter,
        indexScoreList: scoreList,
        backtestSetting: backtestSetting,
        isDuplicate: data.from_duplicate,
        status: data.status
    };
};