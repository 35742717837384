import { TOGGLE_STRATEGY_SIDEBAR, SET_ACTIVE_KEY } from "../actionTypes/app"
const initialState = {
    strategySidebarVisibility: true,
    // loggedIn: false
    activeKey: ''
}

export default function reducer(state=initialState, action){
    switch(action.type) {
        case TOGGLE_STRATEGY_SIDEBAR: {
            // console.log(action);
            return {
                ...state,
                strategySidebarVisibility: action.strategySidebarVisibility
            }
        }
        case SET_ACTIVE_KEY: {
            return {
                ...state,
                activeKey: action.activeKey
            }
        }
        default: {
            return state;
        }
    }
}