// import { showNotice } from "Util/notice";
import history from "Src/history";
// import store from "Redux/store"


/**
 * @alias RequestErrorHandlings
 * @memberof module:Fetch
 * @description 處理網路發送前，若未檢查通過發送條件，進行的處理。
 */

const reqErrHandlings = {
	offLine: () => {
		alert("網路尚未連接");
	},
	noTokenFound: () => {
		// alert("尚未登入")
		// store
		// showNotice("通知", "您尚未登入。")

		// Consider the "code" query string returned from Cnyes API
		// Allow tutorial page and backtest result page can be viewed without
		// login
		if (
			history.location.pathname.startsWith('/login') ||
			history.location.pathname.startsWith('/tutorial') ||
			history.location.pathname.startsWith('/backtestResult')
		) {
			return;
		}

		history.push('/login');
	}
};

export default reqErrHandlings;