import React from 'react'
import { hot } from 'react-hot-loader/root';
import { Modal, Button } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';

import { CLOSE_NOTICE } from '../../redux/actionTypes/notice';

import './style.scss';

const Notice = () => {
    const dispatch = useDispatch();
    const notice = useSelector(store => store.notice);

    const showLink = () => {
        window.open(notice.link, '_blank');
    };

    const close = () => {
        dispatch({ type: CLOSE_NOTICE });
    };

    const onExit = () => {
        if (typeof notice.onExit !== 'function') { return; }

        notice.onExit();
    };

    return (
        <Modal
            className="notice"
            show={notice.show}
            onExit={onExit}>
            <Modal.Header closeButton={false}>
                <Modal.Title>{notice.title}</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                {notice.content}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    {notice.closeText ?? '確定'}
                </Button>
                {
                    notice.link &&
                    <Button className="btn-orange" onClick={showLink}>
                        更多詳情
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default hot(Notice);