import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga"; 
import * as backtestSagas from "./saga/backtest"
import strategySagas from "./saga/strategy";
import dashboardSagas from "./saga/dashboard";
import rootReducer from './reducer'
import notificationSagas from "./saga/notification";
import stockSaga from "./saga/stock"
import index from  "./saga/index"
import industries from "./saga/industries";
import profileSagas from './saga/profile';
import configsSagas from './saga/configs';

const sagas = {
	...industries,
	...strategySagas,
    ...backtestSagas,
	...dashboardSagas,
	...notificationSagas,
	...stockSaga,
	...index,
	...profileSagas,
	...configsSagas
};

const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = composeWithDevTools({
// 	maxAge: 3,
// 	latency: 0,
// 	// traceLimit: 2
// 	shouldRecordChanges:  false,
// 	// features: {
// 	// 	pause: false, // start/pause recording of dispatched actions
// 	// 	lock: false, // lock/unlock dispatching actions and side effects    
// 	// 	persist: false, // persist states on page reloading
// 	// 	export: false, // export history of actions in a file
// 	// 	import: 'custom', // import history of actions from a file
// 	// 	jump: false, // jump back and forth (time travelling)
// 	// 	skip: false, // skip (cancel) actions
// 	// 	reorder: false, // drag and drop actions in the history list 
// 	// 	dispatch: false, // dispatch custom actions or action creators
// 	// 	test: false // generate tests for the selected actions
// 	//   },
// 	// Specify name here, actionsBlacklist, actionsCreators and other options if needed
// });
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
for(let o in sagas){
	sagaMiddleware.run(sagas[o]);
}

export default store;