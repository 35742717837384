import { SET_A_PAGINATION, REMOVE_A_PAGINATION, CHANGE_PAGE } from "../actionTypes/pagination";
const initialState = {
    list: [],
}

export default function reducer(state=initialState, action)
{
    switch (action.type) {
        case SET_A_PAGINATION: {
            const exist = state.list.find(o => o.id === action.id);
            if(exist)
            {
                return state;
            } else {
                return {
                    ...state,
                    list: [
                        ...state.list,
                        {
                            id: action.id,
                            page: 1,
                            maxButtons: action.maxButtons
                        }
                    ]
                }
            }
        }
        case REMOVE_A_PAGINATION: {
            const list = state.list.filter(o => o.id !== action.id)
            return {
                ...state,
                list: list
            }
        }
        case CHANGE_PAGE:{
            const list = state.list.map(o => {
                if(o.id === action.id)
                {
                    return {
                        ...o,
                        page: action.page
                    }
                } else {
                    return o;
                }
            })
            return {
                list: list
            }
        }
        default: {
            return state;
        }
    }
};