import React from 'react';

import './style.scss';

const Component = props => {
    if (!props.data) { return <></>; }

    return (
        <div className="list-panel">
            {
                props.data.map((row, i) => {
                    let valueClassName = 'value';

                    if (row.highlight) {
                        valueClassName += ' highlight';
                    }

                    let content = row.data;
                    // Win/loss ratio
                    if (
                        row.ex_data && row.ex_data.win_rate &&
                        row.ex_data.loss_rate
                    ) {
                        content = 
                            <>
                                {row.data}&nbsp;(
                                <span className="red">
                                    +{row.ex_data.win_rate}%
                                </span>
                                &nbsp;/&nbsp;
                                <span className="green">
                                    {row.ex_data.loss_rate}%
                                </span>
                                )
                            </>;
                    }

                    return (
                        <div key={'row-' + i} className="row">
                            <div
                                className="title"
                                style={{ width: props.titleWidth }}>
                                {row.name}
                            </div>
                            <div className={valueClassName}>
                                {content}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default Component;