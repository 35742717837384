import React from 'react';

import Step1 from './component/step1';
import Step2 from './component/step2';
import Step3 from './component/step3';

import './style.scss';

const Container = ({
    industries,
    selections,
    totalFiltered,
    scores,
    backtest
}) => {
    return (
        <div className="strategy-detail">
            <Step1 
                industries={industries}
                selections={selections}
                totalFiltered={totalFiltered} />
            <Step2 indexScores={scores} />
            <Step3 {...backtest} />
        </div>
    );
}

export default Container;