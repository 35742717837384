import React from 'react';
import { Button } from 'rsuite';

import IndexScorePanel from 'Components/indexScorePanel';
import ScoringProgressModal from 'Components/scoringProgressModal';
import StockFilterPanel from 'Components/stockFilterPanel';
import StrategyDesignStep from 'Components/strategyDesignStep';
import TwoStepTutorialModal from 'Components/twoStepTutorialModal';

import './style.scss';

function Component({
    id,
    strategyDesign,
    resetCopiedStrategy,
    revertToLastSave,
    scored,
    onStrategyNameChange,
    onStrategyDescriptionChange,
    onIndustryFilterChange,
    onConstraintFilterChange,
    startScore,
    isScoringProgressModalOpen,
    scoringPercentage,
    scoringRemainTime,
    showStrategyOverview,
    errorMessages,
    isTutorialModalOpen,
    showTutorialModal,
    onTutorialModalClose
}) {
    return (
        <div className="strategy">
            <div>
                <div className="page-title">投資策略設計工具</div>
                <Button
                    onClick={resetCopiedStrategy}
                    disabled={!strategyDesign.isDuplicate}>
                    還原至初始設定
                </Button>
                <Button
                    onClick={revertToLastSave}
                    disabled={!id}>
                    還原至上次儲存
                </Button>
            </div>
            <hr />
            <div className="step-container">
                <StrategyDesignStep strategyId={id} scored={scored} step={1} />
            </div>
            <div className="strategy__stock_filter">
                <div className="title">
                    第一步：設定股票池
                </div>
                <div className="block">
                    <StockFilterPanel
                        onNameChange={onStrategyNameChange}
                        onDescriptionChange={onStrategyDescriptionChange}
                        onIndustryFilterChange={onIndustryFilterChange}
                        onConstraintFilterChange={onConstraintFilterChange} />
                </div>
            </div>
            <div className="strategy__index_score_panel">
                <div className="title">第二步：幫股票池的股票打分數</div>
                <div className="block">
                    <IndexScorePanel startScore={startScore} />
                </div>
                <div>
                    {
                        errorMessages.length > 0 &&
                        <div className="error-message">
                            {
                                errorMessages.map((x, i) =>
                                    <div key={i}>{x}</div>
                                )
                            }
                        </div>
                    }
                    <div className="button-container">
                        <Button onClick={showTutorialModal}>如何設定</Button>
                        <Button
                            onClick={startScore}
                            disabled={errorMessages.length > 0}>
                            下一步：運算評分
                        </Button>
                    </div>
                </div>
            </div>
            <TwoStepTutorialModal
                isOpen={isTutorialModalOpen}
                onClose={onTutorialModalClose} />
            <ScoringProgressModal
                isOpen={isScoringProgressModalOpen}
                percentage={scoringPercentage}
                remainTime={scoringRemainTime}
                showStrategyOverview={showStrategyOverview} />
        </div>
    );
};

export default Component;