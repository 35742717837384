export const FETCH_LIST = 'FETCH_LIST';
export const FETCH_STRATEGY = 'FETCH_STRATEGY';
export const REVERT_TO_LAST_SAVE = "REVERT_TO_LAST_SAVE";
export const FETCH_STOCKS = "FETCH_STOCKS";
export const FETCH_STRATEGYSCORE = "FETCH_STRATEGYSCORE";
export const COPY_STRATEGY = 'COPY_STRATEGY';
export const COPY_EDIT_STRATEGY = 'COPY_EDIT_STRATEGY';
export const FETCH_SHARED_LIST = 'FETCH_SHARED_LIST';
export const TRACK_SHARED_STRATEGY = 'TRACK_SHARED_STRATEGY';
export const RENAME_STRATEGY = 'RENAME_STRATEGY';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';
export const DELETE_STRATE = 'DELETE_STRATE';
export const TRACK_STRATEGY = 'TRACK_STRATEGY';
export const PUBLISH_STRATEGY = 'PUBLISH_STRATEGY';
export const RESET_COPIED_STRATEGY_REMOTE = "RESET_COPIED_STRATEGY_REMOTE";
export const START_SCORE = "START_SCORE";
export const UPDATE_STRATEGY_IN_LIST = 'UPDATE_STRATEGY_IN_LIST';