import Fetch from '../fetch';
/**
 * @constant
 * @memberof module:Fetch
 * @description 回測api
 */
const indexApi = {
    library: {
        method: "GET",
        uri: () => '/stockaccounts'
    },
    data: {
        method: "POST",
        uri: () => "/data/accounts"
    }
}
/**
 * @memberof module:Fetch
 * @function BackTesetApi
 * @description 發送回測相關request
 */
export default function IndexApi(type, params = null) {
    return Fetch.request(
        indexApi[type].method,
        indexApi[type].uri,
        params
    )
};