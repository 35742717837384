import React, { Component } from 'react';
import {
    Button,
    DatePicker,
    Input,
    SelectPicker
} from 'rsuite';
import moment from 'moment';

import './backtestSettingPanel.scss';
import {
    BuyRule,
    BuyRank,
    NoDailyLimit,
    Timespan,
    TimespanYear,
    ChangeFreqency,
    BuyPriority,
    ScoreLimit
} from '../constant/backtest';

var _ = require('lodash');

export default class BacktestSettingPanel extends Component {
    constructor(props) {
        super();

        // Open advanced setting when advanced settings are not default value
        let showAdvancedSettings =
            props.data && (props.data.backtestDate || !props.data.noDailyLimit);

        this.state = { ...props.data, showAdvancedSettings };

        if (props.data && props.data.buyRule !== 'custom') {
            this.state.customWeight = [];
        }
    }

    componentDidUpdate(prevProps) {
        if (_.isEqual(prevProps.data, this.props.data)) { return; }

        this.setState(this.props.data);
        if (this.props.data && this.props.data.buyRule !== 'custom') {
            this.setState({ customWeight: [] });
        }

        // Open advanced setting when advanced settings are not default value
        if (
            !this.state.showAdvancedSettings && this.props.data && (
                this.props.data.backtestDate || !this.props.data.noDailyLimit
            )
        ) {
            this.setState({ showAdvancedSettings: true });
        }
    }

    onChange() {
        if (!this.props.onChange) { return; }

        let settings = {...this.state};
        delete this.state.showAdvancedSettings;

        this.props.onChange(settings);
    }

    // Left column
    onFundChange(value) {
        let fund = parseInt(value);
        this.setState({ fund: fund }, () => this.onChange());
    }

    onBuyRuleChange(value) {
        let state =
            value === 'custom' ?
                { buyRule: value } : { buyRule: value, customWeight: [] };

        this.setState(state, () => this.onChange());
    }

    onBuyRankChange(value) {
        this.setState({ buyRank: value }, () => this.onChange());
    }

    onScoreLimitChange(value) {
        this.setState({ scoreLimit: value }, () => this.onChange());
    }

    // Right column
    onChangeFrequencyChange(value) {
        this.setState({ changeFrequency: value }, () => this.onChange());
    }

    onTimespanChange(value) {
        let newState = { timespan: value };

        // Reset backtest date when switching timespan between year and custom
        if (this.state.timespan === 'custom' || value === 'custom') {
            newState.backtestDate = moment().valueOf();
        }

        this.setState(newState, () => this.onChange());
    }

    onTimespanStartChange(value) {
        this.setState({ timespanStart: value }, () => this.onChange());
    }

    onTimespanEndChange(value) {
        let newDate =
            moment(this.state.backtestDate).set('year', value).valueOf();

        this.setState(
            { timespanEnd: value, backtestDate: newDate },
            () => this.onChange()
        );
    }

    onBuyPriorityChange(value) {
        this.setState({ buyPriority: value }, () => this.onChange());
    }

    // Custom weight
    onCustomWeightChange(index, value) {
        let customWeight = _.cloneDeep(this.state.customWeight);
        customWeight[index] = value;

        this.setState({ customWeight: customWeight }, () => this.onChange());
    }

    onCustomWeightBlur(index) {
        // Do parseFloat() when the TextBox onBlur
        let customWeight = _.cloneDeep(this.state.customWeight);
        let value = customWeight[index];
        customWeight[index] = parseFloat(value);

        if (Number.isNaN(customWeight[index])) {
            customWeight[index] = 0;
        }

        this.setState({ customWeight: customWeight }, () => this.onChange());
    }

    // Advanced settings
    toggleAdvancedSettings() {
        let nextState = !this.state.showAdvancedSettings;

        this.setState({ showAdvancedSettings: nextState });

        if (!nextState) {
            // Reset to default setting when closing advanced settings
            this.setState(
                { backtestDate: null, noDailyLimit: true },
                () => this.onChange()
            );
        } else {
            // Set default backtest date when opening advanced settings
            this.setState({ backtestDate: moment() });
        }
    }

    setBacktestDate(value) {
        this.setState({ backtestDate: value }, () => this.onChange());
    }

    setBacktestDateMonth(value) {
        let newDate =
            moment(this.state.backtestDate).set('month', value).valueOf();

        this.setState({ backtestDate: newDate }, () => this.onChange());
    }

    setBacktestDateDay(value) {
        let newDate =
            moment(this.state.backtestDate).set('date', value).valueOf();

        this.setState({ backtestDate: newDate }, () => this.onChange());
    }

    onNoDailyLimitChange(value) {
        this.setState({ noDailyLimit: value }, () => this.onChange());
    }

    // Render
    renderCustomWeightInput() {
        const columnCount = 3;

        let columnList = [];
        let inputCountInColumn =
            this.state.buyRank <= 15 ?
                5 : Math.ceil(this.state.buyRank / columnCount);

        for (
            let columnIndex = 0; columnIndex <= columnCount - 1; columnIndex++
        ) {
            let inputList = [];

            for (let i = 1; i <= inputCountInColumn; i++) {
                let count = columnIndex * inputCountInColumn + i;

                if (count > this.state.buyRank) { break; }

                inputList.push(
                    <div key={'weight-' + count} className="row">
                        <div className="title">第{count}名</div>
                        <div className="value">
                            <Input
                                value={this.state.customWeight ? this.state.customWeight[count - 1] : ''}
                                onChange={value => this.onCustomWeightChange(count - 1, value)}
                                onBlur={() => this.onCustomWeightBlur(count - 1)} />
                        </div>
                    </div>
                );
            }

            columnList.push(
                <div key={'column-' + columnIndex} className="column">
                    {inputList}
                </div>
            );
        }

        return columnList;
    }

    renderBacktestDate() {
        if (this.state.timespan !== 'custom') {
            return (
                <DatePicker
                    placement="autoVerticalStart"
                    cleanable={false}
                    format="YYYY/MM/DD"
                    value={this.state.backtestDate}
                    onChange={date => this.setBacktestDate(date)} />
            );
        } else {
            let months =
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x =>
                    ({ label: x + '月', value: x - 1 })
                );

            let dayInMonth = moment(this.state.backtestDate).daysInMonth();
            let days = [];

            for (let i = 1; i <= dayInMonth; i++) {
                days.push({ label: i + '日', value: i });
            }

            let backtestDate = moment(this.state.backtestDate);

            return (
                <>
                    {this.state.timespanStart}年同日至{this.state.timespanEnd}年
                    <SelectPicker
                        className="backtest-date-month-picker"
                        data={months}
                        searchable={false}
                        cleanable={false}
                        value={backtestDate.month()}
                        onChange={value => this.setBacktestDateMonth(value)} />
                    <SelectPicker
                        className="backtest-date-day-picker"
                        data={days}
                        searchable={false}
                        cleanable={false}
                        value={backtestDate.date()}
                        onChange={value => this.setBacktestDateDay(value)} />
                </>
            );
        }
    }

    renderAdvancedSettings() {
        return (
            <div className="advanced-settings">
                <div className="column">
                    <div className="row">
                        <div className="title">回測結束日</div>
                        <div className="value">
                            {this.renderBacktestDate()}
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="row">
                        <div className="title">買賣時避開漲跌停股</div>
                        <div className="value">
                            <SelectPicker
                                data={NoDailyLimit}
                                searchable={false}
                                cleanable={false}
                                value={this.state.noDailyLimit}
                                onChange={value => this.onNoDailyLimitChange(value)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let timespanPickerClassName = 'timespan-picker';

        if (this.state.timespan === 'custom') {
            timespanPickerClassName += ' custom';
        }

        return (
            <div className="backtest-setting-panel">
                <div className="column-container">
                    <div className="column left-column">
                        <div className="row">
                            <div className="title">起始資金</div>
                            <div className="value">
                                <Input
                                    value={this.state.fund}
                                    onChange={value => this.onFundChange(value)} />
                                <span style={{ marginLeft: 9 }}>萬</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">買進策略</div>
                            <div className="value">
                                <SelectPicker
                                    className="buy-rule-picker"
                                    data={BuyRule}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.buyRule}
                                    onChange={value => this.onBuyRuleChange(value)} />
                                <SelectPicker
                                    data={BuyRank}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.buyRank}
                                    onChange={value => this.onBuyRankChange(value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">低於幾分不買</div>
                            <div className="value">
                                <SelectPicker
                                    data={ScoreLimit}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.scoreLimit}
                                    onChange={value => this.onScoreLimitChange(value)} />
                            </div>
                        </div>
                    </div>
                    <div className="column right-column">
                        <div className="row">
                            <div className="title">換股頻率/時機</div>
                            <div className="value">
                                <SelectPicker
                                    data={ChangeFreqency}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.changeFrequency}
                                    onChange={value => this.onChangeFrequencyChange(value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">回測期間</div>
                            <div className="value">
                                <SelectPicker
                                    className={timespanPickerClassName}
                                    data={Timespan}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.timespan}
                                    onChange={value => this.onTimespanChange(value)} />
                                {
                                    this.state.timespan === 'custom' &&
                                    <div className="custom-timespan-container">
                                        <SelectPicker
                                            className="timespan-start-picker"
                                            data={TimespanYear}
                                            searchable={false}
                                            cleanable={false}
                                            value={this.state.timespanStart}
                                            onChange={value => this.onTimespanStartChange(value)} />
                                        <span>~</span>
                                        <SelectPicker
                                            className="timespan-end-picker"
                                            data={TimespanYear}
                                            searchable={false}
                                            cleanable={false}
                                            value={this.state.timespanEnd}
                                            onChange={value => this.onTimespanEndChange(value)} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">同分時選擇買進</div>
                            <div className="value">
                                <SelectPicker
                                    data={BuyPriority}
                                    searchable={false}
                                    cleanable={false}
                                    value={this.state.buyPriority}
                                    onChange={value => this.onBuyPriorityChange(value)} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.buyRule === 'custom' &&
                    <>
                        <hr />
                        <div className="custom-weight-hint">
                            自訂權重 買進評分前{this.state.buyRank}名
                        </div>
                        <div className="custom-weight-container">
                            {
                                this.renderCustomWeightInput()
                            }
                        </div>
                    </>
                }
                <hr />
                <Button
                    className="advanced-settings-button"
                    appearance="link"
                    onClick={() => this.toggleAdvancedSettings()}>
                    進階設定
                </Button>
                {
                    this.state.showAdvancedSettings &&
                    this.renderAdvancedSettings()
                }
            </div>
        );
    }
}