import React  from 'react';
import Highcharts from 'highcharts/highstock';
import PriceIndicator from 'highcharts/modules/price-indicator';
import 'Src/page/highcharts.css';
import './style.scss';
import TabSelector from 'Components/tabSelector';
import HighchartsReact from 'highcharts-react-official';
// import { isEqual  } from  "lodash";
PriceIndicator(Highcharts);

Highcharts.dateFormats = {
    q: function (timestamp) {
        let date = new Date(timestamp);
        let quarter = (Math.floor(date.getUTCMonth() / 3) + 1);

        return quarter;
    }
};

function DashboardChart({ currentTab, tabList, tabItemList, setCurrentTab }) {
    return <div className="dashboard-chart">
        <TabSelector
            tabs={tabList}
            currentTab={currentTab}
            onTabChange={setCurrentTab} />
         <div className = "dashboard__chart__market_indice">
            {
                currentTab ? tabItemList[currentTab].map((o, i)  => {
                    return <div key={i} >
                                <HighchartsReact
                                    // key={i}
                                    highcharts={Highcharts}
                                    constructorType={'stockChart'}
                                    options={o}
                                    containerProps={{ style: { height: "100%" } }}
                                    // callback={chart => this.handleLegendSymbolBorder(chart)} 
                                />
                        </div>
                }): null
            }
         </div>
    </div>
}

function areEqual(prevProps, nextProps)
{
    // return false;
    if(
        // !isEqual(prevProps.data, nextProps.data) ||
        // !isEqual(prevProps.tabItemList, nextProps.tabItemList) ||
        prevProps.currentTab !== nextProps.currentTab
    )
    {
        return false
    } else {
        return true;
    }
}
export default React.memo(DashboardChart, areEqual);