import React, { useEffect } from "react";
import { SET_REFKEY_TABLE, SET_SELECTED_ANALYSISID, ON_ANALYSIS_SELECT } from  "Redux/actionTypes/sidebar"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Component from "../component";
import { cookies } from 'Https/fetch';
import { SHOW_NEED_LOGIN_MODAL } from 'Redux/actionTypes/needLoginModal';
// import _ from "lodash";
import showPricingPlan from '../../../utils/showPricingPlan';
import '../style.scss';
export function Container(){
    const dispatch = useDispatch()
    const analysisList = useSelector(store => store.stock.analysisList)
    const refKeyTable = useSelector(store => store.sidebar.refKeyTable)
    const sidebar = useSelector(store => store.sidebar);
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })
    let strategyId = null;
    let analysisId =  null;
    let id =  null;
    if(match)
    {
        strategyId = match.params.strategyId;
        analysisId = match.params.analysisId;
        id = match.params.id;
    }

    // 設定列表資料
    useEffect(() => {
        dispatch({
            type: SET_REFKEY_TABLE,
            analysisList: analysisList,
            analysisId: match?.params.analysisId
        })
        // history.listen(onRouteChange)
    /* eslint-disable react-hooks/exhaustive-deps */
    },[analysisList])
    // 打開當前列表
    useEffect(() => {
        if(match && refKeyTable.length)
        {
            const target = refKeyTable.find(o => o.value === match.params.analysisId*1)
            dispatch({
                type: ON_ANALYSIS_SELECT,  item: target
            })
        }
        // history.listen(onRouteChange)
    /* eslint-disable react-hooks/exhaustive-deps */
    },[refKeyTable])

    useEffect(() => {
        if(analysisId)
        {
            dispatch({
                type: SET_SELECTED_ANALYSISID,  selectedAnalysisId: analysisId*1
            })
        } else {
            dispatch({
                type: SET_SELECTED_ANALYSISID,  selectedAnalysisId: null
            })
        }
        // history.listen(onRouteChange)
    /* eslint-disable react-hooks/exhaustive-deps */
    },[analysisId])

    const checkLogin = link => {
        if (!cookies.get('accessToken')) {
            dispatch({ type: SHOW_NEED_LOGIN_MODAL });
            return;
        }

        history.push(link);
    }

    // 選擇財報分析
    // 若選標頭則開啟複選單然後設定該Tree選到的值
    const onAnalysisSelect = (item) => {
        dispatch({
            type: ON_ANALYSIS_SELECT,  item: item
        })
        // alert('123')
        if (!(item.children && item.children.length > 0)) {
            let path = '/stockAnalysis/' + id + '/' + item.value;
            if (strategyId) {
                path += '/' + strategyId
            }

            // Do not push the path into history if the page is current page
            if (history.location.pathname === path) { return; }

            const newlocation = { pathname: path };
            history.push(newlocation);
            // alert('123')
        }
    }

    let activeKey = location.pathname;

    if (location.pathname.startsWith('/stockAnalysis')) {
        // Use '/stockAnalysis/{analysisId}' as activeKey when showing stock
        // analysis page
        activeKey = '/stockAnalysis/' + analysisId;
    } else if (location.pathname.startsWith('/strategySharing')) {
        // Use '/strategySharing/{strategyId}' as activeKey when browsing
        // shared strategy
        activeKey = '/strategySharing';
    }

    const showVideoTutorial = () => {
        window.open(
            'https://hao.cnyes.com/post/7788',
            '_blank'
        );
    };

    const showChangeLog = () => {
        history.push('/changeLog');
    }

    const showLineGroup = () => {
        window.open(
            'https://line.me/ti/g2/RFAmsX8AWYHOIq073PQWuqNwDVkKymtMnxUltQ',
            '_blank'
        );
    }

    return <Component 
        activeKey={activeKey}
        analysisList={analysisList}
        checkLogin={checkLogin}
        onAnalysisSelect={onAnalysisSelect}
        expandItemValues={sidebar.expandItemValues}
        selectedAnalysisId={sidebar.selectedAnalysisId}
        show={!location.pathname.startsWith('/login')}
        showPricingPlan={showPricingPlan}
        showVideoTutorial={showVideoTutorial}
        showChangeLog={showChangeLog}
        showLineGroup={showLineGroup}
        // toStrategy={toStrategy}
    />
}
export default Container;