import React from 'react';
import { Icon } from 'rsuite';

import './style.scss';

const Component = props => {
    return (
        <div className="card" onClick={props.onClick}>
            <div
                className="image"
                style={{ background: props.background }}>
                <Icon icon={props.image} />
            </div>
            <div className="card-title">{props.title}</div>
            <div className="content">
                {props.children}
            </div>
        </div>
    );
};

export default Component;