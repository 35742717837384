/**
 * @alias RequestConditions
 * @memberof module:Fetch
 * @description 處理網路發送前，發送檢查的條件。
 */

const reqConditions = [
	{
		type: "offLine",
		check: () => !navigator.onLine,
	}
];

export default reqConditions;