import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import { AutoSizer } from 'react-virtualized';
import {
    Button,
    Dropdown,
    Icon
} from 'rsuite';

import API from '../api';
import DataDateModal from '../component/table/dataDateModal';
import DataTable from '../component/table/dataTable';
import IconColumn from '../image/svgIcon/table/column.svg';
import IconRow from '../image/svgIcon/table/row.svg';
import IconSwitch from '../image/svgIcon/table/switch.svg';
import IndexLibraryModalTable from '../component/table/indexLibraryModalTable';
import IndexUtil from '../indexUtil';
import ReactUtil from '../reactUtil';
import StockSelectionModal from '../component/table/stockSelectionModal';
import TableManagerModal from '../component/table/tableManagerModal';
import TableNameModal from '../component/table/tableNameModal';
import TabSelector from 'Components/tabSelector';

import './tablePage.css';

var _ = require('lodash');

export default class TablePage extends Component {
    state = {
        tableId: -1,
        tableName: '',
        showIndexLibraryModalTable: false,
        selectedIndexList: [],
        columnList: [],
        defaultIndex: null,
        showStockSelectionModal: false,
        selectedStockList: [],
        showDataDateModal: false,
        dataDate: moment().format('YYYY-MM-DD'),
        autoUpdateDate: false,
        showTableNameModal: false,
        tableNameModalMode: 'new', // new, copy,
        showTableManagerModal: false,
        loading: false
    };

    tableData = {};
    selectedTemplateTable = null;
    needSave = false; // Indicates user has queried but has't save the table
    fetchTimerId = null;

    async componentDidMount() {
        let stockPool = await API.getStockPool();
        if (stockPool) {
            this.setState({ stockPool: stockPool });
        } else {
            alert('取得股票池失敗');
        }

        let indexLibrary = await API.getIndexLibrary();
        if (indexLibrary) {
            this.setState({ indexLibrary: indexLibrary });
        } else {
            alert('取得指標庫失敗');
        }

        this.refreshStockGroup();

        let sameIndustry = await API.getSameIndustry();
        if (sameIndustry) {
            this.setState({ sameIndustry });
        } else {
            alert('取得競業資料失敗');
        }

        API.getStrategyList().then(strategyList => {
            if (strategyList) {
                this.setState({ strategyList });
            } else {
                alert('取得策略清單失敗');
            }
        });

        API.getTrackingStock().then(trackingStock => {
            if (trackingStock) {
                this.setState({ trackingStock });
            } else {
                alert('取得追蹤個股失敗');
            }
        });

        API.getTableList(indexLibrary).then(result => {
            this.setState(
                { tableList: result ? result : [] },
                () => {
                    if (!result || result.length === 0) { return; }

                    let selectedTable = result.find(x => x.selected);

                    if (!selectedTable) { return; }
                    this.setCurrentTable(selectedTable.id);
                }
            );
        });
    }

    componentWillUnmount() {
        this.checkNeedSave();

        if (!this.fetchTimerId) { return; }
        clearTimeout(this.fetchTimerId);
    }

    async refreshStockGroup() {
        let stockGroups = await API.getStockGroups();
        if (stockGroups) {
            this.setState({ stockGroups });
        } else {
            alert('取得股票分類失敗');
        }
    }

    onTabChange(id) {
        this.checkNeedSave();
        this.setCurrentTable(id);

        API.selectTable(id).then(result => {
            if (result) { return; }
            alert('設定預設表格失敗');
        });
    }

    async setCurrentTable(id) {
        if (this.state.loading) { return; }

        let table = this.state.tableList.find(x => x.id === id);

        if (!table) {
            let result =
                await API.getDataDate(
                    null, null, moment().format('YYYY/MM/DD'));

            this.setState({
                tableId: -1,
                tableName: '',
                tableData: null,
                columnList: [],
                selectedIndexList: [],
                selectedStockList: [],
                dataDate: moment(result.day).format('YYYY-MM-DD'),
                autoUpdateDate: false
            });
            return;
        }

        let columnList =
            await API.getColumnList(
                table.columns, table.date, this.state.indexLibrary);

        this.setState(
            {
                tableId: table.id,
                tableName: table.name,
                tableData: this.tableData[id],
                columnList,
                selectedIndexList: table.columns,
                selectedStockList: table.stocks,
                dataDate: table.date,
                autoUpdateDate: table.updated
            },
            () => {
                if (
                    table.columns.length !== 0 && table.stocks.length !== 0 &&
                    !this.tableData[this.state.tableId]
                ) {
                    this.getSavedTableData();
                }
            }
        );
    }

    startQuery() {
        this.needSave = true;

        API.startQueryTableData(
            this.state.tableId,
            this.state.selectedIndexList,
            this.state.selectedStockList,
            this.state.dataDate
        ).then(result => {
            if (result) {
                this.tableData[this.state.tableId] = null;
                this.setState({ tableData: null });

                this.fetchTimerId =
                    setTimeout(() => this.getQueryingTableData(), 1000);
                this.setState({ loading: true });
            } else {
                alert('送出查詢失敗');
            }
        })
    }

    getQueryingTableData() {
        API.getQueryingTableData(
            this.state.tableId, this.state.indexLibrary
        ).then(data => {
            if (data.querying) {
                this.fetchTimerId =
                    setTimeout(() => this.getQueryingTableData(), 1000);
            } else {
                this.fetchTimerId = null;
                this.setState({ loading: false });
            }

            this.tableData[this.state.tableId] = data;
            this.setState({ tableData: data });
        });
    }

    getSavedTableData() {
        this.setState({ loading: true });

        API.getSavedTableData(this.state.tableId).then(data => {
            this.tableData[this.state.tableId] = data;
            this.setState({ loading: false, tableData: data });
        });
    }

    removeStock(code) {
        let selectedStockList =
            this.state.selectedStockList.filter(x => x !== code);

        let table = this.state.tableList.find(x => x.id === this.state.tableId);
        table.stocks = table.stocks.filter(x => x !== code);

        let tableData = _.cloneDeep(this.state.tableData);
        tableData.data =
            tableData.data.filter(stock => stock.stock_code.value !== code);
        this.tableData[this.state.tableId] = tableData;

        this.setState({ selectedStockList, tableData });
    }

    deleteColumn(groupIndex) {
        let selectedIndexList = _.cloneDeep(this.state.selectedIndexList);
        let columnList = _.cloneDeep(this.state.columnList);

        selectedIndexList.splice(groupIndex, 1);
        columnList.splice(groupIndex, 1);

        let table = this.state.tableList.find(x => x.id === this.state.tableId);
        table.columns = selectedIndexList;

        // Update the columns in tableData to prevent rendering deleted column's
        // cell
        let tableData = _.cloneDeep(this.state.tableData);
        tableData.columns =
            tableData.columns.filter(x =>
                !x.id.startsWith('index_' + groupIndex));
        this.tableData[this.state.tableId] = tableData;

        this.setState({ selectedIndexList, columnList, tableData });
    }

    showDataDateModal() {
        this.setState({ showDataDateModal: true });
    }

    closeDataDateModal(date, autoUpdateDate) {
        if (date) {
            let list = _.cloneDeep(this.state.tableList);
            let table = list.find(x => x.id === this.state.tableId);

            if (table) {
                table.date = date;
                table.updated = autoUpdateDate;
                this.tableData[this.state.tableId] = null;

                API.getColumnList(
                    table.columns, table.date, this.state.indexLibrary
                ).then(columnList =>
                    this.setState({
                        tableList: list,
                        columnList,
                        dataDate: table.date,
                        autoUpdateDate: table.updated,
                        tableData: null
                    })
                );
            }
        }

        this.setState({ showDataDateModal: false });
    }

    async toggleUpdate() {
        let tableList = _.cloneDeep(this.state.tableList);
        let table = tableList.find(x => x.id === this.state.tableId);
        let updated = table.updated ? 0 : 1;

        API.getTableDataDate(this.state.tableId, updated)
            .then(result => {
                table.date = result.date;
                table.updated = updated === 1;
                this.tableData[this.state.tableId] = null;

                API.getColumnList(
                    table.columns, table.date, this.state.indexLibrary
                ).then(columnList =>
                    this.setState({
                        tableList,
                        columnList,
                        dataDate: table.date,
                        autoUpdateDate: table.updated,
                        tableData: null
                    })
                );
            });
    }

    checkNeedSave() {
        if (!this.needSave) { return; }

        if (!window.confirm('表格尚未儲存，是否儲存?')) {
            this.needSave = false;
            return;
        }

        this.saveTable();
    }

    showIndexModalTable(index) {
        this.setState(
            { showIndexLibraryModalTable: true, defaultIndex: index });
    }

    async closeIndexModalTable(indexList) {
        if (indexList) {
            let columnList =
                await API.getColumnList(
                    indexList, this.state.dataDate, this.state.indexLibrary);

            this.tableData[this.state.tableId] = null;

            let table =
                this.state.tableList.find(x => x.id === this.state.tableId);
            table.columns = indexList;

            this.setState(
                { selectedIndexList: indexList, columnList, tableData: null });
        }

        this.setState(
            { showIndexLibraryModalTable: false, defaultIndex: null });
    }

    showStockSelectionModal() {
        this.setState({ showStockSelectionModal: true });
    }

    closeStockSelectionModal(stockList) {
        if (stockList) {
            this.tableData[this.state.tableId] = null;

            let table =
                this.state.tableList.find(x => x.id === this.state.tableId);
            table.stocks = stockList;

            this.setState({ selectedStockList: stockList, tableData: null });
        }

        this.setState({ showStockSelectionModal: false });
    }

    transposeTable() {
        this.setState({ transpose: !this.state.transpose });
    }

    // CSV
    getCSVHeader() {
        if (!this.state.tableData || !this.state.tableData.columns) {
            return [];
        }

        return (
            this.state.tableData.columns.map(column => {
                let label;

                if (column.id.includes('index_')) {
                    // This is an index column, get index from array
                    let split = column.id.split('_');
                    let groupIndex = parseInt(split[1]);
                    let itemIndex = parseInt(split[2]);
                    let index = this.state.columnList[groupIndex][itemIndex];

                    label =
                        ReactUtil.getNodeText(
                            IndexUtil.getTableHeaderIndexText(index))
                } else {
                    label = column.name;
                }

                return { label: label, key: column.id };
            })
        );
    }

    getCSVData() {
        if (!this.state.tableData || !this.state.tableData.data) { return []; }

        let tableData = _.cloneDeep(this.state.tableData.data);

        tableData.forEach(data => {
            let keys = Object.keys(data);

            keys.forEach(key => {
                if (key === 'stock_name') {
                    // Fill stock's name
                    let stock =
                        this.state.stockPool.find(x =>
                            x.stock_code === data['stock_code']);

                    data[key] = stock ? stock.stock_name : '';
                } else {
                    data[key] = data[key].value;
                }
            });
        });

        return tableData;
    }

    showNewTableModal(table) {
        this.selectedTemplateTable = table;
        this.setState({ showTableNameModal: true, tableNameModalMode: 'new' });
    }

    showCopyTableModal() {
        this.setState({ showTableNameModal: true, tableNameModalMode: 'copy' });
    }

    showTableManagerModal() {
        this.setState({ showTableManagerModal: true });
    }

    closeTableManagerModal() {
        this.setState({ showTableManagerModal: false });
    }

    onTableListChange(list, currentTableId) {
        this.setState(
            { tableList: list },
            () => {
                if (currentTableId == null) { return; }

                this.setCurrentTable(currentTableId)
            });
    }

    closeTableNameModal(mode, name) {
        if (name) {
            switch (mode) {
                case 'new':
                    this.createTable(name);
                    break;
                case 'copy':
                    this.copyTable(name);
                    break;
                default:
            }
        }

        this.setState({ showTableNameModal: false });
    }

    async createTable(name) {
        let table;

        if (this.selectedTemplateTable) {
            table = _.cloneDeep(this.selectedTemplateTable);

            table.id = -1;
            table.name = name;
        } else {
            let result =
                await API.getDataDate(null, moment().format('YYYY-MM-DD'));

            if (!result) {
                alert('取得最近交易日失敗');
                return;
            }

            let lastTradingDate = moment(result.day).format('YYYY-MM-DD');

            table = {
                id: -1,
                name: name,
                columns: [],
                stocks: [],
                date: lastTradingDate,
                updated: false
            };
        }

        API.saveTable(
            table.id, table.name, table.stocks, table.columns, table.date,
            table.updated
        ).then(result => {
            if (!result) {
                alert('新增表格失敗');
                return;
            } else {
                alert('新增表格成功');
            }

            let tableList = _.cloneDeep(this.state.tableList);
            table.id = result.id;
            tableList.push(table);

            this.setState({ tableList }, () => this.setCurrentTable(result.id));
        });
    }

    saveTable() {
        this.needSave = false;

        let table =
            this.state.tableList ?
                this.state.tableList.find(x => x.id === this.state.tableId) :
                null;

        if (!table) { return; }

        API.saveExistingTable(
            this.state.tableId,
            this.state.tableName,
            table.stocks,
            this.state.selectedIndexList,
            this.state.dataDate,
            this.state.autoUpdateDate
        ).then(result =>
            alert('儲存表格' + (result ? '成功' : '失敗'))
        );
    }

    copyTable(name) {
        let table =
            _.cloneDeep(
                this.state.tableList.find(x => x.id === this.state.tableId)
            );

        table.id = -1;
        table.name = name;

        API.saveTable(
            table.id, table.name, table.stocks, table.columns, table.date,
            table.updated
        ).then(result => {
            if (!result) {
                alert('複製表格失敗');
                return;
            } else {
                alert('複製表格成功');
            }

            let tableList = _.cloneDeep(this.state.tableList);
            table.id = result.id;
            tableList.push(table);

            this.setState(
                { tableList }, () => this.setCurrentTable(result.id)
            );
        });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        let trackingStock = _.cloneDeep(this.state.trackingStock);
        let group = trackingStock.find(group => group.id === groupId);

        if (!group) { return; }

        if (tracked) {
            group.items.push(stockId);
        } else {
            group.items = group.items.filter(x => x !== stockId);
        }

        this.setState({ trackingStock });
    }

    renderTemplateList() {
        if (!this.state.tableList) { return; }

        return (
            this.state.tableList
                .filter(table => table.template)
                .map((table, i) =>
                    <Dropdown.Item
                        key={i}
                        onSelect={() => this.showNewTableModal(table)}>
                        {table.name}
                    </Dropdown.Item>
                )
        );
    }

    renderExportButton() {
        if (
            !this.state.tableData || !this.state.tableData.columns ||
            !this.state.tableData.data
        ) {
            return <div className="grey">匯出CSV</div>
        }

        return (
            <CSVLink
                headers={this.getCSVHeader()}
                data={this.getCSVData()}
                filename={'易策略_' + moment().format('YYYYMMDD_HHmm') + '.csv'}>
                匯出CSV
            </CSVLink>
        );
    }

    getTabList() {
        if (!this.state.tableList) { return; }

        return (
            this.state.tableList
                .filter(table => !table.template)
                .map(table => ({ id: table.id, name: table.name }))
        );
    }

    onSortEnd(props) {
        let { oldIndex, newIndex } = props;
        let list = _.cloneDeep(this.state.selectedStockList);

        // Subtract 1 to exclude the header row to get correct index of the
        // stock in this.state.selectedStockList
        let stock = list[oldIndex - 1];
        list.splice(oldIndex - 1, 1);
        list.splice(newIndex - 1, 0, stock);

        this.setState({ selectedStockList: list });

        let table =
            this.state.tableList.find(x => x.id === this.state.tableId);
        table.stocks = list;
    }

    renderTable() {
        if (
            this.state.columnList.length === 0 &&
            this.state.selectedStockList.length === 0
        ) {
            return;
        }

        let fixedRowCount = 1;
        let fixedColumnCount = 1;

        return (
            <AutoSizer>
                {
                    ({ height, width }) =>
                        <DataTable
                            width={width}
                            height={height}
                            fixedRowCount={fixedRowCount}
                            fixedColumnCount={fixedColumnCount}
                            transpose={this.state.transpose}
                            onSortEnd={props => this.onSortEnd(props)}
                            columnList={this.state.columnList}
                            stockList={this.state.selectedStockList}
                            stockPool={this.state.stockPool}
                            tableData={this.state.tableData}
                            trackingStock={this.state.trackingStock}
                            onColumnEdit={groupIndex => this.showIndexModalTable(groupIndex)}
                            onColumnDelete={groupIndex => this.deleteColumn(groupIndex)}
                            onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                }
            </AutoSizer>
        );
    }

    renderHint() {
        if (!this.state.tableList || this.state.tableList.length === 0) {
            return (
                <div className="hint">
                    <div className="subtle">開始建立您的表格</div>
                    <Button
                        appearance="link"
                        onClick={() => this.showNewTableModal()}>
                        <Icon icon={IconRow} />建立新表格
                    </Button>
                </div>
            );
        }

        if (
            this.state.selectedStockList.length === 0 &&
            this.state.selectedIndexList.length === 0
        ) {
            return (
                <div className="hint">
                    <div className="subtle">開始建立您的表格</div>
                    <Button
                        appearance="link"
                        onClick={() => this.showStockSelectionModal()}>
                        <Icon icon={IconRow} />選擇股票
                    </Button>
                </div>
            );
        }

        if (this.state.loading) {
            return <div className="hint overlay">資料查詢中...</div>;
        }
    }

    render() {
        let table =
            this.state.tableList ?
                this.state.tableList.find(x => x.id === this.state.tableId) :
                null;
        let dataDateStr = moment(this.state.dataDate).format('YYYY/M/D');

        return (
            <div className="table-page">
                <TabSelector
                    enableDropdown
                    tabs={this.getTabList()}
                    currentTab={this.state.tableId}
                    onTabChange={id => this.onTabChange(id)} />
                <div className="block-1">
                    {
                        this.state.tableList &&
                        this.state.tableList.length > 0 &&
                        <div className="button-container">
                            <div className="left-button-container">
                                <Button
                                    appearance="link"
                                    onClick={() => this.showStockSelectionModal()}>
                                    <Icon icon={IconRow} />
                                    {
                                        this.state.selectedStockList.length === 0 ?
                                            '選擇' : '編輯'
                                    }
                                    個股
                                </Button>
                                <Button
                                    appearance="link"
                                    onClick={() => this.showIndexModalTable()}>
                                    <Icon icon={IconColumn} />
                                    {
                                        this.state.selectedIndexList.length === 0 ?
                                            '選擇' : '編輯'
                                    }
                                    指標
                                </Button>
                                |
                                <Button
                                    appearance="link"
                                    onClick={() => this.transposeTable()}>
                                    <Icon icon={IconSwitch} />欄列互換
                                </Button>
                                |
                                <Button onClick={() => this.startQuery()}>
                                    查詢
                                </Button>
                                <Button onClick={() => this.saveTable()}>
                                    儲存
                                </Button>
                            </div>
                            <div className="right-button-container">
                                <Button
                                    className="date-button"
                                    appearance="link"
                                    onClick={() => this.showDataDateModal()}>
                                    資料日期：{dataDateStr}
                                </Button>
                                <Button
                                    className="update-date-button"
                                    appearance="link"
                                    onClick={() => this.toggleUpdate()}>
                                    {
                                        this.state.autoUpdateDate ?
                                            '(自動更新)' : '(不更新)'
                                    }
                                </Button>
                                <Dropdown
                                    title="檔案管理"
                                    placement="bottomEnd">
                                    <Dropdown.Item onSelect={() => this.showNewTableModal()}>
                                        建立新表格
                                    </Dropdown.Item>
                                    <Dropdown.Menu
                                        pullLeft={true}
                                        title="從範本建立 新表格">
                                        {this.renderTemplateList()}
                                    </Dropdown.Menu>
                                    <hr />
                                    <Dropdown.Item onSelect={() => this.showCopyTableModal()}>
                                        複製表格
                                    </Dropdown.Item>
                                    <Dropdown.Item onSelect={() => this.showTableManagerModal()}>
                                        刪除/重命名
                                    </Dropdown.Item>
                                    <hr />
                                    <Dropdown.Item>
                                        {this.renderExportButton()}
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        </div>
                    }
                    <div className="table-container">
                        {this.renderTable()}
                        {this.renderHint()}
                    </div>
                    <IndexLibraryModalTable
                        show={this.state.showIndexLibraryModalTable}
                        indexLibrary={this.state.indexLibrary}
                        selectedIndexList={this.state.selectedIndexList}
                        dataDate={this.state.dataDate}
                        defaultIndex={this.state.defaultIndex}
                        onCancel={() => this.closeIndexModalTable()}
                        onClose={columnList => this.closeIndexModalTable(columnList)} />
                    <StockSelectionModal
                        show={this.state.showStockSelectionModal}
                        indexLibrary={this.state.indexLibrary}
                        stockPool={this.state.stockPool}
                        stockGroups={this.state.stockGroups}
                        strategyList={this.state.strategyList}
                        sameIndustry={this.state.sameIndustry}
                        selectedStockList={table ? table.stocks : []}
                        notifyRefreshStockGroup={() => this.refreshStockGroup()}
                        onCancel={() => this.closeStockSelectionModal()}
                        onConfirmClick={stockList => this.closeStockSelectionModal(stockList)} />
                    <DataDateModal
                        show={this.state.showDataDateModal}
                        dataDate={this.state.dataDate}
                        autoUpdateDate={this.state.autoUpdateDate}
                        onCancel={() => this.closeDataDateModal()}
                        onConfirmClick={(date, autoUpdateDate) => this.closeDataDateModal(date, autoUpdateDate)} />
                    <TableNameModal
                        show={this.state.showTableNameModal}
                        mode={this.state.tableNameModalMode}
                        onCancel={() => this.closeTableNameModal()}
                        onConfirmClick={(mode, name) => this.closeTableNameModal(mode, name)} />
                    <TableManagerModal
                        show={this.state.showTableManagerModal}
                        tables={this.state.tableList}
                        onTableListChange={(list, currentTableId) => this.onTableListChange(list, currentTableId)}
                        onClose={() => this.closeTableManagerModal()} />
                </div>
            </div >
        );
    }
}