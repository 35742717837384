import React from 'react';
import {
    Dropdown,
    Icon,
    Popover,
    Whisper
} from 'rsuite';

import Hamburger from 'Image/svgIcon/hamburger.svg';

export default function Component({ 
    tabs, onSelect, closeDropdown, whisperRef 
}) {
    const renderPopover = () => {
        return (
            <Popover className="tab-selector__popover" full>
                <Dropdown.Menu onSelect={() => closeDropdown()}>
                    {renderDropdownItem()}
                </Dropdown.Menu>
            </Popover>
        );
    }

    const renderDropdownItem = () => {
        if (!tabs) { return; }

        return (
            tabs.map(tab =>
                <Dropdown.Item
                    key={tab.id}
                    onSelect={() => onSelect(tab.id)}
                    eventKey={tab.id}>
                    {tab.name}
                </Dropdown.Item>
            )
        );
    }

    return (
        <Whisper
            placement="bottomEnd"
            trigger="click"
            speaker={renderPopover()}
            ref={whisperRef}>
            <Icon
                className="clickable"
                icon={Hamburger} />
        </Whisper>
    );
}