import Component from "./component.jsx"
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPanelData } from "Redux/actionCreators/dashboard"

function Container({ panel, rowHeight, parameters  })
{
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id))

    useEffect(() => {
        getPanelData(panel, parameters)
    },[panel, parameters])
    
    
    

    return panelData ? <Component 
        data={panelData.rawData}
    />: null
}

export default Container;