import React, { Component } from 'react';
import {
    Button,
    CheckTree,
    Icon,
    Input,
    InputGroup,
    Modal
} from 'rsuite';

export default class IndustryFilterModal extends Component {
    state = {
        searchBoxText: ''
    };

    selectedList = [];

    componentDidUpdate(prevProps) {
        if (this.props.show) {
            this.selectedList = this.props.selectedList;
        }

        if (!prevProps.show && this.props.show) {
            this.setState({ searchBoxText: '' });
        }
    }

    render() {
        let industryData = this.props.data[this.props.type];

        let modalTitle = this.props.isIncludeMode ? '選擇' : '排除';
        if (this.props.type === 'industries') {
            modalTitle += '產業 (證交所分類)';
        } else if (this.props.type === 'concepts') {
            modalTitle += '概念股';
        } else if (this.props.type === 'conglomerates') {
            modalTitle += '集團股';
        }

        return (
            <Modal show={this.props.show} onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <InputGroup inside>
                        <Input
                            placeholder="搜尋指標/項目"
                            onChange={value => this.setState({ searchBoxText: value })} />
                        <InputGroup.Button>
                            <Icon icon="search" />
                        </InputGroup.Button>
                    </InputGroup>
                    <CheckTree
                        data={industryData}
                        defaultExpandAll
                        renderTreeIcon={() => <div />}
                        style={{ marginTop: 14 }}
                        searchKeyword={this.state.searchBoxText}
                        defaultValue={this.props.selectedList}
                        onChange={values => this.selectedList = values} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.onClose(this.selectedList)}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}