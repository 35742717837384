export const getYAxis = (stock, data, state, visible) => {
    if(state.graphStart === 'normal')
    {
        // console.log(stock.stock_code + '-' + data.code);
        // window.b = stock.stock_code + '-' + data.code
        let indexNumber = state.graphType === 'byIndex' ?
                        0 : state.selectedIndex.findIndex(
                            index => data.code === index.value.value);

        return {
            id: stock.stock_code + ' ' + data.code + ' ' + data.range,
            visible: visible,
            className: 'yAxis-' + indexNumber,
            offset: 50 * indexNumber,
            stockCode: stock.stock_code,
            indexCode: data.code,
            gridLineColor: 'rgba(255, 255, 255, 0.1)',
            opposite: true,
            labels: {
                align: 'left',
                style: {
                    color: '#fff',
                    fontSize: '12px'
                },
                formatter: function()
                {
                    return state.graphStart === 'normal' ? this.value: ((this.value > 0 ? '+' : '') + this.value + '%');
                }
            },
            title: {
                text: data.name + ' (' + data.unit + ')',
                style: {
                    color: '#fff',
                    fontSize: '12px'
                }
            },
        }
    } else {
        return {
            gridLineColor: 'rgba(255, 255, 255, 0.3)',
            opposite: true,
            labels: {
                align: 'left',
                style: {
                    color: '#fff',
                    fontSize: '12px'
                },
                formatter: function()
                {
                    return state.graphStart === 'normal' ? this.value: ((this.value > 0 ? '+' : '') + this.value + '%');
                }
            },
            title: {
                enabled: false
            }
        }
    }
}


export const percentYAxis = {
    id: '0',
    gridLineColor: 'rgba(255, 255, 255, 0.3)',
    opposite: true,
    labels: {
        align: 'left',
        style: {
            color: '#fff',
            fontSize: '12px'
        },
        formatter: function () {
            return (this.value > 0 ? '+' : '') + this.value + '%'
        }
    },
    title: {
        enabled: false
    }
};