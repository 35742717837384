import React from 'react';
import {
    Dropdown,
    Icon,
    Nav,
    Sidenav,
    Tree,
    Sidebar
} from 'rsuite';
import { Link } from 'react-router-dom';

import IconAnalysis from 'Image/svgIcon/analysis.svg';
import IconBulb from 'Image/svgIcon/bulb.svg';
import IconChangeLog from 'Image/svgIcon/changeLog.svg'
import IconDashboard from 'Image/svgIcon/dashboard.svg';
import IconFavoriteList from 'Image/svgIcon/favoriteList.svg';
import IconGraph from 'Image/svgIcon/graph.svg';
import IconLineApp from 'Image/svgIcon/lineApp.svg';
import IconMarket from 'Image/svgIcon/market.svg';
import IconStrategyOverview from 'Image/svgIcon/strategyOverview.svg';
import IconStrategySharing from 'Image/svgIcon/strategySharing.svg';

import './style.scss';

function Component({
    analysisList,
    activeKey,
    checkLogin,
    onAnalysisSelect,
    expandItemValues,
    selectedAnalysisId,
    show,
    showPricingPlan,
    showVideoTutorial,
    showChangeLog,
    showLineGroup
}) {
    if (!show) { return <></>; }

    const strategyScoreAnalysisId = 2;
    let strategyScoreAnalysis =
        analysisList.find(x => x.value === strategyScoreAnalysisId);

    return (
        <div className="sidebar">
            <Sidebar style={{flex: 1}}>
                <Sidenav
                    style={{ width: '250px', background: 'transparent' }}
                    activeKey={activeKey}>
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Item
                                eventKey="/dashboard"
                                icon={<Icon icon={IconDashboard} />}
                                componentClass={Link}
                                to="/dashboard">
                                我的戰情室
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/strategyOverview"
                                icon={<Icon icon={IconStrategyOverview} />}
                                componentClass={"div"}>
                                <div>
                                    <div
                                        className="clickable"
                                        onClick={() => checkLogin('/strategyOverview')}>
                                        我的策略/回測
                                    </div>
                                    <div
                                        className="add-strategy blue"
                                        onClick={() => checkLogin('/strategy')}>
                                        新增
                                    </div>
                                </div>
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/strategySharing"
                                icon={<Icon icon={IconStrategySharing} />}
                                onClick={() => checkLogin('/strategySharing')}>
                                知名策略
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/market"
                                icon={<Icon icon={IconMarket} />}
                                componentClass={Link}
                                to="/market">
                                大盤
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/favorite"
                                icon={<Icon icon={IconFavoriteList} />}
                                onClick={() => checkLogin('/favorite')}>
                                我的最愛
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/graph"
                                icon={<Icon icon={IconGraph} />}
                                componentClass={Link}
                                to="/graph">
                                圖表大師
                            </Nav.Item>
                            <Nav.Item
                                eventKey="/table"
                                icon={<Icon icon={IconGraph} />}
                                componentClass={Link}
                                to="/table">
                                表格大師
                            </Nav.Item>
                            <Nav.Item
                                eventKey={'/stockAnalysis/' + strategyScoreAnalysis?.value}
                                icon={<Icon icon={IconGraph} />}
                                onClick={() => onAnalysisSelect(strategyScoreAnalysis)}>
                                {strategyScoreAnalysis?.label}
                            </Nav.Item>
                            <Dropdown
                                eventKey="/stockAnalysis"
                                title="財報/技術/籌碼"
                                icon={<Icon icon={IconAnalysis} />}>
                                <Tree
                                    data={analysisList.filter(x => x.value !== strategyScoreAnalysisId)}
                                    locale={{ noResultsText: '載入中...' }}
                                    value={selectedAnalysisId}
                                    expandItemValues={expandItemValues}
                                    onSelect={onAnalysisSelect} />
                            </Dropdown>
                            <Nav.Item
                                eventKey="/tutorial"
                                icon={<Icon icon={IconBulb} />}
                                componentClass={Link}
                                to="/tutorial">
                                使用秘訣
                            </Nav.Item>
                            <Nav.Item
                                eventKey={'/pricingPlan'}
                                icon={<Icon icon={IconStrategyOverview} />}
                                onClick={showPricingPlan}>
                                產品方案介紹
                            </Nav.Item>
                            <Nav.Item
                                eventKey={'/videoTutorial'}
                                icon={<Icon icon={IconBulb} />}
                                onClick={showVideoTutorial}>
                                影片教學
                            </Nav.Item>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>
            <div className="sidebar-footer">
                <div className="item" onClick={showChangeLog}>
                    <Icon icon={IconChangeLog} /><span>更新日誌</span>
                </div>
                <div className="item" onClick={showLineGroup}>
                    <Icon icon={IconLineApp} />
                </div>
            </div>
        </div>
    );
}

export default Component;