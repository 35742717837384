import React from 'react';
import IndexUtil from '../indexUtil';
import RuleStatusButton from 'Components/strategyScorePanel/component/ruleStatusButton';
import Bar from 'Components/bar';

import './scoreBar.scss';

const Component = props => {
    let {name, score, total, rowList} = props.category;

    let scoreClassName = score === total ? 'score blue' : 'score';

    // Process rules
    let processRules =
        rowList
            .filter(x => IndexUtil.checkIndexScore(x))
            .map(x => ({
                content: IndexUtil.getIndexRuleScore(x),
                status: x.status
            }));

    return (
        <div className="score-bar">
            <div className="name">
                {name}
                <RuleStatusButton rules={processRules} />
            </div>
            <Bar value={score} total={total} />
            <div className="value">
                <span className={scoreClassName}>{score}</span>
                <span className="total">/{total}</span>
            </div>
        </div>
    );
};

export default Component;