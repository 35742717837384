import React, { Component } from 'react';
import { Table, Pagination } from 'rsuite';
import TrackStockToggle from 'Components/trackStockToggle/index';
import _ from 'lodash';
import AppPagination from "Components/pagination";
const { Column, HeaderCell, Cell } = Table;
/**
 * @memberof PaginationTable
 * @description 介面描述檔
 */

function Ui({
    id,
    rawData,
    columns,
    height,
    rowHeight,
    headerHeight,
    data,
    sortColumn,
})
{
    return <div className = "dashboard__pagination_table">
    <Table
        autoHeight={height ? false : true}
        height={height}
        rowHeight={rowHeight ? rowHeight : undefined}
        headerHeight={headerHeight ? headerHeight : undefined}
        data={data}
        // sortColumn={sortColumn}
        // sortType={sortType}
        // onSortColumn={sortColumnData}
    >
        {
            columns.map(column =>
                <Column
                    key={column.id}
                    width={column.width}
                    sortable={column.sortable}>
                    <HeaderCell>
                        {
                            sortColumn === column.id ?
                                <div className="blue">{column.name}</div> :
                                column.name
                        }
                    </HeaderCell>
                    <Cell dataKey={column.id}>
                        {
                            rowData => {
                                let cellData = rowData[column.id];

                                if (Array.isArray(cellData.value)) {
                                    return cellData.value.join(' ');
                                }

                                let content =
                                    cellData.text ?
                                        <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
                                        cellData.value
                                let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

                                if (cellData.link) {
                                    content =
                                        <a href={cellData.link}
                                            target={target}
                                            rel="noopener noreferrer">
                                            {content}
                                        </a>;
                                }

                                if (cellData.tracked !== undefined) {
                                    content =
                                        <div style={{ display: 'flex' }}>
                                            <TrackStockToggle 
                                                id={cellData.value}
                                            />
                                            {/* <TrackStockToggle
                                                style={{ marginRight: 10 }}
                                                id={cellData.value}
                                                trackingStock={this.props.trackingStock}
                                                onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} 
                                            /> */}
                                            {content}
                                        </div>;
                                }

                                return content;
                            }
                        }
                    </Cell>
                </Column>
            )
        }
    </Table>

    <div className = "dashboard__pagination">
        <AppPagination
            id={id}
            list={rawData}
            maxButtons={10}
            displayLength={10}
        />
    </div>
</div>
}


// rawData,
// columns,
// height,
// rowHeight,
// headerHeight,
// data,
// sortColumn,
// sortType,
// displayLength,
// page,
// sortColumnData,
// onChangePage

function areEqual(prevProps, nextProps)
{
    if(
        !_.isEqual(prevProps.sortType, nextProps.sortType) ||
        !_.isEqual(prevProps.sortColumn, nextProps.sortColumn) ||
        !_.isEqual(prevProps.rawData, nextProps.rawData) ||
        !_.isEqual(prevProps.data, nextProps.data) ||
        !_.isEqual(prevProps.page, nextProps.page)
    ) {
        return false
    } else {
        return true
    } 
}

export default React.memo(Ui, areEqual);
export class PaginationTable1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            sortColumn:
                this.props.sortColumn ? this.props.sortColumn : undefined,
            sortType: this.props.sortType ? this.props.sortType : undefined
        };

        if (!props) { return; }

        this.state.columnList = props.columns;
        this.state.displayLength =
            this.props.displayLength ? this.props.displayLength : 10;
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.columns, this.props.columns) ||
            !_.isEqual(prevProps.data, this.props.data) ||
            !_.isEqual(prevProps.displayLength, this.props.displayLength)
        ) {
            this.setState({ page: 1 });
        }

        if (!_.isEqual(prevProps.trackingStock, this.props.trackingStock)) {
            this.forceUpdate();
        }
    }

    sortColumnData(sortColumn, sortType) {
        this.setState({ sortColumn: sortColumn, sortType: sortType });
    }

    getData() {
        const { sortColumn, sortType } = this.state;

        let data = _.cloneDeep(this.props.data);

        if (sortColumn && sortType) {
            data = data.sort((a, b) => {
                let x =
                    a[sortColumn].value !== null ?
                        a[sortColumn].value : a[sortColumn];
                let y =
                    b[sortColumn].value !== null ?
                        b[sortColumn].value : b[sortColumn];

                if (typeof x === 'string' || typeof y === 'string') {
                    return (
                        sortType === 'asc' ?
                            ('' + x).localeCompare(y) :
                            ('' + y).localeCompare(x)
                    );
                }

                return sortType === 'asc' ? x - y : y - x;
            });
        }

        let start = this.state.displayLength * (this.state.page - 1);
        let end = start + this.state.displayLength;

        return data.slice(start, end);
    }

    onChangePage(page) {
        this.setState({ page: page });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        if (!this.props.onTrackingStockChange) { return; }
        this.props.onTrackingStockChange(groupId, stockId, tracked);
    }









    render() {
        return (
            <div>
                <Table
                    autoHeight={this.props.height ? false : true}
                    height={this.props.height}
                    rowHeight={this.props.rowHeight ? this.props.rowHeight : undefined}
                    headerHeight={this.props.headerHeight ? this.props.headerHeight : undefined}
                    data={this.getData()}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    onSortColumn={(sortColumn, sortType) => this.sortColumnData(sortColumn, sortType)}>
                    {
                        this.state.columnList.map(column =>
                            <Column
                                key={column.id}
                                width={column.width}
                                sortable={column.sortable}>
                                <HeaderCell>
                                    {
                                        this.state.sortColumn === column.id ?
                                            <div className="blue">{column.name}</div> :
                                            column.name
                                    }
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        rowData => {
                                            let cellData = rowData[column.id];

                                            if (Array.isArray(cellData[column.id])) {
                                                return cellData[column.id].join('、');
                                            }

                                            let content =
                                                cellData.text ?
                                                    <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
                                                    cellData.value
                                            let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

                                            if (cellData.link) {
                                                content =
                                                    <a href={cellData.link}
                                                        target={target}
                                                        rel="noopener noreferrer">
                                                        {content}
                                                    </a>;
                                            }

                                            if (cellData.tracked !== undefined) {
                                                content =
                                                    <div style={{ display: 'flex' }}>
                                                        <TrackStockToggle
                                                            style={{ marginRight: 10 }}
                                                            id={cellData.value}
                                                            trackingStock={this.props.trackingStock}
                                                            onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                                                        {content}
                                                    </div>;
                                            }

                                            return content;
                                        }
                                    }
                                </Cell>
                            </Column>
                        )
                    }
                </Table>
                <div style={{ textAlign: 'center', marginTop: 16, height: 30 }}>
                    <Pagination
                        first prev next last ellipsis
                        pages={Math.ceil(this.props.data.length / this.state.displayLength)}
                        maxButtons={10}
                        activePage={this.state.page}
                        onSelect={page => this.onChangePage(page)} />
                </div>
            </div>
        );
    }
}