import React, { useEffect, useCallback } from "react"
import DashboardPage from "../dashboardPage"
import { useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import * as DashboardSagaActions from "Redux/saga/actionTypes/dashboard"
import * as DashboardActions from "Redux/actionTypes/dashboard/dashboardPage"
import { saveConfigs } from 'Redux/actionCreators/configs';

/**
 * @function Container
 * @memberof module:DashboardPage
 * @description mounted後取得dashboard list與可用singles
 * @returns {ReactElement}
 */

export default function Container()
{
    // console.log('render');
    const history = useHistory();
    const dispatch = useDispatch();
    const dashboardPage = useSelector(store => store.dashboardPage)
    // const singles = useSelector(store => store.singles)
    
    const selectDashboard = useCallback((id) => {
        dispatch({
            type: DashboardSagaActions.SET_SELECTED_DASHBOARD,
            id: id
        })
        // alert('切換戰情室失敗');
        // this.editSubmenuTriggerRef.hide();
        /* eslint-disable-next-line */
    },[])

    const showLayoutModal = useCallback(() => {
        dispatch({
            type: DashboardActions.SHOW_LAYOUTMODAL,
            layout: []
        })
        /* eslint-disable-next-line */
    },[])
    
    const hideLayoutModal = useCallback(() => {
        dispatch({
            type: DashboardActions.HIDE_LAYOUTMODEL
        })
        /* eslint-disable-next-line */
    },[])

    //  Create a new dashboard
    const showCreateDashboardModal = (e, event) => {
        const id = event.target.dataset.id ? event.target.dataset.id: null
        dispatch({
            type: DashboardActions.SHOW_CREATEDASHBOARDMODAL,
            id: id
        })
    }

    const showDashboardModal = useCallback(() => {
        dispatch({
            type: DashboardActions.SHOW_DASHBOARDMODAL
        })
         /* eslint-disable-next-line */
    },[])

    const closeDashboardModal = useCallback(() => {
        dispatch({
            type: DashboardActions.CLOSE_DASHBOARDMODAL
        })
        /* eslint-disable-next-line */
    },[])

    // Tutorial hint modal
    const configs = useSelector(store => store.configs);
    const showTutorialHintModal =
        configs.guided != null && configs.guided !== 1;

    const closeTutorialHintModal = () => {
        saveConfigs({ name: 'guided', data: 1 });
    }

    const showTutorialPage = () => {
        closeTutorialHintModal();
        history.push('/tutorial');
    }

    useEffect(() => {
        dispatch({ type: DashboardSagaActions.FETCH_DASHBOARD_LIST })
        dispatch({ type: DashboardSagaActions.FETCH_AVAILABLE_SINGLES })
    },[dispatch])

    return <DashboardPage
        dashboardPage={dashboardPage}
        // singles={singles}
        selectDashboard={selectDashboard}
        showLayoutModal={showLayoutModal}
        hideLayoutModal={hideLayoutModal}
        showDashboardModal={showDashboardModal}
        showCreateDashboardModal={showCreateDashboardModal}
        closeDashboardModal={closeDashboardModal}
        showTutorialHintModal={showTutorialHintModal}
        closeTutorialHintModal={closeTutorialHintModal}
        showTutorialPage={showTutorialPage}
    />
};