import React from 'react';
import {
    Popover,
    Whisper,
    Icon,
    Dropdown
} from 'rsuite';

import AddTabModal from 'Components/dashboard/addTabModal';
import IconSettings from 'Image/svgIcon/dashboard/settings.svg';
import RenameTabModal from '../renameTabModal';

const Component = ({
    menuRef,
    currentName,
    addTab,
    removeTab,
    renameTab,
    showAddTabModal,
    onAddTabModalHide,
    showRenameTabModal,
    onRenameTabModalHide,
    onRenameTabConfirm
}) => {
    const menu =
        <Popover className="grid-setting-popover" full>
            <Dropdown.Menu>
                <Dropdown.Item onSelect={addTab}>
                    新增 頁籤
                </Dropdown.Item>
                <Dropdown.Item onSelect={removeTab}>
                    刪除 頁籤
                </Dropdown.Item>
                <Dropdown.Item onSelect={renameTab}>
                    重新命名 頁籤
                </Dropdown.Item>
            </Dropdown.Menu>
        </Popover>;

    return (
        <>
            <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={menu}
                ref={menuRef}>
                <Icon icon={IconSettings} size='lg' />
            </Whisper>
            <AddTabModal
                show={showAddTabModal}
                onModalHide={onAddTabModalHide} />
            <RenameTabModal
                currentName={currentName}
                show={showRenameTabModal}
                onModalHide={onRenameTabModalHide}
                onConfirm={onRenameTabConfirm} />
        </>
    );
};

export default Component;