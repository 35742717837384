import React, { useCallback, useEffect, useState } from 'react';
import { usePrevious } from "Util/hooks";
import '../style.scss';
import FavoriteList from "../favoriteList"
import _ from "lodash";
import { TRACK_STOCK } from "Redux/saga/actionTypes/stock"
import buildButtonList from "../buttonList"
import { useDispatch, useSelector } from 'react-redux';
import { updatePanelData, getPanelData } from "Redux/actionCreators/dashboard"
import { changePage} from "Redux/actionCreators/pagination";
import { readyCheck } from "Util/readyCheck";

export default function Container({ panel, rowHeight, parameters })
{
    // console.log('rneder');
    const panelHeight = rowHeight * panel.height + 20 * (panel.height - 1) - 30 - 10;
    const tableHeight = panelHeight - 45 - 9 * 2 - 30 - 16
    const displayLength = Math.floor((tableHeight - 40) / 48)
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id))
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === panel.id))
    const trackingStock = useSelector(store => store.stock.trackingStock)
    const readyCheckItems = [
        panelData,
        trackingStock,
    ]
    const [currentTab, setCurrentTab] = useState();
    const dispatch = useDispatch()

    buildButtonList(panel.id, currentTab, setCurrentTab);
    
    const prepareTableData = (rawData) => {
        
        const mainKey = rawData.columns[0].id;
        let tableData = [];
        // 用tab的item裡面的股票代號去data找資料，然後蒐集給tableData。
        rawData.tab.forEach(tab =>
            tableData[tab.id] =
            tab.items.map(item =>
                rawData.data.find(row => item === row[mainKey].value))
        );
        return tableData;
    }

    const getCurrentTabData = (rawData, tabName) => {
        
        const mainKey = rawData.columns[0].id;
        let tableData = [];
        rawData.tab.forEach(tab =>
            tableData[tab.id] =
            tab.items.map(item =>
                rawData.data.find(row => item === row[mainKey].value))
        );
        return tableData[tabName] ? tableData[tabName]: [];
    }
    
    useEffect(() => {
        getPanelData(panel, parameters);
    },[panel, parameters])

    useEffect(() => {
        // Set default tab to the first tab after getting data
        if (!panelData || panelData.rawData.main.tab.length === 0) { return; }

        // If currentTab is already set and the tab exists, don't set current
        // tab again
        if (
            currentTab && 
            panelData.rawData.main.tab.find(x => x.id === currentTab)
        ) {
            return;
        }

        setCurrentTab(panelData.rawData.main.tab[0].id);
    }, [panelData, currentTab]);
    
    const prevTrackingStock = usePrevious(trackingStock)
    useEffect(()=>{
        if (
            !_.isEqual(prevTrackingStock, trackingStock) && panelData
        ) {
            getPanelData(panel, parameters);   
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[prevTrackingStock, trackingStock])

    const getPages = () => {
        if(!panelData){ return }
        if(!panelData.tableData){ return }
        let pages = panelData.tableData[currentTab] ?
        Math.ceil(
            panelData.tableData[currentTab].length /
            displayLength) :
            0;
            return pages;
    }
        
    const getData = (name) => {
        if (!panelData) { return; }
        if(!name){ return }
        const { sortColumn, sortType } = panelData;
        // 比較即時追蹤狀態，以reducer stock.js的trackingStock為準。
        const currentTrackingStockInGroup = trackingStock.find(o => o.id === name);

        const tableData = prepareTableData(panelData.rawData.main);

        const data = tableData[name].filter(stock => currentTrackingStockInGroup.items.indexOf(stock.stock_code.value) !== -1)
        if (sortColumn && sortType) {
            data.sort((a, b) => {
                let x = a[sortColumn].value;
                let y = b[sortColumn].value;

                if (typeof x === 'string' || typeof y === 'string') {
                    return (
                        sortType === 'asc' ?
                            ('' + x).localeCompare(y) :
                            ('' + y).localeCompare(x)
                    );
                }
                return sortType === 'asc' ? x - y : y - x;
            });
        }

        const page = pagination ? pagination.page : 1;
        const start = displayLength * (page - 1);
        const end = start + displayLength;
        return data.slice(start, end);
    }

    const sortColumnData = useCallback((sortColumn, sortType) => {
        updatePanelData(panel.id, {
            sortColumn: sortColumn, sortType: sortType
        })
        /* eslint-disable-next-line */
    },[])

    const onChangeTabCallBack = id => {
        setCurrentTab(id);
        changePage(panel.id, 1)
    }

    const removeStock = useCallback((e) => {
        const groupId = e.currentTarget.dataset.currenttab
        const stockId = e.currentTarget.dataset.stockid
        dispatch({
            type: TRACK_STOCK,
            groupId: groupId,
            stockId: stockId,
            tracking: false
        })
        /* eslint-disable-next-line */
    },[])

    return ( readyCheck(readyCheckItems) ?
         <FavoriteList
            id={panel.id}
            currentTab={currentTab}
            tabList={trackingStock}
            columnList={panelData.rawData.main.columns ? panelData.rawData.main.columns: []}
            pages={getPages()}
            page={panelData.page}
            tableHeight={tableHeight}
            currentPageData={getData(currentTab)}
            currentTabData={getCurrentTabData(panelData.rawData.main, currentTab)}
            sortColumn={panelData.sortColumn}
            sortType={panelData.sortType}
            sortColumnData={sortColumnData}
            removeStock={removeStock}
            onChangeTabCallBack={onChangeTabCallBack}
    />: null
    );
}