import React from 'react';

import ArrowBlock from '../arrowBlock';
import ListItem from '../listItem';
import {
    BuyPriority,
    BuyRule,
    ChangeFreqency
} from '../../../../../../../constant/backtest';

const getBuyRule = (rule, rank) => {
    let ruleStr = BuyRule.find(x => x.value === rule)?.label;

    return (
        <>
            <span className="blue">{ruleStr}</span>
            買進符合篩選條件、且評分<span className="blue">前{rank}名</span>個股
        </>
    );
}

const getBuyPriority = priority => {
    let priorityStr = BuyPriority.find(x => x.value === priority)?.label;

    return '若同分買' + priorityStr;
}

const getScoreLimit = score => {
    return score !== 0 ? '若低於' + score + '分不買' : '分數不做限制';
}

const getChangeFrequency = frequency => {
    let str = ChangeFreqency.find(x => x.value === frequency)?.label;

    if (
        frequency === '10d' || frequency === '6m' || frequency === '12m' ||
        frequency === '36m' || frequency === '60m' || frequency === '120m'
    ) {
        str = '每' + str;
    }

    return <><span className="blue">{str}</span>依新評分換股</>
}

const Component = ({
    buy_rule: buyRule,
    buy_rank: buyRank,
    buy_priority: buyPriority,
    score_limit: scoreLimit,
    change_frequency: changeFrequency
}) => {
    return (
        <ArrowBlock value="STEP3" title="依評分結果，按下列原則買賣">
            <ListItem>
                {getBuyRule(buyRule, buyRank)}
            </ListItem>
            <ListItem>
                {getBuyPriority(buyPriority)}
            </ListItem>
            <ListItem>
                {getScoreLimit(scoreLimit)}
            </ListItem>
            <ListItem>
                {getChangeFrequency(changeFrequency)}
            </ListItem>
        </ArrowBlock>
    );
}

export default Component;