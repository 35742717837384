import * as StrategySagaActions from '../saga/actionTypes/strategy';
import { SET_SIDEBAR_STRATEGY_SCORE_ID } from '../actionTypes/strategy/strategy';
import store from '../store';

/**
 * 取一個策略
 * @param {string} id - 策略id
*/
export function fetchStrategyScore(id){
    const exist = store.getState().strategy.strategyScoreList.find(o => o.id === id);
    if(!exist)
    {
        const obj = {
            type: StrategySagaActions.FETCH_STRATEGYSCORE,
            id: id
        }
        store.dispatch(obj);
    } else {
        store.dispatch({
            type: SET_SIDEBAR_STRATEGY_SCORE_ID,
            id: id
        });
    }
}
/**
 * 複製策略
 * @param {string} id - 策略id
*/
export function copyStrategy(e){
    const obj = {
        type: StrategySagaActions.COPY_STRATEGY,
        id: e.currentTarget.dataset.id
    }
    store.dispatch(obj);
}

export function copyEditStrategy(id) {
    store.dispatch({
        type: StrategySagaActions.COPY_EDIT_STRATEGY,
        id: id
    });
}

/**
 * 取得分享的策略
*/
export function getSharedStrategyList(){
    const obj = {
        type: StrategySagaActions.FETCH_SHARED_LIST,
    }
    store.dispatch(obj);
}
/**
 * 刪除策略
 * @param {string} id - 策略id
*/

export function deleteStrategy(e){
    const obj = {
        type: StrategySagaActions.DELETE_STRATE,
        id: e.currentTarget.dataset.id
    }
    store.dispatch(obj);
}

/**
 * 重新命名策略
 * @param {string} id - 策略id
*/

export function renameStrategy(e){
    const obj = {
        type: StrategySagaActions.RENAME_STRATEGY,
        id: e.currentTarget.dataset.id,
        name: e.currentTarget.dataset.name
    }
    store.dispatch(obj);
}

