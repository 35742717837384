import React from 'react';

import ArrowBlock from '../arrowBlock';
import ListItem from '../listItem';
import IndexUtil from 'Src/indexUtil';

import './style.scss';

const getIndustryFilter = industries => {
    let result = '選擇產業：';
    if (!industries) { return result + '(分享者將篩選法設為不公開)'; }

    const {include, exclude} = industries;
    let includeList = [
        ...include.industries,
        ...include.concepts,
        ...include.conglomerates
    ];

    let excludeList = [
        ...exclude.industries,
        ...exclude.concepts,
        ...exclude.conglomerates
    ];

    result += includeList.join('、');

    if (excludeList.length !== 0) {
        result += '　但 排除：' + excludeList.join('、');
    }

    return result;
}

const getIndexFilter = selections => {
    let content;
    if (selections) {
        const { include, exclude } = selections;
        let includeFilter =
            include.map(x => IndexUtil.getIndexRuleJSX(x)).filter(x => x);
        let excludeFilter =
            exclude.map(x => IndexUtil.getIndexRuleJSX(x)).filter(x => x);

        content =
            <>
                <div className="filter-list">
                    {
                        includeFilter.map((x, i) =>
                            <div
                                key={'include-' + i}
                                className="filter">
                                {x}
                            </div>
                        )
                    }
                    {
                        includeFilter.length === 0 && excludeFilter.length === 0 &&
                        <div>無</div>
                    }
                </div>
                {
                    excludeFilter.length !== 0 &&
                    <>
                        <div className="filter-title">　但 排除：</div>
                        <div className="filter-list">
                            {
                                excludeFilter.map((x, i) =>
                                    <div key={'exclude-' + i}>{x}</div>
                                )
                            }
                        </div>
                    </>
                }
            </>;
    } else {
        content = '(分享者將篩選法設為不公開)';
    }

    return (
        <div className="index-filter">
            <div className="filter-title">指標篩選：</div>
            {content}
        </div>
    );
}

const Component = ({
    industries,
    selections,
    totalFiltered
}) => {
    return (
        <ArrowBlock
            className="step-1"
            value="STEP1"
            title="設定好球帶，篩選出符合條件的股票">
            <ListItem>
                {getIndustryFilter(industries)}
            </ListItem>
            <ListItem>
                {getIndexFilter(selections)}
            </ListItem>
            <ListItem>
                篩選出<span className="blue">{totalFiltered}</span>檔
            </ListItem>
        </ArrowBlock>
    );
}

export default Component;