import Fetch from "../fetch"
/**
 * @constant
 * @memberof module:Fetch
 * @description 股票api
 */
const stockApi = {
    // 股票池
    pool: {
        method: "GET",
        uri: () => '/data/stockpool'
    },
    stockPrice: {
        method: "GET",
        uri: id => '/data/model/stock-price?stock_code=' + id
        // uri: () => '/data/model/stock-price'
    },
    // 左側選單用基本面相關
    analysis: {
        metho: "GET",
        uri: () => '/user/finanalysis'
    },
    // 取得追蹤清單
    tracking: {
        method: "GET",
        uri: () => '/user/stocks'
    },
    // 控制指定股票追蹤與否
    track: {
        method: "POST",
        uri: () => '/user/stocks'
    },
    // 增加最愛清單
    addFavoriteGroup: {
        method: "POST",
        uri: () => '/user/stocks/groups'
    },
    // 刪除最愛清單
    deleteFavoriteGroup: {
        method: "DELETE",
        uri: id => '/user/stocks/groups/' + id
    },
    // 清空最愛清單
    clearFavoriteGroup: {
        method: 'DELETE',
        uri: id => '/user/stocks/groups/' + id + '/clear'
    },
    renameFavoriteGroup: {
        method: 'PUT',
        uri: id => '/user/stocks/groups/' + id
    }
}

/**
 * @memberof module:Fetch
 * @function StockApi
 * @description 發送股票相關request
 */

export default function StockApi(type, params = null)
{
    return Fetch.request(
        stockApi[type].method,
        stockApi[type].uri,
        params
    )
};