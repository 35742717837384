import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Icon, Popover, Whisper } from 'rsuite';
import _ from 'lodash';

import IconUntracked from 'Image/svgIcon/untracked.svg';
import IconTracked from 'Image/svgIcon/tracked.svg';

import './trackStockToggle.scss';

/**
 * 
 * @param {boolean} tracked - 此股票是否被追蹤
 * @param {object} trackingStock - 此股票是否被追蹤
 * @param {function} setTrackStock - 設定追中與否
 * @param {string} id - 股票代號
 * @memberof module:TrackStockToggle
 * @summary 介面 
 * @returns {ReactElement}
 */

function TrackStockToggle({
    tracked, trackingStock, setTrackStock, id, style, inPopover
}) {
    const [, setDummy] = useState(null);
    const containerRef = useRef();

    // Force rerender when container ref is available.
    // Whisper needs container ref to render popover in the container when
    // inPopover is true.
    // inPopover is used when this component is in another popover.
    // The outer popover will close if the user interacted with this component.
    // Because the outer popover closes when the mouse is clicked outside the
    // outer popover.
    useEffect(() => setDummy(), [containerRef]);

    const popover =
        <Popover className="track-stock-toggle-list">
            <div className="list-header">新增至 我的最愛清單</div>
            <div className="list">
                {
                    trackingStock.map((group, i) =>
                        <div key={i} className="row">
                            <Checkbox
                                value={group.id}
                                name={id}
                                defaultChecked={group.items.includes(id)}
                                onChange={setTrackStock}
                            />
                            <div>{group.name}</div>
                        </div>
                    )
                }
            </div>
        </Popover>;

    return (
        <div className="track__stock__toggle" ref={containerRef}>
            <Whisper
                container={inPopover && containerRef ? containerRef.current : null}
                speaker={popover}
                trigger="click"
                placement="bottomStart">
                <Icon
                    style={{ cursor: 'pointer', ...style }}
                    icon={tracked ? IconTracked : IconUntracked}
                    size="lg" />
            </Whisper>
        </div>
    );
};

const areEqual = (prevProps, nextProps) => {
    return (
        prevProps.tracked === nextProps.tracked &&
        _.isEqual(prevProps.trackingStock, nextProps.trackingStock) &&
        prevProps.id === nextProps.id
    );
};

export default React.memo(TrackStockToggle, areEqual);