import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import RGL from "react-grid-layout";
import { withResizeDetector } from 'react-resize-detector';
import GridPanel from 'Components/gridPanel'; 
import './dashboard.css';
// import { CLEAR_DASHBOARD } from "Redux/actionTypes/dashboard/dashboard";
import { useLocation } from "react-router";

const ReactGridLayout = withResizeDetector(RGL);

/**
 * @memberof module:Dashboard
 * @function Dashboard
 * @description 戰情室儀錶板元件UI
 */

export default function Dashboard({cols, rowHeight, layout, singles, activePanelList, showDelete })
{
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        // return () => {
        //     dispatch({type:　CLEAR_DASHBOARD})
        // }
    },[location.pathname, dispatch])
    return <ReactGridLayout
                className="layout"
                layout={layout}
                cols={cols}
                rowHeight={rowHeight}
                isResizable={false}
                isDraggable={false}
                compactType="vertical"
                style={{ marginLeft: -20, marginRight: -20, marginTop: -20 }}
                margin={[20, 20]}
                draggableHandle=".grid-title"
                // onLayoutChange={layout => this.onLayoutChange(layout)}
            >
                {
                    activePanelList.map(
                        x => GridPanel.create(x.id, x.name, x.component, x.buttonList, showDelete)
                    )
                }
            </ReactGridLayout>
}