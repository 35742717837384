import React from "react";
import "./style.scss"
import BacktestSettingPanel from 'Components/backtestSettingPanel';
import { Button } from "rsuite";
import StockScoreTable from 'Components/stockScoreTable/index';
import StrategyDesignStep from 'Components/strategyDesignStep';
import { isEqual } from "lodash"
function Component({
    id, backtestSetting, onBacktestChange, onPreviousStepClick, onPreviewClick,
    preview, trackingStock, onBacktestClick
}) {
    
    return  <div className="backtest">
    <div className = "backtest__title">
        <div className="page-title">投資策略設計工具</div>
    </div>
    <hr />
    <div className="step-container">
        <StrategyDesignStep strategyId={id} scored={true} step={3} />
    </div>
    <div className="title">設定回測條件</div>
    <div className="block">
        <BacktestSettingPanel
            data={backtestSetting}
            onChange={onBacktestChange}
        />
    </div>
    <div className="button-container">
        <Button onClick={onPreviousStepClick}>上一步</Button>
        <Button
            className="preview-button"
            onClick={onPreviewClick}
        >
            最新選股預覽
        </Button>
        <Button
            className="backtest-button"
            onClick={onBacktestClick}
        >
            開始回測
        </Button>
    </div>
    {
        // stockColumnList is null before receiving it from server
        // preview.stockColumnList &&
        preview ?
        <>
            <div className="title">最新選股預覽</div>
            <div className="block">
                <StockScoreTable
                    columnList={preview.stockColumnList}
                    data={preview.stockData}
                    trackingStock={trackingStock}
                    // onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} 
                />
            </div>
        </>: null
    }
</div>
}



function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.preview ,nextProps.preview) ||
        !isEqual(prevProps.backtestSetting ,nextProps.backtestSetting) ||
        !isEqual(prevProps.trackingStock ,nextProps.trackingStock) 
    )
    {
        return false
    }else {
        return true
    }
}
// export default Component;
export default React.memo(Component, areEqual);