import resErrHandlings from "./response/errHandlings";
import reqConditions from "./request/conditions";
import { Cookies } from 'react-cookie';
import reqErrHandlings from "./request/errHandlings";
// eslint-disable-next-line no-undef
const host = process.env.REACT_APP_API_HOST;
// eslint-disable-next-line no-undef
const timeout = process.env.REACT_APP_TIMEOUT;

export const cookies = new Cookies();

/** 
 * 處理網路請求 Class processing request
 * @module Fetch
 * @summary 處理網路請求
 * */


/**
 * @constructs Fetch
 * @memberof module:Fetch
 * @description 透過getInit取得發送所需內容如: header, form, 重組url等。 發送前reqConditionCheck檢查發送條件，收到response或其他原因沒收到以resErrHandlings處理。
 */

class Fetch {
	
	/**
     * get a init object.
     * @param {string} method - http方法 http method.
     * @param {object} formData - 表單資料 form data.
     */
	
	static getInit(method, formData = null, signal) {
		const token = cookies.get('accessToken');
		let init = {
			method: method,
			headers: {
				"Authorization": "Bearer " + token,
				"Accept": "application/json"
			},
			signal
		};

		if(formData) {
			init = {
				...init,
				body: formData
			};
		}
		
		return init;
	}

	/**
     * get a api url.
     * @param {string} method - http方法 http method.
     * @param {string} uri - api路徑 api uri.
     */
	static getUrl(method, uri, params = null) {
		// let id = null;
        // if(params)
        // {
        //     id = params.id;
        // }
        // return host + uri(id);
        let id = null;
		let url = host
        if(params)
        {
            id = params.id;
			url += uri(id)
			const paramsKey = Object.keys(params).filter(o => o !== 'id');
			paramsKey.forEach((key, i) => {
				url += (i === 0 ? '?' : '&') + key + '=' + params[key];
            });
        } else {
			url += uri()
		}
        return url
	}
	
	/**
	 * 設定逾時判斷
     */
	static timeout() {
		return new Promise((res) => {
			setTimeout(() => {
				res({ timeout: "timeout"});
			}, timeout);
		});
	}

	/**
	 * 發送前檢查條件
	 * @returns {boolean}
	 */
	static reqConditionCheck() {
		const condition = reqConditions.find(o => o.check());
		if(condition !== undefined)
		{
			reqErrHandlings[condition.type]();
			return false;
		} else {
			return true;
		}
	}

	/**
	 * 發送請求
	 * @param {string} method - http方法 http method.
	 * @param {string} uri - api路徑 api uri.
	 * @param {object} formData - 表單資料 form data.
     */
	static request(method, uri, requestData = null) {
		if(this.reqConditionCheck())
		{
			let timeOutController = new AbortController();
			let signal = timeOutController.signal;
            let params = null
            let formData = null
            if(requestData) {
                params = requestData.params ? requestData.params: null
                formData = requestData.formData ? requestData.formData: null
            }

			setTimeout(() => {
				timeOutController.abort();
			}, timeout);

			return fetch(this.getUrl(method, uri, params), this.getInit(method, formData, signal))
				.then(res => res.json()).then(res => {
                    if(res.status === "success") {
                        return res.data;
                    } else {
                        resErrHandlings[res.error.code](res.error.data);
                    }
				}).catch(e => {
					resErrHandlings[e.name]();
				});
		} else {
			return null;
		}
	}
}

export default Fetch;