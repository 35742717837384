import React, { useEffect, useMemo, useRef } from 'react';
import Component from './component';
import { getPanelData } from "Redux/actionCreators/dashboard"
import { useRouteMatch } from 'react-router';
import { deepEqual } from  "Util/deepEqual"
import { useSelector } from "react-redux";
import "Page/highcharts.css"
import "./style.scss";
import { getTableData, getChartData } from "./methods";

function Container({ panel, rowHeight })
{   
    // console.log(panel);
    const match = useRouteMatch({
        path: [
            '/stockAnalysis/:id/:analysisId/:strategyId',
            '/stockAnalysis/:id/:analysisId',
            '/stockAnalysis/:id'
        ],
        strict: true,
        sensitive: true
    })
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id), deepEqual)
    const timeRangeTabs = useSelector(store => store.tabList.list.find(o => o.id === "time_range_" + panel.id), deepEqual)
    const periodTabs = useSelector(store => store.tabList.list.find(o => o.id === "period_" + panel.id), deepEqual)
    const tableDivRef = useRef();
    const tableRef = useRef();
    
    const tableData = useMemo(() => {
        // 第一次render時要用資料源 取得tab name, 因為沒有資料尚未建立tab。
        if(periodTabs)
        {
            return getTableData(panelData, periodTabs)
        } else {
            return getTableData(panelData, { currentTab: panelData?.rawData.main.switcher ? panelData.rawData.main.switcher[0].id: null })
        }
    },[periodTabs, panelData])
    
    const chartData = useMemo(() => {
        return getChartData(panelData, periodTabs, timeRangeTabs)
    },[panelData, periodTabs, timeRangeTabs])
    
    useEffect(() => {
        if (!match) { return; }

        getPanelData(panel, {
            stock_code: match.params.id,
            strategy: match.params.strategyId,
            single: panel.id
        })
        /* eslint-disable-next-line */
    },[])

    useEffect(() => {
        if(tableDivRef.current)
        {
            tableDivRef.current.scroll(tableRef.current.clientWidth, 0)
        }
        /* eslint-disable-next-line */
    }, [tableDivRef.current, periodTabs?.currentTab])

    return panelData ? <Component
        timeRangeTabs={timeRangeTabs?.currentTab}
        panelData={panelData}
        periodTabs={periodTabs?.currentTab}
        id={panel.id}
        periodTabsList={panelData.rawData.main.switcher}
        rangeTabsList={panelData.rawData.main.ranges}
        tableData={tableData}
        chartData={chartData}
        tableDivRef={tableDivRef}
        tableRef={tableRef}
    />: null
}
export default Container;