import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Input,
    InputGroup,
    Modal
} from 'rsuite';

import './style.scss';

const Component = props => {
    const defaultCopyButtonText = '複製連結';
    const [copyButtonText, setCopyButtonText] = useState(defaultCopyButtonText);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);

        setCopyButtonText('已複製');
        setTimeout(() => setCopyButtonText(defaultCopyButtonText), 1000);
    }

    const onShareBacktestSettingsChange = checked => {
        if (!props.onShareBacktestSettingsChange) { return; }

        props.onShareBacktestSettingsChange(checked);
    }

    let link = props.link ?? window.location.href;

    return (
        <>
            <Button onClick={() => props.onClick()}>{props.buttonText}</Button>
            <Modal
                className="copy-link-modal"
                show={props.show}
                onHide={props.onHide}>
                <Modal.Header>
                    <Modal.Title>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <Checkbox
                        className="share-backtest-settings"
                        checked={props.shareBacktestSettings}
                        onChange={(_, checked) => onShareBacktestSettingsChange(checked)}>
                        公開股票池、及評分條件
                    </Checkbox>
                    <InputGroup>
                        <Input value={link} />
                        <InputGroup.Button
                            className="blue"
                            onClick={() => copyToClipboard(link)}>
                            {copyButtonText}
                        </InputGroup.Button>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Component;