import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import MoreButton from '../moreButton'
import history from "Src/history";
import { isEqual } from "lodash";
import * as BacktestSagaActions from "Redux/saga/actionTypes/backtest";
import store from "Redux/store";
import { deleteStrategy as _deleteStrategy } from 'Redux/actionCreators/strategy';

import Api from 'Api/strategy';
import { showNotice } from 'Redux/actionCreators/notice';
import { UPDATE_STRATEGY_LIST } from 'Redux/actionTypes/strategy/strategy';

/**
 * 顯示回測報告
 * @param {string} id - 策略id
 */

const showBacktestResult = e => {
    const location = { pathname: '/backtestResult/' + e.currentTarget.dataset.id };
    history.push(location);
}

/**
 * 跳轉編輯策略
 * @param {string} id - 策略id
 */
 const editStrategy = e => {
    const location = { pathname: '/strategy/' + e.currentTarget.dataset.id };
    history.push(location);
}

const deleteStrategy = e => {
    if (!window.confirm('是否要刪除策略?')) { return; }

    _deleteStrategy(e);
}

/**
 * 取消回測
 * @param {string} id - 策略id
 */

function cancelBacktest(e){
    const obj = {
        type: BacktestSagaActions.CANCEL_BACKTEST,
        id: e.currentTarget.dataset.id 
    }
    store.dispatch(obj)
}




function areEqual(prevProps, nextProps) {
    if(isEqual(prevProps.rowData, nextProps.rowData))
    {
        return true
    } else {
        return false
    }
}

function Container({ rowData, 
    // toggleExpand 
}){
    // console.log('morebutton inside');
    // console.log(rowData);
    const dispatch = useDispatch();
    const strategies = useSelector(store => store.strategy.list);

    const copyStrategy = async e => {
        const result =
            await Api('copy', { params: { id: e.currentTarget.dataset.id } });
        let list = [...strategies];

        if (!result) { return; }

        list.push(result);
        dispatch({
            type: UPDATE_STRATEGY_LIST,
            list: list
        });

        showNotice('通知', '複製策略成功');
    }

    const { status } = rowData;
    const scored =
        status != null && status !== 0 && status !== 1 && status !== 10 &&
        status !== 11 && status !== 15;

    const doBacktest = e => {
        if (!scored) { return; }

        history.push('/backtest/' + e.currentTarget.dataset.id);
    };

    const showScoreResult = e => {
        if (!scored) { return; }

        history.push('/strategyScore/' + e.currentTarget.dataset.id);
    };

    return <MoreButton
        rowData={rowData}
        editStrategy={editStrategy}
        copyStrategy={copyStrategy}
        deleteStrategy={deleteStrategy}
        scored={scored}
        showScoreResult={showScoreResult}
        doBacktest={doBacktest}
        cancelBacktest={cancelBacktest}
        showBacktestResult={showBacktestResult}
        // toggleExpand={toggleExpand}
    />
};

export default React.memo(Container, areEqual);