import Fetch from "../fetch";

/**
 * @memberof module:Fetch
 * @description 通知api
 */

const notificationApi = {
    notifications: {
        method: "GET",
        uri: () => '/user/notifications'
    }
}

/**
 * @memberof module:Fetch
 * @function NotificationApi
 * @description 發送通知相關request
 */

export default function NotificationApi(type, params=null){
    return Fetch.request(
        notificationApi[type].method,
        notificationApi[type].uri,
        params
    )
}