import React from "react";
import StrategyCard from "./component";
import { colorDecidedByValue } from "Util/colorDecider"
import { colorRangeMapping } from './colorRange';
import { TRACK_SHARED_STRATEGY } from "Redux/saga/actionTypes/strategy";
import store from 'Redux/store';
import { useHistory } from 'react-router-dom';

/**
 * 追蹤策略
 * @param {string} id - 策略id
 */

const trackStrategy = (e) => {
    const id = Number(e.currentTarget.dataset.id);
    const tracked = JSON.parse(e.currentTarget.dataset.tracked)
    store.dispatch({ type: TRACK_SHARED_STRATEGY, id: id, tracked: !tracked });
}

const Container = ({ data, recentMonth }) => {
    const history = useHistory();
    
    const showBacktestResult = () => {
        history.push('/strategySharing/' + data.id);
    };

    let recentMonthGain, diff;
    if (data.returns && data.returns.recents) {
        let key = recentMonth + '_m';
        let recents = data.returns.recents.find(x => x.id === key);

        if (recents) {
            recentMonthGain = recents.value;
            diff = recents.diff;
        }
    }

    return (
        <StrategyCard
            data={data}
            recentMonth={recentMonth}
            recentMonthGain={recentMonthGain}
            recentMonthGainColor={colorDecidedByValue(recentMonthGain, colorRangeMapping)}
            diff={diff}
            diffColor={colorDecidedByValue(diff, colorRangeMapping)}
            yearReturnRate={data.year_return_rate}
            showBacktestResult={showBacktestResult}
            trackStrategy={trackStrategy} />
    );
};

export default Container;