import Component from "./component"
import React from 'react';
import Highcharts from 'highcharts';
import './style.scss';
import _  from 'lodash';
import { useSelector } from "react-redux";
import { options } from "./constants";
import { isEqual } from "lodash"
window.Highcharts = Highcharts;

const DisplayLength = 5;

export function Container()
{
    const stockList = useSelector(store =>  store.strategyDesign.stockList);
    const trackingStock = useSelector(store => store.stock.trackingStock)
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === 'stockResult'))

    const page = pagination ? pagination.page : 1;
    let startIndex = DisplayLength * (page - 1);

    const getIndustryGraphOption = () => {
        const graphOptinos = _.cloneDeep(options);
        const data = stockList.industrySummary.map(x => {
            return { name: x.name, y: x.count }
        });

        graphOptinos.series[0].data = data;
        return graphOptinos;
    }  

    const getSummaryData = () => {
        if (!stockList.industrySummary) { return []; }

        let start = DisplayLength * (page - 1);
        let end = start + DisplayLength;
        return stockList.industrySummary.slice(start, end);
    }

    const getDistributionData = () => {
        if (!stockList.stockData) { return []; }

        let score100 = 0;
        let score90 = 0;
        let score80 = 0;
        let score70 = 0;
        let score60 = 0;
        let scoreBelow60 = 0;

        stockList.stockData.forEach(x => {
            if (x.score === 100) {
                score100++;
            } else if (x.score >= 90) {
                score90++;
            } else if (x.score >= 80) {
                score80++;
            } else if (x.score >= 70) {
                score70++;
            } else if (x.score >= 60) {
                score60++;
            } else {
                scoreBelow60++;
            }
        });

        const result = [
            {
                name: '100分',
                count: score100,
                percentage: score100 / stockList.stockData.length * 100
            },
            {
                name: '90~99分',
                count: score90,
                percentage: score90 / stockList.stockData.length * 100
            },
            {
                name: '80~89分',
                count: score80,
                percentage: score80 / stockList.stockData.length * 100
            },
            {
                name: '70~79分',
                count: score70,
                percentage: score70 / stockList.stockData.length * 100
            },
            {
                name: '60~69分',
                count: score60,
                percentage: score60 / stockList.stockData.length * 100
            },
            {
                name: '60分以下',
                count: scoreBelow60,
                percentage: scoreBelow60 / stockList.stockData.length * 100
            }
        ];

        return result.filter(x => x.count !== 0);
    };

    const distributionData = getDistributionData();

    const getDistributionGraphOption = () => {
        const graphOptinos = _.cloneDeep(options);

        graphOptinos.series[0].data =
            distributionData.map(x => ({ name: x.name, y: x.count }));

        return graphOptinos;
    };

    return <Component
        componentId="stockResult"
        stockList={stockList}
        startIndex={startIndex}
        distributionData={distributionData}
        summaryData={pagination ? getSummaryData(): []}
        trackingStock={trackingStock}
        displayLength={DisplayLength}
        distributionGraphOption={getDistributionGraphOption()}
        industryGraphOption={getIndustryGraphOption()}
    />;
}

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps, nextProps)
    )
    {
        return false
    } else {
        return true
    }
}

export default React.memo(Container, areEqual);