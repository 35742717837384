import React from "react"
import "./style.scss";
import { Input, Dropdown, Tag } from "rsuite"
import { isEqual } from "lodash"
import StockFilterModal from 'Components/stockFilterModal';
import IndustryFilterModal from 'Components/industryFilterModal';
import IndexConstraintModal from 'Components/indexConstraintModal';
// import IndustryFilterModal from 'Components/industryFilterModal';
function Component({
    name,
    description,
    state,
    industryData,
    indexLibrary,
    openIndustryModal,
    onNameChange,
    onDescriptionChange,
    // renderIndustryFilterList,
    // onIndustryFilterChange,
    // onConstraintFilterChange,
    onIndustryModalConfirm,
    onIndustryModalCancel,
    openConstraintModal,
    onConstraintModalConfirm,
    // renderConstraintFilter,
    onIndexConstraintModalCancel,
    industryFilterListInclude,
    industryFilterListExclude,
    deleteIndustryFilter,
    deleteIndexConstraint,
    constraintFilterListInclude,
    constraintFilterListExclude,
    stockFilterInclude,
    stockFilterExclude,
    deleteStockFilter,
    openStockModal,
    onStockModalConfirm,
    closeStockModal
    // filterStatus
})
{
    return (
        <div className="stock-filter-panel">
            <div className="inner-block">
                <div className="form-row">
                    <div className="form-title">投資策略名稱</div>
                    <Input
                        id="name-input"
                        value={name}
                        onChange={onNameChange} />
                </div>
                <div className="form-row" style={{ marginTop: 12 }}>
                    <div className="form-title">策略概念描述(選填)</div>
                    <Input
                        id="description-input"
                        value={description}
                        onChange={onDescriptionChange} />
                </div>
            </div>
            <hr />
            <div className="inner-block" style={{ paddingBottom: 2 }}>
                <div className="filter">
                    <Dropdown
                        className="filter-dropdown"
                        title="選擇個股或產業">
                        <Dropdown.Item onSelect={() => openStockModal(true)}>
                            選擇個股
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item onSelect={() => openIndustryModal('industries', true)}>
                            選擇產業 (證交所分類)
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => openIndustryModal('concepts', true)}>
                            選擇概念股
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => openIndustryModal('conglomerates', true)}>
                            選擇集團股
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item onSelect={() => openIndustryModal('industries', false)}>
                            排除產業 (證交所分類)
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => openIndustryModal('concepts', false)}>
                            排除概念股
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => openIndustryModal('conglomerates', false)}>
                            排除集團股
                        </Dropdown.Item>
                    </Dropdown>
                    <div>
                        <span className="filter-title">選擇：</span>
                        <span className="filter-list">
                            {
                                stockFilterInclude.map(x =>
                                    <Tag
                                        closable
                                        key={x}
                                        onClose={() => deleteStockFilter(x.stock_code, true)}>
                                        {x.stock_name}
                                    </Tag>
                                )
                            }
                            {
                                industryFilterListInclude.map((o, i) => <Tag closable key={i} onClose={() => deleteIndustryFilter(o.index, o.isIncludeMode, o.type)}>
                                    {o.name}
                                </Tag>)
                            }
                        </span>
                        <span className="filter-title">但 排除：</span>
                        <span>
                            {
                                stockFilterExclude.map(x =>
                                    <Tag
                                        closable
                                        key={x}
                                        onClose={() => deleteStockFilter(x.stock_code, false)}>
                                        {x.stock_name}
                                    </Tag>
                                )
                            }
                            {
                                industryFilterListExclude.map((o, i) => <Tag closable key={i} onClose={() => deleteIndustryFilter(o.index, o.isIncludeMode, o.type)}>
                                    {o.name}
                                </Tag>)
                            }
                        </span>
                    </div>
                </div>
                <div className="filter index-filter">
                    <Dropdown
                        className="filter-dropdown"
                        title="指標篩選">
                        <Dropdown.Item
                            onSelect={() => openConstraintModal(true)}
                        >
                            選擇符合條件個股
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item
                            onSelect={() => openConstraintModal(false)}
                        >
                            排除符合條件個股
                        </Dropdown.Item>
                    </Dropdown>
                    <div className="filter-type">
                        <div className="filter-title">符合：</div>
                        <div className="filter-list">
                            {
                                constraintFilterListInclude.map((o, i) => {
                                    return o ? <Tag
                                        key={i}
                                        closable
                                        onClose={() => deleteIndexConstraint(o.rowIndex, o.isIncludeMode)}
                                    >
                                        {o.text}
                                    </Tag> : null
                                })
                            }
                        </div>
                    </div>
                    <div className="filter-type">
                        <div className="filter-title">但 排除：</div>
                        <div className="filter-list">
                            {
                                constraintFilterListExclude.map((o, i) => {
                                    return o ? <Tag
                                        key={i}
                                        closable
                                        onClose={() => deleteIndexConstraint(o.rowIndex, o.isIncludeMode)}
                                    >
                                        {o.text}
                                    </Tag> : null
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <StockFilterModal
                show={state.showStockModal}
                selections={state.selectedStockFilter}
                onConfirm={stocks => onStockModalConfirm(stocks)}
                onClose={closeStockModal} />
            <IndustryFilterModal
                show={state.showIndustryModal}
                data={industryData ? industryData : []}
                isIncludeMode={state.isIndustryIncludeMode}
                type={state.industryType}
                selectedList={state.selectedIndustryFilter}
                onClose={onIndustryModalConfirm}
                onCancel={onIndustryModalCancel}
            />

            <IndexConstraintModal
                show={state.showConstraintModal}
                indexLibrary={indexLibrary ? indexLibrary : []}
                isIncludeMode={state.isConstraintIncludeMode}
                constraintFilter={state.selectedConstraintFilter}
                onClose={list => onConstraintModalConfirm(list)}
                onCancel={onIndexConstraintModalCancel}
            />
        </div>
    );
}

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.stockFilterInclude, nextProps.stockFilterInclude) ||
        !isEqual(prevProps.stockFilterExclude, nextProps.stockFilterExclude) ||
        !isEqual(prevProps.industryFilter, nextProps.industryFilter) ||
        !isEqual(prevProps.industryFilterListExclude, nextProps.industryFilterListExclude) ||
        !isEqual(prevProps.industryFilterListInclude, nextProps.industryFilterListInclude) ||
        !isEqual(prevProps.constraintFilterListInclude, nextProps.constraintFilterListInclude) ||
        !isEqual(prevProps.constraintFilterListExclude, nextProps.constraintFilterListExclude) ||
        !isEqual(prevProps.state, nextProps.state) ||
        prevProps.name !== nextProps.name ||
        prevProps.description !== nextProps.description
    )
    {
        return false
    } else {
        return true
    }
}
export default React.memo(Component, areEqual);

// export default Component;