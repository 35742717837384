import React from 'react';

import svg1 from 'Image/tutorial/1.svg';
import svg2 from 'Image/tutorial/2.svg';
import svg3 from 'Image/tutorial/3.svg';
import svg4 from 'Image/tutorial/4.svg';
import svg5 from 'Image/tutorial/5.svg';
import svg6 from 'Image/tutorial/6.svg';
import svg7 from 'Image/tutorial/7.svg';
import svg8 from 'Image/tutorial/8.svg';
import svg9 from 'Image/tutorial/9.svg';
import svg10 from 'Image/tutorial/10.svg';
import svg11 from 'Image/tutorial/11.svg';
import svg12 from 'Image/tutorial/12.svg';

const cards = {
    copyStrategy: [
        {
            image: svg1,
            title: '多種知名策略任意使用、修改',
            background: '#10476d',
            content:
                <>
                    <div>多種回測十年皆有效的知名策略，供您使用。包含各種基本面、技術面、籌碼面、或混合型投資策略。</div>
                    <div>過去看到某策略，卻不知道會不會賺錢的情況不會再發生。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/1-1.jpg'),
                    title: '多種知名策略任意使用、修改',
                    content:
                        <>
                            <div>多種回測十年皆有效的知名策略，供您使用、修改。包含各種基本面、技術面、籌碼面、或混合型投資策略。</div>
                            <div>過去看到某策略，卻不知道會不會賺錢的情況不會再發生。</div>
                        </>
                }
            ]
        },
        {
            image: svg2,
            title: '認為30日均線更賺？OK隨時調',
            background: '#564773',
            content:
                <>
                    <div>我覺得黃金交叉買進報酬率才高。</div>
                    <div>我覺得加殖利率高的條件會更賺。</div>
                    <div>無論您有任何想法，隨時可修改知名策略，調校出最賺錢投資策略。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/2-1.jpg'),
                    title: '認為30日均線更賺？OK隨時調',
                    content:
                        <>
                            <div>我覺得黃金交叉買進報酬率才高。我覺得加殖利率高的條件會更賺。</div>
                            <div>無論您有任何想法，隨時可修改知名策略，調校出最賺錢投資策略。</div>
                        </>
                }
            ]
        },
        {
            image: svg3,
            title: '我想回測，但我不想寫程式啊',
            background: '#5c4e2e',
            content:
                <>
                    <div>只是想改個條件，要先學寫程式？學完天都黑了。</div>
                    <div>在鉅亨易策略，您只需點擊滑鼠，即可完成條件設定，輕鬆回測。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/3-1.jpg'),
                    title: '我想回測，但我不想寫程式啊',
                    content:
                        <>
                            <div>我只是想改個條件，要先學寫程式？學完天都黑了。</div>
                            <div>在鉅亨易策略，您只需點擊滑鼠，即可完成條件設定，輕鬆回測。</div>
                        </>
                }
            ]
        },
        {
            image: svg4,
            title: '公開回測數據，不黑箱作業',
            background: '#105154',
            content:
                <>
                    <div>你這是怎麼回測的？</div>
                    <div>過去選出哪些股票？</div>
                    <div>篩選條件真的有符合嗎？</div>
                    <div>每一檔報酬率各多少？</div>
                    <div>所有回測數據全公開，讓你驗證準確性。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/4-1.jpg'),
                    title: '公開回測數據，不黑箱作業',
                    content:
                        <>
                            <div>你這是怎麼回測的？過去選出哪些股票？篩選條件真的有符合嗎？每一檔報酬率各多少？</div>
                            <div>所有回測數據全公開，讓你驗證準確性。</div>
                        </>
                }
            ]
        }
    ],
    customStrategy: [
        {
            image: svg5,
            title: '回測您的選股方法，看有沒有效',
            background: '#10476d',
            content:
                <>
                    <div>立即驗證您想過的選股法、或操作法，看看會不會賺錢。</div>
                    <div>不用長時間真錢投入，幾年後才知道這方法會賠錢。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/5-1.jpg'),
                    title: '回測您的選股方法，看有沒有效',
                    content:
                        <>
                            <div>立即驗證您想過的選股法、或操作法，看看會不會賺錢。</div>
                            <div>不用長時間真錢投入，幾年後才知道這方法會賠錢。</div>
                        </>
                }
            ]
        },
        {
            image: svg6,
            title: '黃金交叉時買會賺嗎，怎麼設？',
            background: '#564773',
            content:
                <>
                    <div>月均線與季均線，剛剛黃金交叉時買進，此條件怎麼設？</div>
                    <div>設定評分條件：</div>
                    <div>1.今天的月均線&gt;季均線</div>
                    <div>2.但昨天的月均線&lt;季均線</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/6-1.jpg'),
                    title: '黃金交叉時買會賺嗎，怎麼設？',
                    content:
                        <>
                            <div>月均線與季均線，剛剛黃金交叉時買進，此條件怎麼設？</div>
                            <div>設定評分條件：</div>
                            <div>1.今天的月均線&gt;季均線</div>
                            <div>2.但昨天的月均線&lt;季均線</div>
                        </>
                }
            ]
        },
        {
            image: svg7,
            title: '買電動車概念股+高成長怎麼設？',
            background: '#5c4e2e',
            content:
                <>
                    <div>只買電動車概念股中，盈餘成長性高的，此條件怎麼設？</div>
                    <div>設定股票池條件：篩選電動車概念股</div>
                    <div>設定評分條件：EPS年複合成長率&gt;20%</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/7-1.jpg'),
                    title: '買電動車概念股+高成長怎麼設？',
                    content:
                        <>
                            <div>只買電動車概念股中，盈餘成長性高的，此條件怎麼設？</div>
                            <div>設定股票池條件：篩選電動車概念股</div>
                            <div>設定評分條件：EPS年複合成長率&gt;20%</div>
                        </>
                }
            ]
        },
        {
            image: svg8,
            title: '投信連買，但不要金融股怎麼設？',
            background: '#105154',
            content:
                <>
                    <div>投信連買5日，但要排除金融股，此條件怎麼設？</div>
                    <div>設定股票池條件：排除金融股</div>
                    <div>設定評分條件：投信買賣超，5日皆&gt;0</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/8-1.jpg'),
                    title: '投信連買，但不要金融股怎麼設？',
                    content:
                        <>
                            <div>投信連買5日，但要排除金融股，此條件怎麼設？</div>
                            <div>設定股票池條件：排除金融股</div>
                            <div>設定評分條件：投信買賣超，5日皆&gt;0</div>
                        </>
                }
            ]
        },
        {
            image: svg9,
            title: 'RSI黃金交叉+法人狂買怎麼設？',
            background: '#6b3d4c',
            content:
                <>
                    <div>RSI黃金交叉，法人5天買1萬張以上，此條件怎麼設？</div>
                    <div>設定評分條件：</div>
                    <div>RSI(5)&gt;RSI(10)，但昨日RSI(5)&lt;RSI(10)</div>
                    <div>近5日三大法人買超合計&gt;10000</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/9-1.jpg'),
                    title: 'RSI黃金交叉+法人狂買怎麼設？',
                    content:
                        <>
                            <div>RSI黃金交叉，法人5天買1萬張以上，此條件怎麼設？</div>
                            <div>設定評分條件：</div>
                            <div>RSI(5)&gt;RSI(10)，但昨日RSI(5)&lt;RSI(10)</div>
                            <div>近5日三大法人買超合計&gt;10000</div>
                        </>
                }
            ]
        },
        {
            image: svg10,
            title: '毛利率連2升+營收創高怎麼設？',
            background: '#4a423b',
            content:
                <>
                    <div>毛利率連2季上升，且月營收創新高，此條件怎麼設？</div>
                    <div>設定評分條件：本季毛利率&gt;上季毛利率，上季毛利率&gt;上上季毛利率，最新月營收&gt;上個月之前的120個月的最高值</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/10-1.jpg'),
                    title: '毛利率連2升+營收創高怎麼設？',
                    content:
                        <>
                            <div>毛利率連2季上升，且月營收創新高，此條件怎麼設？</div>
                            <div>設定評分條件：本季毛利率&gt;上季毛利率，上季毛利率&gt;上上季毛利率，最新月營收&gt;上個月之前的120個月的最高值</div>
                        </>
                }
            ]
        },
        {
            image: svg11,
            title: '股票池先剔除爛股，提升報酬率',
            background: '#40542c',
            content:
                <>
                    <div>先把爛蘋果挑掉，再從好蘋果中挑選最甜的，報酬率將會大幅提升。</div>
                    <div>這是我們回測過數百種策略後，得出的結論。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/11-1.jpg'),
                    title: '股票池先剔除爛股，提升報酬率',
                    content:
                        <>
                            <div>先把爛蘋果挑掉，再從好蘋果中挑選最甜的，報酬率將會大幅提升。</div>
                            <div>這是我們回測過數百種策略後，得出的結論。</div>
                        </>
                }
            ]
        },
        {
            image: svg12,
            title: '優化評分權重，可提升報酬率',
            background: '#434457',
            content:
                <>
                    <div>如果股價在均線之上，比其他條件重要，您可以把這個條件的權重上調。</div>
                    <div>經過不斷優化後，報酬率有機會更高。</div>
                </>,
            detail: [
                {
                    image: require('Image/tutorial/12-1.jpg'),
                    title: '優化評分權重，可提升報酬率',
                    content:
                        <>
                            <div>如果股價在均線之上，比其他條件重要，您可以把這個條件的權重上調。</div>
                            <div>經過不斷優化後，報酬率有機會更高。</div>
                        </>
                }
            ]
        }
    ]
};

export default cards;