import React, { useRef } from "react";
import { Icon, Col, Whisper, Popover,  Dropdown} from "rsuite";
import Arrow from "Image/svgIcon/arrow-2.svg";
import Graph from "../graph/index"
import { toEditStrategy, copyStrategy } from './util'
import { isEqual } from "lodash"

import IconAdd from 'Image/svgIcon/dashboard/add.svg';

// export default 
function Card({ data, isRunning, yearReturnRate, yearReturnRateColor, publishStrategy, trackStrategy,
	showRenameModal,
	deleteStrategy,
	scored,
	showScoreResult,
	doBacktest,
	showBacktestResult
}){
	const moreMenu = useRef(null);
	const close = () => {
		moreMenu.current.close()
	}
	// console.log(moreMenu);
	const renderMenu = () => {
		return (
			<Popover className="strategy_overview_list__card__action__more" full>
                <Dropdown.Menu data-id={data.id} onSelect={close}>
                    <Dropdown.Item data-id={data.id} onSelect={toEditStrategy}>
                        編輯
                    </Dropdown.Item>
                    <Dropdown.Item data-id={data.id} onSelect={copyStrategy}>
                        複製
                    </Dropdown.Item>
                    <Dropdown.Item data-id={data.id} data-name={data.name} onSelect={showRenameModal}>
						重新命名
                    </Dropdown.Item>
                    <Dropdown.Item data-id={data.id} onSelect={deleteStrategy}>
                        刪除
                    </Dropdown.Item>
					<hr/>
					<Dropdown.Item 
						data-id={data.id}
						disabled={!scored}
						onSelect={showScoreResult}>
                    	評分報告
                    </Dropdown.Item>
                    <Dropdown.Item data-id={data.id} onSelect={doBacktest}>
                        開始回測
                    </Dropdown.Item>
                    <Dropdown.Item data-id={data.id} onSelect={showBacktestResult}>
                        回測報告
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Popover>
        );
    }
	return <Col xs={24} sm={24} md={8} lg={6}>
		<div className="strategy_overview_list__card">
			<div className="strategy_overview_list__card__title">
				{data.name}
			</div>
				{/* {console.log('here')} */}
			<div className="strategy_overview_list__card__graph">
				<div className="overlay">
					<div>
						<span className = {yearReturnRateColor}>
							年化報酬率: {yearReturnRate? yearReturnRate : 0}%
						</span>
					</div>
					<div>
						<span className = {isRunning}>
							{data.status_text}
						</span>
					</div>
				</div>
				{data.returns && <Graph data={data.returns.data}/>}
			</div>
			<div className="strategy_overview_list__card__content">
				<div>
					<div><span>上次回測日期</span></div>
					<div><span>
						{data.backtested_at}
					</span></div>
				</div>
				<div>
					<div><span>策略最大漲幅</span></div>
					<div><span>{data.strategy_max_up ? data.strategy_max_up + "%": null}</span></div>
				</div>
				<div>
					<div><span>策略最大跌幅</span></div>
					<div><span>{data.strategy_max_down ? data.strategy_max_down + "%": null}</span></div>
				</div>
				<div>
					<div><span>打敗指數機率</span></div>
					<div><span>{data.stocks_win_rate ? data.stocks_win_rate + "%": null}</span></div>
				</div>
				<div>
					<div><span>個股勝率</span></div>
					<div><span>{data.win_rate ? data.win_rate + "%": null}</span></div>
				</div>
				<div>
					<div><span>買進策略</span></div>
					<div><span>{data.backtest_buy_rule}</span></div>
				</div>
				<div>
					<div><span>換股頻率</span></div>
					<div><span>{data.backtest_change_frequency}</span></div>
				</div>
				<div>
					<div><span></span></div>
					<div><span></span></div>
				</div>
			</div>

			<div className="strategy_overview_list__card__action">
				<div>
					<button data-id={data.id} data-tracked={data.tracked} onClick={trackStrategy} className = {data.tracked ? "active": null}>
						{
							data.tracked ?
								'追蹤中' : <><Icon icon={IconAdd} />追蹤</>
						}
					</button>
				</div>
				<div>
					<Whisper
						ref={moreMenu}
						placement="topEnd"
						trigger="click"
						speaker={renderMenu()}
					>
						<button>
							<div>
								<div>
									<span>
										更多
									</span>
								</div>
								<div>
									<Icon svgStyle={{width: '10px'}} icon={Arrow}/>
								</div>
							</div>
						</button>
					</Whisper>
				</div>
			</div>
		</div>
	</Col>;
}

function areEqual(prevProps, nextProps) {
    if(isEqual(prevProps, nextProps))
    {
        return true
    } else {
        return false
    }
}

export default React.memo(Card, areEqual);