import React from 'react';
import moment from 'moment';
import { Button, Col, Grid, Icon, Row, SelectPicker, Table } from 'rsuite';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';

import AppPagination from "Components/pagination";
import IconAdd from 'Image/svgIcon/dashboard/add.svg';
import IconPageArrow from 'Image/svgIcon/page-arrow.svg';
import IconPageArrowDisabled from 'Image/svgIcon/page-arrow-disabled.svg';
import StrategyCard from './components/strategyCard';
import './style.scss';

const { Column, HeaderCell, Cell } = Table;

const monthOptions = [
    { label: '近1月表現', value: 1 },
    { label: '近2月表現', value: 2 },
    { label: '近3月表現', value: 3 },
    { label: '近6月表現', value: 6 },
    { label: '近12月表現', value: 12 }
];

function StrategySharingPage({
    id, data, columnList, sortColumn, sortType, sortColumnData, sharedList,
    displayLength, trackSharedStrategy,
    recentStrategies, recentMonth, onRecentMonthChange,
    recentLeftIconEnabled, recentRightIconEnabled, decreaseRecentPage,
    increaseRecentPage,
    featuredStrategies, featuredMonth, setFeaturedMonth,
    featuredLeftIconEnabled, featuredRightIconEnabled, decreaseFeaturedPage,
    increaseFeaturedPage
}) {
    const recentLeftIcon =
        recentLeftIconEnabled ? IconPageArrow : IconPageArrowDisabled;
    const recentRightIcon =
        recentRightIconEnabled ? IconPageArrow : IconPageArrowDisabled;

    let recentLeftIconClassName = 'page-control left';
    let recentRightIconClassName = 'page-control right';

    if (!recentLeftIconEnabled) {
        recentLeftIconClassName += ' disabled';
    }

    if (!recentRightIconEnabled) {
        recentRightIconClassName += ' disabled';
    }

    const featuredLeftIcon =
        featuredLeftIconEnabled ? IconPageArrow : IconPageArrowDisabled;
    const featuredRightIcon =
        featuredRightIconEnabled ? IconPageArrow : IconPageArrowDisabled;

    let featuredLeftIconClassName = 'page-control left';
    let featuredRightIconClassName = 'page-control right';

    if (!featuredLeftIconEnabled) {
        featuredLeftIconClassName += ' disabled';
    }

    if (!featuredRightIconEnabled) {
        featuredRightIconClassName += ' disabled';
    }

    return (
        <div className="strategy-sharing">
            <div className="page-title">知名策略</div>
            <hr />
            <div className="title">
                近期表現最優
                <SelectPicker
                    data={monthOptions}
                    searchable={false}
                    cleanable={false}
                    value={recentMonth}
                    onChange={value => onRecentMonthChange(value)} />
                <Icon
                    className={recentLeftIconClassName}
                    icon={recentLeftIcon}
                    onClick={decreaseRecentPage} />
                <Icon
                    className={recentRightIconClassName}
                    icon={recentRightIcon}
                    rotate={180}
                    onClick={increaseRecentPage} />
            </div>
            <div className="recent-strategy">
                <Grid>
                    <Row>
                        {
                            recentStrategies.map((data, i) =>
                                <Col xs={6}>
                                    <StrategyCard
                                        key={i}
                                        data={data}
                                        recentMonth={recentMonth} />
                                </Col>
                            )
                        }
                    </Row>
                </Grid>
            </div>
            <div className="title">
                精選策略
                <SelectPicker
                    data={monthOptions}
                    searchable={false}
                    cleanable={false}
                    value={featuredMonth}
                    onChange={value => setFeaturedMonth(value)} />
                <Icon
                    className={featuredLeftIconClassName}
                    icon={featuredLeftIcon}
                    onClick={decreaseFeaturedPage} />
                <Icon
                    className={featuredRightIconClassName}
                    icon={featuredRightIcon}
                    rotate={180}
                    onClick={increaseFeaturedPage} />
            </div>
            <div className="featured-strategy">
                <Grid>
                    <Row>
                        {
                            featuredStrategies.map((data, i) =>
                                <Col xs={6}>
                                    <StrategyCard key={i} data={data} recentMonth={featuredMonth} />
                                </Col>
                            )
                        }
                    </Row>
                </Grid>
            </div>
            <div className="title">所有策略</div>
            <div className="block">
                <Table
                    autoHeight={true}
                    data={data}
                    shouldUpdateScroll={false}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={sortColumnData}>
                    {
                        columnList.map((column, i) =>
                            <Column
                                key={column.id}
                                width={column.width}
                                sortable={true}
                                fixed={i === 0}
                                align={i === 0 ? 'left' : 'center'}
                                verticalAlign="middle">
                                <HeaderCell>
                                    {
                                        sortColumn === column.id ?
                                            <div
                                                className="blue"
                                                style={{ textAlign: 'center' }}>
                                                {column.name}
                                            </div> :
                                            <div style={{ textAlign: 'center' }}>{column.name}</div>
                                    }
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        rowData => {
                                            const value = rowData[column.id];
                                            const indexYearReturnRateAcc =
                                                rowData['index_year_return_rate'];
                                            const ror = rowData['year_return_rate'];

                                            if (column.id === 'name') {
                                                return (
                                                    <div className="blue">
                                                        <Link to={'/strategySharing/' + rowData['id']}>
                                                            {value}
                                                        </Link>
                                                    </div>
                                                );
                                            }

                                            if (column.id === 'tracked') {
                                                let className = rowData.tracked ? 'toggle on' : 'toggle off';
                                                let text = rowData.tracked ? '追蹤中' : <><Icon icon={IconAdd} />追蹤</>;

                                                return (
                                                    <Button
                                                        className={className}
                                                        onClick={trackSharedStrategy}
                                                        data-id={rowData.id}
                                                        data-tracked={rowData.tracked}>
                                                        {text}
                                                    </Button>
                                                );
                                            }

                                            if (column.id === 'backtested_at') {
                                                return (
                                                    value ?
                                                        moment(value).format('YYYY/MM/DD') :
                                                        ''
                                                );
                                            }

                                            if (column.id === 'index_year_return_rate') {
                                                return (
                                                    indexYearReturnRateAcc != null ?
                                                        indexYearReturnRateAcc + '%' :
                                                        ''
                                                );
                                            }

                                            if (column.id === 'diff') {
                                                if (
                                                    indexYearReturnRateAcc == null ||
                                                    ror == null
                                                ) {
                                                    return '';
                                                }

                                                const diff =
                                                    Number((ror - indexYearReturnRateAcc).toFixed(2));

                                                if (diff === 0) { return '0%'; }

                                                return (
                                                    diff > 0 ?
                                                        <span className="red">+{diff}%</span> :
                                                        <span className="green"> {diff}%</span>
                                                );
                                            }

                                            if (column.id === 'year_return_rate') {
                                                if (value === '') { return ''; }

                                                return (
                                                    value > indexYearReturnRateAcc ?
                                                        <span className="red">{value + '%'}</span> :
                                                        value + '%'
                                                );
                                            }

                                            if (column.id === 'return_rate_acc') {
                                                return value !== '' ? value + '%' : '';
                                            }

                                            if (column.id === 'back_year') {
                                                return value + '年';
                                            }

                                            return rowData[column.id];
                                        }
                                    }
                                </Cell>
                            </Column>
                        )
                    }
                </Table>
                <div className="strategy__sharing__pagination">
                    <AppPagination
                        id={id}
                        list={sharedList}
                        maxButtons={10}
                        displayLength={displayLength} />
                </div>
            </div>
        </div>
    );
}

export default React.memo(StrategySharingPage, isEqual);