import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import Api from "Api/strategy";
import Component from './component';
import {
    SET_SHAREDLIST,
    UPDATE_STRATEGY_LIST
} from 'Redux/actionTypes/strategy/strategy';

const Container = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const { id: idStr } = useParams();
    const id = parseInt(idStr); // Convert id from string to int
    
    const indexLibrary = useSelector(store => store.index.indexLibrary);
    const trackingStock = useSelector(store => store.stock.trackingStock);

    const strategies = useSelector(store => store.strategy.list);
    const sharedStrategies = useSelector(store => store.strategy.shared);
    const strategy = sharedStrategies.find(x => x.id === id);
    const tracked = strategy ? strategy.tracked : 0;

    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

    const profile = useSelector(store => store.profile);
    const isFreeUser = profile.pricing_plan === 'free';;

    const trackSharedStrategy = async () => {
        const newState = !tracked;

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('tracked', newState ? '1' : '0');

        const requestData = {
            params: { id },
            formData: formData
        };

        const result = await Api('trackSharedStrategy', requestData);

        if (!result) { return; }

        let list = [...sharedStrategies];

        const strategyIndex = list.findIndex(x => x.id === id);

        if (strategyIndex !== -1) {
            list[strategyIndex].tracked = newState ? 1 : 0;

            dispatch({ type: SET_SHAREDLIST, sharedList: list });
        }

        // Show track modal when tracking a new strategy
        if (newState) {
            setisTrackModalOpen(true);
        }
    }

    // Track shared strategy modal
    const [isTrackModalOpen, setisTrackModalOpen] = useState(false);

    const closeTrackModal = () => {
        setisTrackModalOpen(false);
    }

    const showDashboardPage = () => {
        closeTrackModal();
        history.push({ pathname: '/dashboard' });
    }

    const copyStrategy = async () => {
        const result = await Api("copy", { params: { id: id } });
        let list = [...strategies];

        if (!result) { return; }

        list.push(result);
        dispatch({
            type: UPDATE_STRATEGY_LIST,
            list: list
        });

        setIsCopyModalOpen(true);
    }

    const closeCopyModal = () => {
        setIsCopyModalOpen(false);
    }

    const showStrategyOverviewPage = () => {
        closeCopyModal();
        history.push({ pathname: '/strategyOverview' });
    }

    return (
        <Component
            id={id}
            indexLibrary={indexLibrary}
            isFreeUser={isFreeUser}
            trackingStock={trackingStock}
            tracked={tracked}
            trackSharedStrategy={trackSharedStrategy}
            isTrackModalOpen={isTrackModalOpen}
            closeTrackModal={closeTrackModal}
            showDashboardPage={showDashboardPage}
            copyStrategy={copyStrategy}
            isCopyModalOpen={isCopyModalOpen}
            closeCopyModal={closeCopyModal}
            showStrategyOverviewPage={showStrategyOverviewPage} />
    );
}

export default Container;