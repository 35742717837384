import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Component from './component';
import { FETCH_STOCKS } from 'Redux/saga/actionTypes/strategy';

const Container = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const strategyList = useSelector(store => store.strategy.list);

    const strategy = strategyList.find(x => x.id.toString() === id);
    const status = strategy?.status;
    const showScoreResult =
        status != null && status !== 0 && status !== 1 && status !== 10 &&
        status !== 11 && status !== 15;

    // Redirect to strategy overview page if id is not specified
    useEffect(() => {
        if (id) { return; }

        history.replace('/strategyOverview');
    }, [id, history]);

    useEffect(() => {
        if (!id) { return; }

        dispatch({ type: FETCH_STOCKS, id: id });
    }, [id, dispatch]);

    // Scoring progress Modal
    const isScoringProgressModalOpen = status === 11 || status === 15;
    const scoringPercentage = strategy?.percentage;
    const scoringRemainTime = strategy?.time;

    const showStrategyOverview = () => {
        history.push('/strategyOverview');
    };

    const onPreviousStepClick = () => {
        history.push('/strategy/' + id);
    }

    const onBacktestClick = () => {
        history.push('/backtest/' + id);
    };

    return (
        <Component
            id={id}
            showScoreResult={showScoreResult}
            onPreviousStepClick={onPreviousStepClick}
            onBacktestClick={onBacktestClick}
            isScoringProgressModalOpen={isScoringProgressModalOpen}
            scoringPercentage={scoringPercentage}
            scoringRemainTime={scoringRemainTime}
            showStrategyOverview={showStrategyOverview} />
    );
};

export default Container;