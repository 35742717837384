import { call, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_INDUSTRIES } from "./actionTypes/industries";
import { SET_INDUSTRIES } from "../actionTypes/industries";
import Api from "Api/industry";
function* _fetchIndustries()
{
    const industryData = yield call(() => Api('industries'));
    if(industryData)
    {
        console.log('industries');
        console.log(industryData);
        yield put({
            type: SET_INDUSTRIES,
            industryData: industryData
        })
    }
}


function* fetchIndustries()
{
    yield takeLatest(FETCH_INDUSTRIES, _fetchIndustries)
}

// function* fetch()
// {
    
// }

export default {
    fetchIndustries
};