import React, { Component } from 'react';

import API from 'Src/api';
import Dashboard from 'Components/dashboard/dashboard/index';

import './style.scss';

export default class FavoritePage extends Component {
    state = {
        singles: [],
        layout: []
    };

    settings = {
        favoriteList: {
            tableHeight: 1488,
            displayLength: 30
        }
    };

    async componentDidMount() {
        let dashboard = await API.getFavorite();
        let singles = dashboard ? dashboard.singles : [];
        let layout = dashboard ? dashboard.layout : [];

        this.setState({ singles, layout });
    }

    render() {
        return (
            <div className="favorite-page">
                <div style={{ display: 'flex', marginBottom: 7 }}>
                    <div className="page-title">我的最愛</div>
                </div>
                <hr />
                <Dashboard
                    enableEdit={false}
                    singles={this.state.singles}
                    layout={this.state.layout}
                    settings={this.settings}
                    rowHeight={164} />
            </div>
        );
    };
}