import * as Actions from "../actionTypes/industries";
const inistialState = {
    industryData: null,
}

export default function reducer(state=inistialState, action){
    switch(action.type) {
        case Actions.SET_INDUSTRIES: {
            return {
                ...state,
                industryData: action.industryData
            }
        }
        default: {
            return state
        }
    }
}