import Fetch from '../fetch';
/**
 * @constant
 * @memberof module:Fetch
 * @description Configs API
 */
const configsApi = {
    configs: {
        method: 'GET',
        uri: () => '/user/configs'
    },
    setConfigs: {
        method: 'POST',
        uri: () => '/user/configs'
    }
}

/**
 * @memberof module:Fetch
 * @function ConfigsApi
 * @description 發送設定相關request
 */
export default function ConfigsApi(type, params = null) {
    return Fetch.request(
        configsApi[type].method,
        configsApi[type].uri,
        params
    )
};