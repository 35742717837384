import * as Actions from "../actionTypes/profile";

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_PROFILE: {
            return action.profile;
        }
        case Actions.UPDATE_PROFILE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
}