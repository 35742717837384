import React from "react";
import { useSelector } from "react-redux";
import Component from "./component";

import { setAPagination, changePage} from "Redux/actionCreators/pagination";
/** 分頁資料處 */
/**
 * 打開重新命名視窗
 * @memberof module:Pagination
 * @summary 投資策略資料處理
 * @description 依上層元件的id設定分頁資料，存在redux，第幾頁直接由此元件控制。
 */
export default function Container({id, list, maxButtons, displayLength})
{
    setAPagination(id, maxButtons);
    // console.log('list');
    // console.log(list);
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === id));

    const page = pagination ? pagination.page : 1;

    const onChangePage = (page) => {
        changePage(id, page)
    }
    return <Component
        pagination={pagination}
        page={page}
        maxButtons={maxButtons}
        displayLength={displayLength}
        onChangePage={onChangePage}
        list={list}
    />
};