import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import StrategyOverviewPage from '../strategyOverviewPage';
import { columnList } from './constants/columnList';
import * as StrategyActions from "Redux/saga/actionTypes/strategy";
import * as BackTestActions from "Redux/saga/actionTypes/backtest";
// import _ from 'lodash';



/** 我的投資策略資料處理 */
/**
 * 打開重新命名視窗
 * @memberof module:StrategyOverviewPage
 * @summary 投資策略資料處理
 * @description 開啟後策略api list輪詢。strategyOverviewPage: 元件本身狀態。 list, _strategyOverviewPage: redux 資料。
 */

export default function Container(props){
    
    // 元件本身狀態
    const [strategyOverviewPage, setStrategyOverviewPage] = useState(
        {
            page: 1,
            sortColumn: 'updated_at',
            sortType: 'desc',
            displayLength: 10,
            mode: "table"
        }
    )
    // 取得存在redux的資料
    const list = useSelector(store => store.strategy.list)
    const _strategyOverviewPage = useSelector(store => store.strategyOverviewPage);
    const dispatch = useDispatch()

    /**
     * 切換列表模式
     */

    const tableMode = () => {
        if(strategyOverviewPage.mode !== 'table')
        {
            setStrategyOverviewPage({
                ...strategyOverviewPage,
                mode: 'table'
            })
        }
    }

    /**
     * 切換卡片模式
     */
    const cardsMode = () => {
        if(strategyOverviewPage.mode !== "cards")
        {
            setStrategyOverviewPage({
                ...strategyOverviewPage,
                mode: 'cards'
            })
        }
    }

    /**
     * 
     * @param {string} id - 策略id
     */
    
    // const toggleExpand = (id) => {
        // if (strategyOverviewPage.expandId === id) {
        //     setExpandId('');
        // } else {
        //     setExpandId(id);
        // }
    // }

    /**
     * 跳轉建立策略
     * @param {string} id - 策略id
     */

    const onNewStrategyClick = () => {
        const location = { pathname: '/strategy/' };
        props.history.push(location);
    }

    const showScoreResult = (id) => {
        const location = {
            pathname: '/strategy/' + id,
            state: { to: 'stockResult' }
        };
        props.history.push(location);
    }

    
    /**
     * 跳轉編輯策略
     * @param {string} id - 策略id
     */
    const editStrategy = (id) => {
        const location = { pathname: '/strategy/' + id };
        props.history.push(location);
    }

    const doBacktest = (id) => {
        const location = { pathname: '/backtest/' + id };
        props.history.push(location);
    }

    const cancelBacktest = e => {
        dispatch({ type: BackTestActions.CANCEL_BACKTEST, id: e.currentTarget.dataset.id })
    }
  
    const trackStrategy = (e) => {
        const id = Number(e.currentTarget.dataset.id);
        const tracked = JSON.parse(e.currentTarget.dataset.tracked)
        dispatch({type: StrategyActions.TRACK_STRATEGY, id: id, tracked: !tracked})
    }

    const publishStrategy = e => {
        const id = Number(e.currentTarget.dataset.id);
        const published = JSON.parse(e.currentTarget.dataset.published)
        dispatch({type: StrategyActions.PUBLISH_STRATEGY, id: id, published: !published})
    }
    
    const onChangePage = (page) => {
        setStrategyOverviewPage({
            ...strategyOverviewPage,
            page: page
        })
    }
    
    const sortColumnData = (sortColumn, sortType) => {
        setStrategyOverviewPage({
            ...strategyOverviewPage,
            sortColumn: sortColumn,
            sortType: sortType
        })
    }

    return <StrategyOverviewPage
        strategyOverviewPage={strategyOverviewPage}
        _strategyOverviewPage={_strategyOverviewPage}
        list={list}
        columnList={columnList}
        trackStrategy={trackStrategy}
        publishStrategy={publishStrategy}
        sortColumnData={sortColumnData}
        onNewStrategyClick={onNewStrategyClick}
        showScoreResult={showScoreResult}
        editStrategy={editStrategy}
        doBacktest={doBacktest}
        cancelBacktest={cancelBacktest}
        onChangePage={onChangePage}
        tableMode={tableMode}
        cardsMode={cardsMode}
    />
}