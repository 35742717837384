import React from 'react';

import LoginButton from 'Components/loginButton';

import './style.scss';

const Component = () => {
    return (
        <div className="need-login-panel">
            <div className="subtle">此功能需登入</div>
            <LoginButton />
        </div>
    );
};

export default Component;