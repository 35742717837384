import { call, put, takeLatest, 
    // select
} from "redux-saga/effects";
import * as SagaActions from "./actionTypes/notification"
import * as Actions from "../actionTypes/messages";
import Api from "Api/notification";



function* _notifications()
{
    const notifications = yield call(() => Api('notifications'))
    if(notifications)
    {
        yield put({
            type: Actions.UPDATE_NOTIFICATIONS, notifications: notifications
        })
        yield put({ type: Actions.CHECK_ALERT })
    }
}

function* notification()
{
    yield takeLatest(SagaActions.FETCH_NOTIFICATIONS, _notifications)
}


export default { notification };