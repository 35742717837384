export default class ReactUtil {
    static getNodeText(node) {
        if (['string', 'number'].includes(typeof node)) {
            return node;
        }

        if (node instanceof Array) {
            return node.map(ReactUtil.getNodeText).join('');
        }

        if (typeof node === 'object' && node) {
            return ReactUtil.getNodeText(node.props.children);
        }
    }
}