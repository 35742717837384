import React from "react";

import ScoreBar from 'Components/scoreBar';
import { isEqual } from "lodash";
import { ChangeFreqency } from '../../constant/backtest';
import moment from 'moment';
import 'moment/locale/zh-tw';

const Component = ({
    total,
    diff,
    inStockpool,
    frequency,
    lastUpdate,
    categoryList
}) => {
    let scoreClassName = 'value';
    let diffStr = diff;

    if (diff > 0) {
        scoreClassName += ' red';
        diffStr = '▲' + diff;
    } else if (diff < 0) {
        scoreClassName += ' green';
        diffStr = '▼' + diff;
    }

    const frequencyStr = ChangeFreqency.find(x => x.value === frequency)?.label;

    moment.locale('zh-tw');
    const lastUpdateObj = moment(lastUpdate);
    const lastUpdateStr =
        lastUpdateObj.fromNow() +
        ' (' + lastUpdateObj.format('YYYY/MM/DD HH:mm') + ')';

    return (
        <div className="strategy-score-panel">
            <div className="info">
                <div className="square">
                    <div className="subtle">最新評分</div>
                    <div className={scoreClassName}>{total}</div>
                </div>
                <div className="square">
                    <div className="subtle">評分較上期(日)</div>
                    <div className={scoreClassName}>{diffStr}</div>
                </div>
                <div className="square">
                    <div className="subtle">股票池條件</div>
                    <div className={inStockpool ? 'value red' : 'value green'}>
                        {inStockpool ? '符合' : '不符合'}
                    </div>
                </div>
                <div className="square frequency">
                    <div className="subtle">評分更新週期</div>
                    <div className="value">{frequencyStr}</div>
                </div>
            </div>
            <div className="time">
                <div className="last-update subtle">
                    最後更新：{lastUpdateStr}
                </div>
            </div>
            <div className="category">
                {categoryList.map(item => <ScoreBar category={item} />)}
            </div>
        </div>
    );
}

const areEqual = (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps)
}

export default React.memo(Component, areEqual);