import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import Api from 'Api/strategy';
import Component from './component';
import { UPDATE_STRATEGY_LIST } from 'Redux/actionTypes/strategy/strategy';

const Container = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();
    const indexLibrary = useSelector(store => store.index.indexLibrary);
    const trackingStock = useSelector(store => store.stock.trackingStock);
    const strategies = useSelector(store => store.strategy.list);

    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

    const copyStrategy = async () => {
        const result = await Api('copy', { params: { id: id } });
        let list = [...strategies];

        if (!result) { return; }

        list.push(result);
        dispatch({
            type: UPDATE_STRATEGY_LIST,
            list: list
        });

        setIsCopyModalOpen(true);
    };

    const closeCopyModal = () => {
        setIsCopyModalOpen(false);
    };

    const showStrategyOverviewPage = () => {
        closeCopyModal();
        history.push({ pathname: '/strategyOverview' });
    };

    return (
        <Component
            id={id}
            copyStrategy={copyStrategy}
            isCopyModalOpen={isCopyModalOpen}
            closeCopyModal={closeCopyModal}
            showStrategyOverviewPage={showStrategyOverviewPage}
            indexLibrary={indexLibrary}
            trackingStock={trackingStock} />
    );
}

export default Container;