export const columnList = [
    { id: 'name', name: '策略名稱', width: 220 },
    { id: 'status_text', name: '狀態', width: 130 },
    { id: 'tracked', name: '戰情室追蹤', width: 97 },
    { id: 'year_return_rate', name: '年化報酬率', width: 100 },
    { id: 'strategy_max_up', name: '策略最大漲福', width: 100 },
    { id: 'strategy_max_down', name: '策略最大跌幅', width: 100 },
    { id: 'diff', name: '跑贏大盤', width: 110 },
    { id: 'stocks_win_rate', name: '打敗指數機率', width: 110 },
    { id: 'win_rate', name: '勝率', width: 60 },
    { id: 'win_loss_rate', name: '賺賠比', width: 61 },
    { id: 'back_year', name: '回測期間', width: 85},
    { id: 'backtest_buy_rule', name: '買進策略', width: 167 },
    { id: 'backtest_change_frequency', name: '換股頻率', width: 80 },
    { id: 'updated_at', name: '修改日期', width: 110 },
    { id: 'backtested_at', name: '上次回測日期', width: 110 },
    { id: 'created_at', name: '建立日期', width: 110 }
];;