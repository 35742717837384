import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'rsuite';

import Disclaimer from './terms/disclaimer';
import Privacy from './terms/privacy';
import ServiceTerms from './terms/serviceTerms';
import TermsModal from './component/termsModal';

import './style.scss';

const Component = () => {
    const location = useLocation();
    let [showModal, setShowModal] = useState(false);
    let [modalTerms, setModalTerms] = useState();

    if (location.pathname === '/login' || location.pathname === '/tutorial') {
        return <></>;
    }

    const showTermsModal = terms => {
        setShowModal(true);
        setModalTerms(terms);
    };

    const closeTermsModal = () => {
        setShowModal(false);
    }

    return (
        <div className="footer">
            <div>特別聲明：本站所有文字、數據、資料與服務僅供參考，絕無任何推介買賣之意。投資必有風險，投資人須謹慎、理性地判讀各種市場資訊妥善決策。</div>
            <div>
                © 2022 鉅亨易策略 |
                <Button
                    appearance='link'
                    onClick={() => showTermsModal(ServiceTerms)}>
                    {ServiceTerms.title}
                </Button>
                |
                <Button
                    appearance='link'
                    onClick={() =>showTermsModal(Privacy)}>
                    {Privacy.title}
                </Button>
                |
                <Button
                    appearance='link'
                    onClick={() => showTermsModal(Disclaimer)}>
                    {Disclaimer.title}
                </Button>
            </div>
            <TermsModal terms={modalTerms} show={showModal} onClose={closeTermsModal} />
        </div>
    )
}

export default Component;