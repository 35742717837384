// import { graphOption as defaultGraphOption } from "../constants";
import { getYAxis, percentYAxis } from "./yAxis"
import { getSeries } from "./series"
import { getExtremes } from "./extreme";

// 如果lineList沒有的則不處理也不顯示
const getLineFromList = (lineList, currentStock, currentIndex) => {
    return lineList.find(line =>
        line.stock.stock_code === currentStock.stock_code &&
        line.index.value.value === currentIndex.code &&
        line.index.range.value === currentIndex.range
    );
}
// series: 資料
// yAxis: y軸
// extremes: y軸極限值
// allIndexes: 所有指標
// rawData: 原始資料, 先以股票分類, 股票下面再以指標分類。
export const singleConfigSeriesDataGather = (rawData = [], state, graphOption) => {
    // console.log('rawData');
    if(rawData.length)
    {
        // Set "compare" in plotOptions does not work
        let compare =
            state.graphStart === 'normal' ?
                undefined : 'percent';
        // 設定時間區間

        // 集合所有資料進series
        // rawData先以股票分類, 股票下面再以指標分類。
        // 第一層先loop through股票
        // 第二層loop through指標, 蒐集與比較資料。
        let seriesAndYAxis = rawData.reduce((_seriesAndYAxis, currentStock) => {
            // 從每一個stock中蒐集index的資料
            const seriesAndYAxisPerStock = currentStock.data.reduce((_seriesAndYAxisPerStock, currentIndex) => {
                // 如果lineList沒有的則不處理也不顯示
                const line = getLineFromList(state.lineList, currentStock, currentIndex)

                if(line)
                {
                    const currentSeries = getSeries(currentIndex, currentStock, line, compare, state)

                    // Use the extremes from other stocks to compare with
                    let extremes = _seriesAndYAxis.extremes[currentIndex.code];

                    // Also consider the same index but diffrent time period
                    // data
                    if (_seriesAndYAxisPerStock.extremes[currentIndex.code]) {
                        if (extremes) {
                            extremes.max =
                                Math.max(
                                    extremes.max,
                                    _seriesAndYAxisPerStock.extremes[currentIndex.code].max);
                            extremes.min =
                                Math.min(
                                    extremes.min,
                                    _seriesAndYAxisPerStock.extremes[currentIndex.code].min);
                            extremes.percentMax =
                                Math.max(
                                    extremes.percentMax,
                                    _seriesAndYAxisPerStock.extremes[currentIndex.code].percentMax);
                            extremes.percentMin =
                                Math.max(
                                    extremes.percentMin,
                                    _seriesAndYAxisPerStock.extremes[currentIndex.code].percentMin);
                        } else {
                            extremes = _seriesAndYAxisPerStock.extremes[currentIndex.code];
                        }
                    }

                    const newExtremes =
                        getExtremes(
                            currentIndex, state.startDate, state.endDate,
                            extremes
                        );
                    
                    const currentYAxis = getYAxis(currentStock, currentIndex, state, line.visible)

                    return {
                        series: [
                            ..._seriesAndYAxisPerStock.series,
                            currentSeries
                        ],
                        yAxis: [
                            ..._seriesAndYAxisPerStock.yAxis,
                            currentYAxis
                        ],
                        extremes: {
                            ..._seriesAndYAxisPerStock.extremes,
                            [currentIndex.code]: newExtremes
                        },
                        allIndexes: _seriesAndYAxisPerStock.allIndexes.add(currentIndex.code)
                    }
                } else {
                    return _seriesAndYAxisPerStock;
                }
            }, {
                series: [],
                yAxis: [],
                extremes: {
                    ..._seriesAndYAxis.extremes
                },
                allIndexes: new Set([])
            })

            const newAccumulator = {
                series: [
                    ..._seriesAndYAxis.series,
                    ...seriesAndYAxisPerStock.series
                ],
                yAxis: [
                    ..._seriesAndYAxis.yAxis,
                    ...seriesAndYAxisPerStock.yAxis
                ],
                extremes: seriesAndYAxisPerStock.extremes,
                allIndexes: new Set([..._seriesAndYAxis.allIndexes, ...seriesAndYAxisPerStock.allIndexes])
            }
            return newAccumulator;
        }, {
            series: [],
            yAxis: [],
            extremes: {},
            allIndexes: new Set([])
        })
    
        const config = {
            ...graphOption,
            legend: {
                ...graphOption.legend,
                y: 0
            },
            series: seriesAndYAxis.series,
            // 加上最大最小值
            yAxis: state.graphStart === 'normal' ? seriesAndYAxis.yAxis.map(o => ({
                ...o,
                max: seriesAndYAxis.extremes[o.indexCode].max + 1,
                min: seriesAndYAxis.extremes[o.indexCode].min - 1
            })): [percentYAxis]
            // yAxis: state.graphStart === 'normal' ? seriesAndYAxis: [percentYAxis]
            //, 濾掉重複的指標。
            // .reduce((accumulator, current) => {
            //     if(accumulator.findIndex(o => o.indexCode === current.indexCode) === -1)
            //     {
            //         return [...accumulator, current]
            //     } else {
            //         return accumulator
            //     }
            // },[])
        }

        return [config];
    } else {
        return []
    }
};

export const configByStockSeriesDataGather = (rawData, state, graphOption) => {
    // alert('123')
    if(rawData.length)
    { 
        let compare =
            state.graphStart === 'normal' ?
                undefined : 'percent';        
       
        const seriesAndYAxis = rawData.reduce((_seriesAndYAxis, currentStock) => {
            // 從每一個stock中蒐集index的資料
            const seriesAndYAxisPerStock = currentStock.data.reduce((_seriesAndYAxisPerStock, currentIndex) => {
                // 如果lineList沒有的則不處理也不顯示
                const line = getLineFromList(state.lineList, currentStock, currentIndex)

                if(line)
                {
                    const currentSeries = getSeries(currentIndex, currentStock, line, compare, state)

                    const newExtremes = getExtremes(currentIndex, state.startDate, state.endDate, 
                        _seriesAndYAxisPerStock.extremes[currentIndex.code]
                    )
                    
                    const currentYAxis = getYAxis(currentStock, currentIndex, state, line.visible)

                    return {
                        ...graphOption,
                        legend: {
                            ...graphOption.legend,
                            y: 29
                        },
                        title: {
                            ...graphOption.title,
                            text: currentStock.stock_name,
                        },
                        series: [
                            ..._seriesAndYAxisPerStock.series,
                            currentSeries
                        ],
                        yAxis: [
                            ..._seriesAndYAxisPerStock.yAxis,
                            currentYAxis
                        ],
                        extremes: {
                            ..._seriesAndYAxisPerStock.extremes,
                            [currentIndex.code]: newExtremes
                        },
                        allIndexes: _seriesAndYAxisPerStock.allIndexes.add(currentIndex.code)
                    }
                } else {
                    return _seriesAndYAxisPerStock;
                }
            }, {
                series: [],
                yAxis: [],
                extremes: {
                    ..._seriesAndYAxis.extremes
                },
                allIndexes: new Set([])
            })
            
            if(state.graphStart === 'normal')
            {
                // 加入y軸最大最小值
                const maxMinYAxisPerStock = seriesAndYAxisPerStock.yAxis.map(o => {
                    return {
                        ...o,
                        max: seriesAndYAxisPerStock.extremes[o.indexCode].max + 1,
                        min: seriesAndYAxisPerStock.extremes[o.indexCode].min - 1,
                    }
                })
                seriesAndYAxisPerStock.yAxis = maxMinYAxisPerStock;
                
            } else {
                seriesAndYAxisPerStock.yAxis = [percentYAxis];
            }

            const newAccumulator = [
                ..._seriesAndYAxis,
                seriesAndYAxisPerStock
            ]

            return newAccumulator;
        }, [])
        return seriesAndYAxis
    } else {
        return  []
    }

};

export const configByIndexSeriesDataGather = (rawData, state, graphOption) => {

    let compare =
            state.graphStart === 'normal' ?
                undefined : 'percent';
    const _allIndexes = rawData.reduce((allIndexes, currentStock)=> {
        const currentStockIndexed = currentStock.data.reduce((indexeNames, index) => {
            return indexeNames.add(index.code)
        }, new Set([]))
        return new Set([...allIndexes, ...currentStockIndexed])
    },new Set([]))
    
    const allIndexes = Array.from(_allIndexes)
    const allIndexesData = allIndexes.reduce((_allConfigs, indexCode) => {
        const allIndexesInOneStock = rawData.reduce((_allIndexesInOneStock, currentStock) => {
            const foundIndexList = currentStock.data.filter(index => index.code === indexCode);

            if (foundIndexList) {
                return [
                    ..._allIndexesInOneStock,
                    ...foundIndexList.map(foundIndex => (
                        { stock: currentStock, index: foundIndex }
                    ))
                ];
            } else {
                return _allIndexesInOneStock;
            }
        }, [])
        // console.log(allIndexesInOneStock);
        return {
            ..._allConfigs,
            [indexCode]: allIndexesInOneStock
        }
    }, {})

    // const graphOption = { ...defaultGraphOption };

    const allConfigs = allIndexes.map(indexName => {
        const config = allIndexesData[indexName].reduce((_configPerIndex, currentIndexAndStock) => {
            const line = getLineFromList(state.lineList, currentIndexAndStock.stock, currentIndexAndStock.index)
            if(line)
            {
                const currentSeries = getSeries(currentIndexAndStock.index, currentIndexAndStock.stock, line, compare, state)
                const newExtremes = getExtremes(currentIndexAndStock.index, state.startDate, state.endDate, 
                    _configPerIndex.extremes[currentIndexAndStock.index.code]
                )
                const currentYAxis = getYAxis(currentIndexAndStock.stock, currentIndexAndStock.index, state, line.visible)
                

                return {
                    ...graphOption,
                    title: {
                        ...graphOption.title,
                        text: currentIndexAndStock.index.name
                    },
                    legend: {
                        ...graphOption.legend,
                        y: 29
                    },
                    series: [
                        ..._configPerIndex.series,
                        currentSeries
                    ],
                    yAxis: state.graphStart === 'normal' ?[
                        ..._configPerIndex.yAxis,
                        currentYAxis
                    ]: [percentYAxis],
                    extremes: {
                        ..._configPerIndex.extremes,
                        [currentIndexAndStock.index.code]: newExtremes
                    },
                    allIndexes: _configPerIndex.allIndexes.add(currentIndexAndStock.index.code)
                }
            } else {
                return _configPerIndex
            } 
        },{
            series: [],
            yAxis: [],
            extremes: {
                // ..._configPerIndex.extremes
            },
            allIndexes: new Set([])
        })
        return config
    })

    // console.log('allConfigs');
    // console.log(allConfigs);
    // console.log(allIndexes);
    // console.log(allIndexesData);
    return allConfigs
};