import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
    Icon
} from 'rsuite';
import IconAdd from 'Image/svgIcon/dashboard/add.svg';
import './style.css';

export default function AddTabModal({title, show, name, onModalHide, showAddTabModal, onNameChange, onAddTab, focusInput, showIcon, onExited})
{   
    return <>
    {
        showIcon ? 
        <Icon
            icon={IconAdd}
            size='lg'
            onClick={showAddTabModal} 
        />: null
    }
    <Modal
        className="add-tab-modal"
        show={show}
        onHide={onModalHide}
        onExited={onExited}
    >    
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
            <div className="text">為新頁籤命名</div>
            <Input
                placeholder="輸入最愛清單名稱"
                inputRef={ref => focusInput(ref)}
                value={name}
                onChange={onNameChange} />
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onAddTab}>
                確定
            </Button>
        </Modal.Footer>
    </Modal>
</>
}

export class AddTabModal1 extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            show: false
        };
        this.showAddTabModal = this.showAddTabModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show === this.props.show) { return; }

        if (this.props.show) {
            this.setState({ name: '' });
        }

        this.forceUpdate();
    }

    showAddTabModal() {
        this.setState({
            show: true
        })
    }

    onNameChange(name) {
        this.setState({ name: name });
    }

    onModalHide() {
        if (!this.props.onHide) { return; }
        this.props.onHide();
    }

    onAddTab() {
        if (this.state.name === '') {
            alert('請輸入新頁籤名稱');
            return;
        }

        if (!this.props.onAddTab) { return; }
        this.props.onAddTab(this.state.name);
    }

    focusInput(ref) {
        if (!ref) { return; }
        ref.focus();
    }

    render() {
        let title = this.props.addAfter ? '往下插入頁籤' : '插入頁籤';

        return (
            <>
                    <Icon
                        icon={IconAdd}
                        size='lg'
                        onClick={this.showAddTabModal} 
                    />
                <Modal
                    className="add-tab-modal"
                    show={this.state.show}
                    onHide={this.onModalHide}>    
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <div className="text">為新頁籤命名</div>
                        <Input
                            placeholder="輸入最愛清單名稱"
                            inputRef={ref => this.focusInput(ref)}
                            value={this.state.name}
                            onChange={value => this.onNameChange(value)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.onAddTab()}>
                            確定
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}