import * as Actions from '../actionTypes/configs';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CONFIGS: {
            return action.payload;
        }
        case Actions.UPDATE_CONFIGS: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
}