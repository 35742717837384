import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Component from './component';
import {
    deleteFavoriteGroup,
    renameFavoriteGroup
} from 'Redux/actionCreators/stock';

const Container = ({
    panel,
    currentTab,
    setCurrentTab
}) => {
    const [showAddTabModal, setShowAddTabModal] = useState(false);
    const [showRenameTabModal, setShowRenameTabModal] = useState(false);

    const panelData =
        useSelector(store =>
            store.dashboard.panelDataList.find(o => o.id === panel.id));

    // This "index" is used in removeTab() for deciding new currentTab
    const index =
        panelData?.rawData.main.tab.findIndex(x => x.id === currentTab);
    const tab = panelData?.rawData.main.tab[index];
    const { name: tabName, id: tabId } = tab ?? {};

    const menuRef = useRef();

    // Add Tab Modal
    const addTab = () => {
        menuRef.current.hide();

        setShowAddTabModal(true);
    };

    const onAddTabModalHide = () => {
        setShowAddTabModal(false);
    };

    // Remove tab
    const removeTab = () => {
        menuRef.current.hide();

        if (!window.confirm('是否刪除「' + tabName + '」頁籤?')) { return; }

        // Set new currentTab
        if (tabId === currentTab) {
            if (panelData.rawData.main.tab[index + 1]) {
                setCurrentTab(panelData.rawData.main.tab[index + 1].id)
            } else if (panelData.rawData.main.tab[index - 1]) {
                setCurrentTab(panelData.rawData.main.tab[index - 1].id)
            } else {
                setCurrentTab(null);
            }
        }

        deleteFavoriteGroup(tabId);
    };

    // Rename Tab Modal
    const renameTab = () => {
        menuRef.current.hide();
        setShowRenameTabModal(true);
    };

    const onRenameTabModalHide = () => {
        setShowRenameTabModal(false);
    };

    const onRenameTabConfirm = name => {
        renameFavoriteGroup(currentTab, name);
        onRenameTabModalHide();
    };

    return (
        <Component
            menuRef={menuRef}
            currentName={tabName}
            setCurrentTab={setCurrentTab}
            addTab={addTab}
            showAddTabModal={showAddTabModal}
            onAddTabModalHide={onAddTabModalHide}
            removeTab={removeTab}
            renameTab={renameTab}
            showRenameTabModal={showRenameTabModal}
            onRenameTabModalHide={onRenameTabModalHide}
            onRenameTabConfirm={onRenameTabConfirm} />
    );
};

export default Container;