import React from "react"
import { useDrag, useDrop } from 'react-dnd';
import {
    Icon,
    Pagination,
    SelectPicker,
    Table
} from 'rsuite';
import { ItemTypes, style, 
    DisplayLengthList
} from "./constants"
// import AppTable from "Components/appTable"
const { Column, HeaderCell, Cell } = Table;



function DraggableHeaderCell({ children, onDrag, id, ...rest }) {
    const ref = React.useRef(null);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.COLUMN,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item) {
            onDrag(item.id, id);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { id, type: ItemTypes.COLUMN },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    const isActive = canDrop && isOver;

    drag(drop(ref));

    const styles = {
        ...style,
        opacity: isDragging ? 0 : 1,
        background: isActive ? '#ddd' : null,
        marginLeft: 17
    };

    return (
        <HeaderCell {...rest} style={{ justifyContent: 'center' }}>
            <div ref={ref} style={styles}>
                {children}
            </div>
        </HeaderCell>
    );
}

function Component({ onDisplayLengthChange, data, columnList, sortColumn, sortType, sortColumnData, handleDragColumn, formatValue, displayLength, dataLength, page, onChangePage })
{
    
    return  <div className="stock-score-table">
        <SelectPicker
            id="displayLengthSelectPicker"
            data={DisplayLengthList}
            searchable={false}
            cleanable={false}
            onSelect={onDisplayLengthChange}
            defaultValue={10}
        />

        <Table
            autoHeight={true}
            headerHeight={62}
            data={data}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={(sortColumn, sortType) => sortColumnData(sortColumn, sortType)}
        >
            {
                columnList &&
                columnList.map(column => {
                    let columnName =
                        sortColumn === column.id ?
                            <div className="blue">{column.name}</div> :
                            column.name;

                    return (
                        <Column
                            width={column.width}
                            key={column.id}
                            sortable={true}
                            align={column.id !== 'stock_code' ? 'center' : 'left'}
                        >
                            {
                                column.id !== 'stock_code' ?
                                    <DraggableHeaderCell
                                        onDrag={handleDragColumn}
                                        id={column.id}>
                                        <Icon icon="arrows-h" />
                                        {columnName}
                                    </DraggableHeaderCell>
                                    :
                                    <HeaderCell style={style}>
                                        {columnName}
                                    </HeaderCell>
                            }
                            <Cell dataKey={column.id}>
                                {
                                    rowData => formatValue(column.id, rowData[column.id])
                                }
                            </Cell>
                        </Column>
                    );
                })
            }
        </Table>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
            <Pagination
                first prev next last ellipsis
                pages={Math.ceil(dataLength / displayLength)}
                maxButtons={10}
                activePage={page}
                onSelect={page => onChangePage(page)}
            />
        </div>
    </div>
}
export default Component;