import React from 'react';
import StockPicker from 'Components/stockPicker/index.js';
import IndexPicker from 'Components/indexPicker';
import IndexUtil from 'Src/indexUtil';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { isEqual } from  "lodash";
import {
    Checkbox,
    Icon,
    Button,
    Radio,
    RadioGroup,
    DatePicker,
} from 'rsuite';

function Component({ 
    stockPool,
    state,
    setIndexVisible,
    setStockVisible,
    selectedStock,
    setLineVisible,
    chartRefList,
    onStockSelected,
    deleteStock,
    indexLibrary,
    onIndexSelected, 
    selectedIndex, 
    deleteIndex, 
    graphStart, 
    graphType, 
    setGraphStart,
    setGraphType, 
    lineList,
    setDateRange,
    chartOptions,
    setStartDate,
    setEndDate
 })
{
    // console.log('chartOptions');
    // console.log(chartOptions);
    return  stockPool.length ? <div>
        <div style={{ display: 'flex', marginBottom: 7 }}>
            <div className="page-title">圖表大師</div>
        </div>
        <hr />
        <div className="title">數據分析 / 比較工具</div>
        <div className="block-1" style={{ display: 'flex' }}>
        <div className="block-2 graph-setting">
                <div className="setting-title">比較對象</div>
                <StockPicker
                    clearOnSelect
                    data={stockPool}
                    onSelect={onStockSelected} 
                />
                <div className="row-container">
                    {
                        selectedStock.map((stock, index) =>
                        <div 
                            key={index}
                            className="row"
                        >
                            <Checkbox
                                checked={stock.visible}
                                onChange={(_, checked) => setStockVisible(index, checked)} 
                            />
                            <div className="row-name">{stock.label}</div>
                            <div className="row-icon">
                                <Button onClick={() => deleteStock(index, stock.stock_code)}>
                                    <Icon icon="close" />
                                </Button>
                            </div>
                        </div>)
                    }
                </div>
                <hr />
                <div className="setting-title">指標</div>
                <IndexPicker
                    hideTime={true}
                    disableInput={true}
                    clearOnSelect={true}
                    indexLibrary={indexLibrary ? indexLibrary: []}
                    useGraphText={true}
                    onChange={onIndexSelected} 
                />
                <div className="row-container">
                    {
                        selectedIndex.map((index, aryIndex) =>
                        <div 
                            key={aryIndex} 
                            className="row"
                        >
                            <Checkbox
                                checked={index.visible}
                                onChange={(_, checked) => setIndexVisible(aryIndex, checked)}
                            />
                            <div className="row-name">{IndexUtil.getGraphIndexText(index)}</div>
                            <div className="row-icon">
                                <Button onClick={() => deleteIndex(index)}>
                                    <Icon icon="close" />
                                </Button>
                            </div>
                        </div>
                    )
                    }
                </div>
                <hr />
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="setting-title">圖表格式</div>
                        <RadioGroup
                            value={graphStart}
                            onChange={setGraphStart}
                        >
                            <Radio value='normal'>一般</Radio>
                            <Radio value='sameStart'>同起始點</Radio>
                        </RadioGroup>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="setting-title">圖表數量</div>
                        <RadioGroup
                            value={graphType}
                            onChange={setGraphType}
                        >
                            <Radio value='one'>單一圖表</Radio>
                            <Radio
                                value='byStock'
                                disabled={selectedStock.length < 2}>
                                多圖表(依標的)
                            </Radio>
                            <Radio
                                value='byIndex'
                                disabled={selectedIndex.length < 2}>
                                多圖表(依指標)
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>
                <hr />
                <div className="setting-title">資料</div>
                <div>
                    {
                        lineList.map((line, index) =>
                            <div
                                key={index}
                                className="row"
                            >
                                <Checkbox
                                    checked={line.visible}
                                    onChange={(_, checked) => setLineVisible(index, checked)} 
                                />
                                <div
                                    className="legend-symbol"
                                    style={{ background: line.color }} />
                                <div className="row-name">
                                    {line.stock.label + ' ' + IndexUtil.getGraphIndexText(line.index)}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="block-2 graph-area">
                <div style={{ display: 'flex' }}>
                    <RadioGroup
                        className="tab"
                        inline
                        appearance="picker"
                        value={state.dateRange}
                        onChange={setDateRange}
                    >
                        <Radio value="7D">7日</Radio>
                        <Radio value="15D">15日</Radio>
                        <Radio value="1M">1月</Radio>
                        <Radio value="3M">3月</Radio>
                        <Radio value="6M">6月</Radio>
                        <Radio value="start">年初自今</Radio>
                        <Radio value="1Y">1年</Radio>
                        <Radio value="3Y">3年</Radio>
                        <Radio value="5Y">5年</Radio>
                        <Radio value="10Y">10年</Radio>
                        <Radio value="all">全部</Radio>
                    </RadioGroup>
                    <div className="customDateRange">
                        <span>自訂期間 </span>
                        <DatePicker
                            placement="autoVerticalEnd"
                            cleanable={false}
                            format="YYYY/MM/DD"
                            value={state.startDate}
                            onChange={date => setStartDate(date)}
                        />
                        <span> ~ </span>
                        <DatePicker
                            placement="autoVerticalEnd"
                            cleanable={false}
                            format="YYYY/MM/DD"
                            value={state.endDate}
                            onChange={date => setEndDate(date)}
                        />
                    </div>
                </div>
                {
                    chartOptions.length === 0 &&
                    <div className="hint">請輸入股票及指標</div>
                }
                {
                    chartOptions.map((o, i) => {
                        return <HighchartsReact
                                    key={i}
                                    ref={ref => chartRefList[i] = ref}
                                    highcharts={Highcharts}
                                    constructorType={'stockChart'}
                                    options={o}
                                />
                    })
                }
            </div>
        </div>
    </div>: null
}

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.selectedStock, nextProps.selectedStock) ||
        !isEqual(prevProps.stockPool, nextProps.stockPool) ||
        !isEqual(prevProps.indexLibrary, nextProps.indexLibrary) ||
        !isEqual(prevProps.selectedIndex, nextProps.selectedIndex) ||
        !isEqual(prevProps.graphStart, nextProps.graphStart) ||
        !isEqual(prevProps.graphType, nextProps.graphType) ||
        !isEqual(prevProps.lineList, nextProps.lineList) ||
        !isEqual(prevProps.chartOptions, nextProps.chartOptions) ||
        !isEqual(prevProps.startDate, nextProps.startDate) ||
        !isEqual(prevProps.endDate, nextProps.endDate)
    )
    {
        return false
    }else {
        return  true
    }
}
export default React.memo(Component,
    areEqual
);