import React, { useCallback, useEffect, useState } from "react"
import Component1 from "./component"

import IndexUtil from 'Src/indexUtil';
import { SET_DESIGN_STRATEGY } from "Redux/actionTypes/strategy/strategyDesign"
// import './stockFilterPanel.css';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { deepEqual } from "Util/deepEqual.js"
import { getIndexUnit } from 'Util/getIndexUnit';
import { Operator } from 'Src/constant/operator';
import { FETCH_INDUSTRIES } from 'Redux/saga/actionTypes/industries';

function Container({
    onNameChange,
    onDescriptionChange,
    onIndustryFilterChange,
    onConstraintFilterChange, 
})
{
    const dispatch = useDispatch()
    const [state, setState] = useState({
        showStockModal: false,
        showIndustryModal: false,
        showConstraintModal: false
    })

    const stockpool = useSelector(store => store.stock.pool);
    const strategyDesign = useSelector(store =>  store.strategyDesign);
    const industryData = useSelector(store => store.industries.industryData, deepEqual);
    const indexLibrary = useSelector(store => store.index.indexLibrary, deepEqual);

    useEffect(() => {
        if (industryData) { return; }

        dispatch({ type: FETCH_INDUSTRIES });
    }, [industryData, dispatch]);

    // Stock modal
    const openStockModal = isIncludeMode => {
        let key = isIncludeMode ? 'include' : 'exclude';
        let selectedStockFilter = strategyDesign.industryFilter[key].stocks;

        setState({
            ...state,
            isStockIncludeMode: isIncludeMode,
            showStockModal: true,
            selectedStockFilter
        });
    }

    const onStockModalConfirm = list => {
        let industryFilter = { ...strategyDesign.industryFilter };

        let key = state.isStockIncludeMode ? 'include' : 'exclude';
        industryFilter[key].stocks = list;

        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                ...industryFilter
            }
        });

        closeStockModal();
    }

    const closeStockModal = () => {
        setState({ ...state, showStockModal: false });
    }

    const deleteStockFilter = (stockCode, isIncludeMode) => {
        let list = { ...strategyDesign.industryFilter };
        let key = isIncludeMode ? 'include' : 'exclude';

        list[key].stocks = list[key].stocks.filter(x => x !== stockCode);

        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                industryFilter: list
            }
        });
    }

    const deleteIndustryFilter = (index, isIncludeMode, type )=> {
        let list = _.cloneDeep(strategyDesign.industryFilter);

        list[isIncludeMode][type].splice(index, 1);
        
        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                industryFilter: list
            }
        })
        // this.onIndustryFilterListChange(list);
    }

    const onIndustryModalCancel = () => {
        setState({
            ...state,
            showIndustryModal: false
        })
    }

    const updateIndustryFilter = (values) => {
        let list = _.cloneDeep(strategyDesign.industryFilter);
        let key = state.isIndustryIncludeMode ? 'include' : 'exclude';

        list[key][state.industryType] = values;

        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                industryFilter: list
            }
        })
    }

    const onIndustryModalConfirm = (selectedList) => {
        updateIndustryFilter(selectedList);
        
        setState({ ...state, showIndustryModal: false });
    }

    const openIndustryModal = (type, isIncludeMode) => {
        let key = isIncludeMode ? 'include' : 'exclude';
        let selectedIndustryFilter = strategyDesign.industryFilter[key][type];

        setState({
            ...state,
            showIndustryModal: true,
            isIndustryIncludeMode: isIncludeMode,
            industryType: type,
            selectedIndustryFilter: selectedIndustryFilter
        });
    }

    const getIndustryFilterList = (isIncludeMode) => {
        if (!strategyDesign || !strategyDesign.industryFilter) { return; }

        const list = strategyDesign.industryFilter[isIncludeMode];

        const listIndustries =
            list.industries.map(
                (x, i) => ({
                    index: i,
                    name: x,
                    isIncludeMode: isIncludeMode, 
                    type: 'industries'
                })
            );

        const listConcepts =
            list.concepts.map(
                (x, i) => ({
                    index: i,
                    name: x,
                    isIncludeMode: isIncludeMode, 
                    type: 'concepts'
                })
            );

        const listConglomerates =
            list.conglomerates.map(
                (x, i) => ({
                    index: i,
                    name: x,
                    isIncludeMode: isIncludeMode, 
                    type: 'conglomerates'
                })
            );

        return [...listIndustries, ...listConcepts, ...listConglomerates];
    }
    
    const openConstraintModal = (isIncludeMode) => {
        let key = isIncludeMode ? 'include' : 'exclude';

        setState({
            ...state,
            showConstraintModal: true,
            isConstraintIncludeMode: isIncludeMode,
            selectedConstraintFilter: strategyDesign.constraintFilter[key]
        });
    }
  
    const onConstraintModalConfirm = (values) => {
        let list = _.cloneDeep(strategyDesign.constraintFilter);
        let key = state.isConstraintIncludeMode ? 'include' : 'exclude';

        list[key] = values;

        setState({
            ...state,

            showConstraintModal: false
        });

        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                constraintFilter: list
            }
        })

        // this.onConstraintFilterListChange(list);
    }

    const getConstraintFilterList = (isIncludeMode) => {
        const list = strategyDesign.constraintFilter[isIncludeMode].map(
            (constraint, rowIndex) => {
                if (
                    !constraint.index || !constraint.index.value ||
                    !constraint.index.value.label || !constraint.operator
                ) {
                    return null;
                }
        
                let text = IndexUtil.getIndexText(constraint.index);
                let unit = getIndexUnit(constraint.index);
        
                if (constraint.operator === 'between') {
                    if (!constraint.rangeFrom || !constraint.rangeTo) { return null; }
        
                    text +=
                        ' 介於 ' + constraint.rangeFrom + ' ~ ' + constraint.rangeTo +
                        unit;
                } else {
                    let operatorText = getOperatorText(constraint.operator);
        
                    if (operatorText === '') { return null; }
        
                    if (constraint.operator.includes('Index')) {
                        if (!constraint.index2) return null;
        
                        let index2Text = IndexUtil.getIndexText(constraint.index2);
                        text += operatorText + index2Text;
                    } else if (
                        constraint.operator === 'increase' ||
                        constraint.operator === 'decrease'
                    ) {
                        text = text.replace('皆...', '') + operatorText;
                    } else {
                        if (!constraint.value) { return null; }

                        text +=
                            !constraint.operator.includes('rank') ?
                                operatorText + constraint.value + unit :
                                operatorText + constraint.value + '%';
                    }
                }

                return {
                    rowIndex: rowIndex,
                    text: text,
                    isIncludeMode: isIncludeMode
                }
            }
        );
        
        return list;
    }

    const getOperatorText = useCallback((operand) => {
        if (operand.includes('greaterEqual')) {
            return ' >= ';
        } else if (operand.includes('lessEqual')) {
            return ' <= ';
        } else if (operand.includes('greater')) {
            return ' > ';
        } else if (operand.includes('equal')) {
            return ' = ';
        } else if (operand.includes('less')) {
            return ' < ';
        } else if (operand === 'increase') {
            return '逐漸上升';
        } else if (operand === 'decrease') {
            return '逐漸下降';
        } else if (operand && operand.startsWith('rank')) {
            return ' ' + (Operator.find(x => x.value === operand)?.label) + ' ';
        }

        return '';
    }, [])

    const deleteIndexConstraint = (rowIndex, isIncludeMode) => {
        let list = _.cloneDeep(strategyDesign.constraintFilter);

        list[isIncludeMode].splice(rowIndex, 1);

        // Keep at least 1 row in the list
        if (list[isIncludeMode].length === 0) {
            list[isIncludeMode].push({ index: null, operator: 'greater' });
        }

        dispatch({
            type: SET_DESIGN_STRATEGY,
            payload: {
                ...strategyDesign,
                constraintFilter: list
            }
        })
        // this.onConstraintFilterListChange(list);
    }

    const onIndexConstraintModalCancel = () => {
        setState({
            ...state,
            showConstraintModal: false,
        })
    }

    let filterStatus = '支數計算中';
    if (strategyDesign.stockList && strategyDesign.stockList.ready) {
        filterStatus =
        strategyDesign.stockList.total_filtered + '/' +
        strategyDesign.stockList.total + '檔';
    }

    let stockFilterInclude =
        strategyDesign.industryFilter.include.stocks ?
            strategyDesign.industryFilter.include.stocks.map(x =>
                stockpool.find(s => s.stock_code === x) ?? {}
            ) : [];

    let stockFilterExclude =
        strategyDesign.industryFilter.exclude.stocks ?
            strategyDesign.industryFilter.exclude.stocks.map(x =>
                stockpool.find(s => s.stock_code === x) ?? {}
            ) : [];

    return <Component1 
        name={strategyDesign.strategyName}
        state={state}
        industryData={industryData}
        description={strategyDesign.strategyDescription}
        strategyDesign={strategyDesign}
        onNameChange={onNameChange}
        openIndustryModal={openIndustryModal}
        onDescriptionChange={onDescriptionChange}
        onIndustryModalConfirm={onIndustryModalConfirm}
        onIndustryModalCancel={onIndustryModalCancel}
        indexLibrary={indexLibrary ? indexLibrary: []}
        openConstraintModal={openConstraintModal}
        onConstraintModalConfirm={onConstraintModalConfirm}
        constraintFilterListInclude={getConstraintFilterList("include")}
        constraintFilterListExclude={getConstraintFilterList("exclude")}
        onIndexConstraintModalCancel={onIndexConstraintModalCancel}
        industryFilterListInclude={getIndustryFilterList("include")}
        industryFilterListExclude={getIndustryFilterList("exclude")}
        deleteIndustryFilter={deleteIndustryFilter}
        deleteIndexConstraint={deleteIndexConstraint}
        filterStatus={filterStatus}
        deleteStockFilter={deleteStockFilter}
        stockFilterInclude={stockFilterInclude}
        stockFilterExclude={stockFilterExclude}
        openStockModal={openStockModal}
        onStockModalConfirm={onStockModalConfirm}
        closeStockModal={closeStockModal}
    />
}


export default Container;