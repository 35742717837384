import React from 'react';
import { Cookies } from 'react-cookie';
import { Button, Checkbox, Modal } from 'rsuite';

import './style.scss';

const cookies = new Cookies();

const setDismissMobileAlertModal = checked => {
    cookies.set('dismissMobileAlertModal', checked);
};

const Component = ({isOpen, onClose}) => {
    return (
        <Modal
            className="mobile-alert-modal"
            show={isOpen}
            size="xs"
            onEntered={() => setDismissMobileAlertModal(true)}
            onHide={onClose}>
            <Modal.Header>
                <Modal.Title>電腦使用體驗更佳</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <div>建議使用電腦開啟本網站，享受更好的體驗。</div>
                <Checkbox
                    className="mobile-alert-checkbox"
                    defaultChecked={true}
                    onChange={(_, checked) => setDismissMobileAlertModal(checked)}>
                    下次不再顯示
                </Checkbox>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>確定</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;