import * as Actions from "../actionTypes/stock";

const initialState = {
    pool: [],
    trackingStock: null,
    loaded: false,
    analysisList: [],
    selectedStockId: 1101,
    analysisListSet: false,
    stockPrice: null
}
export default function reducer(state=initialState, action)
{
    switch(action.type) {
        case Actions.SET_STOCK_POOL: {
            return {
                ...state,
                pool: action.pool
            }
        }
        case Actions.SET_ANALYSIS_LIST: {
            return {
                ...state,
                analysisList: action.analysisList,
                analysisListSet: true
            }
        }
        case Actions.CLEAR_SPECIFIC_GROUP_IN_TRACKING_STOCK: {
            const newTrackingStock = state.trackingStock.map(o => {
                if(o.id === action.groupId)
                {
                    return {
                        ...o,
                        items: []
                    }
                } else {
                    return o
                }
            })
            return {
                ...state,
                trackingStock: newTrackingStock
            }
        }
        case Actions.SET_STOCK_PRICE: {
            let data = {
                code: action.stockPrice.main.stock.stock_code,
                name: action.stockPrice.main.stock.stock_name
            }

            action.stockPrice.main.data.forEach(row => {
                data[row.code] = row;
            })

            return {
                ...state,
                stockPrice: data
            }
        }
        case Actions.UPDATE_TRACKING_STOCK: {
            return {
                ...state,
                loaded: true,
                trackingStock: action.trackingStock
            }
        }
        // case Actions.INSERT_TRACKING_STOCK: {
        //     return {
        //         ...state,
        //         loaded: true,
        //         trackingStock: action.trackingStock
        //     }
        // }
        case Actions.INSERT_FAVORITE_GROUP: {
            let trackingStock = [...state.trackingStock];
            const newGroup = {
                ...action.group,
                items: []
            }
            if(action.afterGroupId)
            {
                const groupIndex = state.trackingStock.findIndex(o => o.id === action.afterGroupId*1)
                trackingStock.splice(groupIndex + 1, 0, newGroup)
            } else {
                trackingStock = [
                    ...trackingStock,
                    newGroup
                ]
            }
            return {
                ...state,
                trackingStock: trackingStock
            }
        }
        case Actions.REMOVE_FAVORITE_GROUP: {
            const id = action.id*1
            const trackingStock = state.trackingStock.filter(o => {
                return o.id !== id;
            })
            return {
                ...state,
                trackingStock: trackingStock
            }
        }
        case Actions.RENAME_FAVORITE_GROUP: {
            const trackingStock = [...state.trackingStock];
            const group = trackingStock.find(x => x.id === action.id);
            group.name = action.name;

            return {
                ...state,
                trackingStock
            };
        }        
        case Actions.REMOVE_TRACKING_STOCK: {
            const newTrackingStock = state.trackingStock.map((group) => {
                if(group.id === action.groupId*1)
                {
                    const newItems = group.items.filter(o => o !== action.stockId)
                    const newGroup = {
                        ...group,
                        items: newItems
                    }
                    return newGroup;
                } else {
                    return group;
                }
            })
            return {
                ...state,
                trackingStock: newTrackingStock
            }
        }

        case Actions.CLEAR_STOCK_PRICE: {
            return {
                ...state,
                stockPrice: null
            }
        }
        default: {
            return state;
        }
    }
};