import store from '../store';
import { FETCH_CONFIGS, SAVE_CONFIGS } from '../saga/actionTypes/configs';

const getConfigs = () => {
    store.dispatch({ type: FETCH_CONFIGS });
};

const saveConfigs = payload => {
    store.dispatch({ type: SAVE_CONFIGS, payload });
}

export { getConfigs, saveConfigs };