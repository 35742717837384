import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import DashboardLayoutModal from "../layoutModal";
import { showNotice } from 'Redux/actionCreators/notice';
import * as DashboardSagaActions from "Redux/saga/actionTypes/dashboard"

/** 
 * 戰情室看板編輯、新增
 * @param {booloean} show - 是否視窗顯示
 * @param {booloean} isCreateMode - 是否新建模式
 * @param {object} layout - 版型
 * @param {object} singles - 所有可供使用的singles
 * @param {object} displaySingles - 左欄顯示的可選single
 * @param {function} onHide - 關閉視窗
 * @summary 戰情室看板編輯、新增。
 * @memberof module:DashboardLayoutModal
 * @description show開啟後先用props設定一次id跟layout，使之內容與dashboardPage選擇的一致。
 * */

function Container({
    show,
    isCreateMode,
    layout,
    singles,
    onHide,
})
{
    const dispatch = useDispatch()
    const [state, setState] = useState({
        name: '',
        searchText: '',
        layout: [],
        displaySingles: []
    })
    /** props建立state layout */
    useEffect(() => {
        // console.log(state.name);
        setState({
            name: '',
            searchText: '',
            layout: layout,
            displaySingles: singles
        })
    },[layout, show, singles])

    /**
     *  @param {string} value - input看板名稱
     *  @description 設定此元件狀態: input看板名稱
     */
    const onNameChange = (value) => {
        setState({ 
            ...state,
            name: value
        });
    }
    /**
     * 
     * @param {object} value - 搜尋字串
     * @description 設定元件狀態: 搜尋字串與過濾含single的name含有搜尋字串的single
     */
    const onSearchTextChange = (value) => {
        if(value === '')
        {
            setState({
                ...state,
                searchText: '',
                displaySingles: singles
            })
        } else {
            const displaySingles = singles.filter(single => {
                return single.name.includes(value);
            })
            setState({
                ...state,
                displaySingles: displaySingles,
                searchText: value
            });
        }
    }
    
    /**
     * @param {object} e - event物件
     * @description 設定元件狀態: 用id檢查增減single
     * */
    const toggleSingle = (e) => {
        const id = e.target.dataset.id;
        let layout = [
            ...state.layout
        ]

        if (layout.some(panel => panel.i === id)) {
            // The panel already in the list. Remove it.
            layout = layout.filter(panel => panel.i !== id);
        } else {
            // The panel is not in the list. Add it.
            const _single = singles.find(o => o.id === id)
            layout.push({
                i: id,
                x: 0,
                y: Infinity,
                w: _single.width,
                h: _single.height
            });
        }
        onLayoutChange(layout);
    }

    /**
     * 
     * @param {object} layout - 版型
     * @description 設定元件狀態: layout
     */
    const onLayoutChange = layout => {
        setState({ 
            ...state,
            layout: layout
        });
    }

    /**
     * @param {string} id - single的id
     * @description 檢查是否為被選入layout的single。
     *  */
    const checkIfSelected = (singleId) => {
        return state.layout.some(panel => panel.i === singleId)
    }

    /**
     * @memberof #DashboardLayoutModalContainer
     * @description 確認送出新建或編輯, 用isCreateMode區分 
     * */
    const _onConfirm = () => {
        if (isCreateMode) {
            if (state.name.trim() === '') {
                showNotice("通知", "請輸入戰情室名稱")
                return;
            }
            // if (!this.props.onCreateConfirm) { return; }
            dispatch({
                type: DashboardSagaActions.CREATE_DASHBOARD,
                name: state.name,
                layout: state.layout
            })
        } else {
            // if (!this.props.onConfirm) { return; }
            dispatch({
                type: DashboardSagaActions.SAVE_DASHBOARD,
                layout: state.layout
            })
        }
    }

    return <DashboardLayoutModal 
        show={show}
        isCreateMode={isCreateMode}
        name={state.name}
        layout={state.layout}
        searchText={state.searchText}
        singles={state.displaySingles}
        onHide={onHide}
        onConfirm={_onConfirm}
        onNameChange={onNameChange}
        onLayoutChange={onLayoutChange}
        onSearchTextChange={onSearchTextChange}
        toggleSingle={toggleSingle}
        checkIfSelected={checkIfSelected}
    /> 
};

export default Container;