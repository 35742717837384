import React, { Component } from 'react';
import { Button } from 'rsuite';

import API from 'Src/api';
import CurrentStockTable from 'Components/viewSharedStrategy/currentStockTable';
import ReturnRatePanel from 'Components/viewSharedStrategy/returnRatePanel';
import ShowPageModal from 'Components/showPageModal';
import StockHoldingsTable from 'Components/stockHoldingsTable';
import StrategyPanel from 'Components/backtestResult/strategyPanel';

var _ = require('lodash');

export default class ViewSharedStrategyPage extends Component {
    state = {};

    async componentDidMount() {
        if (this.props.trackingStock) {
            this.setState({ trackingStock: this.props.trackingStock });
        }

        if (this.props.indexLibrary) {
            this.updateBacktestResult(this.props.indexLibrary);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trackingStock !== this.props.trackingStock) {
            this.setState({ trackingStock: this.props.trackingStock });
        }

        if (prevProps.indexLibrary !== this.props.indexLibrary) {
            this.updateBacktestResult(this.props.indexLibrary);
        }
    }

    async updateBacktestResult(indexLibrary) {
        if (!indexLibrary) { return; }

        let result = await API.getBacktestResult(this.props.id, indexLibrary);

        if (!result) {
            alert('取得回測結果失敗');
            return;
        }

        this.setState({ backtestResult: result });
    }

    onTrackingStockChange(groupId, stockId, tracked) {
        let trackingStock = _.cloneDeep(this.state.trackingStock);
        let group = trackingStock.find(group => group.id === groupId);

        if (!group) { return; }

        if (tracked) {
            group.items.push(stockId);
        } else {
            group.items = group.items.filter(x => x !== stockId);
        }

        this.setState({ trackingStock });
    }

    render() {
        let pageClassName = 'view-shared-strategy';

        if (this.props.isFreeUser) {
            pageClassName += ' free';
        }

        return (
            <div className={pageClassName}>
                <div style={{ display: 'flex', marginBottom: 7 }}>
                    <div className="page-title">知名策略</div>
                    <div className="page-buttons">
                        {
                            !this.state.backtestResult?.owned &&
                            this.state.backtestResult?.published &&
                            <>
                                <Button onClick={this.props.trackSharedStrategy}>
                                    {this.props.tracked ? '取消' : ''}追蹤策略
                                </Button>
                                <Button onClick={this.props.copyStrategy}>
                                    複製/修改策略
                                </Button>
                            </>
                        }
                    </div>
                </div>
                <hr />
                <div className="title">策略名片</div>
                <div className="block">
                    <StrategyPanel
                        industries={this.state.backtestResult?.industries}
                        selections={this.state.backtestResult?.selections}
                        totalFiltered={this.state.backtestResult?.total_filtered}
                        scores={this.state.backtestResult?.scores}
                        backtest={this.state.backtestResult?.backtest}
                        data={this.state.backtestResult ? this.state.backtestResult.data[0].display : null} />
                </div>
                <div className="title">策略報酬率</div>
                <div className="block">
                    <ReturnRatePanel
                        data={this.state.backtestResult ? this.state.backtestResult.data[1].display : null}
                        performanceData={this.state.backtestResult ? this.state.backtestResult.data[2] : null} />
                </div>
                <div className="title">最新持股</div>
                <div className="block">
                    <CurrentStockTable
                        columns={this.state.backtestResult?.data[6].main.columns}
                        data={this.state.backtestResult?.data[6].main.data}
                        sortColumn='current_score'
                        sortType='desc'
                        displayLength={20} />
                </div>
                <div className="title">過去持股</div>
                <div className="block">
                    <StockHoldingsTable
                        data={this.state.backtestResult ? this.state.backtestResult.data[5].main : null}
                        trackingStock={this.state.trackingStock}
                        onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                </div>
                <ShowPageModal
                    isOpen={this.props.isTrackModalOpen}
                    title="追蹤策略成功"
                    text="可前往『我的戰情室』>>『追蹤的投資策略』查看"
                    closeModal={this.props.closeTrackModal}
                    showPage={this.props.showDashboardPage} />
                <ShowPageModal
                    isOpen={this.props.isCopyModalOpen}
                    title="複製策略成功"
                    text="可前往『我的策略/回測』，查看或修改"
                    closeModal={this.props.closeCopyModal}
                    showPage={this.props.showStrategyOverviewPage} />
            </div>
        );
    }
}