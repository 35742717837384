import React from 'react';
import { RadioGroup, Radio } from 'rsuite';

export default function TabList({ list, value, handleOnChange, type }){
    return <div className={`grid-tab-${type}`}>
        <RadioGroup
            className={type}
            inline
            appearance="picker"
            value={value ? value: null}
            onChange={handleOnChange}
        >
            {
                list.map((o, i) => {
                    const name = o.name;
                    return (
                        <Radio key={i} value={o.id ? o.id: o.value}>{name}</Radio>
                    );
                })
            }
        </RadioGroup>
    </div>
};