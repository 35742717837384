import React, { useEffect, useRef } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import './style.scss';

const graphOptions = {
    chart: {
        backgroundColor: 'transparent',
        type: 'column',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 1
    },
    credits: {
        enabled: false
    },
    colors: ['rgba(0, 221, 255, 0.7)', 'rgba(242, 150, 0, 0.8)'],
    plotOptions: {
        column: {
            borderColor: 'transparent',
            maxPointWidth: 14
        }
    },
    title: null,
    legend: {
        enabled: false
    },
    xAxis: {
        labels: {
            style: { color: '#fff', fontSize: '12px' }
        },
        type: 'category'
    },
    yAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.3)',
        opposite: true,
        labels: {
            format: '{value}%',
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            x: 7,
            y: 0
        },
        title: null
    },
    series: [
        {
            name: '投資策略',
            data: []
        },
        {
            name: '大盤',
            data: []
        }
    ]
};

let options = {};

const updateGraph = (ref, data) => {
    options.xAxis.categories = data ? Object.keys(data.data[0].data) : [];

    options.series[0].name = data?.data[0].name;
    options.series[0].data = data ? Object.values(data.data[0].data) : [];

    options.series[1].name = data?.data[1].name;
    options.series[1].data = data ? Object.values(data.data[1].data) : [];

    if (!ref || !ref.current || !ref.current.chart) { return; }
    ref.current.chart.update(options);
}

const Component = props => {
    let ref = useRef();

    useEffect(() => {
        options = _.cloneDeep(graphOptions);
    }, []);

    useEffect(() => {
        updateGraph(ref, props.data);
    }, [props.data]);

    return (
        <div className="profit-loss-ratio-graph">
            <div className="graph-title">
                {props.data?.name}
                <div className="legend">
                    <div className="legend-icon blue" />
                    {props.data?.data[0].name}
                    <div className="legend-icon orange" />
                    {props.data?.data[1].name}
                </div>
            </div>
            <HighchartsReact
                ref={ref}
                highcharts={Highcharts}
                options={options} />
        </div>
    )
}

export default Component;