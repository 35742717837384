import React from 'react';
import { Button, Modal } from 'rsuite';

const Component = ({title, text, isOpen, closeModal, showPage}) => {
    return (
        <Modal
            className="track-shared-strategy-modal"
            show={isOpen}
            onHide={closeModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <hr/>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-grey" onClick={closeModal}>
                    留在此頁
                </Button>
                <Button onClick={showPage}>立即查看</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default Component;