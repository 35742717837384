import React from "react";
import {
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { routes } from "./routes";
import history from "Src/history";
import { CLEAR_DASHBOARD } from "Redux/actionTypes/dashboard/dashboard";
import { cookies } from 'Https/fetch';

export default function Routes() {
	const dispatch = useDispatch()
	history.listen((location, action) => {		
		if(location.prevPath !== location.pathname)
		{
			dispatch({type:　CLEAR_DASHBOARD})
		}
	})

	// Redirect to login page if not logged in
	const accessToken = cookies.get('accessToken');
	if (
		(!accessToken || accessToken === '') &&
		!history.location.pathname.startsWith('/login') &&
		!history.location.pathname.startsWith('/tutorial') &&
		!history.location.pathname.startsWith('/backtestResult')
	) {
		history.push('/login');
	}

	return (
		<Switch>
			<Route exact path="/">
				<Redirect to="/dashboard" />
			</Route>
			{
				accessToken && accessToken !== '' &&
				<Route path="/login">
					<Redirect to="/dashboard" />
				</Route>
			}
			{routes.map((route, i) => (
				<RouteWithSubRoutes key={i} {...route} />
			))}
			<Route>
				<Redirect to="/dashboard" />
			</Route>
		</Switch>
	);
}
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			render={(props) => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}
