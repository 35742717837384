export const BuyRule = [
    { label: '平均資金', value: 'average' },
    { label: '加權平均', value: 'weightedAverage' },
    { label: '自訂權重', value: 'custom' }
];

export const BuyRank = [
    { label: '買進評分前5名', value: 5 },
    { label: '買進評分前10名', value: 10 },
    { label: '買進評分前15名', value: 15 },
    { label: '買進評分前20名', value: 20 },
    { label: '買進評分前25名', value: 25 },
    { label: '買進評分前30名', value: 30 },
    { label: '買進評分前50名', value: 50 }
];

export const Timespan = [
    { label: '自訂期間', value: 'custom' },
    { label: '10年', value: '10' },
    { label: '9年', value: '9' },
    { label: '8年', value: '8' },
    { label: '7年', value: '7' },
    { label: '6年', value: '6' },
    { label: '5年', value: '5' },
    { label: '4年', value: '4' },
    { label: '3年', value: '3' },
    { label: '2年', value: '2' },
    { label: '1年', value: '1' }
];

export const TimespanYear = [
    { label: '2010', value: 2010 },
    { label: '2011', value: 2011 },
    { label: '2012', value: 2012 },
    { label: '2013', value: 2013 },
    { label: '2014', value: 2014 },
    { label: '2015', value: 2015 },
    { label: '2016', value: 2016 },
    { label: '2017', value: 2017 },
    { label: '2018', value: 2018 },
    { label: '2019', value: 2019 },
    { label: '2020', value: 2020 },
    { label: '2021', value: 2021 }
];

export const Fee = [
    { label: '原價 (0.1425%)', value: 100 },
    { label: '9折 (0.12825%)', value: 90 },
    { label: '8折 (0.114%)', value: 80 },
    { label: '7折 (0.09975%)', value: 70 },
    { label: '6.5折 (0.092625%)', value: 65 },
    { label: '6折 (0.0855%)', value: 60 },
    { label: '5.6折 (0.0798%)', value: 56 },
    { label: '5折 (0.7125%)', value: 50 },
    { label: '4折 (0.57%)', value: 40 },
    { label: '3.5折 (0.049875%)', value: 35 },
    { label: '3折 (0.04275%)', value: 30 },
    { label: '2.8折 (0.0399%)', value: 28 },
    { label: '2折 (0.285%)', value: 20 },
    { label: '1折 (0.01425%)', value: 10 },
    { label: '零手續費', value: 0 }
];

export const ChangeFreqency = [
    { label: '每日', value: '1d' },
    { label: '每週', value: '5d' },
    { label: '兩週', value: '10d' },
    { label: '每個月', value: '1m' },
    { label: '每2個月', value: '2m' },
    { label: '每季', value: '3m' },
    { label: '半年', value: '6m' },
    { label: '1年', value: '12m' },
    { label: '3年', value: '36m' },
    { label: '5年', value: '60m' },
    { label: '10年', value: '120m' }
];

export const BuyPriority = [
    { label: '市值較高者', value: 'valueHigh' },
    { label: '市值較低者', value: 'valueLow' },
    { label: '股價較高者', value: 'priceHigh' },
    { label: '股價較低者', value: 'priceLow' }
];

export const ScoreLimit = [
    { label: '不做限制', value: 0 },
    { label: '98分', value: 98 },
    { label: '95分', value: 95 },
    { label: '93分', value: 93 },
    { label: '90分', value: 90 },
    { label: '85分', value: 85 },
    { label: '80分', value: 80 },
    { label: '75分', value: 75 },
    { label: '70分', value: 70 },
    { label: '65分', value: 65 },
    { label: '60分', value: 60 },
    { label: '50分', value: 50 },
    { label: '40分', value: 40 },
    { label: '30分', value: 30 },
    { label: '20分', value: 20 },
    { label: '10分', value: 10 }
];

export const NoDailyLimit = [
    { label: '是', value: true },
    { label: '否', value: false }
];
