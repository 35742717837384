import React from 'react';
import {
    Button,
    Icon,
    Modal
} from 'rsuite';
import IconClose from 'Image/svgIcon/close.svg';
import PreviewDashboard from 'Components/dashboard/previewDashboard';
import { isEqual } from 'lodash';

/**
 *
 * @param {number} id - 被設定的看板id
 * @param {object} singles - 所有可供使用的singles
 * @param {object} layout - 版型
 * @param {object} dashboardList - 看板列表
 * @param {function} selectDashboard - 設定被選擇的看板
 * @param {function} removeDashboard - 移除看板
 * @param {function} onConfirm - 確定
 * @param {function} onHide - 關閉元件
 * @memberof module:DashboardManagerModal
 * @description 戰情室管理員UI
 * @returns {ReactElement}
 */

function DashboardManagerModal({
    id,
    singles,
    layout,
    show,
    dashboardList,
    selectDashboard,
    removeDashboard,
    onConfirm,
    onHide,
}) {
    return  (
        <Modal
            className="dashboard-manager-modal"
            show={show}
            onHide={onHide}>
            <Modal.Header>
                <Modal.Title>戰情室管理員</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <div className="list-panel">
                    <div className="list-header">
                        <div className="name">戰情室名稱</div>
                        <div className="icon">刪除</div>
                    </div>
                    {
                        dashboardList
                        .filter(dashboard => !dashboard.template)
                        .map(dashboard => {
                            return (
                                <div
                                    key={'dashboard-' + dashboard.id}
                                    className={id === dashboard.id ? 'row selected': 'row'}>
                                    <div
                                        className="name"
                                        data-id={dashboard.id}
                                        onClick={selectDashboard}
                                    >
                                        {dashboard.name}
                                    </div>
                                    <div className="remove">
                                        <Icon
                                            data-id={dashboard.id}
                                            data-name={dashboard.name}
                                            icon={IconClose}
                                            onClick={removeDashboard} 
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className="layout-panel">
                    <div className="header">戰情室預覽</div>
                    <PreviewDashboard
                        singles={singles}
                        layout={layout}
                        enableEdit={false} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onConfirm}>確定</Button>
            </Modal.Footer>
        </Modal>
    );
}




/**
 * 
 * @memberof DashboardManagerModal
 * @param {object} prevProps 前狀態
 * @param {object} nextProps 後狀態
 * @description 比較show, dashboardList, id
 * @returns {boolean}
 */

function areEqual(prevProps, nextProps)
{
    if(
        prevProps.show !== nextProps.show ||
        !isEqual(prevProps.dashboardList, nextProps.dashboardList) ||
        prevProps.id !== nextProps.id
    )
    {
        return false;
    } else {
        return true;
    }
}

export default React.memo(DashboardManagerModal, areEqual);
