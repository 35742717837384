import API from 'Src/api';
export const getWidgetOptions = (isTWIndex, parameters, DataFeed, stock_code) => {
    return {
        debug: true,
        symbol:
            isTWIndex ? 'TWI' : stock_code ? stock_code: 1011,
        interval: 'D',
        container_id: 'chartContainer',
        datafeed: DataFeed,
        library_path: '/charting_library/',
        locale: 'zh_TW',
        timeframe: '6m',
        time_frames: [
            { text: '5d', resolution: 'D', description: '5日', title: '5日' },
            { text: '10d', resolution: 'D', description: '10日', title: '10日' },
            { text: '1m', resolution: 'D', description: '1月', title: '1月' },
            { text: '3m', resolution: 'D', description: '3月', title: '3月' },
            { text: '6m', resolution: 'D', description: '6月', title: '6月' },
            { text: '1y', resolution: 'D', description: '1年', title: '1年' },
            { text: '3y', resolution: 'D', description: '3年', title: '3年' },
            { text: '5y', resolution: 'D', description: '5年', title: '5年' },
            { text: '10y', resolution: 'D', description: '10年', title: '10年' },
            { text: '1000y', resolution: 'D', description: '全部', title: '全部' }
        ],
        timezone: 'Asia/Taipei',
        theme: 'Dark',
        height: '100%',
        width: '100%',
        custom_css_url:
            isTWIndex ?
                '../kLineGraphTWIndex.css' : '../kLineGraph.css',
        disabled_features: [
            'header_symbol_search', 'display_market_status',
            'header_chart_type', 'symbol_info'
        ],
        overrides: {
            'paneProperties.background':
                isTWIndex ? '#353945' : '#2b2f3b',
            'paneProperties.legendProperties.showStudyArguments': true,
    
            // Candles styles
            'mainSeriesProperties.candleStyle.upColor': '#ff4a68',
            'mainSeriesProperties.candleStyle.downColor': '#72cf00',
            'mainSeriesProperties.candleStyle.borderUpColor': '#ff4a68',
            'mainSeriesProperties.candleStyle.borderDownColor': '#72cf00',
            'mainSeriesProperties.candleStyle.wickUpColor': '#ff4a68',
            'mainSeriesProperties.candleStyle.wickDownColor': '#72cf00',
    
            // Hollow Candles styles
            'mainSeriesProperties.hollowCandleStyle.upColor': '#ff4a68',
            'mainSeriesProperties.hollowCandleStyle.downColor': '#72cf00',
            'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#ff4a68',
            'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#72cf00',
    
            // Heikin Ashi styles
            'mainSeriesProperties.haStyle.upColor': '#ff4a68',
            'mainSeriesProperties.haStyle.downColor': '#72cf00',
            'mainSeriesProperties.haStyle.borderUpColor': '#ff4a68',
            'mainSeriesProperties.haStyle.borderDownColor': '#72cf00',
    
            // Bar styles
            'mainSeriesProperties.barStyle.upColor': '#ff4a68',
            'mainSeriesProperties.barStyle.downColor': '#72cf00',
    
            // Drawings
            'linetoolbrush.linecolor': '#ffffff'
        },
        studies_overrides: {
            // Volume
            'volume.volume.color.0': '#72cf00', // Down
            'volume.volume.color.1': '#ff4a68', // Up
    
            // MACD
            'macd.histogram.color': '#ff4a68',
            'macd.macd.color': '#00ddff',
            'macd.signal.color': '#ff4a68',
    
            // Stoch
            'stoch.%k.color': '#00ddff',
            'stoch.%d.color': '#ff4a68',
    
            // Stock RSI
            'stoch rsi.%k.color': '#00ddff',
            'stoch rsi.%d.color': '#ff4a68',
    
            // RSI
            'rsi.plot.color': '#00ddff',
    
            // Bollinger Bands
            'bb.median.color': '#ff4a68',
            'bb.upper.color': '#00ddff',
            'bb.lower.color': '#00ddff',
            'bb.plots background.color': '#00ddff'
        }
    }
};

export const prepareData = (data) => {
    let openPriceList = data[0].data;
    let highPriceList = data[1].data;
    let lowPriceList = data[2].data;
    let closePriceList = data[3].data;
    let volumeList = data[4].data;

    let dateList = Object.keys(openPriceList);

    let bars = [];

    dateList.forEach(dateStr => {
        let date = Date.parse(dateStr);

        bars.push({
            time: date,
            open: openPriceList[dateStr],
            high: highPriceList[dateStr],
            low: lowPriceList[dateStr],
            close: closePriceList[dateStr],
            volume: volumeList[dateStr]
        });
    });
    // console.log(bars)
    return bars;
}



export const configurationData = {
    supported_resolutions: ['1D', '1W', '1M'],
    exchanges: [{ value: 'TWS', name: 'TWS', desc: 'TWS' }],
    symbols_types: [{ name: 'stock', value: 'stock' }],
};


export const getDataFeed = (data, stockPool, barsCache, singleId) => {
    return {

        onReady: (callback) => {
            console.log('[onReady]: Method call');
            setTimeout(() => callback(configurationData));
        },
        searchSymbols: (userInput, _exchange, _symbolType, onResultReadyCallback) => {
            console.log('[searchSymbols]: Method call');
            const newSymbols =
            stockPool.filter(symbol =>
                    symbol.full_name.includes(userInput));
            onResultReadyCallback(newSymbols);
        },
        resolveSymbol:(
            symbolName, onSymbolResolvedCallback, onResolveErrorCallback
        ) => {
            // console.log('[resolveSymbol]: Method call', symbolName, stockPool);
            let symbolItem =
            stockPool.find(x => {
                    return x.symbol === symbolName || x.full_name === symbolName
                });
    
            if (!symbolItem) {
                console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
                onResolveErrorCallback('cannot resolve symbol');
                return;
            }
    
            const symbolInfo = {
                name: symbolItem.symbol,
                description: symbolItem.description,
                type: symbolItem.type,
                session: '24x7',
                timezone: 'Asia/Taipei',
                exchange: symbolItem.exchange,
                minmov: 1,
                pricescale: 100,
                has_intraday: false,
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                volume_precision: 2,
                data_status: 'streaming'
            };
    
            // console.log('[resolveSymbol]: Symbol resolved', symbolName);
            onSymbolResolvedCallback(symbolInfo);
        },
        getBars: async (
            symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback,
            _firstDataRequest
        ) => {
            console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
    
            if (!barsCache[symbolInfo.name]) {
                // console.log('data1231231321321332131323321312133123');
                // console.log('data1231231321321332131323321312133123');
                // console.log('data1231231321321332131323321312133123');
                // console.log(data);
                let result =
                    data ? data :
                        await API.getPanelData(
                            'k-line',
                            {
                                stock_code: symbolInfo.name,
                                single: singleId
                            });
                barsCache[symbolInfo.name] = prepareData(result.main.data);
            }
            try {
                if (!barsCache[symbolInfo.name] || barsCache[symbolInfo.name].length === 0) {
                    // "noData" should be set if there is no data in the requested period.
                    onHistoryCallback([], {
                        noData: true,
                    });
                    return;
                }
                // console.log(barsCache)
                let bars = barsCache[symbolInfo.name].filter(x => x.time >= from * 1000 && x.time < to * 1000);
    
                // console.log(`[getBars]: returned ${bars.length} bar(s)`);
                onHistoryCallback(bars, {
                    noData: false,
                });
            } catch (error) {
                console.log('[getBars]: Get error', error);
                onErrorCallback(error);
            }
        },
        subscribeBars:(
            _symbolInfo,
            _resolution,
            _onRealtimeCallback,
            subscribeUID,
            _onResetCacheNeededCallback,
        ) => {
            console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
        },
        unsubscribeBars:(subscriberUID) => {
            console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        }
    }
}

export const prepareStockPool1 = async (isTWIndex) => {
    const data = isTWIndex ? [{ stock_code: 'TWI', stock_name: '台灣加權指數' }] :await API.getStockPool();
    return data.map(x => (
        {
            symbol: x.stock_code,
            full_name: x.stock_code + ' ' + x.stock_name,
            description: x.stock_name,
            exchange: 'TWS',
            type: 'stock'
        }
    ));
}


export const prepareStockPool2 = (isTWIndex, _stockpool) => {
    // console.log('_stockpool-------------------------------------');
    // console.log('_stockpool');
    // console.log('_stockpool');
    // console.log(_stockpool);
    const data = isTWIndex ? [{ stock_code: 'TWI', stock_name: '台灣加權指數' }] : _stockpool;


    // if( isTWIndex || _stockpool.length > 0)
    // {
        return data.map(x => (
            {
                symbol: x.stock_code,
                full_name: x.stock_code + ' ' + x.stock_name,
                description: x.stock_name,
                exchange: 'TWS',
                type: 'stock'
            }
        ));
    // } else {
    //     return null
    // }
}