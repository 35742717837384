import React from 'react';
import '../style/subtitle.css'
import { colorDecidedByIndexAndValue } from "Util/colorDecider"

const withColorIndexes = [ 1, 2 ]

const colorMappingByRange = [
    {
        color: 'red',
        after: 0,
    },
    {
        color: 'green',
        before: 0
    },
    {
        color: '',
        equals: 0
    }
]

const textTranspile = (source) => {
    const value = source.value
    const unit = source.unit
    const isNumber = typeof value === 'number';
    if(isNumber)
    {
        return value > 0 ? '+' + value + unit : value + unit;
    } else {
        return value != null ? value + unit : '-';
    }
}

export default function Subtitle({ display, tabId })
{
    const displayData = display.find(o => o.tab_id === tabId)
    return <div className="strategy_list__subtitle">
        {
            displayData ? displayData.data.map((o, i) => {
                return <div key={i} className = "strategy_list__subtitle__unit">
                    <div>
                        {o.name}
                    </div>
                    <div className = {colorDecidedByIndexAndValue(i, o.value, withColorIndexes, colorMappingByRange)}>
                        {textTranspile(o)}
                    </div>
                </div>
            }): null
        }
    </div>
}