import React from 'react';

import ArrowText from '../arrowText';

import './style.scss';

export default function Component(props) {
    let className = 'arrow-block';
    if (props.className) {
        className += ' ' + props.className;
    }

    return (
        <div className={className}>
            <ArrowText value={props.value} />
            <div className="content">
                <div className="title">{props.title}</div>
                <div>{props.children}</div>
            </div>
        </div>
    );
}