import { call, put, takeLatest } from "redux-saga/effects";
import Api from "Api/_index";
import { FETCH_INDEX_LIBRARY, FETCH_INDEX_DATA } from "./actionTypes/index";
import { SET_INDEX_LIBRARY, 
    // SET_INDEX_DATA, 
    INSERT_INDEX_DATA } from "../actionTypes/_index"

function* _fetchIndexLibrary()
{
    const indexLibrary = yield call(() => Api('library'))
    if(indexLibrary)
    {
        yield put({
            type:  SET_INDEX_LIBRARY,
            indexLibrary: indexLibrary
        })
    }
}

function* _fetchIndexData(action)
{
    const data = yield call(() => Api("data", {
        formData: action.formData
    }))

    if(data)
    {
        yield put({
            type: INSERT_INDEX_DATA,
            data: data
        })
    }
}

function* fetchIndexLibrary()
{
    yield takeLatest(FETCH_INDEX_LIBRARY, _fetchIndexLibrary)
}

function* fetchIndexData()
{
    yield takeLatest(FETCH_INDEX_DATA, _fetchIndexData)
}

export default { fetchIndexLibrary, fetchIndexData }