import { combineReducers } from "redux";
import { messages } from './messages';
import { notice } from  './notice'
import { stockPicker } from  './stockPicker';
import strategy from "./strategy/strategy"
import strategyOverviewPage from "./strategy/strategyOverviewPage"
import singles from "./singles"
import dashboardPage from "./dashboard/dashboardPage"
import dashboard from "./dashboard/dashboard"
import stock from  "./stock"
import app from "./app";
import sidebar from  "./sidebar"
import pagination from  "./pagination"
import tabList from  "./tabList"
import graph from "./graph"
import industries from "./industries"
import index from "./_index"
import strategyDesign from  "./strategy/strategyDesign"
import backtest from "./backtest";
import needLoginModal from './needLoginModal';
import profile from './profile';
import configs from './configs';
import questionnaireModal from './questionnaireModal';

const rootReducer = combineReducers({
    app,
    backtest,
    messages,
    notice,
    stockPicker,
    strategy,
    strategyDesign,
    sidebar,
    strategyOverviewPage,
    dashboardPage, 
    dashboard,
    singles,
    stock,
    pagination,
    tabList,
    graph,
    industries,
    index,
    needLoginModal,
    profile,
    configs,
    questionnaireModal
})


export default rootReducer;