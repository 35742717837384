import * as Actions from "../../actionTypes/dashboard/dashboardPage"
// import _ from "lodash"
const initialState = {
    dashboard: [],
    dashboardList: [],
    activePanelList: [],
    layout: null,
    showLayoutModal: false,
    editLayout: null,
    showDashboardModal: false,
    isCreateMode: false,
    showCreateDashboardModal: [],
    singles: [],
    availableSingles: []
}

export default function reducer(state=initialState, action)
{
    switch(action.type) {
        case Actions.UPDATE_DASHBOARD_LIST: {
            const dashboard = action.dashboardList.find(dashboard => dashboard.selected);
            const singles = dashboard ? dashboard.singles : [];
            const layout = dashboard.layout === '' ? null : dashboard.layout;
            return {
                ...state,
                dashboardList: action.dashboardList,
                dashboard: dashboard,
                singles: singles,
                layout: layout
            }
        }

        case Actions.UPDATE_SELECTED_DASHBOARD: {
            const dashboardList = state.dashboardList.map((o) => {
                if(o.id === action.id)
                {
                    o.selected = 1
                    return o
                } else {
                    o.selected = 0
                    return o;
                }
            })
            const dashboard = state.dashboardList.find((o) => {
                return o.id === action.id;
            })
            const layout = dashboard.layout === '' ? null : dashboard.layout;
            const singles = dashboard ? dashboard.singles : [];
            return {
                ...state,
                dashboardList: dashboardList,
                dashboard: dashboard,
                layout: layout,
                singles: singles
            }
        }        
        case Actions.UPDATE_AVAILABLE_SINGLES: {
            return {
                ...state,
                availableSingles: action.availableSingles
            }
        }
        case Actions.UPDATE_ACTIVEPANELLIST: {
            return {
                ...state,
                activePanelList: action.activePanelList
            };
        }
        case Actions.SHOW_LAYOUTMODAL: {
            return {
                ...state,
                showLayoutModal: true,
                isCreateMode: false,
                editLayout: state.layout
            }
        }

        case Actions.HIDE_LAYOUTMODEL: {
            return {
                ...state,
                showLayoutModal: false
            }
        }

        case Actions.SHOW_CREATEDASHBOARDMODAL: {
            const target = state.dashboardList.find(o => o.id === action.id*1);
            const layout = target ? target.layout: []
            return {
                ...state,
                showLayoutModal: true,
                isCreateMode: true,
                editLayout: layout
            }
        }

        case Actions.SHOW_DASHBOARDMODAL: {
            return {
                ...state,
                showDashboardModal: true
            }
        }

        case Actions.CLOSE_DASHBOARDMODAL: {
            return {
                ...state,
                showDashboardModal: false
            }
        }
        
        case Actions.AFTER_SAVE_DASHBOARD_REMOTE: {
            return {
                ...state,
                singles: action.singles,
                layout: action.layout,
                showLayoutModal: false,
                dashboardList: action.dashboardList
            }
        }

        case Actions.AFTER_CREATE_DASHBOARD: {
            return {
                ...state,
                dashboardList: action.dashboardList,
                dashboard: action.dashboard,
                layout: action.layout,
                singles: action.singles,
                showLayoutModal: false,
                isCreateMode: false
            }
        }

        case Actions.AFTER_REMOVE_DASHBOARD: {
            if(action.differFromSelected)
            {
                return {
                    ...state,
                    dashboardList: action.dashboardList,
                    dashboard: action.dashboard,
                    singles: action.singles,
                    layout: action.layout
                }
            } else {
                return {
                    ...state,
                    dashboardList: action.dashboardList,
                }
            }
        }

        
        default: {
            return state;
        }
    }
};