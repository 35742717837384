import React from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import Component from './component';
import {
    CLOSE_QUESTIONNAIRE_MODAL
} from 'Redux/actionTypes/questionnaireModal';

import './style.scss';

const cookies = new Cookies();

const showQuestionnaire = () => {
    window.open(
        'https://docs.google.com/forms/u/1/d/e/1FAIpQLSeo5OSjrQn9zxgKITGfOquvq2mTlToJXHyWyJcJyyM_wD0YMg/viewform?usp=send_form',
        '_blank'
    );
};

const setDismiss = checked => {
    cookies.set('dismissQuestionnaireModal', checked);
}

const Container = () => {
    const isOpen = useSelector(store => store.questionnaireModal.isOpen);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch({ type: CLOSE_QUESTIONNAIRE_MODAL });
    };

    return (
        <Component
            isOpen={isOpen}
            onClose={onClose}
            showQuestionnaire={showQuestionnaire}
            setDismiss={setDismiss} />
    );
};

export default Container;