import StrategyList from './container';
// import './style/subtitle.css';

/** 
 * @constructs StrategyList
 * @module StrategyList
 * @see module:Dashboard
 * @description 投資策略列表
 */

export default StrategyList;