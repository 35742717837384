import React from 'react';

import StrategyRatingSingle from './component/strategyRatingSingle';

import './style.scss';

export default function Container(props) {
    if (!props.ratings) { return <></>; }

    return (
        <div className="strategy-ratings">
            {
                props.ratings.map(x =>
                    <StrategyRatingSingle
                        key={x.name}
                        name={x.name}
                        rating={x.score}
                        info={x.info} />
                )
            }
        </div>
    );
}