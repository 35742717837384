const Disclaimer = {
    title: '免責聲明',
    content:
        '本服務條款係由「易策略有限公司」（以下簡稱本公司）所擬訂，其範圍包括但不限於「服務條款」、「免責聲明」、「著作權與智財權規範」及「隱私權條款」，用以協助「鉅亨易策略」網站（以下簡稱本網站）之使用者了解本公司之服務。\n' +
        '\n' +
        '一、服務範圍：\n' +
        '\n' +
        '本網站所提供之資訊服務（以下簡稱為：本服務），當您使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容。此外，本公司有權於任何時間修改或變更本服務條款之內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用本服務，視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意本服務條款的內容，請您務必立即停止使用本服務。\n' +
        '\n' +
        '二、本網站之收費機制：\n' +
        '\n' +
        '(一) 本網站針對不同的投資組合以及資訊內容，訂定不同的服務價格，您應依據本網站提供的服務價格購買本網站之服務。\n' +
        '\n' +
        '(二) 您同意使用本網站購買服務時，於本網站通知確認交易成立前，本網站仍有不接受您的訂購之權利。您向本網站發出訂購通知後，系統將自動發出接受通知，但此通知並非訂單確認通知，關於交易成立與否本網站將另行告知。若因標的服務之交易條件有誤時(包括但不限於價錢、服務內容、圖片)，本網站得於您訂購後三日內取消該筆交易。\n' +
        '\n' +
        '(三) 如您以信用卡刷卡扣款，本公司會於交易成立後始向信用卡收單業務機構請求帳款。\n' +
        '\n' +
        '三、未成年人保護條款：\n' +
        '\n' +
        '(一) 未滿二十歲之未成年人，應與法定代理人（或監護人）共同閱讀、瞭解並同意本服務條款所載之所有內容及其後修改變更後，方得註冊成為會員。當您完成註冊而使用或繼續使用本服務，即推定您與法定代理人（或監護人）已閱讀、瞭解並同意接受本服務條款之所有內容及其後之修改變更。\n' +
        '\n' +
        '(二) 未滿二十歲之未成年人，請務必提供相關同意書（須法定代理人或監護人簽章同意），始得購買本公司之加值服務。\n' +
        '\n' +
        '四、免責聲明：\n' +
        '\n' +
        '鉅亨易策略 必須特別聲明：投資必有風險，投資人必須謹慎、理性地判讀各種市場資訊並為個人投資決策及其後果負起盈虧責任。\n' +
        '\n' +
        '鉅亨易策略 各項服務係依據公開資訊觀測站、台灣證券交易所與櫃檯買賣中心之資料直接呈現，或綜整；圖表化；工具化等方式呈現，已力求正確與即時。但不保證本網站的效能、速度與安全性，以及資訊內容完整、正確與可靠性等。其可能發生錯誤、遺漏、瑕疵、中斷與延遲等狀況，一切資料仍以公開資訊觀測站、台灣證券交易所與櫃檯買賣中心的資訊為準。如發生相關狀況，本公司不負擔相關之損害賠償及法律責任。\n' +
        '\n' +
        '本網站所提供之各項資訊服務，包括其他使用者張貼的文章、評論及意見等，皆不具立即性、統一適用性，不應視為任何程度的投資建議，亦不代表本公司立場。相關資訊的真實性、完整性、可信度有賴使用者自行審度，投資人務必多方蒐集資訊參考佐證，自行分析且獨立思考判斷，萬不可直接依賴本網站服務進行投資決策，相關風險應自行承擔。若因此發生直接或衍生之損失，本公司恕不負擔任何損害賠償及法律責任。\n' +
        '\n' +
        '此外，本公司之關係企業、相關管理人員及董事、以及所屬員工或代理人在法律容許的限度內，不向您承擔以下原因所引發之任何損害：\n' +
        '\n' +
        '本網站服務之可能錯誤、延遲、遺漏、瑕疵、中斷或停止傳輸；\n' +
        '\n' +
        '第三方駭客攻擊或藉由本網站服務傳輸惡意軟體、病毒、木馬或類似性質之程式；\n' +
        '\n' +
        '本網站受託廣告刊登行為，或使用者對廣告商或贊助人所發生的任何交易或行為；\n' +
        '\n' +
        '本網站對提供服務之範圍與內容，以及相關免責聲明、服務條款、隱私權條款等規範所進行的任何異動之行為（ 例如：一部分或全部性的變更、修改、暫時停止服務、永久停止服務等異動行為）。\n' +
        '\n' +
        '本網站使用者違反中華民國法律及本網站各項服務條款、承諾、義務與注意事項之行為。\n' +
        '\n' +
        '五、與第三人網站的連結：\n' +
        '\n' +
        '本服務可能會提供其他網站連結。您可能會因此連結至其他業者經營的網站，但不表示本公司與該等業者有任何關係。其他業者經營的網站均由各該業者自行負責，不屬於本公司控制及負責範圍。如遇相關情狀，您可以不瀏覽或者離開該等網站，本公司無須為該等行為，負起任何賠償責任。\n' +
        '\n' +
        '六、註冊義務：\n' +
        '\n' +
        '為了能使用本服務，您同意以下事項：\n' +
        '\n' +
        '(一) 註冊時，提供正確、最新及完整的資料。\n' +
        '\n' +
        '(二) 請勿重複註冊；\n' +
        '\n' +
        '(三) 請勿以第三人名義或以虛偽姓名註冊；\n' +
        '\n' +
        '(四) 維持並更新您個人資料，以確保相關資料的正確性、最新性及完整性。\n' +
        '\n' +
        '若您提供任何錯誤、不實或不完整的資料，本公司有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。\n' +
        '\n' +
        '七、確保個人會員帳號與密碼義務：\n' +
        '\n' +
        '請注意！請您秉持誠信完成註冊程序，並有義務維持個人密碼及帳號的機密安全。您同意以下事項：\n' +
        '\n' +
        '(一) 您的密碼或帳號發生被盜用或其他安全問題時，請您立即通知本網站；\n' +
        '\n' +
        '(二) 每次連線完畢，務必登出您的帳號。\n' +
        '\n' +
        '八、使用者的守法義務及承諾：\n' +
        '\n' +
        '您承諾遵守中華民國相關法規及一切使用網際網路之國際慣例，不得以任何非法目的或以任何非法方式使用本服務。不論您是否為中華民國之使用者皆須遵守相關法令，不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：\n' +
        '\n' +
        '(一) 上載、張貼、公布或傳送任何違反公共秩序或善良風俗的文字、圖片或任何形式的檔案於本服務上；\n' +
        '\n' +
        '(二) 侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、智慧財產權及其他權利；\n' +
        '\n' +
        '(三) 違反依法律或契約所應負之保密義務；\n' +
        '\n' +
        '(四) 冒名註冊，使用本服務；\n' +
        '\n' +
        '(五) 上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料；\n' +
        '\n' +
        '(六) 從事散布虛假不實之訊息；\n' +
        '\n' +
        '(七) 濫發廣告訊息與違法之多層次傳銷訊息等；\n' +
        '\n' +
        '(八) 偽造訊息來源或以任何方式干擾傳輸來源之認定；\n' +
        '\n' +
        '(九) 其他不適當之行為。\n' +
        '\n' +
        '您若於本網站進行違法或侵害他人之行為，本公司將盡力配合相關執法單位進行調查。\n' +
        '\n' +
        '九、智慧財產權保護：\n' +
        '\n' +
        '本網站上所有內容，包括但不限於著作、圖文資訊、網站架構及畫面配置、網頁設計等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權與專有技術等權利。任何人不得逕自使用、修改、重製、公開傳輸、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載本網站內容，除明確為法律所許可者外，必須依法取得本公司或其他權利人的事前書面同意，事後配合註明來源與出處。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任。\n' +
        '\n' +
        '十、著作權保護\n' +
        '\n' +
        '本公司尊重他人著作權，亦請您承諾共同尊重他人著作權。若您認為本公司網站中之任何網頁內容或其他使用者使用服務時，已侵害您的著作權，請通知我們，我們將盡速查證排除相關侵害。另外，您使用本服務而涉及侵權情事時，本公司有權暫停或終止您全部或部分服務。\n' +
        '\n' +
        '十一、權利人通知義務：\n' +
        '\n' +
        '為維護權利人之權益，權利人得通知排除侵害，以維護權益，例如：針對性不當言論之排除、智慧財產權與著作權之侵害排除等。相關權利人請配合提供以下資訊，以利本公司作業：\n' +
        '\n' +
        '事由：不當內容或侵害事實及相關網址。\n' +
        '\n' +
        '聲明提出之內容為真實，提出人的利害關係，例如：本身為權益人或有權承受排除侵害利益之權利人等。\n' +
        '\n' +
        '聯絡方式：真實姓名或公司名稱(含聯絡人)、電話、地址、電子信箱等。\n' +
        '\n' +
        '十二、不得為商業利用\n' +
        '\n' +
        '您同意不對本服務任何部分或本服務之使用或存取，進行重製、拷貝、出售、交易、轉售或作任何商業目的之使用。\n' +
        '\n' +
        '十三、服務變更\n' +
        '\n' +
        '本公司保留於任何時點，不經通知隨時修改；或暫時；或永久停止繼續提供本服務（或其任一部分）的權利。您同意本公司對於您或是任何第三方對於任何修改、暫停或停止繼續提供本服務不負任何責任。\n' +
        '\n' +
        '十四、服務的停止或中斷\n' +
        '\n' +
        '您同意本公司有條件停止或中斷提供本服務 ：\n' +
        '\n' +
        '本網站電子通信設備進行必要之保養、維護及進行相關施工時；\n' +
        '\n' +
        '發生突發性之電子通信設備故障時；\n' +
        '\n' +
        '本網站申請之電子通信服務被停止，無法提供服務時；\n' +
        '\n' +
        '因天災、不可抗力之因素或其他不可歸責於本公司致使本網站無法提供服務時。\n' +
        '\n' +
        '十五、會員對本公司之授權\n' +
        '\n' +
        '您同意在網站內自行公開傳輸之資料、所分享之訊息與文章或提供之各種檔案，皆為合法、或具合法授權，且無涉個人隱私，並同意 鉅亨易策略 蒐集、處理、保存、傳遞並合法授權使用該等資訊，或作成會員統計資料、或進行網路行為之相關研究與調查、或為任何商業性及非商業性用途、或為任何之合法使用等行為，以繼續精進本公司資訊服務內容。\n' +
        '\n' +
        '十六、準據法及管轄法院\n' +
        '\n' +
        '本服務條款之解釋與適用，以及與本服務條款有關會員與本公司間之爭議或糾紛，雙方同意本誠信原則解決之，如有訴訟之必要，應依照中華民國法律處理，並同意以台灣台北地方法院為第一審管轄法院，但若法律對於管轄法院另有強制規定者，仍應依其規定。\n' +
        '\n' +
        '十七、反映與聯絡\n' +
        '\n' +
        '會員如對於 鉅亨易策略 之隱私權聲明與政策有任何疑問，敬請以「電話」與「電子郵件」向 鉅亨易策略 反映，我們將盡速與您聯絡，謝謝。'
};

export default Disclaimer;