import Fetch from '../fetch';
/**
 * @constant
 * @memberof module:Fetch
 * @description 回測api
 */

const backtestApi = {
    // 開始回測
    start: {
        method: "POST",
        uri: () => "/user/strategies/backtest"
    },
    // 取消回測
    cancel: {
        method: "POST",
        uri: () => '/user/strategies/backtest/cancel'
    },
    prepare:  {
        method: ""
    },
    preview: {
        method: "POST",
        uri: id => '/data/backtest/' + id + '/preview'
    }
}

/**
 * @memberof module:Fetch
 * @function BackTesetApi
 * @description 發送回測相關request
 */

export default function BackTesetApi(type, params = null){
    return Fetch.request(
        backtestApi[type].method,
        backtestApi[type].uri,
        params
    )
}