import React from 'react';
import {
    Icon,
    Popover,
    Whisper
} from 'rsuite';

import IconCheck from 'Image/svgIcon/check-red.svg';
import IconCross from 'Image/svgIcon/cross-green.svg';
import IconInfo from 'Image/svgIcon/info.svg';

import './style.scss';

const renderRules = rules =>
    rules.map((x, i) => {
        let icon = x.status ? IconCheck : IconCross;

        return (
            <div className="rule" key={i}>
                <Icon icon={icon} />
                <div className="content">{x.content}</div>
            </div>
        );
    });

const Component = props => {
    const speaker =
        <Popover className="rule-status-popover">
            {renderRules(props.rules)}
        </Popover>;

    return (
        <Whisper
            trigger="click"
            speaker={speaker}
            placement="autoVerticalStart">
            <div className="rule-status-button">
                <Icon icon={IconInfo} />
            </div>
        </Whisper>
    );
};

export default Component;