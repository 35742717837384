import Component from './container';
// import './style/subtitle.css';

/** 
 * @constructs kLineGraph
 * @module kLineGraph
 * @see module:Dashboard
 * @description 投資策略列表
 */

export default Component;