export const getExtremes = (index, startDate, endDate, originExtreme) => {
    // console.log('originExtreme');
    // console.log(originExtreme);
    let axisExtreme = {
        max: undefined,
        min: undefined,
        percentMax: undefined,
        percentMin: undefined
    };


    const dateList = Object.keys(index.data).filter(key => {
        let date = new Date(key);

        if (startDate && endDate) {
            return date >= startDate && date <= endDate;
        } else if (startDate) {
            return date >= startDate;
        } else if (endDate) {
            return date <= endDate;
        }

        return date;
    });
    let valueList = dateList.map(date => index.data[date]);

    if(originExtreme)
    {
        // console.log('originExtreme');
        // console.log(originExtreme);
        axisExtreme = {
            ...originExtreme
        }
        // console.log('axisExtreme');
        // console.log(axisExtreme);
    } 
    // console.log('index.code');
    // console.log(index.code);
    valueList.forEach(value => {
        if (!value && value !== 0) { return; }

        if (
            (!axisExtreme.max && axisExtreme.max !== 0) ||
            axisExtreme.max < value
        ) {
                axisExtreme.max = value;
            }
            
        if (
            (!axisExtreme.min && axisExtreme.min !== 0) ||
            axisExtreme.min > value
        ) {
                    
            // console.log('<');
            // console.log(value);
            axisExtreme.min = value;
        }

        let valueStart = valueList[0];
        let percent = (value - valueStart) / valueStart * 100;

        if (
            (!axisExtreme.percentMax && axisExtreme.percentMax !== 0) ||
            axisExtreme.percentMax < percent
        ) {
            axisExtreme.percentMax = percent;
        }

        if (
            (!axisExtreme.percentMin && axisExtreme.percentMin !== 0) ||
            axisExtreme.percentMin > percent
        ) {
            axisExtreme.percentMin = percent;
        }
    });
    // console.log('index');
    // console.log(index);
    // console.log(valueList);
    // console.log('axisExtreme');
    // console.log(axisExtreme);
    return axisExtreme;
}