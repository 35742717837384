import React from 'react';
import {
    Button,
    Icon,
    Input,
    InputGroup,
    Modal
} from 'rsuite';
import PreviewDashboard from 'Components/dashboard/previewDashboard';
import { isEqual } from "lodash"
import './style.scss'
/**
 * @param {booloean} show - 是否視窗顯示
 * @param {booloean} isCreateMode - 是否新增模式
 * @param {string} name - 看板名稱
 * @param {string} searchText - 搜尋single用字串
 * @param {number} id - 被設定的看板id
 * @param {object} singles - 所有可供使用的singles
 * @param {object} layout - 版型
 * @param {object} dashboardList - 看板列表
 * @param {function} onNameChange - 看板名稱變更
 * @param {function} onSearchTextChange - 搜尋字串變更
 * @param {function} onLayoutChange - 版型變更
 * @param {function} toggleSingle - 增減選到的single
 * @param {function} checkIfSelected - 檢查single是否被選到
 * @param {function} onConfirm - 確定
 * @param {function} onHide - 關閉元件
 * @memberof module:DashboardLayoutModal
 * @description 戰情室新增編輯UI
 * @returns {ReactElement}
 */

// function DashboardLayoutModal(props)
function DashboardLayoutModal({
    show,
    isCreateMode,
    name,
    layout,
    searchText,
    singles,
    onHide,
    onConfirm,
    onNameChange,
    onLayoutChange,
    onSearchTextChange,
    toggleSingle,
    checkIfSelected
})
{
    const title = !isCreateMode ? '新增/編輯模塊' : '新增戰情室';
    return (
        <Modal
            className="dashboard-layout-modal"
            show={show}
            onHide={onHide}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                {
                    isCreateMode &&
                    <div className="name-container">
                        <div className="header">戰情室名稱</div>
                        <Input value={name} onChange={onNameChange} />
                    </div>
                }
                <div className="panel-container">
                    <div className="item-panel">
                        <div className="header">點擊新增模塊</div>
                        <div className="item-container">
                            <InputGroup className="search-box">
                                <Input
                                    placeholder="搜尋模塊"
                                    value={searchText}
                                    onChange={onSearchTextChange} />
                                <InputGroup.Button>
                                    <Icon icon="search" />
                                </InputGroup.Button>
                            </InputGroup>
                            <div className="item-list">
                                {
                                    layout ? singles.map((single) => {
                                        return <div
                                            key={single.id}
                                            data-id={single.id}
                                            className={checkIfSelected(single.id) ? "single selected": "single"}
                                            onClick={toggleSingle}>
                                            {single.name}
                                        </div>
                                    }): null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="layout-panel">
                        <div className="header">拖曳調整順序</div>
                        <PreviewDashboard
                            singles={singles}
                            layout={layout}
                            onLayoutChange={onLayoutChange}
                            enableEdit={true} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onConfirm}>確定</Button>
            </Modal.Footer>
        </Modal>
    );
};



function areEqaul(prevProps, nextProps)
{
    if(
        prevProps.show !== nextProps.show || 
        !isEqual(prevProps.layout, nextProps.layout) ||
        prevProps.name !== nextProps.name ||
        prevProps.searchText !== nextProps.searchText
    )
    {
        return false
    } else {
        return true;
    }
}

export default React.memo(DashboardLayoutModal, areEqaul);