import moment from 'moment';
function getXAxisFormatter(periodType, value) {
    switch (periodType) {
        case 'month':
            return moment(value).format('YYYY/M');
        case 'quarter':
            return moment(value).format('YYYY[Q]Q');
        case 'year':
            return moment(value).format('YYYY');
        default:
            return moment(value).format('YYYY/M/D');
    }
}
export const graphOption = {
    chart: {
        backgroundColor: 'transparent',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 23,
        // height: '495px'
    },
    credits: {
        enabled: false
    },
    time: {
        useUTC: true
    },
    plotOptions: {
        line: {
            marker: { enabled: false }
        },
        series: {
            dataGrouping: {
                enabled: false
            }
        }
    },
    rangeSelector: {
        enabled: false
    },
    navigator: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    },
    title: {
        text: '',
        floating: true,
        align: 'left',
        x: -4,
        y: 13,
        style: {
            color: '#fff',
            fontSize: '16px'
        }
    },
    subtitle: {
        text: '',
        floating: true,
        align: 'right',
        x: 4,
        y: 13,
        style: {
            color: '#fff',
            fontSize: '16px'
        }
    },
    tooltip: {
        xDateFormat: '%Y/%m/%d',
        shared: true,
        useHTML: true,
        formatter: function (d) {
            let time =
                getXAxisFormatter(d.chart.userOptions.periodType, this.x);

            let item =
                this.points.map(point => {
                    let legendType = point.series.userOptions.type;
                    let legendClassName = 'legend-' + legendType;
                    let legendStyle = 'background: ' + point.color + ';';

                    if (legendType === 'column') {
                        legendStyle +=
                            'border: ' + point.series.userOptions.borderWidth +
                            'px solid' + point.series.userOptions.borderColor +
                            ';';
                    }

                    let legendIcon =
                        '<div class="legend ' + legendClassName + '" style="' +
                        legendStyle + '"></div>';

                    let unit = point.series.userOptions.yAxis;
                    let displayUnit = unit === '%' ? unit : ' (' + unit + ')';

                    return (
                        '<div class="row">' +
                        legendIcon + point.series.name + ': ' +
                        point.y.toLocaleString() + displayUnit +
                        '</div>'
                    );
                });

            return (
                '<div class="tooltip">' + time + '<br />' + item.join('') +
                '</div>'
            );
        }
    },
    legend: {
        enabled: true,
        itemStyle: {
            color: '#fff',
            fontWeight: 'normal'
        },
        itemHoverStyle: {
            color: '#00ddff'
        },
        symbolRadius: 0,
        verticalAlign: 'top',
        y: -16
    },
    xAxis: [{
        labels: {
            style: { color: '#fff', fontSize: '12px' }
        },
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        gridLineWidth: 1
    }],
    yAxis: []
};

export const colorList = [
    '#00b0db', '#ff9c00', '#fa77d0', '#afb100', '#ff5400',
    '#0081ff', '#a172ff', '#bd9f9c', '#63a438', '#ff00ff',
    '#e1bd00', '#9e9e9f'
];