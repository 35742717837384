import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
} from 'rsuite';

import './addStockGroupModal.css';

export default class AddStockGroupModal extends Component {
    state = {
        name: ''
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            this.setState({ name: '' });
        }
    }

    onNameChange(value) {
        this.setState({ name: value });
    }

    onConfirmClick() {
        if (!this.props.onConfirmClick) { return; }
        this.props.onConfirmClick(this.state.name);
    }

    render() {
        return (
            <Modal
                className="add-stock-group-modal"
                show={this.props.show}
                onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>儲存自定義分類</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <span>分類名稱</span>
                        <Input
                            className="name-input"
                            value={this.state.name}
                            onChange={value => this.onNameChange(value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onConfirmClick()}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}