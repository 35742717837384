export const colorList = [
    '#ff9600', '#ff4a68', '#72cf00', '#00bfff', '#ff86d6',
    '#bd9f71', '#b081ff', '#ff00ff', '#bede00', '#008bff',
    '#59ca95', '#d63d00', '#ff8300', '#2f862f', '#ffc200'
];

export const options = {
    chart: {
        backgroundColor: 'transparent',
        type: 'pie',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        width: 380,
        height: 220
    },
    credits: {
        enabled: false
    },
    title: null,
    colors: colorList,
    tooltip: {
        pointFormat: '{point.percentage:.2f}%'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            cursor: 'pointer',
            borderColor: null,
            dataLabels: {
                enabled: true,
                connectorColor: 'transparent',
                distance: 5,
                color: 'white',
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    textOutline: null
                }
            }
        }
    },
    series: [{
        colorByPoint: true,
        data: []
    }]
};;