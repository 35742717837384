import Fetch from "../fetch";

/**
 * @constant
 * @memberof module:Fetch
 * @description 戰情室api
 */

const dashboardApi = {
    item: {
        method: "GET",
        // uri: 
    },
    list: {
        method: "GET",
        uri: () => '/user/dashboards'
    },
    setSelected: {
        method: "POST",
        uri: () => "/user/dashboards/selected"
    },
    saveDashboard: {
        method: "POST",
        uri: () => "/user/dashboards/"
    },
    create: {
        method: "POST",
        uri: () => "/user/dashboards/"
    },
    availableSingles: {
        method: 'GET',
        uri: () => '/user/singles'
    },
    delete: {
        method: 'DELETE',
        uri: id => '/user/dashboards/' + id
    },
    panelData: {
        method: "GET",
        uri: id => '/data/model/' + id
    }
}

/**
 * @memberof module:Fetch
 * @function DashboardApi
 * @description 發送戰情室相關request
 */

export default function DashboardApi(type, params=null)
{
    return Fetch.request(
        dashboardApi[type].method,
        dashboardApi[type].uri,
        params
    )
};