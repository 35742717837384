import * as Actions from  "../../actionTypes/strategy/strategyDesign";
const initialState  =  {
    id: null,
    strategyName: '',
    strategyDescription: '',
    backtestSetting: {
        fund: 100,
        buyRule: 'average',
        buyRank: 30,
        timespan: '10',
        timespanStart: 2010,
        timespanEnd: 2020,
        fee: 0,
        changeFrequency: '12m',
        buyPriority: 'valueHigh',
        scoreLimit: 60,
        noDailyLimit: true,
        customWeight: [],
        backtestDate: null
    },
    totalScore: 0,
    industryFilter: {
        include: {
            industries: ['所有產業'],
            concepts: [],
            conglomerates: []
        },
        exclude: {
            industries: [],
            concepts: [],
            conglomerates: []
        }
    },
    constraintFilter: {
        include: [
            { index: null, operator: 'greater' }
        ],
        exclude: [
            { index: null, operator: 'greater' }
        ]
    },
    indexScoreList: [], // Initialized in App.js
    stockList: {
        industrySummary: [],
        stockColumnList: [],
        stockData: []
    },
    industryData: [],
    indexLibrary: []
}

export default function reducere(state=initialState,  action)
{
    switch(action.type) {
        case Actions.SET_INITIAL_INDEX_SCORE_LIST: {
            initialState.indexScoreList = action.payload;
            return { ...state };
        }
        case Actions.RESET_COPIED_STRATEGY_LOCAL: {
            return {
                ...state,
                ...action.strategyDesign,
            }
        }

        case  Actions.SET_DESIGN_STRATEGY:  {
            return  {
                ...state,
                ...action.payload
            }
        }

        case Actions.SET_STOCK_LIST: {
            return {
                ...state,
                stockList: action.stockList
            }
        }

        case Actions.SET_STRATEGY_NAME: {
            return {
                ...state,
                strategyName: action.strategyName
            }
        }
        case Actions.UPDATE_DESIGN_STRATEGY: {
            return {
                ...state,
                ...action.payload
            }
        }
        case Actions.SET_STRATEGY_DESCRIPTION: {
            return {
                ...state,
                strategyDescription: action.strategyDescription
            }
        }
        case Actions.SET_INDUSTRY_FILTER: {
            return {
                ...state,
                industryFilter: action.industryFilter
            }
        }

        case Actions.SET_CONSTRAINT_FILTER: {
            return {
                ...state,
                constraintFilter: action.constraintFilter
            }
        }

        case Actions.SET_INDEX_SCORELIST_AND_TOTALSCORE: {
            return {
                ...state,
                indexScoreList: action.indexScoreList,
                totalScore: action.totalScore
            }
        }
        
        case Actions.UPDATE_BACKTEST_SETTING: {
            return {
                ...state,
                backtestSetting: action.setting
            }
        }

        case Actions.RESET_STRATEGY_DESIGN: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};