import * as Actions from "../../actionTypes/strategy/strategy"
// import strategy from "../../saga/strategy";
export const initialState = {
    list: [],
    shared: [],
    sidebarStrategyScoreId: null,
    strategyScoreList: []
};


export default function reducer(state=initialState, action){
    switch(action.type) {
        case Actions.UPDATE_STRATEGY_LIST:{
            return {
                ...state,
                sidebarStrategyScoreId: state.sidebarStrategyScoreId ? state.sidebarStrategyScoreId :(action.list[0] ? action.list[0].id: null),
                list: action.list
            }
        }
        case Actions.SET_SHAREDLIST: {
            return {
                ...state,
                shared: action.sharedList
            }
        }
        case Actions.INSERT_STRATEGYSCORE: {
            let strategyScoreList = null;
            if(state.strategyScoreList.find(o => o.id === action.id*1))
            {
                strategyScoreList = state.strategyScoreList;
            } else {
                strategyScoreList = [
                    ...state.strategyScoreList,
                    action.strategyScore
                ]
            }
            return {
                ...state,
                strategyScoreList: strategyScoreList
            }
        }
        case Actions.SET_SIDEBAR_STRATEGY_SCORE_ID: {
            return {
                ...state,
                sidebarStrategyScoreId: action.id
            }
        }
        default: {
            return state;
        } 
    }
}