import React, { useEffect } from 'react';
import { useSelector } from "react-redux"
import StrategyList from "../strategyList";
import '../style/strategyList.css';
// import _ from 'lodash';
import { getPanelData, updatePanelData } from "Redux/actionCreators/dashboard" 
import { changePage} from "Redux/actionCreators/pagination";

// import Empty from '../components/empty'
/**
 * @memberof module:StrategyList
 * @param {object} panel - 由dashboard api來的面板資料
 * @param {number} rowHeight - 行高
 * @param {object} parameters - 參數
 * @description 處理策略列表的資料
 * @returns {ReactElement}
 */

const DisplayLength = 10;

let currentTab;

function Container({panel, rowHeight, parameters})
{
    const panelHeight = rowHeight * panel.height + 20 * (panel.height - 1) - 30 - 10;
    const height = panelHeight - 45 - 9 * 2 - 30 - 16 - 60
    const graphHeight = height - 65 - 9;
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id))
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === panel.id))
    const trackingStockLoaded = useSelector(store => store.stock.loaded)

    const page = pagination ? pagination.page : 1;

    useEffect(() => {
        // console.log('useEffect strategyList');
        getPanelData(panel, parameters);
    },[panel, parameters])
    useEffect(() => {
        afterGotPanelData()

        let defaultTab =
            panelData && panelData.rawData.main.tab ?
                panelData.rawData.main.tab[0]?.id : null;
        setCurrentTab(defaultTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(panelData ? panelData.rawData: panelData)])
    const afterGotPanelData = () => {
        updatePanelData(panel.id, {
            showGraph: true,
            sortColumn: 'ror',
            sortType: 'desc',
        })
    }
    
    const setCurrentTab = value => {
        currentTab = value;
        changePage(panel.id, 1);
    }
    
    const onToggleGraphClick = () => {
        updatePanelData(panel.id, {
            showGraph: !panelData.showGraph
        })
    }

    const getPages = () => { 
        let pages =
            panelData.tableData && panelData.tableData[panelData.currentTab] &&
                panelData.tableData[panelData.currentTab][panelData.displayType] ?
                Math.ceil(
                    panelData.tableData[panelData.currentTab][panelData.displayType].length /
                    panelData.displayLength) :
                0;
        return pages
    }
    
    const onChangePage = (page) => {
        updatePanelData(panel.id, {
            page: page
        })
    }

    
    const sortColumnData = (sortColumn, sortType) => {
        updatePanelData(panel.id, {
            ...panelData,
            sortColumn: sortColumn,
            sortType: sortType
        })
    }
    
    const mapData = (stockCodes, data, key) => {
        if (!stockCodes || !data || !key) { return []; }

        return (
            stockCodes.map(code => data.find(row => code === row[key].value))
        );
    }

    const prepareTableData = (main) => {
        const { tab, columns, data} = main;
        // origin
        let key = columns[0].id;
        // let key = ''

        let tableList = [];
        tab.forEach(tab => {
            tableList[tab.id] = {
                latest: mapData(tab.latest, data, key),
                backtest: mapData(tab.backtest, data, key),
                new: mapData(tab.new, data, key),
                removed: mapData(tab.removed, data, key)
            };
        });

        // console.log('tableList');
        // console.log(tableList);
        return tableList;
    }

    const currentTabData =
        panelData && currentTab ?
            prepareTableData(panelData.rawData.main)[currentTab]['latest'] :
            [];

    currentTabData.sort((a, b) => {
        let x = '';
        let y = '';

        if (Array.isArray(a[panelData.sortColumn])) {
            let xObj =
                a[panelData.sortColumn].find(x => x.tab_id === currentTab);
            let yObj =
                b[panelData.sortColumn].find(x => x.tab_id === currentTab);

            x = xObj ? xObj.value : '';
            y = yObj ? yObj.value : '';
        } else {
            x = a[panelData.sortColumn].value;
            y = b[panelData.sortColumn].value;
        }

        if (typeof x === 'string' || typeof y === 'string') {
            return (
                panelData.sortType === 'asc' ?
                    ('' + x).localeCompare(y) :
                    ('' + y).localeCompare(x)
            );
        }

        return panelData.sortType === 'asc' ? x - y : y - x;
    });

    const yearReturnRate =
        panelData && currentTab ?
            panelData.rawData.main.chart.find(x => x.tab_id === currentTab)
                .year_return_rate :
            null;

    const returnRateAcc =
        panelData && currentTab ?
            panelData.rawData.main.chart.find(x => x.tab_id === currentTab)
                .return_rate_acc :
            null;

    const indexYearReturnRate =
        panelData && currentTab ?
            panelData.rawData.main.chart.find(x => x.tab_id === currentTab)
                .index_year_return_rate :
            null;

    const indexReturnRateAcc =
        panelData && currentTab ?
            panelData.rawData.main.chart.find(x => x.tab_id === currentTab)
                .index_return_rate_acc :
            null;

    const backYear =
        panelData && currentTab ?
            panelData.rawData.main.chart.find(x => x.tab_id === currentTab)
                .back_year :
            null;

    const listData =
        currentTabData.slice((page - 1) * DisplayLength, page * DisplayLength);

    return panelData ? <StrategyList
        id={panelData.id}
        currentTab={currentTab}
        tabList={panelData.rawData.main.tab}
        setCurrentTab={setCurrentTab}
        display={panelData.rawData.main.display}
        trackingStockLoaded={trackingStockLoaded}
        showGraph={panelData.showGraph}
        onToggleGraphClick={onToggleGraphClick}
        pages={getPages()}
        page={panelData.page}
        onChangePage={onChangePage}
        graphChartData={panelData.rawData.main.chart?.find(chart => chart.tab_id === currentTab)}
        graphHeight={graphHeight}
        listAutoHeight={height ? false : true}
        listHeight={height + 9}
        listData={listData}
        currentTabData={currentTabData}
        columnList={panelData.rawData.main.columns}
        sortColumn={panelData.sortColumn}
        sortType={panelData.sortType}
        sortColumnData={sortColumnData}
        yearReturnRate={yearReturnRate}
        returnRateAcc={returnRateAcc}
        indexYearReturnRate={indexYearReturnRate}
        indexReturnRateAcc={indexReturnRateAcc}
        backYear={backYear}
    />: null
}

export default Container;