import * as Notices from '../actionTypes/notice';

const initialSate = {
    show: false,
    title: '',
    content: '',
    closeText: '',
    isShowStoreButtonVisible: false,
    onExit: null
};

export function notice(state = initialSate, action) {
    switch (action.type) {
        case Notices.SHOW_NOTICE: {
            return {
                show: true,
                title: action.title,
                content: action.content,
                closeText: action.closeText,
                link: action.link,
                onExit: action.onExit ? action.onExit : null
            }
        }
        case Notices.CLOSE_NOTICE: {
            return {
                ...state,
                show: false
            }
        }
        default: {
            return state;
        }
    }
}