import store from "Redux/store";
import { FETCH_PANELDATA, UPDATE_PANEL_DATA } from "Redux/actionTypes/dashboard/dashboard"
import { cloneDeep } from 'lodash'

/**
 * @constant
 * @memberof module:Dashboard
 * @function getPanelData
 * @param {object} panel - 由dashboard api來的面板資料
 * @param {object} parameters - 參數
 * @description 取得panelData, 並放到redux。
 */
export function getPanelData(panel ,parameters, callback)
{
    let params = cloneDeep(parameters);
    if (!params) { params = {}; }
    params.single = panel.id;
    store.dispatch({
        type: FETCH_PANELDATA,
        id: panel.id,
        params: {
            id: panel.model,
            single: panel.id,
            ...parameters
        }
    })    
}

/**
 * @constant
 * @memberof module:Dashboard
 * @param {string} panelId - 面板id
 * @function updatePanelData
 * @param {object} properties - 欲更新的面板屬性
 * @description 更新指定的panelData
 */
export function updatePanelData(panelId, properties){
    store.dispatch({
        type: UPDATE_PANEL_DATA,
        id: panelId,
        properties: properties
    })
}