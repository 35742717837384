import React from 'react';
import { Input, SelectPicker } from 'rsuite';

import IndexPicker from 'Components/indexPicker';
import { ConstraintOperator, MultiValueOperator } from 'Src/constant/operator';
import { getIndexUnit } from 'Util/getIndexUnit';

const renderOperandComponent = (expression, unit, onChange, indexLibrary) => {
    switch (expression?.operator) {
        case 'greater':
        case 'equal':
        case 'less':
        case 'greaterEqual':
        case 'lessEqual':
        case 'rankGreater':
        case 'rankExcludeGreater':
        case 'rankGreaterEqual':
        case 'rankExcludeGreaterEqual':
        case 'rankLess':
        case 'rankExcludeLess':
        case 'rankLessEqual':
        case 'rankExcludeLessEqual':
            return (
                <div className="operand-component">
                    <Input
                        placeholder="輸入數值"
                        value={expression.value}
                        onChange={value => onChange({ value: value })} />
                    {
                        unit &&
                        <span style={{ marginLeft: 7 }}>{unit}</span>
                    }
                </div>
            );
        case 'greaterIndex':
        case 'equalIndex':
        case 'lessIndex':
        case 'greaterEqualIndex':
        case 'lessEqualIndex':
            return (
                <IndexPicker
                    className="operand-component"
                    value={expression.index2}
                    excludeMultiValue={true}
                    indexLibrary={indexLibrary ? indexLibrary : []}
                    onChange={index => onChange({ index2: index })} />
            );
        case 'between':
            return (
                <div className="operand-component">
                    <Input
                        placeholder="輸入數值"
                        style={{ flex: 1 }}
                        value={expression.rangeFrom}
                        onChange={value => onChange({ rangeFrom: value })} />
                    <span style={{ marginLeft: 7, marginRight: 7 }}>~</span>
                    <Input
                        placeholder="輸入數值"
                        style={{ flex: 1 }}
                        value={expression.rangeTo}
                        onChange={value => onChange({ rangeTo: value })} />
                    {
                        unit &&
                        <span style={{ marginLeft: 7 }}>{unit}</span>
                    }
                </div>
            );
        default:
            return <div className="operand-component" />;
    }
};

const removeExcessOperand = expression => {
    switch (expression?.operator) {
        case 'greater':
        case 'equal':
        case 'less':
        case 'greaterEqual':
        case 'lessEqual':
        case 'rankGreater':
        case 'rankExcludeGreater':
        case 'rankGreaterEqual':
        case 'rankExcludeGreaterEqual':
        case 'rankLess':
        case 'rankExcludeLess':
        case 'rankLessEqual':
        case 'rankExcludeLessEqual':
            delete expression.index2;
            delete expression.rangeFrom;
            delete expression.rangeTo;
            break;
        case 'greaterIndex':
        case 'equalIndex':
        case 'lessIndex':
        case 'greaterEqualIndex':
        case 'lessEqualIndex':
            delete expression.value;
            delete expression.rangeFrom;
            delete expression.rangeTo;
            break;
        case 'between':
            delete expression.value;
            delete expression.index2;
            break;
        default:
            delete expression.value;
            delete expression.index2;
            delete expression.rangeFrom;
            delete expression.rangeTo;
    }

    return expression;
}

const renderOperatorPickerMenuItem = (_, item) => {
    let color;
    const lowerCase = item.value.toLowerCase();

    if (lowerCase.includes('greater') || lowerCase.includes('asc')) {
        color = 'red';
    } else if (lowerCase.includes('less') || lowerCase.includes('desc')) {
        color = 'green';
    }

    return color ? <span className={color}>{item.label}</span> : item.label;
};

const Component = ({
    className, useTwoRowLayout, expression, indexLibrary, onChange,
    disableAscDesc
}) => {
    let operators =
        expression.index && expression.index.dataType &&
            expression.index.dataType.multivalue ?
            [...ConstraintOperator, ...MultiValueOperator] :
            ConstraintOperator;

    if (disableAscDesc) {
        operators =
            operators.filter(x =>
                !x.value.toLowerCase().includes('asc') &&
                !x.value.toLowerCase().includes('desc')
            );
    }

    const _onChange = value => {
        let expr = { ...expression, ...value };

        if (
            expr.index && expr.index.dataType &&
            !expr.index.dataType.multivalue &&
            (expr.operator === 'increase' || expr.operator === 'decrease')
        ) {
            expr.operator = 'equal';
        }

        expr = removeExcessOperand(expr);

        onChange(expr);
    };

    const unit =
        expression.operator && expression.operator.startsWith('rank') ?
            '%' :
            getIndexUnit(expression.index);

    let name = 'index-expression';
    if (className) {
        name += ' ' + className;
    }

    if (!useTwoRowLayout) {
        return (
            <div className={name}>
                <IndexPicker
                    className="operand-component"
                    value={expression.index}
                    indexLibrary={indexLibrary}
                    onChange={value => _onChange({ index: value })} />
                <SelectPicker
                    className="operator-picker"
                    title="選擇"
                    data={operators}
                    cleanable={false}
                    searchable={false}
                    value={expression.operator}
                    onChange={value => _onChange({ operator: value })}
                    renderMenuItem={renderOperatorPickerMenuItem}
                    renderValue={renderOperatorPickerMenuItem} />
                {renderOperandComponent(expression, unit, _onChange, indexLibrary)}
            </div>
        );
    } else {
        name += ' two-row';

        return (
            <div className={name}>
                <div className="row">
                    <IndexPicker
                        className="operand-component"
                        value={expression.index}
                        indexLibrary={indexLibrary}
                        onChange={value => _onChange({ index: value })} />
                </div>
                <div className="row">
                    <SelectPicker
                        className="operator-picker"
                        title="選擇"
                        data={operators}
                        cleanable={false}
                        searchable={false}
                        value={expression.operator}
                        onChange={value => _onChange({ operator: value })}
                        renderMenuItem={renderOperatorPickerMenuItem}
                        renderValue={renderOperatorPickerMenuItem} />
                    {renderOperandComponent(expression, unit, _onChange, indexLibrary)}
                </div>
            </div>
        );
    }

};

export default Component;