import React, { useEffect, useRef, useReducer } from 'react';
import { hot } from 'react-hot-loader/root';
import * as StockPickerActions from 'Redux/actionTypes/stockPicker';
import { reducer, initialSate, init} from './container/reducer';
import {
    AutoComplete,
    Icon,
    InputGroup
} from 'rsuite';

import './style.scss';

function StockPicker( { data, onSelect, clearOnSelect, icon} ){
    const [state, dispatch] = useReducer(reducer, initialSate, init);
    const autoComplete = useRef(null)
    useEffect(() => {
        dispatch({ 
            type: StockPickerActions.UPDATE_AUTOCOMPLETEARRAY,
            data: data
        })
    },[data])
    const setValue = (value) => {
        dispatch({
            type: StockPickerActions.SET_VALUE,
            value: value
        })
    }

    const onPickerSelect = item => {
        onSelect(item);

        // Add delay to overwrite the value set by AutoComplete's onSelect
        setTimeout(() =>
            dispatch({
                type: StockPickerActions.SET_VALUE,
                value: ''
            })
        );
    };

    const onKeyUp = (e) => {
        const found = autoComplete.current.getFocusableMenuItems();

        if (
            (e.key === 'Enter' || e.keyCode === 13) && (
                found.length === 1 &&
                autoComplete.current.state.focusItemValue === ''
            )
        ) {
            onSelect(...found);
            dispatch({
                type: StockPickerActions.SET_VALUE,
                value: ''
            });
            autoComplete.current.close();
        }
    }
    return <InputGroup className="stock-picker" inside>
        <AutoComplete
            ref={autoComplete}
            value={state.value}
            data={state.autoCompleteArray}
            onChange={setValue}
            onSelect={onPickerSelect}
            onKeyUp={onKeyUp}
            placeholder="搜尋股票/代號"
            selectOnEnter={true}
        />
        <InputGroup.Addon>
            <Icon icon={icon ? icon : 'search'} />
        </InputGroup.Addon>
    </InputGroup>
}


// export default StockPicker;
export default hot(StockPicker);