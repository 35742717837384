import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Component from './component';
import { track } from 'Redux/actionCreators/stock';

import './component';

const Container = ({ currentTab }) => {
    const stockPool = useSelector(store => store.stock.pool);
    const [show, setShow] = useState(false);

    const showModal = () => {
        setShow(true);
    };

    const onModalHide = () => {
        setShow(false);
    };

    const onStockSelected = (a) => {
        track(currentTab, a.stock_code, true);
    };

    return (
        <Component
            stockPool={stockPool}
            show={show}
            showModal={showModal}
            onModalHide={onModalHide}
            onStockSelected={onStockSelected} />
    );
};

export default Container;