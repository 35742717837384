import React, { Component } from 'react';
import Question from 'Image/svgIcon/dashboard/quesion-1.svg';
import Delete from 'Image/svgIcon/dashboard/delete-2.svg';
import { Icon } from "rsuite"; 
import './gridPanel.css';

export default class GridPanel extends Component {
    static renderButton(buttonList) {
        return (
            buttonList ?
                buttonList.map(button =>
                    <div key={button.id} className="button">
                        {button.component}
                    </div>
                ) :
                null
        );
    }

    static create(key, title, component, buttonList, showDelete) {
        // console.log(key);
        // console.log(key);
        return (
            <div key={key} className="grid">
                <div className="grid-title">
                    <span className="grid-title-text">{title}</span>
                    {
                        buttonList ?
                        buttonList.map(button =>
                            <div key={button.id} className="button">
                                {button.component}
                            </div>
                        ) :
                        null
                    }
                {
                    showDelete ? <> 
                        <div className="button">
                            <Icon icon={Question} size="lg"/>
                        </div>
                        <div className="button">
                            <Icon icon={Delete} size="lg"/>
                        </div>
                    </>:null
                }
                </div>
                <div className="grid-content">
                    {component}
                </div>
            </div>
        );
    }
}