// items 陣列
// 檢查元件是否已可以顯示
export const readyCheck = (items) => {
    let ready = true;
    for (let index = 0; index < items.length; index++) {
        if(!items[index])
        {
            ready = false
        }
    }
    return ready;
}