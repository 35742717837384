import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_DASHBOARD } from "Redux/actionTypes/dashboard/dashboard";
// import { FETCH_TRACKING_STOCK } from "Redux/saga/actionTypes/stock"
import Dashboard from "../dashboard";
import getActivePanelList from '../components/getActivePanelList';
import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

import { cookies } from 'Https/fetch';

/**
 * 
 * @memberof module:Dashboard
 * @description 戰情室儀表模組，資料處理、決定render哪一個元件。
 */

function Container({ layout, singles })
{
    const dispatch = useDispatch()
    const dashboard = useSelector(store => store.dashboard)
    const location = useLocation();
    const isLoggedIn = cookies.get('accessToken');

    useEffect(() => {
        dispatch({ type: CLEAR_DASHBOARD });
        setTimeout(() => getActivePanelList(singles, layout, 50, isLoggedIn));
        // Add location as dependency to solve blank dashboard due to empty
        // activePanelList when navigating to the same page
    }, [dispatch, singles, layout, location, isLoggedIn]);

    return <Dashboard
        showDelete={false}
        cols={3}
        rowHeight={50}
        layout={dashboard.layout}
        singles={singles}
        activePanelList={dashboard.activePanelList}
        // onLayoutChange={onLayoutChange}
    />
}

export default Container;