import React from 'react';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    Icon,
    Popover,
    Whisper
} from 'rsuite';

import IconAvatar from 'Image/svgIcon/avatar-2.svg';
import LoginButton from '../../../loginButton';
import Logo from 'Image/svgIcon/logo.svg';
import Message from 'Components/message';
import StockPicker from 'Components/stockPicker/index.js';
import { cookies } from 'Https/fetch';

import './style.scss';

function Header({
    profile,
    pricingPlan,
    onStockSelected,
    avatarRef,
    closeAvatarMenu,
    showProfile,
    showStore,
    showSubscription,
    logout,
    isAvatarMenuOpened,
    onOpenAvatarMenu,
    onCloseAvatarMenu,
    stockPool,
    showStockPicker,
    strategySidebarVisibility,
    showLoginButton
}) {
    const renderMenu = () => {
        return (
            <Popover className="layout-popover avatar__popover" full>
                <Dropdown.Menu onSelect={closeAvatarMenu}>
                    <Dropdown.Item onSelect={showProfile}>
                        <div className="profile">
                        <Icon icon={IconAvatar} className="avatar" />
                        <div>
                            <div>{profile.username}</div>
                            <div>{profile.email}</div>
                        </div>
                        </div>
                    </Dropdown.Item>
                    <hr />
                    <div className="pricing-plan">
                        目前使用：{pricingPlan}
                    </div>
                    <hr />
                    <Dropdown.Item onSelect={showStore}>
                        付費方案
                    </Dropdown.Item>
                    <Dropdown.Item onSelect={showSubscription}>
                        查詢訂閱紀錄
                    </Dropdown.Item>
                    <hr />
                    <Dropdown.Item onSelect={logout}>
                        登出
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Popover>
        );
    };

    let headerRightClassName = 'header__right';
    if (strategySidebarVisibility) {
        headerRightClassName += ' strategy__sidebar__close';
    }

    let isLoggedIn = cookies.get('accessToken');

    return (
        <div className="header">
            <div className="header__left">
                <Link to="/dashboard">
                    <div className="header__left__container">
                        <Icon
                            icon={Logo}
                            svgStyle={{ verticalAlign: 'middle', height: 30 }} />
                    </div>
                </Link>
            </div>
            <div className="header__middle">
                <div>
                    {
                        showStockPicker &&
                        <div className="header__middle__search">
                            <StockPicker
                                clearOnSelect
                                data={stockPool}
                                onSelect={onStockSelected} />
                        </div>
                    }
                </div>
            </div>
            <div className={headerRightClassName}>
                {
                    isLoggedIn &&
                    <>
                        <Message />
                        <Whisper
                            ref={avatarRef}
                            placement="bottomEnd"
                            trigger="click"
                            speaker={renderMenu()}
                            onOpen={onOpenAvatarMenu}
                            onClose={onCloseAvatarMenu}>
                            <div className="avatar__container clickable">
                                <Icon icon={IconAvatar} className="avatar" />
                                <div className="avatar__arrow">
                                    {isAvatarMenuOpened ? '▲' : '▼'}
                                </div>
                            </div>
                        </Whisper>
                    </>
                }
                {
                    !isLoggedIn && showLoginButton &&
                    <LoginButton />
                }
            </div>
        </div>
    );
}

export default Header;