import React, { Component } from 'react';
import {
    Button,
    Icon,
    Input,
    InputGroup,
    Modal,
    Tree,
    SelectPicker
} from 'rsuite';

import IconRightArrow from 'Image/svgIcon/right-arrow.svg';
import IndexUtil from '../indexUtil';

import './indexLibraryModal.css';

var _ = require('lodash');

const adjustOptions = [
    {label: '的幾%', value: 'percent'},
    {label: '加幾', value: 'plus'},
    {label: '減幾', value: 'minus'},
    {label: '不運算', value: 'none'}
];

export default class IndexLibraryModal extends Component {
    state = {
        timeList: [{ label: '最新', value: 'latest' }],
        indexList: [],
        rangeList: [],
        dataTypeList: [],
        selectedIndex: {},
        selectedRange: {},
        customRange: null,
        selectedDataType: {},
        customDataType: null,
        showStep4: false,
        adjust: { type: 'none' },
        selectedTimeOption: 'latest',
        customTime: null,
        searchText: '',
        expandIndexCategory: []
    };
    indexLibrary = [];

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            this.setState({
                timeList: [{ label: '最新', value: 'latest' }],
                rangeList: [],
                dataTypeList: [],
                selectedIndex: {},
                selectedRange: {},
                customRange: null,
                selectedDataType: {},
                customDataType: null,
                showStep4: false,
                adjust: { type: 'none' },
                selectedTimeOption: 'latest',
                customTime: null,
                searchText: '',
                expandIndexCategory: []
            });

            // Handle selected index passed into props
            if (this.props.selectedIndex && this.props.selectedIndex.value) {
                let index = this.props.selectedIndex;

                // Set index
                this.onIndexSelect(index.value);

                // Expand the category
                let category =
                    this.indexLibrary.filter(
                        category => category.children.some(
                            item => item.value === index.value.value))[0];
                if (category) {
                    this.setState({ expandIndexCategory: [category.value] });
                }

                // Set range and data type
                if (index.range) {
                    let rangeList = [];
                    index.value.ranges.forEach(category =>
                        category.children.forEach(range => rangeList.push(range))
                    );

                    let range =
                        rangeList.filter(
                            range => range.value === index.range.value)[0];

                    if (range) {
                        this.onRangeSelect(range);
                        this.onRangeInputChange(index.range.customValue);

                        if (index.dataType && range.data_types) {
                            let dataType =
                                range.data_types.filter(
                                    dataType =>
                                        dataType.value === index.dataType.value
                                )[0];

                            if (dataType) {
                                this.onDataTypeSelect(dataType);
                                this.onDataTypeInputChange(
                                    index.dataType.customValue);
                            }
                        }

                        // Set time
                        if (index.time) {
                            this.onTimeOptionSelect(index.time.value);
                            this.onTimeChange(index.time.customValue);
                        }
                    }
                }

                // Set adjustment
                if (index.adjust) {
                    let adjustType = index.adjust.type;
                    if (adjustType === 'value') {
                        adjustType = index.adjust.value >= 0 ? 'plus' : 'minus';
                    }

                    this.setState({
                        adjust: {
                            type: adjustType,
                            value: Math.abs(index.adjust.value)
                        },
                        showStep4: true
                    });
                }
            }
        }

        if (this.indexLibrary === this.props.data) { return; }
        this.indexLibrary = this.props.data;

        this.setState({ indexList: this.indexLibrary });
    }

    getIndexString() {
        let index = this.getSelectedIndex();

        return (
            !this.props.disableInput ?
                IndexUtil.getIndexJSX(index) :
                IndexUtil.getGraphIndexText(index)
        );
    }

    updateTimePicker(unit) {
        let optionList = [
            { label: '最新', value: 'latest' },
            { label: 'Y' + unit + '前', value: 'custom' }
        ];
        this.setState({ timeList: optionList });
    }

    onTimeOptionSelect(value) {
        this.setState({ selectedTimeOption: value, customTime: null });
    }

    onTimeChange(value) {
        this.setState({ customTime: value });
    }

    // Step 1
    onSearchTextChange(value) {
        this.setState({ searchText: value });
    }

    onIndexSelect(data) {
        if (!data.ranges) {
            let expandIndexCategory =
                this.state.expandIndexCategory.includes(data.value) ?
                    [] : [data.value];

            this.setState({
                expandIndexCategory: expandIndexCategory,
                rangeList: [],
                dataTypeList: [],
                selectedIndex: {},
                selectedRange: {},
                selectedDataType: {}
            });
            return;
        }

        let ranges = data.ranges;

        if (this.props.disableInput) {
            ranges = [];
            data.ranges.forEach(category => {
                category.children
                    .filter(range => !range.input)
                    .forEach(range => {
                        range.label = this.getGraphRangeLabel(range.value);
                        ranges.push(range);
                    });
            });
        }

        this.setState({ selectedIndex: data, rangeList: ranges });
    }

    // Step 2
    onRangeSelect(data) {
        // Forbid selecting a category
        // Only category has children
        if (data.children) { return; }

        this.updateTimePicker(data.unit);

        let dataTypeList =
            data.data_types && this.props.excludeMultiValue ?
                data.data_types.filter(dataType => !dataType.multivalue) :
                data.data_types;

        this.setState({
            selectedRange: data,
            dataTypeList: dataTypeList,
            selectedDataType: {}
        });
    }

    onRangeInputChange(value) {
        this.setState({ customRange: value });
    }

    // Step 3
    onDataTypeSelect(data) {
        this.setState({ selectedDataType: data });
    }

    onDataTypeInputChange(value) {
        this.setState({ customDataType: value });
    }

    toggleAdvancePanel() {
        let value = !this.state.showStep4;

        // Reset adjustment when advance panel closes
        if (!value) {
            this.setState({ adjust: { type: 'none' } });
        }

        this.setState({ showStep4: value });
    }

    // Step 4
    onAdjustSelect(data) {
        let state =
            data.value !== 'none' ?
                { ...this.state.adjust, type: data.value } :
                { type: data.value };

        this.setState({ adjust: state });
    }

    onAdjustInputChange(value) {
        let state = { ...this.state.adjust, value: value };

        this.setState({ adjust: state });
    }

    renderAdjustInput() {
        switch (this.state.adjust?.type) {
            case 'percent':
                return (
                    <div className="treeCustomInput">
                        的
                        <Input
                            value={this.state.adjust?.value}
                            onChange={value => this.onAdjustInputChange(value)} />
                        %
                    </div>
                );
            case 'plus':
                return (
                    <div className="treeCustomInput">
                        加
                        <Input
                            value={this.state.adjust?.value}
                            onChange={value => this.onAdjustInputChange(value)} />
                    </div>
                );
            case 'minus':
                return (
                    <div className="treeCustomInput">
                        減
                        <Input
                            value={this.state.adjust?.value}
                            onChange={value => this.onAdjustInputChange(value)} />
                    </div>
                );
            default:
                return;
        }
    }

    // Return value when the modal is closed
    onClose() {
        if (!this.props.onClose) { return; }
        this.props.onClose(this.getSelectedIndex());
    }

    getSelectedIndex() {
        let index =
            this.state.selectedIndex ? this.state.selectedIndex : {};

        let range = { value: this.state.selectedRange.value };
        if (this.state.selectedRange.input) {
            range.customValue = this.state.customRange;
        }

        let dataType = _.cloneDeep(this.state.selectedDataType);
        if (this.state.selectedDataType.input) {
            dataType.customValue = this.state.customDataType;
        }

        let time = { value: this.state.selectedTimeOption };
        if (this.state.selectedTimeOption.value !== 'latest') {
            time.customValue = this.state.customTime;
        }

        let selected = {
            value: index,
            range: range,
            dataType: dataType,
            time: time
        };

        if (this.state.adjust.type !== 'none') {
            let type =
                this.state.adjust.type === 'percent' ? 'percent' : 'value';
            let value =
                this.state.adjust.type === 'minus' ?
                    -this.state.adjust.value : this.state.adjust.value;
            value = parseFloat(value);

            if (type === 'value' && value === 0) {
                selected.adjust = { type: 'none' };
            } else {
                selected.adjust = { type: type, value: value };
            }
        }

        return selected;
    }

    getGraphRangeLabel(value) {
        switch (value) {
            case 'day_latest': return '日資料';
            case 'week_latest': return '周資料';
            case 'month_latest': return '月資料';
            case 'quarter_latest': return '季資料';
            case 'year_latest': return '年資料';
            case 'cumulative_latest': return '季資料累計';
            default: return null;
        }
    }

    checkIndexSelected() {
        // Check step 1
        if (!this.state.selectedIndex || !this.state.selectedIndex.value) {
            return false;
        }

        // Check step 2
        if (
            !this.state.selectedRange || !this.state.selectedRange.value ||
            (this.state.selectedRange.input && !this.state.customRange)
        ) {
            return false;
        }

        // Check step 3
        if (
            this.state.dataTypeList && this.state.dataTypeList.length !== 0 &&
            (
                !this.state.selectedDataType ||
                !this.state.selectedDataType.value ||
                (
                    !this.props.disableInput &&
                    this.state.selectedDataType.input &&
                    !this.state.customDataType
                )
            )
        ) {
            return false;
        }

        // Check step 4
        if (
            this.state.adjust && this.state.adjust.type !== 'none' &&
            isNaN(this.state.adjust.value)
        ) {
            return false;
        }

        // Check time
        if (
            !this.state.selectedTimeOption ||
            (
                this.state.selectedTimeOption !== 'latest' &&
                !this.state.customTime
            )
        ) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <Modal
                className="index-library-modal"
                show={this.props.show}
                size="lg"
                onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>選擇指標</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    {
                        !this.props.hideTime &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            選取的時間：
                            <SelectPicker
                                data={this.state.timeList}
                                searchable={false}
                                style={{ width: 88 }}
                                cleanable={false}
                                defaultValue={this.state.selectedTimeOption}
                                onSelect={value => this.onTimeOptionSelect(value)} />
                            {
                                this.state.selectedTimeOption === 'custom' &&
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        placeholder="輸入時間"
                                        style={{ width: 90, marginLeft: 11 }}
                                        defaultValue={this.state.customTime}
                                        onChange={value => this.onTimeChange(value)} />
                                    <span style={{ marginLeft: 7, display: 'flex', alignItems: 'center' }}>
                                        {this.state.selectedRange.unit + '前'}
                                    </span>
                                </div>
                            }
                        </div>
                    }

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: !this.props.hideTime ? 25 : 0 }}>
                        已選擇指標：
                        {
                            <div className="indexTime">
                                {this.getIndexString()}
                            </div>
                        }
                    </div>

                    <div style={{ display: 'flex', marginTop: 27 }}>
                        <div style={{ flex: 3 }}>
                            <span className="sub-text">第一步：選擇指標</span>
                            <div className="treeContainer">
                                <InputGroup>
                                    <Input
                                        placeholder="搜尋指標"
                                        onChange={value => this.onSearchTextChange(value)} />
                                    <InputGroup.Button>
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <Tree
                                    data={this.state.indexList}
                                    searchKeyword={this.state.searchText}
                                    expandItemValues={this.state.expandIndexCategory}
                                    locale={{ noResultsText: '無選項' }}
                                    defaultValue={this.state.selectedIndex.value}
                                    onSelect={nodeData => this.onIndexSelect(nodeData)} />
                            </div>
                        </div>
                        <div style={{ flex: 2, marginLeft: 13, marginRight: 13 }}>
                            <span className="sub-text">
                                第二步：
                                {
                                    !this.props.disableInput ?
                                        '取單值或多值' : '選資料期間'
                                }
                            </span>
                            <div className="treeContainer">
                                <Tree
                                    data={this.state.rangeList}
                                    locale={{ noResultsText: '無選項' }}
                                    expandItemValues={['singleValue', 'multiValue']}
                                    value={this.state.selectedRange.value}
                                    onSelect={nodeData => this.onRangeSelect(nodeData)} />
                                {
                                    this.state.selectedRange.value &&
                                    this.state.selectedRange.value.endsWith('_n') &&
                                    <div className="treeCustomInput">
                                        近
                                        <Input
                                            defaultValue={this.state.customRange}
                                            onChange={value => this.onRangeInputChange(value)} />
                                        {this.state.selectedRange.unit}...
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            !this.props.disableInput &&
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                <span className="sub-text">
                                    第三步：算法 (只適用於多值)
                                </span>
                                <div className="treeContainer">
                                    <Tree
                                        className="single-layer"
                                        data={this.state.dataTypeList}
                                        locale={{ noResultsText: '無選項' }}
                                        value={this.state.selectedDataType.value}
                                        onSelect={nodeData => this.onDataTypeSelect(nodeData)} />
                                    {
                                        this.state.selectedDataType.value === 'include' &&
                                        <div className="treeCustomInput">
                                            有
                                            <Input
                                                defaultValue={this.state.customDataType}
                                                onChange={value => this.onDataTypeInputChange(value)} />
                                            {this.state.selectedRange.unit}皆...
                                        </div>
                                    }
                                </div>
                                <Button
                                    className="advance"
                                    appearance="link"
                                    onClick={() => this.toggleAdvancePanel()}>
                                    進階運算<Icon icon={IconRightArrow} />
                                </Button>
                            </div>
                        }
                        {
                            !this.props.disableInput &&
                            this.state.showStep4 &&
                            <div style={{ flex: 2, marginLeft: 13 }}>
                                <span className="sub-text">
                                    第四步：%數或加減
                                </span>
                                <div className="treeContainer">
                                    <Tree
                                        className="single-layer"
                                        data={adjustOptions}
                                        locale={{ noResultsText: '無選項' }}
                                        value={this.state.adjust.type}
                                        onSelect={nodeData => this.onAdjustSelect(nodeData)} />
                                    {this.renderAdjustInput()}
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={!this.checkIndexSelected()}
                        onClick={() => this.onClose()}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}