import React from 'react';

import AddStockModal from './component/addStockModal';
import store from 'Redux/store';
import Menu from './component/menu';
import { REPLACE_ACTIVEPANELLIST } from 'Redux/actionTypes/dashboard/dashboard';

export default function buildButtonList(panelId, currentTab, setCurrentTab) {
    const activePanelList = store.getState().dashboard.activePanelList;
    const panel = activePanelList.find(o => o.id === panelId);

    panel.buttonList = [
        {
            id: 'add',
            component: <AddStockModal currentTab={currentTab} />
        },
        {
            id: 'settings',
            component:
                <Menu
                    panel={panel}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab} />
        }
    ];

    store.dispatch({
        type: REPLACE_ACTIVEPANELLIST,
        activePanelList: activePanelList
    });
};