import { REMOVE_A_PAGINATION, SET_A_PAGINATION, CHANGE_PAGE } from "../actionTypes/pagination";
import store from "../store";
export function setAPagination(id, maxButtons){
    store.dispatch({
        type: SET_A_PAGINATION,
        id: id,
        maxButtons: maxButtons
    })
}
export function removeAPagination(){
    store.dispatch({
        type: REMOVE_A_PAGINATION
    })
}

export function changePage(id, page){
    store.dispatch({
        type: CHANGE_PAGE,
        id: id,
        page: page
    })
}