import React, { useState } from 'react';
import { Button, Icon } from 'rsuite';

import Card from 'Components/card';
import cards from './cards';
import IconLogo from 'Image/svgIcon/logo.svg';
import TutorialModal from 'Components/tutorialModal';

import './style.scss';

const renderRows = (mode, setDetailItems) =>
    cards[mode].map((x, i) =>
        <Card
            key={'card-' + i}
            image={x.image}
            title={x.title}
            background={x.background}
            onClick={() => setDetailItems(x.detail)}>
            {x.content}
        </Card>
    );

const Container = () => {
    const [mode, setMode] = useState('copyStrategy');
    const [detailItems, setDetailItems] = useState();

    return (
        <div className="tutorial-page">
            <div className="logo-header">
                <Icon
                    icon={IconLogo}
                    svgStyle={{ verticalAlign: 'middle', height: 60 }} />
                <div className="logo-text">使用秘訣</div>
            </div>
            <div class="intro">
                複製知名策略、自建投資策略、回測不需寫程式、每日自動選股
            </div>
            <div className="switcher">
                <Button
                    className={mode === 'copyStrategy' ? 'active' : 'not-active'}
                    onClick={() => setMode('copyStrategy')}>
                    複製知名策略
                </Button>
                <Button
                    className={mode === 'customStrategy' ? 'active' : 'not-active'}
                    onClick={() => setMode('customStrategy')}>
                    自建策略秘訣
                </Button>
            </div>
            <div className="cards">
                {renderRows(mode, setDetailItems)}
            </div>
            <TutorialModal items={detailItems} onHide={setDetailItems} />
        </div>
    )
}

export default Container;