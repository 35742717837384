export const UPDATE_PANEL_DATA = 'UPDATE_PANEL_DATA';
export const CLEAR_PANEL_DATA = 'CLEAR_PANEL_DATA';
export const INSERT_ACTIVEPANELLIST = 'INSERT_ACTIVEPANELLIST';
export const UPDATE_ACTIVEPANELLIST = 'UPDATE_ACTIVEPANELLIST';
export const UPDATE_DASHBOARD_LAYOUT = 'UPDATE_DASHBOARD_LAYOUT';
export const CLEAR_ACTIVEPANELLIST = 'CLEAR_ACTIVEPANELLIST';
export const CLEAR_DASHBOARD_LAYOUT = 'CLEAR_DASHBOARD_LAYOUT';
export const REPLACE_ACTIVEPANELLIST = 'REPLACE_ACTIVEPANELLIST';
export const INSERT_PANEL_DATA = "INSERT_PANEL_DATA";
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const CLEAR_FAVORITE_GROUP = "CLEAR_FAVORITE_GROUP";
export const FETCH_PANELDATA = 'FETCH_PANELDATA';
export const AFTER_REMOTE_DELETE_PANEL = 'AFTER_REMOTE_DELETE_PANEL';