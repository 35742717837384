import React, { Component } from 'react';
import { Icon } from 'rsuite';

import IconClose from '../../image/svgIcon/close.svg';

import './previewGrid.css';

export default class PreviewGrid extends Component {
    // Can't use render() here
    // react-grid-layout needs React component
    static create(id, name, onRemove, enableEdit) {
        return (
            <div className="grid preview-grid" key={id}>
                {
                    enableEdit &&
                    <div className="remove">
                        <Icon icon={IconClose} onClick={onRemove} />
                    </div>
                }
                {name}
            </div>
        );
    }
}