export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_ALERTS = 'UPDATE_ALERTS';
export const SET_IF_ALERT = 'SET_IF_ALERT';
export const TOGGLE_MESSAGE_BOX = 'TOGGLE_MESSAGE_BOX';
export const SET_ALL_TASKS_READ = 'SET_ALL_TASKS_READ';
export const TOGGLE_ALERT_BOX = 'TOGGLE_ALERT_BOX';
export const SET_ALL_EMAILS_READ = 'SET_ALL_EMAILS_READ';
export const READ_TASK = 'READ_TASK';
export const READ_EMAIL = 'READ_EMAIL';
export const CHECK_ALERT = 'CHECK_ALERT';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const ADD_ALERT = 'ADD_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
