import React from 'react';
import Dashboard from 'Components/dashboard/dashboard/index';
import './style.scss';

export default function Component({ singles, layout })
{
    return (
        <div className="market-page">
            <div>
                <div className="page-title">大盤</div>
            </div>
            <hr />
            <Dashboard
                enableEdit={false}
                singles={singles}
                layout={layout}
            />
        </div>
    )
}