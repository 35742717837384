
import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './performanceGraph.css';
import moment from 'moment'
var _ = require('lodash');


const timeFormats = {
    4: 'YYYY',
    6: 'YYYYMM',
    8: 'YYYYMMDD'
}
const format = '{value}';



const performanceGraphOption = {
    chart: {
        backgroundColor: 'transparent',
        type: 'area',
        zoomType: 'x',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 1,
    },
    credits: {
        enabled: false
    },
    colors: ['#00ddff', '#ff9600'],
    plotOptions: {
        area: {
            marker: { enabled: false },
            fillOpacity: 0.2
        }
    },
    title: null,
    legend: {
        enabled: false
    },
    xAxis: {
        labels: {
            style: { color: '#fff', fontSize: '12px' },
            format: '{value:%Y}'
        },

        plotLines: [
            // {
            //     color: 'rgba(255, 255, 255, 0.6)',
            //     width: 0.5,
            //     value: 2
            // }
        ]
    },
    yAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.3)',
        opposite: true,
        labels: {
            // format: '{value}',


            format: '',
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            x: 7,
            y: 0
        },
        title: null
    },
    series: [
        {
            name: '投資策略',
            data: []
        },
        {
            name: '大盤',
            fillColor: 'transparent',
            data: []
        }
    ]
};

const formatValue = value => {
    return value > 0 ? '+' + value : value;
};

let yearReturnRate, returnRateAcc, indexYearReturnRate, indexReturnRateAcc,
    backYear;

export default class PerformanceGraph extends Component {
    options = _.cloneDeep(performanceGraphOption);
    
    constructor(props) {
        super(props);

        yearReturnRate = props.yearReturnRate;
        returnRateAcc = props.returnRateAcc;
        indexYearReturnRate = props.indexYearReturnRate;
        indexReturnRateAcc = props.indexReturnRateAcc;
        backYear = props.backYear;

        this.updateGraphOption(props.data);
    }
    
    componentDidUpdate(prevProps) {
        if (
            _.isEqual(prevProps.data, this.props.data) &&
            prevProps.yearReturnRate === this.props.yearReturnRate &&
            prevProps.returnRateAcc === this.props.returnRateAcc &&
            prevProps.backYear === this.props.backYear
        ) {
            return;
        }

        yearReturnRate = this.props.yearReturnRate;
        returnRateAcc = this.props.returnRateAcc;
        indexYearReturnRate = this.props.indexYearReturnRate;
        indexReturnRateAcc = this.props.indexReturnRateAcc;
        backYear = this.props.backYear;

        this.updateGraphOption(this.props.data);
        this.graph.chart.update(this.options);
        this.graph.chart.reflow();
    }

    updateGraphOption(data) {
        this.options.xAxis.categories =
            data ? Object.keys(data.data[0].data) : [];

        this.options.xAxis.plotLines =
            data ?
                [{
                    color: 'rgba(255, 255, 255, 0.6)',
                    width: 0.5,
                    value: this.backTestTimeLinePosition(data),
                    label: {
                        text: '回測/真實報酬率分隔線',
                        rotation: 0,
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                        y: 16,
                        x: -132
                    }
                }] :
                [];

        this.options.series[0].name = data ? data.data[0].name : '';
        this.options.series[0].data =
            data ? Object.values(data.data[0].data) : [];

        this.options.series[1].name = data ? data.data[1].name : '';
        this.options.series[1].data =
            data ? Object.values(data.data[1].data) : [];
        this.options.yAxis.labels.format =
            data ? format + data.data[0].unit : '';

        this.options.chart.events = {
            render: function () {
                if (
                    yearReturnRate == null || returnRateAcc == null ||
                    indexYearReturnRate == null || indexReturnRateAcc == null ||
                    backYear == null
                ) {
                    return;
                }

                let text =
                    '年化報酬率：<span class="blue">' + formatValue(yearReturnRate) + '%</span><br />' +
                    '累積報酬率：<span class="blue">' + formatValue(returnRateAcc) + '%</span> (' + backYear + '年)<br />' +
                    '年化報酬率：<span class="orange">' + formatValue(indexYearReturnRate) + '%</span><br />' +
                    '累積報酬率：<span class="orange">' + formatValue(indexReturnRateAcc) + '%</span> (' + backYear + '年)';

                if (!this.returnRateLabel) {
                    this.returnRateLabel =
                        this.renderer.text(text, 15, 26, true)
                            .css({
                                fontSize: '12px',
                                lineHeight: 1.5,
                                color: 'rgba(255, 255, 255, 0.5)'
                            })
                            .add();
                }

                this.returnRateLabel.attr({ text: text });
            }
        }
    }

    backTestTimeLinePosition(data) {
        const dates = Object.keys(data.data[0].data);
        const backTestTime = moment(data.backtest_date, 'YYYYMMDD').valueOf();
        const timeFormat = timeFormats[dates[0].length]
        const lastDataTime = moment(dates[dates.length - 1], timeFormat).valueOf();
        let position = 0;
        if(lastDataTime <= backTestTime)
        {
            position = dates.length - 1;
        } else {
            let firstAnchor = null;
            let secondAnchor = null;
            let positionBetweenAnchors = null;
            for (let index = 0; index < dates.length; index++) {
                const parsedTime = moment(dates[index], timeFormat).valueOf();
                if(parsedTime >= backTestTime)
                {
                    firstAnchor = moment(dates[index - 1], timeFormat).valueOf();
                    secondAnchor = moment(dates[index], timeFormat).valueOf();
                    if(backTestTime !== parsedTime)
                    {
                        positionBetweenAnchors = (backTestTime - firstAnchor)/(secondAnchor - firstAnchor);
                        position = index - 1 + positionBetweenAnchors;
                    } else {
                        position = index;
                    }
                    break;
                }
            }
        }
        return position;
    }

    render() {
        let chartHeight = this.props.height ? this.props.height : '100%';

        return (
            <div
                className="performance-graph"
                style={{ height: this.props.height }}>
                <div className="graph-title">
                    {this.props.data && this.props.data.name}
                    <div className="legend">
                        <div className="legend-icon blue" />
                        {this.props.data && this.props.data.data[0].name}
                        <div className="legend-icon orange" />
                        {this.props.data && this.props.data.data[1].name}
                    </div>
                </div>
                <HighchartsReact
                    ref={ref => this.graph = ref}
                    highcharts={Highcharts}
                    options={this.options}
                    containerProps={{ style: { height: chartHeight } }} />
            </div>
        );
    }
}