import * as StockPickerActions from '../actionTypes/stockPicker';
const initialSate = {
    value: '',
    // stockPool: props.data,
    autoCompleteArray: [],
    specificStockFound: false,
    specificStock: {},
}


export function stockPicker(state=initialSate, action){
    switch(action.type) {
        case StockPickerActions.SPECIFIC_STOCK_FOUND: {
            // console.log(action);
            return {
                ...state,
                specificStockFound: true,
                specificStock: action.stock
            }
        }
        case StockPickerActions.SPECIFIC_STOCK_CLEAR: {
            return {
                ...state,
                specificStockFound: false,
                specificStock: {}
            }
        }
        case StockPickerActions.SET_VALUE: {
            return {
                ...state,
                value: action.value
            }
        }
        case StockPickerActions.UPDATE_AUTOCOMPLETEARRAY: {
            const autoCompleteArray = action.data.map(x => ({
                ...x,
                label: x.stock_code + ' ' + x.stock_name,
                value: x.stock_code
            }));
            // console.log(autoCompleteArray);
            return {
                ...state,
                autoCompleteArray: autoCompleteArray
            }
        }
        default: {
            return state;
        }
    }
}