import React from "react"
import IconCopy from 'Image/svgIcon/copy-white.svg';
import IconDelete from 'Image/svgIcon/delete-white.svg';
import IconEdit from 'Image/svgIcon/edit.svg';
import IconRename from 'Image/svgIcon/rename.svg';
import { Icon } from 'rsuite'
import { isRunning as checkIsRunning } from "Util/isRunning"
import { showRenameModal } from "Redux/actionCreators/strategyOverviewPage"

function MoreButton({
    rowData,
    editStrategy,
    copyStrategy,
    deleteStrategy,
    scored,
    showScoreResult,
    doBacktest,
    cancelBacktest,
    showBacktestResult,
    toggleExpand
})
{
    const isRunning = checkIsRunning(rowData.status)
    return (
        <div className="more blue">
            <div className="more-item" data-id={rowData.id}>
                <div 
                    className = {isRunning ? "disabled": "clickable"}
                    onClick={isRunning ? null: editStrategy}
                    data-id={rowData.id}
                >
                    <Icon icon={IconEdit} />
                </div>
                <div
                    className="clickable"
                    onClick={copyStrategy}
                    data-id={rowData.id}
                >
                    <Icon icon={IconCopy} />
                </div>
                <div
                    className="clickable"
                    onClick={showRenameModal}
                    data-id={rowData.id}
                    data-name={rowData.name}
                >
                    <Icon icon={IconRename}/>
                </div>
                <div
                    className={isRunning ? "disabled": "clickable"}
                    onClick={isRunning ? null: deleteStrategy}
                    data-id={rowData.id}
                >
                    <Icon icon={IconDelete} />
                </div>
                <div
                    className={scored ? 'clickable' : 'disabled'}
                    onClick={showScoreResult}
                    data-id={rowData.id}>
                    評分結果
                </div>
                <div
                    className={scored ? 'clickable' : 'disabled'}
                    onClick={isRunning ? cancelBacktest: doBacktest}
                    data-id={rowData.id}
                >
                    {isRunning ? "取消回測" : (rowData.status === 22 ? '再測' : '回測')}
                </div>
                <div
                    className={rowData.status === 22 || rowData.status === 42 ? "clickable": "disabled"}
                    onClick={rowData.status === 22 || rowData.status === 42 ? showBacktestResult: null}
                    data-id={rowData.id}>
                    回測報告
                </div>
            </div>
            <div
                className="more-text"
                onClick={() => toggleExpand}
            >
                <span>{"< "}</span>
                <span>{"> "}</span>
                 更多
            </div>
        </div>
    );
};
// const MoreButtonMemo = React.memo(MoreButton, areEqual)
export default MoreButton;