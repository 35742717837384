import * as Messages from '../actionTypes/messages'

const initialSate = {
    display: {
        ifAlert: false,
        showAlertBox: false,
        showMessageBox: false,
    },
    alerts: { 
        msg: [
            // {
            //     data: {
            //         name: '成長股策略',
            //         status_text: '成長股策'
            //     },
            //     msg: '成長股策略',
            //     status: 0,
            //     read: true
            // },
            // {
            //     data: {
            //         name: '成長股策略',
            //         status_text: '成長股策'
            //     },
            //     msg: '金融股投資策略',
            //     status: 1,
            //     read: true
            // },
      ]
    },
    messages: {
        emails: {
            emails:[
            // {
            //     msg: '信箱成長股策略123',
            //     date: '2020/3/10',
            //     read: true
            // },
            // {
            //     msg: '信箱金融股投資策略',
            //     date: '2020/3/10',
            //     read: true
            // },
        ]},
        tasks: {
            tasks:[
            // {
            //     msg: '任務成長股策略',
            //     status: 0,
            //     read: true,
            //     strategyId:  398
            // },
            // {
            //     msg: '任務金融股投資策略',
            //     status: 1,
            //     read: true,
            //     strategyId:  399
            //  },
        ]}
    },
    notifications: [],
    notificationMessages: [
        // {
        //     category: 'message',
        //     msg: '站內信',
        //     date: '2021/3/4',
        //     read: false,
        //     type: 'inMail',
        //     id: 'ffrw2fsssq1222w3p121231231233'
        //   },
        //   {
        //       category: 'message',
        //       msg: '系統公告',
        //       date: '2020/3/3',
        //       read: true,
        //       type: 'announcement',
        //       id: 'ffw2fsssq1222w3p121231231233'
        // },
        // {
        //     category: 'message',
        //     msg: '指數一直跌怎麼辦',
        //     date: '2020/3/1',
        //     read: false,
        //     type: 'article',
        //     id: 'ffw2fq1222w3p121231231233'
        // },
        // {
        //     category: 'message',
        //     msg: '站內信',
        //     date: '2021/3/1',
        //     read: true,
        //     type: 'inMail',
        //     id: 'ffw2fq123p121231231233'
        //   },
        //   {
        //       category: 'message',
        //       msg: '系統公告',
        //       date: '2020/2/18',
        //       read: true,
        //       type: 'announcement',
        //       id: 'ffw2fq123p123'
        // },
        // {
        //     category: 'message',
        //     msg: '指數一直跌怎麼辦',
        //     date: '2020/2/17',
        //     read: true,
        //     type: 'article',
        //     id: 'ffw2fq123p'
        // },
        // {
        //     category: 'message',
        //     msg: '站內信',
        //     date: '2021/3/4',
        //     read: true,
        //     type: 'inMail',
        //     id: 'ffw2fq123'
        //   },
        //   {
        //       category: 'message',
        //       msg: '系統公告',
        //       date: '2020/3/3',
        //       read: true,
        //       type: 'announcement',
        //       id: 'ff2fq123'
        // },
        // {
        //     category: 'message',
        //     msg: '指數一直跌怎麼辦',
        //     date: '2020/3/1',
        //     read: true,
        //     type: 'article',
        //     id: 'fffq123'
        // },
        // {
        //     category: 'message',
        //     msg: '站內信',
        //     date: '2021/3/1',
        //     read: true,
        //     type: 'inMail',
        //     id: 'fff123'
        //   },
        //   {
        //       category: 'message',
        //       msg: '系統公告',
        //       date: '2020/2/18',
        //       read: true,
        //       type: 'announcement',
        //       id: 'fff122'
        // },
        // {
        //     category: 'message',
        //     msg: '指數一直跌怎麼辦',
        //     date: '2020/2/17',
        //     read: true,
        //     type: 'article',
        //     id: 'fff12'
        // },
    ],
    notificationTasks: []
}
export function messages(state = initialSate, action){
    switch(action.type) {
        case Messages.SET_IF_ALERT: {
            return {
                ...state,
                display: {
                    ...state.display,
                    ifAlert: action.ifAlert
                }
            }
        }
        case Messages.SET_ALL_EMAILS_READ: {
            const emailsRead = state.notifications.map((o) => {
                if(o.category === 'message')
                {
                    return {
                        ...o,
                        read: true
                    }
                } else {
                    return o
                }
            })
            // console.log(emailsRead);
            return {
                ...state,
                notifications: emailsRead
            }
        }
        case Messages.SET_ALL_TASKS_READ: {
            const tasksRead = state.notifications.map((o) => {
                if(o.category === 'task'){
                    return {
                        ...o,
                        read: true
                    }
                } else {
                    return o;
                }
            })
            return {
                ...state,
                notifications: tasksRead
            }
        }
        case Messages.UPDATE_MESSAGES: {
            return {
                ...state,
                messages: action.messages
            }
        }
        case Messages.ADD_ALERT: {
            return {
                ...state,
                alerts: {
                    msg: [
                        action.alert,
                        ...state.alerts.msg,
                    ]
                }
            }
        }
        case Messages.CLEAR_ALERT: {
            return {
                ...state,
                alerts: {
                    msg: []
                }
            }
        }
        case Messages.UPDATE_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [
                    ...action.notifications,
                    ...state.notificationMessages
                ],
            }
        }
        case Messages.READ_TASK: {
            const taskSpecificRead = state.notifications.map((o) => {
                if(o.id === action.id)
                {
                    return {
                        ...o,
                        read: true
                    };
                }
                return o;
            })
            const alertBoxSpecificRead = state.alerts.msg.map(o => {
                if(o.id === action.id)
                {
                    return {
                        ...o,
                        read: true
                    }
                }
                return o;
            })
            return {
                ...state,
                alerts: {
                    msg: alertBoxSpecificRead
                },
                notifications: taskSpecificRead
            }
        }
        case Messages.READ_EMAIL: {
            const emailIdSpecificRead = state.notifications.map((o) => {
                if(o.id === action.id)
                {
                    // console.log(o);
                    return {
                        ...o,
                        read: true
                    };
                }
                return o;
            })
            return {
                ...state,
                notifications: emailIdSpecificRead
            }
        }
        case Messages.UPDATE_ALERTS: {
            return {
                ...state,
                alerts: action.alerts
            }   
        }
        case Messages.TOGGLE_ALERT_BOX: {
            return {
                ...state,
                display: {
                    ...state.display,
                    showAlertBox: action.showAlertBox
                }
            }
        }
        case Messages.TOGGLE_MESSAGE_BOX: {
            return {
                ...state,
                display: {
                    ...state.display,
                    showMessageBox: action.showMessageBox
                }
            }
        }
        case Messages.ADD_NOTIFICATION: {
            return {
                ...state,
                notifications: [
                    action.notification,
                    ...state.notifications
                ]
            }
        }
        case Messages.CHECK_ALERT: {
            const notifications = state.notifications
            // console.log(notifications);
            let ifAlert = false;
            for (let e = 0; e < notifications.length; e++) {
                // console.log('notifications[e].read');
                // console.log(notifications[e].read);
                if(!notifications[e].read)
                {
                    ifAlert = true;
                    break;
                }
            }
            return {
                ...state,
                display: {
                    ...state.display,
                    ifAlert: ifAlert
                }
            }
        }
        default: {
            return state;
        }
    }
}