import { call, put, takeLatest } from "redux-saga/effects";
import Api from "Api/profile";
import { FETCH_PLAN, FETCH_PROFILE } from './actionTypes/profile';
import { SET_PROFILE, UPDATE_PROFILE } from '../actionTypes/profile';

function* _fetchProfile() {
    const result = yield call(() => Api('profile'));

    if (!result) { return; }

    yield put({ type: SET_PROFILE, profile: result });
}

function* _fetchPlan() {
    const result = yield call(() => Api('plan'));

    if (!result) { return; }

    yield put({ type: UPDATE_PROFILE, payload: result });
}

function* fetchProfile() {
    yield takeLatest(FETCH_PROFILE, _fetchProfile);
}

function* fetchPlan() {
    yield takeLatest(FETCH_PLAN, _fetchPlan);
}

export default { fetchPlan, fetchProfile };