import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as SagaActions from "./actionTypes/dashboard"
import * as Actions from "../actionTypes/dashboard/dashboardPage";

import { UPDATE_DASHBOARD_LAYOUT, CLEAR_DASHBOARD, INSERT_PANEL_DATA } from "../actionTypes/dashboard/dashboard";
import Api from "Api/dashboard";
import DashboardUtil from 'Components/dashboard/dashboardUtil';
import { showNotice } from 'Redux/actionCreators/notice';

function* _list()
{
    const dashboardList = yield call(() => Api('list'))
    if(dashboardList)
    {
        dashboardList.forEach(dashboard => {
            // Convert single's id from int to string
            dashboard.singles.forEach(panel => {
                panel.id = panel.id.toString()
            });

            // Convert layout back to object array
            if (dashboard.layout) {
                dashboard.layout = JSON.parse(dashboard.layout);

                dashboard.layout.forEach(x => {
                    let single =
                        dashboard.singles.find(single => single.id === x.i);
                    x.h = single ? single.height : 0;
                });
            }

            if (!dashboard.layout) {
                dashboard.layout =
                    DashboardUtil.getDefaultLayout(dashboard.singles);
            }
        });

        const dashboard = dashboardList.find(dashboard => dashboard.selected);
        const singles = dashboard ? dashboard.singles : [];
        yield put({
            type: Actions.UPDATE_DASHBOARD_LIST,
            dashboardList: dashboardList,
            singles: singles,
            dashboard: dashboard
        })
        yield put({
            type: UPDATE_DASHBOARD_LAYOUT,
            layout: dashboardList.find(dashboard => dashboard.selected).layout
        })
    }    
}

function* setSelected(action)
{
    const formData = new FormData();
    formData.append('id', action.id);
    const success = yield call(() => Api("setSelected", 
        { formData: formData })
    )
    if(success)
    {
        yield put({type: CLEAR_DASHBOARD})
        yield put({
            type: Actions.UPDATE_SELECTED_DASHBOARD,
            id: action.id
        })
    }
}
function* save(action)
{
    const { layout } = action;
    const formData = new FormData();
    const dashboard = yield select(store => store.dashboardPage.dashboard);
    
    formData.append('id', dashboard.id);
    formData.append('name', dashboard.name);
    formData.append('layout', layout ? JSON.stringify(layout) : '');
    const availableSingles = yield select(store => store.dashboardPage.availableSingles)
    const singles = layout.map(panel =>
        availableSingles.find(single => single.id === panel.i)
        );
        const result = yield call(() => Api('saveDashboard', {
            formData: formData
        }))

    if(result) {
        let dashboardList = yield select(store => store.dashboardPage.dashboardList);
        dashboardList = dashboardList.map((_dashboard) => {
            if(_dashboard.id !== dashboard.id)
            {
                return _dashboard
            } else {
                return {
                    ..._dashboard,
                    layout: layout
                }
            }
        })
        yield put({
            type: 'CLEAR_DASHBOARD'
        })
        yield put({
            type: Actions.AFTER_SAVE_DASHBOARD_REMOTE,
            singles: singles,
            layout: layout,
            dashboardList: dashboardList
        })
        showNotice("通知", "戰情室版面已儲存")
    } else {
        yield put({ type: Actions.HIDE_LAYOUTMODEL })
        showNotice("通知", "戰情室版面儲存失敗")
    }
}

function* create(action)
{
    const msg = '新增戰情室';
    const { name, layout } = action
    const formData = new FormData();
    formData.append('name', name);
    formData.append('layout', layout ? JSON.stringify(layout) : '');

    const result = yield call(() => Api("create", {
        formData: formData
    }))

    if (!result) { return; }

    let dashboardList =
        yield select(store => store.dashboardPage.dashboardList);
    let availableSingles =
        yield select(store => store.dashboardPage.availableSingles);

    let singles =
        layout.map(panel =>
            availableSingles.find(single => single.id === panel.i));

    // Rebuild dashboard structure
    result.template = 0;
    result.pathname = null;
    result.sort = 0;
    result.singles = singles;
    result.layout = JSON.parse(result.layout);
    dashboardList.push(result);

    // Set selected dashboard
    dashboardList.forEach(dashboard =>
        dashboard.selected = dashboard.id === result.id ? 1 : 0);

    yield put({ type: CLEAR_DASHBOARD });
    yield put({
        type: Actions.AFTER_CREATE_DASHBOARD,
        dashboardList: dashboardList,
        dashboard: result,
        layout: result.layout,
        singles: singles
    });

    showNotice('通知', msg + '成功');
}

function* _fetchAvailableSingles()
{
    const list = yield call(() => Api('availableSingles'))    
    if(list)
    {
        list.forEach(single => single.id = single.id.toString());
        yield put({
            type: Actions.UPDATE_AVAILABLE_SINGLES,
            availableSingles: list
        })
    }
}

function* _delete(action)
{
    const result = yield call(() => Api('delete',{
        params: {
            id: action.id
        }
    }))

    if(result)
    {
        const originDashboardList = yield select(store => store.dashboardPage.dashboardList);
        const newDashboardList = originDashboardList.filter(dashboard => dashboard.id !== action.id*1)
        const newId = result.selected_id;
        const newDashboard = newDashboardList.find(dashboard => dashboard.id === newId);
        
        newDashboardList.forEach(x => x.selected = x.id === newId ? 1 : 0);
        
        if(originDashboardList && originDashboardList.id !== action.id)
        {
            yield put({
                type: Actions.AFTER_REMOVE_DASHBOARD,
                dashboardList: newDashboardList,
                dashboard: newDashboard,
                singles: newDashboard.singles,
                layout: newDashboard.layout,
                differFromSelected: true
            })
        } else {
            yield put({
                type: Actions.AFTER_REMOVE_DASHBOARD,
                dashboardList: newDashboardList,
                differFromSelected: false
            })
        }
        showNotice("通知","刪除戰情室成功")
    } else {
        showNotice("通知","刪除戰情室失敗")
    }
}

function* _fetchPanelData(action)
{
    const result = yield call(() => Api("panelData", {
        params: action.params
    }))
    if(result)
    {
        yield put({
            type: INSERT_PANEL_DATA,
            id: action.id,
            data: result
        })
    }
}

function* deleteDashboard()
{
    yield takeLatest(SagaActions.DELETE_DASHBOARD, _delete)
}

function* dashboardList()
{
    yield takeLatest(SagaActions.FETCH_DASHBOARD_LIST, _list)
}

function* setSelectedDashboard() {
    yield takeLatest(SagaActions.SET_SELECTED_DASHBOARD, setSelected)
}

function* saveDashboard()
{
    yield takeLatest(SagaActions.SAVE_DASHBOARD, save)
}

function* createDashbboard()
{
    yield takeLatest(SagaActions.CREATE_DASHBOARD, create)
}




function* fetchAvailableSingles()
{
    yield takeLatest(SagaActions.FETCH_AVAILABLE_SINGLES, _fetchAvailableSingles);
}


function* fetchPanelData()
{
    yield takeEvery(SagaActions.FETCH_PANELDATA ,_fetchPanelData)
}
export default {  dashboardList, setSelectedDashboard, saveDashboard, createDashbboard, fetchAvailableSingles, deleteDashboard, fetchPanelData };
