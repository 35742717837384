const Privacy = {
    title: '隱私權政策',
    content:
        '為確保鉅亨易策略網站(下稱本網站)使用者之隱私權，本網站特此向您說明隱私權政策，如您繼續使用本網站，視為您已表示同意本網站之隱私權政策如下，若您未滿二十歲，請您的法定代理人（或監護人）確實閱讀、瞭解並同意遵守本政策之所有內容，您方得使用或繼續使用本網站。\n' +
        '\n' +
        '一、隱私權適用範圍：\n' +
        '\n' +
        '您於加入本網站會員、使用本網站服務、自本網站進行相關資訊及服務之訂閱(例如：網誌、文章訂閱、或其他付費服務)、於本網站中上傳或公開任何類型之資料、投書、發表評論或提出意見等(例如於上傳照片、於留言區發言、文章分享與投書）時，本網站蒐集到的個人識別資料，包括但不限於：您的註冊資料、您的email、您的電腦IP位址、您所使用之網域、您的瀏覽器及操作系統、您登錄本網站之日期、您使用本網站的情形與週期，以及其它有關網路流量相關之資訊。然而，您自行在本網站公開傳輸之資料、照片、檔案、訊息或留言之資料視為公開資訊，本公司不負相關保密義務或其他任何責任。\n' +
        '\n' +
        '二、個資告知事項：\n' +
        '\n' +
        '(一)非公務機關名稱：易策略有限公司(下稱本公司)\n' +
        '\n' +
        '(二)蒐集之目的：\n' +
        '\n' +
        '1. 行銷與業務推行；\n' +
        '\n' +
        '2. 客戶管理與服務；\n' +
        '\n' +
        '3. 消費者保護；\n' +
        '\n' +
        '4. 調查、統計與研究分析；\n' +
        '\n' +
        '5. 法律許可內，與本網站業務具正當與合理關連之特定目的。\n' +
        '\n' +
        '非經您書面同意，本公司不會將本網站蒐集到的個人資料使用於上列蒐集目的以外之用途。\n' +
        '\n' +
        '(三)個人資料之類別：依主管機關公告之個人資料類別。\n' +
        '\n' +
        '(四)個人資料利用之期間、地區、對象及方式：\n' +
        '\n' +
        '1. 期間：本網站營運期間內。本網站營運停止時，除經您書面同意外，本公司應停止處理及利用您的個人資料。\n' +
        '\n' +
        '2. 地區：中華民國及符合主管機關國際傳輸規定之其他地區。\n' +
        '\n' +
        '3.對象：本公司、受本公司委託或與本公司有合作或業務往來之關係企業及合作廠商。\n' +
        '\n' +
        '4.方式：於您使用本網站的過程中，本網站之伺服器將自動記錄您的電腦IP位址、您所使用之網域、您的瀏覽器及操作系統、您登錄本網站之日期、您使用本網站之情形與週期，以及其它有關網路流量相關之資訊。本公司可能會應用於使用分析、估計使用者之點閱率、瀏覽習慣等，以作為改進網站內容暨服務之參考。\n' +
        '\n' +
        '(五)您得依個人資料保護法及相關法律向本公司請求查詢、閱覽、製給複製本、補充、更正、停止蒐集、處理、利用及刪除等權利。本公司應於您提出請求之三十日內處理，若本公司未能於三十日內處理應將原因以書面通知您。您於行使前揭權利時，須由本人以書面向本公司申請。若您委託他人辦理，須出具委託書並同時提供受託人身份證明文件以供核對。若申請人不符前述規定，本公司得請申請人補充資料，以為憑辦。就您行使上開權利之資料提供方式、處理期限、查詢費用及繳費期限等事項，本公司將酌收必要成本費用。本公司得依個人資料保護法第10條、第11條規定，執行業務所必須及法定保存期間等考量否准申請。\n' +
        '\n' +
        '(六)除依法另有規定者外，您得自由選擇提供資料，惟若提供之個人資料不足或有誤時，將影響本網站服務之完整性。\n' +
        '\n' +
        '(七) 本網站可能包含其他網站的連結，這些網站不在本網站及本「隱私權政策」之控制範圍內，因此，如您造訪此類不受本「隱私權政策」規範的網站時提供任何資料，我們無法對該資料的保護及隱私權負任何責任。\n' +
        '\n' +
        '三、本網站與第三人共用個人資料之政策：\n' +
        '\n' +
        '除下列情況外，本網站不會在未得您書面同意前，揭露、出租、出售本網站蒐集到的個人識別資料予任何第二條第四項利用對象外之第三人：\n' +
        '\n' +
        '(一) 基於法律之規定。\n' +
        '\n' +
        '(二) 受司法機關或其他有權機關基於法定程序之要求。\n' +
        '\n' +
        '(三) 依法律規定或在合法範圍內，保護本公司、使用者或公眾之權利、財產或安全不致遭受危害。\n' +
        '\n' +
        '(四) 您的行為違反本網站服務條款、或可能損害或妨礙網站與其他使用者權益、或導致任何人遭受損害，為了辨識、聯絡或採取法律行動而必須揭露。\n' +
        '\n' +
        '四、使用者之個人識別資料之正確性及保護措施：\n' +
        '\n' +
        '(一) 您保證於本網站註冊之個人識別資料皆為真實，如登錄之個人識別資料嗣後變更，應隨時依本網站之規定予以更新。您所提供之個人資料如有填寫不實、原所登錄之資料已不符合真實而未更新、所提供之個人資料違反或破壞本網站服務，或有誤導本公司之虞者，本公司有權拒絕或隨時終止您使用本網站服務之一部或全部。\n' +
        '\n' +
        '(二) 您所填寫的個人識別資料如有冒用他人名義、違反公序良俗、侵害他人姓名權、公司名稱、商標或其他智慧財產權、造成他人誤解，或涉有其他違反法令之虞，或破壞本網站服務者，本公司得拒絕您的註冊、或要求您更改、或取消您的使用資格，您應自負一切民事與刑事上之法律責任。\n' +
        '\n' +
        '(三)請妥善保管您的密碼及或任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。在您使用完畢後，務必記得登出帳戶，若您是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗，以防止他人盜用的情事。\n' +
        '\n' +
        '(四) 本公司對您個人資料之存取均應建立識別碼、通行碼之管理制度，並加設資料存取控制。 個人資料檔案，本公司應指定專人依相關法令辦理安全維護事項，防止個人資料被竊取、竄改、毀損、滅失或洩漏，並應定期不定期稽核其檔案管理情形。\n' +
        '\n' +
        '五、條款變更：\n' +
        '\n' +
        '本公司有權於任何時間修改或變更本隱私權政策之內容，修改後的隱私權政策內容將會更新於本網站上，本公司不會另行通知，本公司建議您隨時注意本隱私權政策之修改或變更。您於本隱私權政策修改或變更後繼續使用本網站提供之服務時，視為您已閱讀、了解且同意本隱私權政策修改或變更後的內容。如您不同意前述內容，或不接受本隱私權政策的其他部分，請您立即終止使用本網站。\n' +
        '\n' +
        '六、Cookie之使用：\n' +
        '\n' +
        '本網站使用Cookie技術，於您的電腦中存取某些資訊，以輔助本網站進行資料之彙集或分析，並提供更好的服務，無侵犯個人隱私之意圖。Cookie是網站伺服器與使用者瀏覽器溝通的技術，若不願意開放此項功能，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致本網站之部分或全部功能無法正常執行。\n' +
        '\n' +
        '七、反映與聯絡：\n' +
        '\n' +
        '如您對於本網站之隱私權政策有任何疑問，請以電話或電子郵件向本公司反映，我們將盡速與您聯絡，謝謝。'
};

export default Privacy;