import React, { Component } from 'react';
import store from "Redux/store"
import * as StrategySagaActions from "Redux/saga/actionTypes/strategy";
import {
    Button,
    Input,
    Modal,
} from 'rsuite';
import './strategyRenameModal.css';
import { CLOSE_RENAME_MODAL } from "Redux/actionTypes/strategy/strategyOverviewPage";
export default class StrategyRenameModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            newName: ''
        };
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            this.setState({ newName: '' });
        }
    }

    onNameChange(e, event) {
        this.setState({ newName: event.target.value });
    }

    onConfirmClick() {
        store.dispatch({
            type: StrategySagaActions.RENAME_STRATEGY,
            name: this.state.newName
        })
        store.dispatch({ type: CLOSE_RENAME_MODAL })
    }
    onCancel() {
        store.dispatch({ type: CLOSE_RENAME_MODAL })
    }

    render() {
        return (
            <Modal
                className="strategy-rename-modal"
                show={store.getState().strategyOverviewPage.showRenameModal}
                onHide={this.onCancel}>
                <Modal.Header>
                    <Modal.Title>重新命名策略</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <span>原策略名稱:&nbsp;</span>
                        {store.getState().strategyOverviewPage.renameModalName}
                    </div>
                    <div className="row">
                        <span>新策略名稱:&nbsp;</span>
                        <Input
                            className="name-input"
                            value={this.state.newName}
                            onChange={this.onNameChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onConfirmClick}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}