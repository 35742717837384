import Fetch from '../fetch'

/**
 * @memberof module:Fetch
 * @description 產業api
 */

const industryApi = {
    industries: {
        method: "GET",
        uri: () => '/industries'
    }
}

/**
 * @memberof module:Fetch
 * @function IndustryApi
 * @description 發送策略相關request
 */

export default function IndustryApi(type, params = null){
    return Fetch.request(
        industryApi[type].method,
        industryApi[type].uri,
        params
    )
};