import React, { useEffect, useState } from "react";
import Component from "./component.jsx";
import API from 'Src/api';
function Container()
{
    const [state, setState] = useState({
        singles: [],
        layout: []
    })
    
    const setMarket = async () => {
        let dashboard = await API.getMarket();
        let singles = dashboard ? dashboard.singles : [];
        let layout = dashboard ? dashboard.layout : [];
        setState({ singles, layout });
    }

    useEffect(() => {
        setMarket()
    /* eslint-disable-next-lint */
    }, [])
    return <Component
        singles={state.singles}
        layout={state.layout}
    />
}
export default Container;