import React from 'react';
import TabList  from 'Components/tabListGeneral';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { isEqual } from 'lodash';


function Component({ id, rangeTabsList, periodTabsList, tableData, chartData, tableDivRef, tableRef, panelData })
{
    
    return <div className="table_chart dashboard-chart">
        <div className="table_chart__header">
            <div>
                {
                    periodTabsList ?
                    <TabList
                        // defaultTab="all"
                        id={"period_"+id}
                        data={periodTabsList}
                        type="blue__border__square"
                    />: null
                }
            </div>
            <div className = "table_chart__switcher">
                {
                    rangeTabsList ? 
                    <TabList
                        defaultTab={panelData.rawData.main.default_range}
                        id={"time_range_"+id}
                        data={rangeTabsList}
                        type="blue__border__square"
                    />: null
                }
            </div>
        </div>
        <div className = "table_chart__contents">
            <div>
                <HighchartsReact
                    // key={'chart-' + this.state.currentSwitcher + '-' + i}
                    // ref={ref => this.chartRefList[i - 1] = ref}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={chartData}
                    containerProps={{ style: { height: "100%" } }}
                    // callback={chart => this.handleLegendSymbolBorder(chart)}
                />
            </div>
            <div>
                <div
                    ref={tableDivRef}
                >
                    <table
                        ref={tableRef}
                    >
                        <thead>
                            <tr>
                                {
                                    tableData.columnList.map(column =>
                                        <th key={column.id}>{column.name}</th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>                      
                            {
                                tableData.seriesData.map((series, i) =>
                                    <tr key={i}>
                                        {
                                            tableData.columnList.map(column => {
                                                let value = series[column.id];
                                                let parsedValue = parseFloat(value);

                                                let text =
                                                    value !== '' && !isNaN(parsedValue) ?
                                                        parsedValue.toLocaleString() :
                                                        value;

                                                return <td key={series.name + '-' + column.id}>{text}</td>;
                                            })
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}

function areEqual(prevProps, nextProps)
{
    if(
        !isEqual(prevProps.rangeTabsList, nextProps.rangeTabsList) ||
        prevProps.timeRangeTabs !== nextProps.timeRangeTabs ||
        prevProps.periodTabs !== nextProps.periodTabs
    )
    {
        return false
    }else {
        return  true
    }
}
export default React.memo(Component, areEqual);