import React from 'react';
import { Button, Icon, Modal } from 'rsuite';

import IconExit from 'Image/svgIcon/exit.svg';
import './style.scss';

const Component = ({ isOpen, onClose }) => {
    const showTutorial = () => {
        window.open('/tutorial', '_blank');
    };

    return (
        <Modal
            show={isOpen}
            className="two-step-tutorial-modal"
            onHide={onClose}>
            <Modal.Header>
                <Modal.Title>2步驟設計投資策略</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <div className="step">
                    <div className="step-icon">❶</div>
                    <div className="step-right">
                        <div className="step-title">
                            設定股票池，先排除不要的股票
                        </div>
                        <div className="step-content">
                            以產業、或指標過濾，只留下符合標準的股票。
                        </div>
                    </div>
                </div>
                <img src={require('Image/two-step-tutorial/1.png')} alt="" />
                <div className="step">
                    <div className="step-icon">❷</div>
                    <div className="step-right">
                        <div className="step-title">
                            為剩下的股票打分數，挑出最強股
                        </div>
                        <div className="step-content">
                            進入<span className="blue">好球帶</span>的股票太多了，我只想挑最高分的買。
                        </div>
                    </div>
                </div>
                <img src={require('Image/two-step-tutorial/2.png')} alt="" />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="tutorial-button"
                    onClick={showTutorial}>
                    使用秘訣<Icon icon={IconExit} />
                </Button>
                <Button onClick={onClose}>我知道了</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;