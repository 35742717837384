import React from 'react';
import { call, put, takeLatest, select } from "redux-saga/effects";
import Api from "Api/backtest";
import * as SagaActions from './actionTypes/backtest';
import * as StrategyActions from "../actionTypes/strategy/strategy";
import { SET_BACKTEST_PREVIEW } from "../actionTypes/backtest";
import { showNotice } from 'Redux/actionCreators/notice';
import { list } from './selector/strategy' 
import history from "Src/history";


import { SHOW_NOTICE } from 'Redux/actionTypes/notice'

function* _startBacktest(action)
{
    const result = yield call(() => Api("start", {
        params: {id: action.payload.id },
        formData: action.payload.formData
    }))

    if (!result) { return; }

    yield put({
        type: SHOW_NOTICE,
        title: '回測排程己啟動',
        content: 
            <>
                <div>請您耐心等候，雲端主機將依序為所有使用者運算。</div>
                <div>可任意跳至易策略其他頁面，不會中斷回測。</div>
            </>,
        onExit: () => {
            const location = { pathname: '/strategyOverview' };
            history.push(location);
        }
    });
}

function* cancel(action)
{
    const formData = new FormData();
    formData.append('id', action.id);
    const result = yield call(() => Api('cancel', { formData: formData}));
    let msg = '取消回測';
    if (result) {
        const strategyList = yield select(list);
        let strategy = strategyList.find(strategy => strategy.id === action.id);
        if (strategy) {
            strategy.status = result.status;
            strategy.status_text = result.status_text;
        }
        
        yield put({
            type: StrategyActions.UPDATE_STRATEGY_LIST,
            list: strategyList
        })
        showNotice('通知', result.status_text);
    } else {
        showNotice('通知', msg + '失敗');
    }    
}

// function* _prepare()
// {
    
// }

function* _backtestPreview(action)
{
    const result = yield call(() => Api("preview", {
        formData: action.payload.formData,
        params: {
            id: action.payload.id
        }
    }))

    if(result)
    {
        yield put({
            type: SET_BACKTEST_PREVIEW,
            preview: result
        })
    }
}

// function* _result()
// {
    
// }

function* startBacktest()
{
    yield takeLatest(SagaActions.START_BACKTEST, _startBacktest)
}

function* backtestCancel()
{
    yield takeLatest(SagaActions.CANCEL_BACKTEST, cancel)
    
}
// function* prepare()
// {
//     yield takeLatest(SagaActions.PREPARE_BACKTEST, _prepare)
// }

function* backtestPreview()
{
    yield takeLatest(SagaActions.FETCH_BACKTEST_PREVIEW, _backtestPreview)
}

// function* result()
// {
//     yield takeLatest(SagaActions.GET_BACKTES_RESULT, _result)   
// }

export {
    startBacktest,
    backtestCancel,
    // prepare,
    backtestPreview,
    // result
}