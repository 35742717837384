import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
// import API from 'Src/api';
import './style.scss';
// import _ from 'lodash';
class StrategySidebar extends Component {
    state = {
        showPanel:
            this.props.defaultVisibility !== undefined ?
                this.props.defaultVisibility : true
    };
    strategyScoreList = [];
    componentDidMount() {
        if (!this.props.strategyList) { return; }

        // this.initStrategyList();
    }
    
    togglePanel() {
        if (this.props.onVisibilityChange) {
            // this.props.onVisibilityChange(!this.state.showPanel);
            this.props.onVisibilityChange();
            return;
        }

        this.setState({ showPanel: !this.state.showPanel });
    }

    renderScoreList() {
        if (!this.props.scoreList || this.props.scoreList.length === 0) {
            return (
                <div className="empty">
                    <div className="text">無符合條件的股票</div>
                </div>
            );
        }

        return this.props.scoreList.map((stock, i) => {
            let analysisId =
                this.props.match ?
                    this.props.match.params.analysisId : '2';
            let analysisPath = '/' + analysisId + '/' + this.props.id;
            
            
            return (
                // <div key={stock.stock_code}>
                <div key={i}>
                    <div className="stock">
                        <div className="row">
                            <div className="name">
                                <Link to={{pathname: '/stockAnalysis/' + stock.stock_code.value + analysisPath, prevPath: this.props.location.pathname }} >
                                    {stock.stock_name.text}
                                </Link>
                            </div>
                            <div className="score">{stock.score.text}分</div>
                        </div>
                        <div className="row">
                            <div className="code">{stock.stock_code.text}</div>
                            <div
                                className="daily-close"
                                dangerouslySetInnerHTML={{ __html: stock.DailyClose.text }} />
                            <div
                                className="change-percent"
                                dangerouslySetInnerHTML={{ __html: stock.DailyPriceChange_Percent.text }} />
                        </div>
                    </div>
                    {
                        // i !== this.state.scoreList.length - 1 &&
                        // <hr />
                        i !== this.props.scoreList.length - 1 &&
                        <hr />
                    }
                </div>
            );
        });
    }

    render() {
        let sidebarClassName = 'strategy-sidebar';
        if (!this.props.visibility) {
            sidebarClassName += ' close';
        }
        
        return ( this.props.show ?
            <div className={sidebarClassName}>
                <div
                    className="panel-button"
                    onClick={() => this.togglePanel()}>
                    {this.props.visibility ? '>' : '<'}
                </div>
                <div className="content">
                    <SelectPicker
                        data={this.props.strategyNameList}
                        searchable={false}
                        cleanable={false}
                        value={this.props.id*1}
                        placement="bottomEnd"
                        menuStyle={{ width: 225 }}
                        onChange={this.props.onStrategyChange}
                    />
                    <div className="score-list">
                        <div className="list-header">
                            <div className="row">
                                <div className="name">股票</div>
                                <div className="score">評分</div>
                            </div>
                            <div className="row">
                                <div className="code">代號 / 股價</div>
                                <div className="change-percent">當日漲跌幅</div>
                            </div>
                        </div>
                        <hr />
                        {this.renderScoreList()}
                    </div>
                </div>
            </div>: null
        );
    }
}

// function areEqual(prevProps, nextProps)
// {
//     if(!_.isEqual(prevProps, nextProps))
//     {
//         return false
//     } else {
//         return true;
//     }
// }

// export default React.memo(StrategySidebar, areEqual);
export default StrategySidebar;