import React, { useEffect, useRef } from 'react';

const tabRefList = [];

function renderTabList(tabs, currentTab, onSelect, tabRefList) {
    if (!tabs) { return; }

    return (
        tabs.map(tab => {
            let className = 'tab';

            if (tab.id === currentTab) {
                className += ' selected';
            }

            return (
                <div
                    key={tab.id}
                    ref={ref => tabRefList[tab.id] = ref}
                    className={className}
                    onClick={() => onSelect(tab.id)}>
                    {tab.name}
                </div>
            );
        })
    );
}

export default function Component({ tabs, currentTab, onSelect }) {
    const ref = useRef();

    const onWheel = (event) => {
        event.preventDefault();

        ref.current.scrollTo({ left: ref.current.scrollLeft + event.deltaY });
    }

    const scrollToTab = id => {
        if (!tabRefList[id]) { return; }

        ref.current.scrollTo({ left: tabRefList[id].offsetLeft });
    }

    useEffect(() => {
        scrollToTab(currentTab);
    }, [currentTab]);

    return (
        <div
            ref={ref}
            className="tab-list"
            onWheel={event => onWheel(event)}>
            {renderTabList(tabs, currentTab, onSelect, tabRefList)}
        </div>
    );
}