import moment from 'moment';

export const colorList = [
    '#ff9600', '#ff4a68', '#72cf00', '#00bfff', '#ff86d6',
    '#bd9f71', '#b081ff', '#ff00ff', '#bede00', '#008bff',
    '#59ca95', '#d63d00', '#ff8300', '#2f862f', '#ffc200'
];

export const graphOption = {
    chart: {
        backgroundColor: 'transparent',
        type: 'line',
        zoomType: 'x',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 1,
        marginLeft: 1,
        // alignTicks: false
    },
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    plotOptions: {
        line: {
            marker: { enabled: false }
        },
        series: {
            pointPlacement: 'on'
        }
    },
    rangeSelector: {
        enabled: false
    },
    navigator: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    },
    title: {
        text: '',
        margin: 5,
        style: {
            color: '#fff',
            fontSize: '18px'
        }
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
        valueDecimals: 2,
        xDateFormat: '%Y/%m/%d'
    },
    legend: {
        enabled: true,
        itemStyle: {
            color: '#fff',
            fontWeight: 'normal'
        },
        itemHoverStyle: {
            color: '#00ddff'
        },
        layout: 'vertical',
        verticalAlign: 'top',
        align: 'left',
        x: -7
    },
    xAxis: [{
        type: 'datetime',
        labels: {
            style: { color: '#fff', fontSize: '12px' },
            formatter: function () {
                return moment(this.value).format('YYYY/MM/DD');
            }
        },
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        gridLineWidth: 1,
        tickmarkPlacement: 'on',
        startOnTick: false,
        ordinal: false
    }],
    // yAxis: []
};