import moment from 'moment';
import { Cookies } from 'react-cookie';

import DashboardUtil from './component/dashboard/dashboardUtil';
import history from './history';
import IndexUtil from './indexUtil';
var _ = require('lodash');
const cookies = new Cookies();

const host = process.env.REACT_APP_API_HOST;

export default class API {
    static getEventSource() {
        let token = cookies.get('accessToken');

        if (!token || token === '') { return null; }

        let url = host + '/user/notification/stream?access_token=' + token;

        return new EventSource(url);
    }

    static async deleteDashboard(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/dashboards/' + id,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getPanelData(id, params) {
        // console.log('token');
        // console.log(id);
        let token = cookies.get('accessToken');

        if (!id || id === '') return null;

        let url = host + '/data/model/' + id;

        if (params) {
            Object.keys(params).forEach((key, i) => {
                url += (i === 0 ? '?' : '&') + key + '=' + params[key];
            });
        }

        let result = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json.status === 'success' ? json.data : null;
    }

    static async getMarket() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/market',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            // Convert single's id from int to string
            json.data.singles.forEach(panel => panel.id = panel.id.toString());

            // Generate default layout
            if (json.data.layout === '') {
                json.data.layout =
                    DashboardUtil.getDefaultLayout(json.data.singles);
            }

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getFavorite() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/stocks/favorites',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            // Convert single's id from int to string
            json.data.singles.forEach(panel => panel.id = panel.id.toString());

            // Generate default layout
            if (json.data.layout === '') {
                json.data.layout =
                    DashboardUtil.getDefaultLayout(json.data.singles);
            }

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStrategyList() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/strategies',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            // Convert id from int to string
            json.data.forEach(strategy => strategy.id = strategy.id.toString());

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStrategy(id, indexLibrary) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/strategies/' + id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        if (json.status !== 'success') { return null; }

        return API.getStrategyObject(json.data, indexLibrary);
    }

    static getStrategyObject(data, indexLibrary) {
        // Industry list
        let industryFilter = JSON.parse(data.industries);

        // Constraint list
        let constraintFilter = JSON.parse(data.selections);

        if (constraintFilter) {
            constraintFilter.include =
                IndexUtil.convertCodeInListToIndex(
                    constraintFilter.include, indexLibrary);
            constraintFilter.exclude =
                IndexUtil.convertCodeInListToIndex(
                    constraintFilter.exclude, indexLibrary);
        }

        // Score list
        let scoreList = JSON.parse(data.scores);
        scoreList = scoreList.map(block => {
            if (!block.rowList) { return block; }

            let newBlock = _.cloneDeep(block);
            newBlock.rowList =
                IndexUtil.convertCodeInListToIndex(newBlock.rowList, indexLibrary);

            return newBlock;
        });

        // Backtest
        let backtest = JSON.parse(data.backtest);
        let backtestSetting = {
            fund: backtest.funds,
            buyRule: backtest.buy_rule,
            buyRank: backtest.buy_rank,
            timespan: backtest.timespan,
            changeFrequency: backtest.change_frequency,
            buyPriority: backtest.buy_priority,
            scoreLimit: backtest.score_limit,
            noDailyLimit: backtest.buy_nodailylimit,
            fee: backtest.transaction_fee,
            backtestDate:
                backtest.backtest_date ?
                    moment(backtest.backtest_date).valueOf() : null
        };

        if (backtestSetting.buyRule === 'custom') {
            backtestSetting.customWeight = backtest.weight;
        }

        if (backtestSetting.timespan === 'custom') {
            backtestSetting.timespanStart = backtest.timespan_start;
            backtestSetting.timespanEnd = backtest.timespan_end;
        }

        // result
        return {
            id: data.id,
            strategyName: data.name,
            strategyDescription: data.description,
            industryFilter: industryFilter,
            constraintFilter: constraintFilter,
            indexScoreList: scoreList,
            backtestSetting: backtestSetting,
            isDuplicate: data.from_duplicate,
            status: data.status
        };
    }

    static async getIndexLibrary() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/stockaccounts',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async copyStrategy(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/strategies/' + id + '/duplicated',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json;
    }

    static async renameStrategy(id, name) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', name);

        let result = await fetch(
            host + '/user/strategies/' + id,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json && json.status === 'success';
    }

    static async shareBacktestSettings(id, shared) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('shared', shared ? 1 : 0);

        let result = await fetch(
            host + '/user/strategies/' + id,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json && json.status === 'success';
    }

    static prepareBacktestSetting(backtest) {
        let backtestSetting = {
            funds: backtest.fund,
            buy_rule: backtest.buyRule,
            buy_rank: backtest.buyRank,
            timespan: backtest.timespan,
            change_frequency: backtest.changeFrequency,
            buy_priority: backtest.buyPriority,
            score_limit: backtest.scoreLimit,
            buy_nodailylimit: backtest.noDailyLimit,
            transaction_fee: backtest.fee
        };

        if (backtest.backtestDate) {
            backtestSetting.backtest_date =
                moment(backtest.backtestDate).format('YYYY-MM-DD');
        }

        if (backtest.buyRule === 'custom') {
            backtestSetting.weight = backtest.customWeight;
        }

        if (backtest.timespan === 'custom') {
            backtestSetting.timespan_start = backtest.timespanStart;
            backtestSetting.timespan_end = backtest.timespanEnd;
        }

        return backtestSetting;
    }

    static async getStrategyScore(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/data/strategies/' + id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();
        if (json.status === 'success') {
            // Convert id from int to string
            json.data.id = json.data.id.toString();

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getBacktestResult(id, indexLibrary) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/data/backtest/' + id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            // Selections
            if (json.data.selections) {
                json.data.selections.include =
                    IndexUtil.convertCodeInListToIndex(
                        json.data.selections.include, indexLibrary);

                json.data.selections.exclude =
                    IndexUtil.convertCodeInListToIndex(
                        json.data.selections.exclude, indexLibrary);
            }

            // Scores
            if (json.data.scores) {
                json.data.scores =
                    json.data.scores.map(block => {
                        if (!block.rowList) { return block; }

                        let newBlock = _.cloneDeep(block);
                        newBlock.rowList =
                            IndexUtil.convertCodeInListToIndex(
                                newBlock.rowList, indexLibrary);

                        return newBlock;
                    });
            }

            // Stock holdings table
            json.data.data[5].main.data.forEach(row => {
                let keyList = Object.keys(row);

                keyList.forEach(key => {
                    if (key === 'row') { return; }

                    row[key].beforeTrade = row[key].bt;
                    row[key].buyPrice = row[key].bp;
                    row[key].buyRePrice = row[key].brp;
                    row[key].buyTime = row[key].btm;
                    row[key].code = row[key].c;
                    row[key].name = row[key].n;
                    row[key].period = row[key].p;
                    row[key].priceDiff = row[key].pd;
                    row[key].score = row[key].s;
                    row[key].sellPrice = row[key].sp;
                    row[key].sellRePrice = row[key].srp;
                    row[key].sellTime = row[key].stm;
                    row[key].transaction_fee = row[key].tsf;
                    row[key].transaction_tax = row[key].tst;
                    row[key].weight = row[key].w;

                    // Score list
                    if (!json.data.scores) { return; }
                    row[key].categoryList = _.cloneDeep(json.data.scores);

                    let i = 0;
                    row[key].categoryList.forEach(category => {
                        category.rowList.forEach(rule => {
                            rule.total = rule.score;
                            rule.score = row[key].cl[i];
                            i++;
                        });
                    });
                });
            });

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStockPool() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/data/stockpool',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStockPrice(stockCode) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/data/model/stock-price?stock_code=' + stockCode,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            let data = {
                code: json.data.main.stock.stock_code,
                name: json.data.main.stock.stock_name
            }

            json.data.main.data.forEach(row => {
                data[row.code] = row;
            })

            return data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStockAnalysisList() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/finanalysis',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            // Convert the id in singles from int to string
            json.data.forEach(item => API.recursiveConvertString(item));

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static recursiveConvertString(item) {
        if (item.children) {
            item.children.forEach(item => API.recursiveConvertString(item));
        } else {
            item.value = item.value.toString();
        }
    }

    static async getStockAnalysis(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/findashboards/' + id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            if (json.data && json.data.singles) {
                json.data.singles.forEach(panel =>
                    panel.id = panel.id.toString());
            }

            // Generate default layout
            if (json.data.layout === '') {
                json.data.layout =
                    DashboardUtil.getDefaultLayout(json.data.singles);
            }

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async exchangeAccessToken(facebookToken) {
        if (!facebookToken) { return null; }

        let formData = new FormData();
        formData.append('accessToken', facebookToken);

        let result = await fetch(
            host + '/auth/facebook/token',
            {
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        return null;
    }

    static async getCnyesAccessToken(code) {
        let formData = new FormData();
        formData.append('code', code);

        let result = await fetch(
            host + '/auth/cnyes/token',
            {
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        return null;
    }

    static revokeToken() {
        cookies.remove('accessToken', { path: '/' });
    }

    static handleAuthFail() {
        API.revokeToken();
        history.replace('/login');
    }

    static async getTrackingStock() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/stocks',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async addFavoriteGroup(name, afterGroupId) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('name', name);
        if (afterGroupId) { formData.append('after_group_id', afterGroupId); }

        let result = await fetch(
            host + '/user/stocks/groups',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json.status === 'success';
    }

    static async deleteFavoriteGroup(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/stocks/groups/' + id,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json.status === 'success';
    }

    static async allTasksRead() {
        // 正式
        const url = host + '/user/notifications/read'
        const token = cookies.get('accessToken');

        const formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('category', 'task');

        let result = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json;

        // 測試
        // const token = cookies.get('accessToken');
        // const result = await fetch(
        //     '/mockapi/readTasks',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //             'Accept': 'application/json'
        //         }
        //     }
        // );
        // const json = await result.json();

        // if (json.status === 'error' && json.error.code === 'E400001') {
        //     API.handleAuthFail();
        // }

        // return true;

    }

    static async readTask(
        id
    ) {
        // 正式
        const url = host + '/user/notifications/read'
        const token = cookies.get('accessToken');

        const formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('id', id);

        let result = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json;
        // mock api
        // const token = cookies.get('accessToken');
        // const result = await fetch(
        //     '/mockapi/readTask',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //             'Accept': 'application/json'
        //         }
        //     }
        // );
        // const json = await result.json();

        // if (json.status === 'error' && json.error.code === 'E400001') {
        //     API.handleAuthFail();
        // }

        // return true;

    }

    static async allEmailsRead() {
        // 正式
        const url = host + '/user/notifications/read'
        const token = cookies.get('accessToken');

        const formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('category', 'message');

        let result = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json;
        // 測試
        // const token = cookies.get('accessToken');
        // const result = await fetch(
        //     '/mockapi/readEmail',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //             'Accept': 'application/json'
        //         }
        //     }
        // );
        // const json = await result.json();
        // if (json.status === 'error' && json.error.code === 'E400001') {
        //     API.handleAuthFail();
        // }
        // return true;
    }

    static async readEmail(id) {
        // 正式
        const url = host + '/user/notifications/read'
        const token = cookies.get('accessToken');

        const formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('id', id);
        // formData.append('category', 'message');

        let result = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json;
        // 測試
        // const token = cookies.get('accessToken');
        // const result = await fetch(
        //     '/mockapi/readEmails',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //             'Accept': 'application/json'
        //         }
        //     }
        // );
        // const json = await result.json();

        // if (json.status === 'error' && json.error.code === 'E400001') {
        //     API.handleAuthFail();
        // }

        // return true;

    }

    static async getTableGroup(index, dataDate, indexLibrary) {
        let token = cookies.get('accessToken');
        let convertedIndex = IndexUtil.convertIndex(index);

        let formData = new FormData();
        formData.append('index', JSON.stringify(convertedIndex));
        formData.append('date', moment(dataDate).format('YYYY-MM-DD'));

        let result = await fetch(
            host + '/data/table/columns',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            json.data.forEach(column => {
                column.index =
                    IndexUtil.restoreIndex(column.index, indexLibrary)
            });

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getColumnList(columns, date, indexLibrary) {
        let list =
            await Promise.all(
                columns.map(index =>
                    API.getTableGroup(index, date, indexLibrary)));

        list.forEach((group, groupIndex) =>
            group.forEach((column, columnIndex) =>
                column.id = 'index_' + groupIndex + '_' + columnIndex)
        );

        return list;
    }

    static async getStockGroups() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/stocks/groups',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async addStockGroup(name, stocks) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('name', name);
        formData.append('stocks', JSON.stringify(stocks));

        let result = await fetch(
            host + '/stocks/groups',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async deleteStockGroup(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/stocks/groups/' + id,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async startQueryTableData(id, indexList, stockList, dataDate) {
        let token = cookies.get('accessToken');

        let convertedIndexList =
            indexList.map(index => IndexUtil.convertIndex(index));

        let formData = new FormData();
        formData.append('stocks', JSON.stringify(stockList));
        formData.append('date', moment(dataDate).format('YYYY-MM-DD'));
        formData.append('columns', JSON.stringify(convertedIndexList));

        let result = await fetch(
            host + '/data/table/' + id,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data.querying;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return false;
    }

    static async getQueryingTableData(id, indexLibrary) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/data/table/' + id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            json.data.columns.forEach(column => {
                if (!column.index_data) { return; }

                column.index_data.index =
                    IndexUtil.restoreIndex(
                        column.index_data.index, indexLibrary);
            });

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getSavedTableData(id, indexLibrary) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/tables/' + id + '/data',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            json.data.columns.forEach(column => {
                if (!column.index_data) { return; }

                column.index_data.index =
                    IndexUtil.restoreIndex(
                        column.index_data.index, indexLibrary);
            });

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getDataDate(quarter, month, day) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        if (quarter) {
            formData.append('quarter', quarter);
        } else if (month) {
            formData.append('month', month);
        } else if (day) {
            formData.append('day', day);
        } else {
            return null;
        }

        let result = await fetch(
            host + '/data/table/date',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getTableDataDate(id, updated) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('id', id);
        formData.append('updated', updated);

        let result = await fetch(
            host + '/user/tables/date',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getSameIndustry() {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/industries/stocks',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getStrategyScoreStock(strategyId, type, operator, score) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('type', type);
        formData.append('operator', operator);

        if (operator === 'between') {
            formData.append('rangeFrom', score.rangeFrom);
            formData.append('rangeTo', score.rangeTo);
        } else {
            formData.append('score', score.value);
        }

        let result = await fetch(
            host + '/data/strategies/' + strategyId + '/stocks',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async getIndexFilteredStock(ruleList) {
        let token = cookies.get('accessToken');

        let list = _.cloneDeep(ruleList);
        list.forEach(rule => {
            rule.index = IndexUtil.convertIndex(rule.index);

            if (rule.index2) {
                rule.index2 = IndexUtil.convertIndex(rule.index2);
            }
        });

        let formData = new FormData();
        formData.append('include', JSON.stringify(list));

        let result = await fetch(
            host + '/data/stocks/filter',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    // Table
    static async getTableList(indexLibrary) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/tables',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            json.data.forEach(x => {
                x.columns = x.columns.map(y =>
                    IndexUtil.restoreIndex(y, indexLibrary)
                );
                x.updated = x.updated === 1;
            });

            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async saveTable(id, name, stocks, columns, date, autoUpdateDate) {
        let token = cookies.get('accessToken');

        let indexList =
            columns ? columns.map(x => IndexUtil.convertIndex(x)) : null;

        let formData = new FormData();
        if (id !== -1) { formData.append('id', id); }
        if (name) { formData.append('name', name); }
        if (stocks) { formData.append('stocks', JSON.stringify(stocks)); }
        if (indexList) {
            formData.append('columns', JSON.stringify(indexList));
        }
        if (date) { formData.append('date', date); }
        formData.append('updated', autoUpdateDate ? 1 : 0);

        let result = await fetch(
            host + '/user/tables',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async saveExistingTable(
        id, name, stocks, columns, date, autoUpdateDate
    ) {
        let token = cookies.get('accessToken');

        let indexList =
            columns ? columns.map(x => IndexUtil.convertIndex(x)) : null;

        let formData = new FormData();
        if (id !== -1) { formData.append('id', id); }
        if (name) { formData.append('name', name); }
        if (stocks) { formData.append('stocks', JSON.stringify(stocks)); }
        if (indexList) {
            formData.append('columns', JSON.stringify(indexList));
        }
        if (date) { formData.append('date', date); }
        formData.append('updated', autoUpdateDate ? 1 : 0);

        let result = await fetch(
            host + '/user/tables/' + id + '/data',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data.saved;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async setTableUpdate(id, updated) {
        return await API.saveTable(id, null, null, null, null, updated);
    }

    static async deleteTable(id) {
        let token = cookies.get('accessToken');

        let result = await fetch(
            host + '/user/tables/' + id,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            }
        );

        let json = await result.json();

        if (json.status === 'success') {
            return json.data;
        }

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return null;
    }

    static async selectTable(id) {
        let token = cookies.get('accessToken');

        let formData = new FormData();
        formData.append('id', id);

        let result = await fetch(
            host + '/user/tables/selected',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            }
        );

        let json = await result.json();

        if (json.status === 'error' && json.error.code === 'E400001') {
            API.handleAuthFail();
        }

        return json.status === 'success';
    }
};