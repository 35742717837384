import React from 'react';

import Component from './component';

const defaultOptions = {
    chart: {
        backgroundColor: 'transparent',
        height: '205px',
        type: 'area',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        margin: 0,
        borderWidth: 0,
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 0,
    },
    credits: {
        enabled: false
    },
    colors: ['#00ddff', '#ff9600'],
    plotOptions: {
        area: {
            marker: { enabled: false },
            fillOpacity: 0.6,
            lineWidth: 1,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#00ddff3f'],
                    [0.6, '#00ddff11'],
                    [1, '#00ddff00']
                ]
            }
        },
        series: {
            dataGrouping: {
                enabled: false
            }
        }
    },
    title: null,
    legend: {
        enabled: false
    },
    xAxis: {
        visible: false,
        min: 1,
        labels: {
            style: { color: '#fff', fontSize: '12px' },
            format: '{value:%Y}'
        },
        plotLines: [
            {
                color: 'rgba(255, 255, 255, 0.6)',
                width: 0.5,
                value: 2
            }
        ]
    },
    yAxis: {
        visible: false,
        gridLineColor: 'rgba(255, 255, 255, 0.3)',
        opposite: true,
        labels: {
            format: '',
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            x: 7,
            y: 0
        },
        title: null
    },
    tooltip: {
        xDateFormat: '%Y/%m/%d',
        shared: true
    },
    series: [
        {
            name: '',
            data: [],
        },
        {
            name: '',
            fillColor: 'transparent',
            data: []
        }
    ]
};

const Container = ({ data }) => {
    const options = {
        ...defaultOptions,
        xAxis: {
            ...defaultOptions.xAxis,
            categories: Object.keys(data[0].data),
            max: Object.keys(data[0].data).length - 2
        },
        series: [
            {
                name: data[0].name,
                data: Object.values(data[0].data),
            },
            {
                name: data[1].name,
                fillColor: 'transparent',
                data: Object.values(data[1].data),
            }
        ]
    };

    return <Component options={options} />;
};

export default Container;