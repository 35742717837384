import IndexUtil from "../../../indexUtil";

export const getSeries = (currentIndex, currentStock, line, compare, state) => {
    const dateList = Object.keys(currentIndex.data);
    const valueList = Object.values(currentIndex.data);
    const seriesName =
        currentStock.stock_code + ' ' + currentStock.stock_name + ' ' +
        IndexUtil.getGraphIndexText(line.index);
    const seriesData =
        dateList.map((date, i) =>
            [Date.parse(date + ' 00:00'), valueList[i]]);
    const visible = line ? line.visible : true;

    // console.log('compare');
    // console.log(compare);
    const option = {
        stockCode: currentStock.stock_code,
        indexCode: currentIndex.code,
        name: seriesName,
        data: seriesData,
        visible: visible,
        compare: compare,
        compareStart: true,
        color: line.color,
        labels: {
            align: 'left',
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            formatter: function()
            {
                return state.graphStart === 'normal' ? this.value: ((this.value > 0 ? '+' : '') + this.value + '%')
            }
        },
        title: {
            text: currentIndex.name + ' (' + currentIndex.unit + ')',
            style: {
                color: '#fff',
                fontSize: '12px'
            }
        },
        lastVisiblePrice: {
            enabled: state.graphStart === 'normal',
            // enabled: true,
            label: {
                enabled: true
            }
        },
        // 如果全部綁定y軸，可能出現在圖上大的數字比小的數字低的狀況。
        // yAxis: state.graphStart === 'normal' && currentIndex.unit !== '元'?
        // currentStock.stock_code + ' ' + currentIndex.code : 0
        yAxis:
            state.graphStart === 'normal' ?
                currentStock.stock_code + ' ' + currentIndex.code + ' ' + currentIndex.range :
                "0"
        // yAxis: currentIndex.code === 'DailyPERTrailing4Q' ?
        // currentStock.stock_code + ' ' + currentIndex.code : 0
        // yAxis: currentStock.stock_code + ' ' + currentIndex.code
        // yAxis: '1'
    }
    // console.log('compare');
    // console.log(compare);
    // console.log('series');
    // console.log(state.graphStart === 'normal');
    // console.log(currentStock.stock_code + '-' + currentIndex.code);
    // window.a = currentStock.stock_code + ' ' + currentIndex.code
    // console.log(option);
    return option;
}