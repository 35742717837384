import React, { Component } from 'react';
import {
    Button,
    Icon,
    Modal
} from 'rsuite';

import API from '../../api';
import IconClose from '../../image/svgIcon/close.svg';
import IconEdit from '../../image/svgIcon/edit.svg';
import TableNameModal from './tableNameModal';

import './tableManagerModal.css';

var _ = require('lodash');

export default class TableManagerModal extends Component {
    state = { showRenameModal: false };
    renameTableId = '';

    componentDidUpdate(prevProps) {
        if (_.isEqual(prevProps.tables, this.props.tables)) { return; }

        this.forceUpdate();
    }

    showRenameModal(id) {
        this.renameTableId = id;
        this.setState({ showRenameModal: true });
    }

    closeRenameModal(name) {
        this.setState({ showRenameModal: false });

        if (!name || name === '') { return; }

        this.renameTable(this.renameTableId, name);
    }

    async renameTable(id, name) {
        let table = this.props.tables.find(x => x.id === id);
        let result =
            await API.saveTable(
                id, name, table.stocks, table.columns, table.date,
                table.updated);

        if (!result) {
            alert('重新命名表格失敗');
            return;
        }

        alert('重新命名表格成功');
        if (!this.props.onTableListChange) { return; }
        table.name = name;
        this.props.onTableListChange(this.props.tables, result.current_id);
    }

    async removeTable(id, name) {
        if (!window.confirm('確認刪除表格「' + name + '」?')) { return; }

        let result = await API.deleteTable(id);

        if (!result) {
            alert('刪除表格失敗');
            return;
        }

        alert('刪除表格成功');

        if (!this.props.onTableListChange) { return; }
        let tableList = this.props.tables.filter(table => table.id !== id);
        this.props.onTableListChange(tableList, result.current_id);
    }

    onClose() {
        if (!this.props.onClose) { return; }
        this.props.onClose();
    }

    renderTableList() {
        if (!this.props.tables) { return; }

        return (
            this.props.tables
                .filter(table => !table.template)
                .map(table => {
                    let className = 'row';

                    return (
                        <div
                            key={'table-' + table.id}
                            className={className}>
                            <div className="name">
                                {table.name}
                            </div>
                            <div className="rename">
                                <Icon
                                    icon={IconEdit}
                                    onClick={() => this.showRenameModal(table.id)} />
                            </div>
                            <div className="remove">
                                <Icon
                                    icon={IconClose}
                                    onClick={() => this.removeTable(table.id, table.name)} />
                            </div>
                        </div>
                    );
                })
        );
    }

    render() {
        return (
            <Modal
                className="table-manager-modal"
                show={this.props.show}
                onHide={() => this.onClose()}>
                <Modal.Header>
                    <Modal.Title>刪除/重命名</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="list-panel">
                        <div className="list-header">
                            <div className="name">表格名稱</div>
                            <div className="rename">重新命名</div>
                            <div className="remove">刪除</div>
                        </div>
                        {this.renderTableList()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onClose()}>確定</Button>
                </Modal.Footer>
                <TableNameModal
                    show={this.state.showRenameModal}
                    mode="rename"
                    onCancel={() => this.closeRenameModal()}
                    onConfirmClick={(_, name) => this.closeRenameModal(name)} />
            </Modal>
        );
    }
}