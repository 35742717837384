import { showNotice } from 'Redux/actionCreators/notice';
import { FreeVersionLink } from '../constant/link';
 
const showFreeVersionNotice = () => showNotice(
    '您目前為免費版用戶',
    '免費版無法「建立策略」，以及「設定回測」，可以點擊「更多詳情」了解免費版與付費版差異',
    '我知道了',
    FreeVersionLink,
    () => window.location.href = '/dashboard'
);

export default showFreeVersionNotice;