import React, { useRef } from 'react';
import {
    Button,
    Dropdown,
    Popover,
    Whisper,
    Icon
} from 'rsuite';
import { useDispatch } from 'react-redux';

// import Dashboard from 'Components/dashboard';
import Dashboard from 'Components/dashboard/dashboard';
import DashboardLayoutModal from 'Components/dashboard/layoutModal';
// import DashboardManagerModal from 'Components/dashboard/dashboardManagerModal';
import DashboardManagerModal from 'Components/dashboard/managerModal';
import TabSelector from 'Components/tabSelector';
import TutorialHintModal from 'Components/tutorialHintModal';
import Arrow from 'Image/svgIcon/dashboard/arrow-1.svg'
import { isEqual } from "lodash";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { cookies } from 'Https/fetch';
import { SHOW_NEED_LOGIN_MODAL } from 'Redux/actionTypes/needLoginModal';

/**
 * @memberof module:DashboardPage
 * @description 介面
 * @param {object} dashboardPage - 頁面資料與狀態
 * @param {function} selectDashboard - 選擇版面
 * @param {function} showLayoutModal - 開啟戰情室編輯
 * @param {function} hideLayoutModal - 關閉戰情室編輯
 * @param {function} showCreateDashboardModal - 開啟新增戰情室畫面
 * @param {function} onLayoutModalCreateConfirm - 開啟新增戰情室畫面
 * @param {function} showDashboardModal - 開啟戰情室管理員
 * @param {function} closeDashboardModal - 關閉戰情室管理員
 * @returns {ReactElement}
 */

function DashboardPage({ 
    dashboardPage,
    selectDashboard, 
    showLayoutModal, 
    hideLayoutModal, 
    showCreateDashboardModal,
    // onLayoutModalCreateConfirm,
    showDashboardModal,
    closeDashboardModal,
    showTutorialHintModal,
    closeTutorialHintModal,
    showTutorialPage
})
{
    const dispatch = useDispatch();
    const editSubmenuTriggerRef = useRef(null);

    const checkLogin = () => {
        // Close the submenu if the user is not logged in
        if (cookies.get('accessToken')) { return; }

        dispatch({ type: SHOW_NEED_LOGIN_MODAL });
        editSubmenuTriggerRef.current.close();
    };

    const close = () => {
        editSubmenuTriggerRef.current.close()
    }
    const renderEditSubmenu = () => {
            return (
                <Popover className="edit-submenu" full>
                    <Dropdown.Menu onSelect={close}>
                        <Dropdown.Item onSelect={showLayoutModal}>
                            新增/編輯 模塊
                        </Dropdown.Item>
                        <hr />
                        <Dropdown.Item onSelect={showCreateDashboardModal}>
                            建立 新戰情室
                        </Dropdown.Item>
                        <Dropdown.Menu title="從範本建立 新戰情室">
                            {
                                dashboardPage.dashboardList      
                                .filter(dashboard => dashboard.template)
                                .map((dashboard, i) => {
                                    return <Dropdown.Item key={i} data-id={dashboard.id} onSelect={showCreateDashboardModal}>
                                        {dashboard.name}
                                    </Dropdown.Item>
                                    }
                                )
                            }
                        </Dropdown.Menu>
                        <hr />
                        <Dropdown.Item onSelect={showDashboardModal}>
                            戰情室管理員
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Popover>
            );
        }

    let selectedDashboard, selectedId;

    if (dashboardPage.dashboardList) {
        selectedDashboard = dashboardPage.dashboardList.find(x => x.selected);

        if (selectedDashboard) {
            selectedId = selectedDashboard.id;
        } else {
            selectedId =
                dashboardPage.dashboardList.length > 0 ?
                    dashboardPage.dashboardList[0].id :
                    undefined;
        }
    }

    return <div className="dashboard-page">
    <div style={{ display: 'flex' }}>
        <div className="page-title">我的戰情室</div>
        <div className = "dashboard___page_title__icon">
            <Icon icon={Arrow}/>
        </div>
            <div className="dashboard-selector">
                <TabSelector
                    tabs={dashboardPage.dashboardList.filter(x => !x.template)}
                    currentTab={selectedId}
                    onTabChange={id => selectDashboard(id)} />
            </div>
            <Whisper
                placement="bottomStart"
                trigger="click"
                triggerRef={editSubmenuTriggerRef}
                speaker={renderEditSubmenu()}>
                <Button
                    className="dashboard___page_title__button"
                    onClick={checkLogin}>
                    設定戰情室
                </Button>
            </Whisper>
        </div>
        <hr />
        <Dashboard
            singles={dashboardPage.singles}
            layout={dashboardPage.layout}
        />
        <DashboardLayoutModal
            show={dashboardPage.showLayoutModal}
            isCreateMode={dashboardPage.isCreateMode}
            layout={dashboardPage.editLayout}
            singles={dashboardPage.availableSingles}
            onHide={hideLayoutModal}
            // onCreateConfirm={onLayoutModalCreateConfirm}
        />
        <DashboardManagerModal
            show={dashboardPage.showDashboardModal}
            dashboardList={dashboardPage.dashboardList}
            id={dashboardPage.dashboard ? dashboardPage.dashboard.id : -1}
            singles={dashboardPage.availableSingles}
            onHide={closeDashboardModal}
            onConfirm={closeDashboardModal}
        />
        <TutorialHintModal
            show={showTutorialHintModal}
            onHide={closeTutorialHintModal}
            onConfirm={showTutorialPage} />
    </div >
};

function areEqual(prevProps, nextProps)
{
    return (
        isEqual(prevProps.dashboardPage, nextProps.dashboardPage) &&
        prevProps.showTutorialHintModal === nextProps.showTutorialHintModal
    );
}
export default React.memo(DashboardPage, areEqual);