export const SET_STOCK_POOL = 'SET_STOCK_POOL';
export const SET_STOCK_PRICE = 'SET_STOCK_PRICE';
export const CLEAR_STOCK_PRICE = 'CLEAR_STOCK_PRICE';
export const SET_ANALYSIS_LIST = 'SET_ANALYSIS_LIST';
export const UPDATE_TRACKING_STOCK = 'UPDATE_TRACKING_STOCK';
export const INSERT_TRACKING_STOCK = 'INSERT_TRACKING_STOCK';
export const INSERT_FAVORITE_GROUP = 'INSERT_FAVORITE_GROUP';
export const REMOVE_TRACKING_STOCK = 'REMOVE_TRACKING_STOCK';
export const REMOVE_FAVORITE_GROUP = 'REMOVE_FAVORITE_GROUP';
export const CLEAR_FAVORITE_GROUP = "CLEAR_FAVORITE_GROUP";
export const CLEAR_SPECIFIC_GROUP_IN_TRACKING_STOCK = "CLEAR_SPECIFIC_GROUP_IN_TRACKING_STOCK";
export const RENAME_FAVORITE_GROUP = 'RENAME_FAVORITE_GROUP';