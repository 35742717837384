import React from 'react';

import './style.scss';

const lowColor = { r: 221, g: 69, b: 86 };
const middleColor = { r: 131, g: 90, b: 141 };
const highColor = { r: 0, g: 129, b: 156 };
const splitPoint = 58.97;

const getMixedColor = percentage => {
    let startColor = percentage < splitPoint ? lowColor : middleColor;
    let endColor = percentage < splitPoint ? middleColor : highColor;

    let r =
        startColor.r * (100 - percentage) / 100 + endColor.r * percentage / 100;
    let g =
        startColor.g * (100 - percentage) / 100 + endColor.g * percentage / 100;
    let b =
        startColor.b * (100 - percentage) / 100 + endColor.b * percentage / 100;

    return `rgb(${r},${g},${b})`;
};

const Component = ({ value, total }) => {
    let percentage = Math.round(value / total * 100);
    let fillStyle = {
        background: getMixedColor(percentage),
        width: percentage + '%'
    };

    return (
        <div className="bar">
            <div className="fill" style={fillStyle} />
        </div>
    );
};

export default Component;