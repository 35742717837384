import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Component from './component';
import { getData } from 'Util/table';
import { getSharedStrategyList } from 'Redux/actionCreators/strategy';
import { saveConfigs } from 'Redux/actionCreators/configs';
import { TRACK_SHARED_STRATEGY } from 'Redux/saga/actionTypes/strategy';

const DisplayLength = 50;
const CardDisplayLength = 4;

const Container = () => {
    const id = 'shared-strategy-list';
    const columnList = [
        { id: 'name', name: '策略名稱', width: 220 },
        { id: 'tracked', name: '戰情室追蹤', width: 97 },
        { id: 'year_return_rate', name: '策略年化報酬率', width: 110 },
        { id: 'return_rate_acc', name: '策略累積報酬率', width: 110 },
        { id: 'back_year', name: '回測期間', width: 80 },
        { id: 'diff', name: '跑贏大盤', width: 110 },
        { id: 'index_year_return_rate', name: '大盤年化報酬率', width: 100 },
        { id: 'share_holds', name: '持股支數', width: 100 },
        { id: 'backtest_change_frequency', name: '換股頻率', width: 80 }
    ];

    const sharedList = useSelector(store => store.strategy.shared);
    const pagination =
        useSelector(store => store.pagination.list.find(o => o.id === id));
    const configs = useSelector(store => store.configs);
    const dispatch = useDispatch();

    const page = pagination ? pagination.page : 1;

    const [state, setState] = useState({
        sortColumn: 'year_return_rate',
        sortType: 'desc',
    });

    const [recentMonth, setRecentMonth] = useState(3);
    const [recentPage, setRecentPage] = useState(1);
    const [featuredMonth, setFeaturedMonth] = useState(3);
    const [featuredPage, setFeaturedPage] = useState(1);

    const sortColumnData = (sortColumn, sortType) => {
        setState({
            ...state,
            sortColumn: sortColumn, sortType: sortType
        });
    }

    useEffect(() => {
        getSharedStrategyList();
    }, []);

    // Handle recent month in configs changed
    useEffect(() => {
        if (!configs.recent_return || configs.recent_return === recentMonth) {
            return;
        }

        setRecentMonth(configs.recent_return);
    }, [configs.recent_return, recentMonth]);

    const trackSharedStrategy = e => {
        const id = Number(e.currentTarget.dataset.id);
        const tracked = JSON.parse(e.currentTarget.dataset.tracked);
        dispatch({ type: TRACK_SHARED_STRATEGY, id: id, tracked: !tracked });
    };

    const data =
        getData(
            sharedList,
            state.sortColumn,
            state.sortColumn,
            DisplayLength,
            page
        );

    const compareFunc = (a, b, month) => {
        if (
            !a || !a.returns || !a.returns.recents ||
            !b || !b.returns || !b.returns.recents
        ) {
            return 0;
        }

        let key = month + '_m';

        let recentsA = a.returns.recents.find(x => x.id === key);
        let recentsB = b.returns.recents.find(x => x.id === key);

        if (!recentsA || !recentsB) { return 0; }

        let valueA = recentsA.value;
        let valueB = recentsB.value;

        return valueB - valueA;
    };

    const getSlicedStrategies = (strategies, page) => {
        const startIndex = CardDisplayLength * (page - 1);
        const endIndex = startIndex + CardDisplayLength;

        return strategies.slice(startIndex, endIndex);
    }

    // Recent strategies
    const recentStrategies = [...sharedList];
    recentStrategies.sort((a, b) => compareFunc(a, b, recentMonth));

    const recentStrategiesSliced =
        getSlicedStrategies(recentStrategies, recentPage);

    const recentMaxPage =
        Math.ceil(recentStrategies.length / CardDisplayLength);

    const onRecentMonthChange = value => {
        setRecentMonth(value);
        saveConfigs({ name: 'recent_return', data: value });
    };

    const decreaseRecentPage = () => {
        if (recentPage <= 1) { return; }

        setRecentPage(recentPage - 1);
    };

    const increaseRecentPage = () => {
        if (recentPage >= recentMaxPage) { return; }

        setRecentPage(recentPage + 1);
    };

    // Featured strategies
    const featuredStrategies = sharedList.filter(x => x.featured);
    const featuredStrategiesSliced =
        getSlicedStrategies(featuredStrategies, featuredPage);

    const featuredMaxPage =
        Math.ceil(featuredStrategies.length / CardDisplayLength);

    const decreaseFeaturedPage = () => {
        if (featuredPage <= 1) { return; }

        setFeaturedPage(featuredPage - 1);
    };

    const increaseFeaturedPage = () => {
        if (featuredPage >= featuredMaxPage) { return; }

        setFeaturedPage(featuredPage + 1);
    };

    return <Component
        id={id}
        columnList={columnList}
        data={data}
        displayLength={DisplayLength}
        sharedList={sharedList}
        sortColumn={state.sortColumn}
        sortType={state.sortType}
        sortColumnData={sortColumnData}
        trackSharedStrategy={trackSharedStrategy}
        recentStrategies={recentStrategiesSliced}
        recentMonth={recentMonth}
        onRecentMonthChange={onRecentMonthChange}
        recentLeftIconEnabled={recentPage > 1}
        recentRightIconEnabled={recentPage < recentMaxPage}
        decreaseRecentPage={decreaseRecentPage}
        increaseRecentPage={increaseRecentPage}
        featuredStrategies={featuredStrategiesSliced}
        featuredMonth={featuredMonth}
        setFeaturedMonth={setFeaturedMonth}
        featuredLeftIconEnabled={featuredPage > 1}
        featuredRightIconEnabled={featuredPage < featuredMaxPage}
        decreaseFeaturedPage={decreaseFeaturedPage}
        increaseFeaturedPage={increaseFeaturedPage} />
};

export default Container;