import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'rsuite';

import { CLOSE_NEED_LOGIN_MODAL } from 'Redux/actionTypes/needLoginModal';
import LoginButton from 'Components/loginButton';

import './style.scss';

const Component = () => {
    const modal = useSelector(store => store.needLoginModal);
    const dispatch = useDispatch();

    const close = () => {
        dispatch({ type: CLOSE_NEED_LOGIN_MODAL });
    };

    return (
        <Modal
            size="xs"
            className="need-login-modal"
            show={modal.show}>
            <Modal.Title>請先登入</Modal.Title>
            <Modal.Body>此功能需登入</Modal.Body>
            <Modal.Footer>
                <Button className="cancel" onClick={close}>取消</Button>
                <LoginButton onClick={close} />
            </Modal.Footer>
        </Modal>
    );
};

export default Component;