import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import store from "Redux/store";
import Layout from "Components/layout/index"
import Routes from "Src/router/router.jsx"
import { 
    FETCH_STOCK_POOL
} from "SagaActions/stock"
import { 
    getAnalysisList
} from "Redux/actionCreators/stock";
import {
    FETCH_INDEX_LIBRARY
} from "Redux/saga/actionTypes/index.js"
import { FETCH_PLAN, FETCH_PROFILE } from 'Redux/saga/actionTypes/profile';
import { FETCH_CONFIGS } from 'Redux/saga/actionTypes/configs';
import { FETCH_LIST, FETCH_STOCKS } from 'Redux/saga/actionTypes/strategy';
import { FETCH_TRACKING_STOCK } from "Redux/saga/actionTypes/stock"
import {
    SET_IF_ALERT,
    TOGGLE_MESSAGE_BOX,
    TOGGLE_ALERT_BOX,
    ADD_ALERT,
    ADD_NOTIFICATION
} from './redux/actionTypes/messages';
import {
    FETCH_SHARED_LIST,
    UPDATE_STRATEGY_IN_LIST
} from 'Redux/saga/actionTypes/strategy';
import { CLOSE_NOTICE } from './redux/actionTypes/notice';
import { cookies } from 'Https/fetch';

import API from './api';
import history from './history';
import IndexUtil from './indexUtil';
import { SHOW_QUESTIONNAIRE_MODAL } from 'Redux/actionTypes/questionnaireModal';
import {
    SET_INITIAL_INDEX_SCORE_LIST
} from 'Redux/actionTypes/strategy/strategyDesign';

window.store = store
function App(){
    const indexLibrary = useSelector(store => store.index.indexLibrary);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!indexLibrary) {
            dispatch({ type: FETCH_INDEX_LIBRARY });
            return;
        }

        getAnalysisList();
        initEventSource();

        // Set default rule's index for strategy design here because can't
        // convert index string value to object in reducer
        initDefaultIndexScoreList(dispatch, indexLibrary);

        // Show questionnaire modal after 10 minutes
        if (cookies.get('dismissQuestionnaireModal') !== 'true') {
            setTimeout(() => {
                dispatch({ type: SHOW_QUESTIONNAIRE_MODAL });
            }, 1000 * 60 * 10);
        }

        // Only call API when user has logged in
        const accessToken = cookies.get('accessToken');
        if (accessToken && accessToken !== '') {
            dispatch({ type: FETCH_STOCK_POOL });
            dispatch({ type: FETCH_TRACKING_STOCK });
            dispatch({ type: FETCH_LIST });
            dispatch({ type: FETCH_SHARED_LIST });
            dispatch({ type: FETCH_PROFILE });
            dispatch({ type: FETCH_CONFIGS });
            dispatch({ type: FETCH_PLAN });

            // Check pricing plan every 15 minutes
            setInterval(() => dispatch({ type: FETCH_PLAN }), 1000 * 60 * 15);
        }

        // Close message box when switching between pages
        history.listen(() =>
            store.dispatch({ type: TOGGLE_MESSAGE_BOX, showMessageBox: false })
        );
    }, [dispatch, indexLibrary]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <Layout Routes={Routes} />
            </Provider>
        </DndProvider>
    );
}

function initEventSource() {
    let eventSource = API.getEventSource();

    if (!eventSource) { return; }

    eventSource.onopen = event => {
        console.log('EventSource opened:', event);
    }

    eventSource.addEventListener('backtest_complete', event => {
        let json = JSON.parse(event.data);

        store.dispatch({ type: UPDATE_STRATEGY_IN_LIST, list: [json.data] });

        // Workaround: Need implement FETCH_BACKTEST
        store.dispatch({ type: FETCH_LIST });

        store.dispatch({ type: SET_IF_ALERT, ifAlert: true });
        store.dispatch({ type: TOGGLE_ALERT_BOX, showAlertBox: true });
        store.dispatch({ type: ADD_ALERT, alert: json });
        store.dispatch({ type: ADD_NOTIFICATION, notification: json });
        store.dispatch({ type: CLOSE_NOTICE });
    });

    eventSource.addEventListener('score_complete', event => {
        let json = JSON.parse(event.data);

        store.dispatch({ type: FETCH_STOCKS, id: json.data.id });
        store.dispatch({ type: UPDATE_STRATEGY_IN_LIST, list: [json.data] });

        store.dispatch({ type: SET_IF_ALERT, ifAlert: true });
        store.dispatch({ type: TOGGLE_ALERT_BOX, showAlertBox: true });
        store.dispatch({ type: ADD_ALERT, alert: json });
        store.dispatch({ type: ADD_NOTIFICATION, notification: json });
        store.dispatch({ type: CLOSE_NOTICE });
    });

    eventSource.addEventListener('strategy_status', event => {
        let list = JSON.parse(event.data);
        
        if (!list) { return; }
        store.dispatch({type: UPDATE_STRATEGY_IN_LIST, list: list});
    });

    eventSource.onerror = function (err) {
        console.error('EventSource failed:', err);
    };
}

function initDefaultIndexScoreList(dispatch, indexLibrary) {
    let list = [
        {
            name: '分類1',
            rowList: [
                {
                    index: IndexUtil.restoreIndex(
                        {
                            value: 'DailyClose',
                            range: { value: 'day_latest' },
                            dataType: {},
                            time: { value: 'latest' }
                        },
                        indexLibrary
                    ),
                    operator: 'greater',
                    value: 0,
                    score: null
                }
            ]
        }
    ];

    dispatch({ type: SET_INITIAL_INDEX_SCORE_LIST, payload: list });
}

export default App;