import React, { useState, useCallback } from 'react';
import Component from './component';
import { useSelector } from "react-redux";
import { data } from "./constant"
import { changePage } from "Redux/actionCreators/pagination";

function Container()
{
    const stockPool = useSelector(store => store.stock.pool)
    const tabs = useSelector(store => store.tabList.list.find(o => o.id === 'eps'))
    const pagination = useSelector(store => store.pagination.list.find(o => o.id === 'eps'))
    const [searchText, setSearchText] = useState();
    const currentTab = tabs ? tabs.currentTab: null;

    const onSearchChange = useCallback((e) => {
        setSearchText(e.target.value)
        changePage('eps', 1);
    },[])

    const onChangeTabCallBack = useCallback(
        () => {
            changePage('eps', 1);
        },
        [],
    );

    // 加入名稱供過濾
    const namedData = currentTab && stockPool.length  ? data[currentTab].map(o => {
        const stock = stockPool.find(_o => _o.stock_code === o.stock_code.value)
        return {
            ...o,
            stock_name: stock.stock_name 
        }
    }): [];

    const filteredData = searchText ? namedData.filter(o => {
        return (o.stock_code.value.includes(searchText) || o.stock_name.includes(searchText))
    }): data[currentTab];

    return stockPool.length ? <Component
        searchText={searchText}
        onSearchChange={onSearchChange}
        data={currentTab  ? filteredData: []}
        currentTab={currentTab}
        stockPool = {stockPool}
        onChangeTabCallBack={onChangeTabCallBack}
        pagination={pagination}
    />: null
}

export default Container;