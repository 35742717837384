import { SET_REFKEY_TABLE, SET_EXPAND_ITEM_VALUTS, ON_ANALYSIS_SELECT, 
    EXPAND_TO_ITEM, SET_SELECTED_ANALYSISID, 
    SET_STRATEGYID_AND_ANALYSISID
 } from  "../actionTypes/sidebar"

const initialState = {
    expandItemValues: [],
    refKeyTable: [],
    selectedAnalysisId: null,
    strategyId: null,
    analysisId: null,
    refKeyTableSet: false
    // activeKey: ""
}
export default function reducer(state=initialState, action){
    switch(action.type) {
        case SET_REFKEY_TABLE:{
            const table = [];
            action.analysisList.forEach(x => {
                table.push(x);

                if (x.children) {
                    x.children.forEach(y => {
                        table.push(y);

                        if (!y.children) { return; }
                        y.children.forEach(z => table.push(z));
                    });
                }
            });

            return {
                ...state,
                refKeyTable: table,
                refKeyTableSet: true
            }
        }
        case SET_EXPAND_ITEM_VALUTS:  {
            return {
                ...state,
                expandItemValues: []
            }
        }
        // 選擇財報分析
        // 若選標頭則開啟複選單然後設定該Tree選到的值
        case ON_ANALYSIS_SELECT: {
            const { item } = action;
            if (state.expandItemValues.includes(item.value)) {
                let expandItemValueList = state.expandItemValues.filter(value => value !== item.value);
                return {
                    ...state,
                    expandItemValues: expandItemValueList
                }
            } else if (item.refKey) {
                let refKeyAry = item.refKey.split('-');
                let expandItems = [];
                let refKey = refKeyAry[0];
                for (let i = 1; i < refKeyAry.length; i++) {
                    refKey += '-' + refKeyAry[i];

                    // eslint-disable-next-line
                    let item = state.refKeyTable.find(x => x.refKey === refKey);
                    
                    expandItems.push(item.value);
                }
                return {
                    ...state,
                    expandItemValues: expandItems,
                    // selectedAnalysisId: item.value
                }                
            }

            // No refKey means not in Tree, so ignore it
            return state;
        }
        case SET_SELECTED_ANALYSISID: {
            return {
                ...state,
                selectedAnalysisId: action.selectedAnalysisId
            }
        }
        
        case EXPAND_TO_ITEM: {
            const { item } = action
            console.log('item');
            console.log(item);
            let refKeyAry = item.refKey.split('-');
            let expandItems = [];
            let refKey = refKeyAry[0];
            for (let i = 1; i < refKeyAry.length; i++) {
                refKey += '-' + refKeyAry[i];

                // eslint-disable-next-line
                let item = this.refKeyTable.find(x => x.refKey === refKey);
                expandItems.push(item.value);
            }
            return {
                ...state,
                expandItemValues: expandItems
            }
        }

        case SET_STRATEGYID_AND_ANALYSISID: {
            return {
                ...state,
                strategyId: action.strategyId ? action.strategyId : null,
                analysisId: action.analysisId ? action.analysisId : null
            }
        }
        default: {
            return state;
        }
    }
}