import React from 'react';
// import { hot } from 'react-hot-loader/root';
// import { statusTranspile } from '../../utils/statusTranspile'
import { useDispatch } from 'react-redux'
import { TOGGLE_ALERT_BOX, CLEAR_ALERT } from '../../redux/actionTypes/messages'
import { Icon } from 'rsuite';
import CheckMark from '../../image/svgIcon/message/checkmark-2.svg'
import Cross from '../../image/svgIcon/message/cross-1.svg'
import { eventTypesToPage } from '../../utils/eventTypesToPage';
import { Link } from "react-router-dom"
import {
    READ_TASK,
    CHECK_ALERT,
    SET_ALL_TASKS_READ
} from '../../redux/actionTypes/messages';
import Api from '../../api';
function AlertBox({ show,  alerts }){
    const dispatch = useDispatch()
    const closeAlert = () => {
        // Mark all tasks as read when closing the alert box
        setAllTasksRead();

        dispatch({ type: TOGGLE_ALERT_BOX, showAlertBox: false });
        dispatch({ type: CLEAR_ALERT });
    }

    const setAllTasksRead = async () => {
        const result = await Api.allTasksRead();
        if (!result) { return; }

        dispatch({ type: SET_ALL_TASKS_READ });
        dispatch({ type: CHECK_ALERT });
    }

    const readTask = async (notificationId) => {
        const result = await Api.readTask(notificationId)
        if(result) {
            dispatch({  type: READ_TASK, id: notificationId } )
            dispatch({ type: CHECK_ALERT })
        }
    }
    const redirect = async (e) => {
        await readTask(e.target.id)
        closeAlert();
    }
    return show ? <div className="alertBox">
        <div className="alertBox_close" onClick={closeAlert}>
            <Icon icon={Cross} />
        </div>

        <div className="alertBox_arrow">
        </div>
        {
            alerts.msg.map((o, i) => {
                return <div  key={i} className="alertBox_row">
                    <div className = "alertBox_row_title">
                        <div>
                            <Icon icon={CheckMark} />
                        </div>
                        <div>
                            <Link to={`/${eventTypesToPage[o.type]}/${o.data.id}`} id={o.id} onClick={redirect}>
                                {o.data.name}
                            </Link>
                            {/* {o.data.name} */}
                        </div>
                    </div>
                    <div className = "alertBox_row_status">
                        {o.data.status_text}
                    </div>
                </div>
            })
        }
    </div>: null
}
// export default hot(AlertBox);
export default AlertBox;