import React, { useRef } from 'react';

import Dropdown from './component/dropdown';
import TabList from './component/tabList';
import './style.scss';

// tabs format:
// { id: '', name: '' }
export default function TabSelector(
    { tabs, currentTab, onTabChange, enableDropdown }
) {
    const ref = useRef();
    const closeDropdown = () => {
        ref.current.close();
    }

    return (
        <div className="tab-selector">
            <TabList
                tabs={tabs}
                currentTab={currentTab}
                onSelect={id => onTabChange(id)} />
            {
                enableDropdown &&
                <Dropdown
                    tabs={tabs}
                    onSelect={id => onTabChange(id)}
                    closeDropdown={() => closeDropdown()}
                    whisperRef={ref} />
            }
        </div>
    );
}