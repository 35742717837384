import React from 'react';

import ListPanel from 'Components/listPanel';

import './styles.scss';

const Component = props => {
    let GraphComponent = props.component;
    let listData =
        props.data?.display.data.map(x => ({ ...x, data: x.data + x.unit }));

    return (
        <div className="graph-panel">
            <div className="graph-column">
                <GraphComponent data={props.data?.main} />
            </div>
            <div className="result-column">
                <div className="block-title">{props.data?.display.name}</div>
                <ListPanel data={listData} />
            </div>
        </div>
    )
}

export default Component;