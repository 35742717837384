import React, { useState } from 'react';

import './style.scss';

const Component = props => {
    const [isExpand, setIsExpand] = useState(false);

    return (
        <div className="expandable-panel">
            <div className="toggle blue" onClick={() => setIsExpand(!isExpand)}>
                {isExpand ? props.collapseText : props.expandText}
            </div>
            {isExpand && props.children}
        </div>
    );
}

export default Component;