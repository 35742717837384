import { call, put, select, takeLatest } from "redux-saga/effects";
import Api from "Api/stock";
import * as SagaActions from './actionTypes/stock';
import * as Actions from '../actionTypes/stock';

function* _fetchPool()
{
    const pool = yield call(() => Api('pool'));
    if(pool) {
        yield put({
            type: Actions.SET_STOCK_POOL, 
            pool: pool
        })
    }
}

function* _fetchStockPrice(action)
{
    const stockPrice = yield call(() => Api('stockPrice',{
        params: {
            id: action.id
        }
    }))
    if(stockPrice)
    {
        yield put({
            type: Actions.SET_STOCK_PRICE,
            stockPrice: stockPrice
        })
    }
}

function* _fetchAnalysis()
{
    const result = yield call(() => Api('analysis'));
    if(result)
    {
        yield put({
            type: Actions.SET_ANALYSIS_LIST,
            analysisList: result
        })
    }
}

function* fetchTracking()
{
    const trackingStock = yield call(() => Api('tracking'))
    // console.log('get trackingStock');
    // console.log(trackingStock);
    if(trackingStock)
    {
        yield put({
            type: Actions.UPDATE_TRACKING_STOCK,
            trackingStock: trackingStock
        })
    }
}

function* track(action)
{
    const { groupId, stockId, tracking } = action;
    const formData = new FormData();
    formData.append('group_id', groupId);
    formData.append('stock_code', stockId);
    formData.append('tracking', tracking);

    const result = yield call(() => Api('track', {
        formData: formData
    }))

    if(result)
    {
        const trackingStock = yield select(state => state.stock.trackingStock)
        if(tracking)
        {
            const newTrackingStock = trackingStock.map((group) => {
                if(group.id === groupId)
                {
                    return {
                        ...group,
                        items: [
                            ...group.items,
                            stockId
                        ]
                    }
                } else {
                    return group;
                }
            })
            yield put({
                type: Actions.UPDATE_TRACKING_STOCK,
                trackingStock: newTrackingStock
            })
            // yield put({
            //     type: Actions.INSERT_TRACKING_STOCK,
            // })
        } else {
            yield put({
                type: Actions.REMOVE_TRACKING_STOCK,
                groupId: groupId,
                stockId: stockId,
            })
        }
    }
}

function* _addFavoriteGroup(action)
{
    const result = yield call(() => Api('addFavoriteGroup', {
        formData: action.formData
    }))
    if(result)
    {
        yield put({
            type: Actions.INSERT_FAVORITE_GROUP,
            afterGroupId: action.afterGroupId,
            group: result
        })
    }
}




function* _deleteFavoriteGroup(action)
{
    const result = yield call(() => Api('deleteFavoriteGroup',{
        params: {
            id: action.id
        }
    }))
    if(result)
    {
        yield put({
            type: Actions.REMOVE_FAVORITE_GROUP,
            id: action.id
        })
    }
    // console.log(result);
}

function* _clearFavoriteGroup(action)
{
    const result = yield call(() => Api('clearFavoriteGroup', {
        params: {
            id: action.groupId
        }
    }))
    if(result)
    {
        yield put({
            type: Actions.CLEAR_FAVORITE_GROUP,
            groupId: action.groupId,
            panelId: action.panelId
        })

        yield put({
            type: Actions.CLEAR_SPECIFIC_GROUP_IN_TRACKING_STOCK,
            groupId: action.groupId,
        })
    }
}

function* _renameFavoriteGroup(action) {
    const result = yield call(() => Api('renameFavoriteGroup', {
        params: {
            id: action.id,
            name: action.name
        }
    }));

    if (result) {
        yield put({
            type: Actions.RENAME_FAVORITE_GROUP,
            id: result.id,
            name: result.name
        });
    }
}

function* fetchStockPrice()
{
    yield takeLatest(SagaActions.FETCH_STOCK_PRICE, _fetchStockPrice);    
} 


function* getTrackingStock()
{
    yield takeLatest(SagaActions.FETCH_TRACKING_STOCK, fetchTracking)
}

function* trackstock()
{
    yield takeLatest(SagaActions.TRACK_STOCK, track)
}

function*addFavoriteGroup()
{
    yield takeLatest(SagaActions.ADD_FAVORITE_GROUP, _addFavoriteGroup)
}

function* deleteFavoriteGroup()
{
    yield takeLatest(SagaActions.REMOTE_DELETE_FAVORITE_GROUP, _deleteFavoriteGroup)
}

function* clearFavoriteGroup()
{
    yield takeLatest(SagaActions.REMOTE_CLEAR_FAVORITE_GROUP, _clearFavoriteGroup)
}

function* renameFavoriteGroup() {
    yield takeLatest(SagaActions.RENAME_FAVORITE_GROUP, _renameFavoriteGroup);
}

function* fetchPool()
{
    yield takeLatest(SagaActions.FETCH_STOCK_POOL, _fetchPool);
}

function* fetchAnalysis()
{
    yield takeLatest(SagaActions.FETCH_ANALYSIS_LIST, _fetchAnalysis)   
}

export default {
    getTrackingStock,
    trackstock,
    addFavoriteGroup,
    deleteFavoriteGroup,
    clearFavoriteGroup,
    renameFavoriteGroup,
    fetchPool,
    fetchAnalysis,
    fetchStockPrice
};