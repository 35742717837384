import React from "react";

/**
 * This renderer supports cell-caching while the user is scrolling.
 */

export default function CellRangeRenderer({
    cellCache,
    cellRenderer,
    columnSizeAndPositionManager,
    columnStartIndex,
    columnStopIndex,
    deferredMeasurementCache,
    horizontalOffsetAdjustment,
    isScrolling,
    parent, // Grid (or List or Table)
    rowSizeAndPositionManager,
    rowStartIndex,
    rowStopIndex,
    styleCache,
    verticalOffsetAdjustment,
    visibleColumnIndices,
    visibleRowIndices,
    scrollTop,
    scrollLeft
},
    fixedRowCount, fixedColumnCount
) {
    if (rowStopIndex < 0) { return []; }

    // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
    // User cannot scroll beyond these size limitations.
    // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
    // We should never cache styles for compressed offsets though as this can lead to bugs.
    // See issue #576 for more.
    const areOffsetsAdjusted =
        columnSizeAndPositionManager.areOffsetsAdjusted() ||
        rowSizeAndPositionManager.areOffsetsAdjusted();
    const canCacheStyle = !isScrolling && !areOffsetsAdjusted;

    let dataStartColumnIndex =
        columnStartIndex < fixedColumnCount ?
            fixedColumnCount : columnStartIndex;
    let dataStartRowIndex =
        rowStartIndex < fixedRowCount ? fixedRowCount : rowStartIndex;

    let headerRows =
        renderRow(
            0, fixedRowCount - 1, rowSizeAndPositionManager,
            canCacheStyle, styleCache, verticalOffsetAdjustment,
            dataStartColumnIndex, columnStopIndex, columnSizeAndPositionManager,
            visibleColumnIndices, visibleRowIndices, deferredMeasurementCache,
            horizontalOffsetAdjustment, isScrolling, parent, cellCache,
            cellRenderer, fixedRowCount, fixedColumnCount, scrollTop,
            scrollLeft);

    let rows =
        renderRow(
            dataStartRowIndex, rowStopIndex, rowSizeAndPositionManager,
            canCacheStyle, styleCache, verticalOffsetAdjustment,
            dataStartColumnIndex, columnStopIndex, columnSizeAndPositionManager,
            visibleColumnIndices, visibleRowIndices, deferredMeasurementCache,
            horizontalOffsetAdjustment, isScrolling, parent, cellCache,
            cellRenderer, fixedRowCount, fixedColumnCount, scrollTop,
            scrollLeft);

    return [...headerRows, ...rows];
}

function renderRow(
    rowStartIndex, rowStopIndex, rowSizeAndPositionManager, canCacheStyle,
    styleCache, verticalOffsetAdjustment, columnStartIndex, columnStopIndex,
    columnSizeAndPositionManager, visibleColumnIndices, visibleRowIndices,
    deferredMeasurementCache, horizontalOffsetAdjustment, isScrolling, parent,
    cellCache, cellRenderer, fixedRowCount, fixedColumnCount, scrollTop,
    scrollLeft
) {
    const renderedCells = [];

    for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
        // Skip not fixed and invisible rows
        if (
            rowIndex >= fixedRowCount && (
                rowIndex < visibleRowIndices.start ||
                rowIndex > visibleRowIndices.stop
            )
        ) {
            continue;
        }

        const rowDatum =
            rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);
        let rowKey = 'row-' + rowIndex;
        let rowStyle;

        if (canCacheStyle && styleCache[rowKey] && rowIndex >= fixedRowCount) {
            rowStyle = styleCache[rowKey];
        } else {
            rowStyle = {
                height: rowDatum.size,
                left: 0,
                position: "absolute",
                top: rowDatum.offset + verticalOffsetAdjustment,
                right: 0
            };

            // Handling fixed header rows rendering
            if (rowIndex < fixedRowCount) {
                rowStyle.top += scrollTop;
            }

            styleCache[rowKey] = rowStyle;
        }

        let fixedCells =
            renderColumn(
                0, fixedColumnCount - 1, columnSizeAndPositionManager,
                visibleColumnIndices, rowIndex, visibleRowIndices,
                canCacheStyle, styleCache, deferredMeasurementCache,
                horizontalOffsetAdjustment, isScrolling, parent,
                verticalOffsetAdjustment, cellCache, cellRenderer,
                fixedRowCount, fixedColumnCount, scrollLeft);

        let rowRenderedCells =
            renderColumn(
                columnStartIndex, columnStopIndex, columnSizeAndPositionManager,
                visibleColumnIndices, rowIndex, visibleRowIndices,
                canCacheStyle, styleCache, deferredMeasurementCache,
                horizontalOffsetAdjustment, isScrolling, parent,
                verticalOffsetAdjustment, cellCache, cellRenderer,
                fixedRowCount, fixedColumnCount, scrollLeft);

        let className = 'row';

        if (rowIndex < fixedRowCount) {
            className += ' header';
        } else {
            className +=
                (rowIndex - fixedRowCount) % 2 === 0 ? ' even' : ' odd';
        }

        const SortableRow =
            <div
                className={className}
                style={{ ...rowStyle }}
                key={rowIndex}
                index={rowIndex}>
                {fixedCells}
                {rowRenderedCells}
            </div>;

        renderedCells.push(SortableRow)
    }

    return renderedCells;
}

function renderColumn(
    columnStartIndex, columnStopIndex, columnSizeAndPositionManager,
    visibleColumnIndices, rowIndex, visibleRowIndices, canCacheStyle,
    styleCache, deferredMeasurementCache, horizontalOffsetAdjustment,
    isScrolling, parent, verticalOffsetAdjustment, cellCache, cellRenderer,
    fixedRowCount, fixedColumnCount, scrollLeft
) {
    const rowRenderedCells = [];

    for (
        let columnIndex = columnStartIndex;
        columnIndex <= columnStopIndex;
        columnIndex++
    ) {
        const columnDatum =
            columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
        const isVisible =
            ( // Value cells
                columnIndex >= visibleColumnIndices.start &&
                columnIndex <= visibleColumnIndices.stop &&
                rowIndex >= visibleRowIndices.start &&
                rowIndex <= visibleRowIndices.stop
            ) || ( // Fixed row cells
                rowIndex < fixedRowCount &&
                columnIndex >= visibleColumnIndices.start &&
                columnIndex <= visibleColumnIndices.stop
            ) || ( // Fixed column cells
                columnIndex < fixedColumnCount &&
                rowIndex >= visibleRowIndices.start &&
                rowIndex <= visibleRowIndices.stop
            ) || ( // Upper-left corner fixed cells
                rowIndex < fixedRowCount && columnIndex < fixedColumnCount
            );
        const key = 'row-' + rowIndex + '-column-' + columnIndex;
        let style;

        // Cache style objects so shallow-compare doesn't re-render unnecessarily.
        if (
            canCacheStyle && styleCache[key] && rowIndex >= fixedRowCount &&
            columnIndex >= fixedColumnCount
        ) {
            style = styleCache[key];
        } else {
            // In deferred mode, cells will be initially rendered before we know their size.
            // Don't interfere with CellMeasurer's measurements by setting an invalid size.
            if (deferredMeasurementCache &&
                !deferredMeasurementCache.has(rowIndex, columnIndex)) {
                // Position not-yet-measured cells at top/left 0,0,
                // And give them width/height of 'auto' so they can grow larger than
                // the parent Grid if necessary.
                // Positioning them further to the right/bottom influences their measured size.
                style = {
                    height: "auto",
                    left: 0,
                    position: "absolute",
                    top: 0,
                    width: "auto"
                };
            } else {
                style = {
                    left: columnDatum.offset + horizontalOffsetAdjustment,
                    position: "absolute",
                    top: 0,
                    width: columnDatum.size
                };

                if (columnIndex < fixedColumnCount) {
                    style.left += scrollLeft;
                }

                if (
                    columnIndex < fixedColumnCount && rowIndex < fixedRowCount
                ) {
                    style.zIndex = 2;
                } else if (
                    columnIndex < fixedColumnCount || rowIndex < fixedRowCount
                ) {
                    style.zIndex = 1;
                }

                styleCache[key] = style;
            }
        }

        const cellRendererParams = {
            columnIndex, isScrolling, isVisible, key, parent, rowIndex, style
        };

        let renderedCell;

        // Avoid re-creating cells while scrolling.
        // This can lead to the same cell being created
        // many times and can cause performance issues for "heavy" cells.
        // If a scroll is in progress- cache and reuse cells.
        // This cache will be thrown away once scrolling completes.
        // However if we are scaling scroll positions and sizes, we should also avoid caching.
        // This is because the offset changes slightly as scroll
        // position changes and caching leads to stale values.
        // For more info refer to issue #395
        if (
            isScrolling && !horizontalOffsetAdjustment &&
            !verticalOffsetAdjustment
        ) {
            renderedCell = cellCache[key];
            if (!renderedCell) {
                cellCache[key]
                    = renderedCell = cellRenderer(cellRendererParams);
            }

            // If the user is no longer scrolling, don't cache cells.
            // This makes dynamic cell content difficult for users
            // and would also lead to a heavier memory footprint.
        } else {
            renderedCell = cellRenderer(cellRendererParams);
        }

        if (renderedCell) {
            if (process.env.NODE_ENV !== "production") {
                warnAboutMissingStyle(parent, renderedCell);
            }

            rowRenderedCells.push(renderedCell);
        }
    }

    return rowRenderedCells;
}

function warnAboutMissingStyle(parent, renderedCell) {
    if (process.env.NODE_ENV !== "production") {
        if (renderedCell) {
            // If the direct child is a CellMeasurer, then we should check its child
            // See issue #611
            if (
                renderedCell.type &&
                renderedCell.type.__internalCellMeasurerFlag
            ) {
                renderedCell = renderedCell.props.children;
            }

            if (
                renderedCell &&
                renderedCell.props &&
                renderedCell.props.style === undefined &&
                parent.__warnedAboutMissingStyle !== true
            ) {
                parent.__warnedAboutMissingStyle = true;

                console.warn(
                    "Rendered cell should include style property for positioning."
                );
            }
        }
    }
}
