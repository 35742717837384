import moment from 'moment';
import NumberHandler from 'Src/numberHandler';
export function getXAxisFormatter(periodType, value) {
    switch (periodType) {
        case 'month':
            return moment(value).format('YYYY/M');
        case 'quarter':
            return moment(value).format('YYYY[Q]Q');
        case 'year':
            return moment(value).format('YYYY');
        default:
            return moment(value).format('YYYY/M/D');
    }
}


export function getYAxisList(seriesList) {
    // console.log('seriesList');
    // console.log(seriesList);
    // Filter unique units
    let unitList =
        seriesList.filter((series, index, array) =>
            array.findIndex(x => series.unit === x.unit) === index
        );

    return unitList.map(series => ({
        id: series.unit,
        enabled: series.showYAxis === undefined || series.showYAxis,
        opposite: true,
        labels: {
            align: 'left',
            style: { color: '#fff', fontSize: '12px' },
            formatter: function () {
                return NumberHandler.getUnitNumber(this.value);
            }
        },
        title: {
            align: 'high',
            text: series.unit,
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            rotation: 0,
            textAlign: 'right',
            x: -23,
            y: -10
        },
        margin: -30,
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        plotLines: [{
            color: 'rgba(255, 255, 255, 0.3)',
            width: 1,
            value: 0
        }]
    }));
}