import React from 'react';
import { useSelector } from 'react-redux';

import Component from './component';
import './style.scss';

const Container = props => {
    const indexLibrary = useSelector(store => store.index.indexLibrary);

    return (
        <Component
            indexLibrary={indexLibrary ?? []}
            {...props} />
    );
};

export default Container;