import React, { useState } from 'react';
import {
    Pagination,
    Table
} from 'rsuite';
import _ from 'lodash';

import './style.scss';

const { Column, HeaderCell, Cell } = Table;

const sortFunction = (a, b, sortColumn, sortType) => {
    let x = a[sortColumn].value ?? a[sortColumn];
    let y = b[sortColumn].value ?? b[sortColumn];

    if (typeof x === 'string' || typeof y === 'string') {
        return (
            sortType === 'asc' ?
                ('' + x).localeCompare(y) : ('' + y).localeCompare(x)
        );
    }

    return sortType === 'asc' ? x - y : y - x;
}

const renderCell = (rowData, column, rowIndex, displayLength, page) => {
    if (column.id === 'serial') {
        return displayLength * (page - 1) + rowIndex + 1;
    }

    const cellData = rowData[column.id];
    const value = cellData?.value;
    if (!value) { return ''; }

    return (
        cellData.text ?
            <div dangerouslySetInnerHTML={{ __html: cellData.text }} /> :
            cellData.value
    );
};

const Component = props => {
    const [sortColumn, setSortColumn] = useState(props.sortColumn);
    const [sortType, setSortType] = useState(props.sortType);
    const [page, setPage] = useState(1);

    if (!props.data) { return <></>; }

    const columns = [
        { id: 'serial', name: '序列', width: 40 }, ...props.columns
    ];

    const onSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const getData = () => {
        let data = _.cloneDeep(props.data);
        data.sort((a, b) => sortFunction(a, b, sortColumn, sortType));

        let start = props.displayLength * (page - 1);
        let end = start + props.displayLength;

        return data.slice(start, end);
    }

    const className = "current-stock-table page-" + page;

    return (
        <div className={className}>
            <Table
                autoHeight={true}
                headerHeight={30}
                data={getData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={onSortColumn}>
                {
                    columns.map(column => {
                        return (
                            <Column
                                width={column.width}
                                key={column.id}
                                sortable={column.id !== 'serial'}
                                align={column.id !== 'stock_code' ? 'center' : 'left'}>
                                <HeaderCell>
                                    {column.name}
                                </HeaderCell>
                                <Cell dataKey={column.id}>
                                    {
                                        (rowData, rowIndex) =>
                                            renderCell(rowData, column, rowIndex, props.displayLength, page)
                                    }
                                </Cell>
                            </Column>
                        );
                    })
                }
            </Table>
            <div style={{ textAlign: 'center', marginTop: 16, height: 30 }}>
                <Pagination
                    first prev next last ellipsis
                    pages={Math.ceil(props.data.length / props.displayLength)}
                    maxButtons={10}
                    activePage={page}
                    onSelect={page => setPage(page)} />
            </div>
        </div>
    );
};

export default Component;