import React, { Component } from 'react';
import { Button, Icon, Modal } from 'rsuite';

import ImageCopy from '../image/svgIcon/copy.svg';
import ImageDelete from '../image/svgIcon/delete.svg';
import IndexExpression from './indexExpression';
import IndexUtil from '../indexUtil';

import './indexConstraintModal.css';

var _ = require('lodash');

export default class IndexConstraintModal extends Component {
    state = {
        constraintFilter: []
    };

    constructor(props) {
        super(props);

        if (!props.constraintFilter) { return; }
        let list = _.cloneDeep(props.constraintFilter);
        this.state.constraintFilter = list;
    }

    componentDidUpdate(prevProps) {
        // Update the list in state when the modal is opened
        if (!prevProps.show && this.props.show && this.props.constraintFilter) {
            let list = _.cloneDeep(this.props.constraintFilter);
            this.setState({ constraintFilter: list });
        }
    }

    addConstraint() {
        let emptyConstraint = { index: null, operator: 'greater' };

        let list = [...this.state.constraintFilter, emptyConstraint];
        this.setState({ constraintFilter: list });
    }

    copyConstraint(index) {
        let list = _.cloneDeep(this.state.constraintFilter);
        let constraint = _.cloneDeep(list[index]);

        list.push(constraint);

        this.setState({ constraintFilter: list });
    }

    deleteConstraint(index) {
        let list = _.cloneDeep(this.state.constraintFilter);

        list.splice(index, 1);

        this.setState({ constraintFilter: list });
    }

    setConstraintIndex(aryIndex, value) {
        let list = [...this.state.constraintFilter];
        list[aryIndex] = value;

        this.setState({ constraintFilter: list });
    }

    renderConstraintFilter() {
        return this.state.constraintFilter.map((x, i) => {
            let name = IndexUtil.getIndexText(x.index);

            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
                    key={i + '-' + name}>
                    <div className="icon-button-column">
                        <Button onClick={() => this.deleteConstraint(i)}>
                            <Icon icon={ImageDelete} />
                        </Button>
                    </div>
                    <div className="icon-button-column">
                        <Button onClick={() => this.copyConstraint(i)}>
                            <Icon icon={ImageCopy} />
                        </Button>
                    </div>
                    <IndexExpression
                        disableAscDesc
                        expression={x}
                        onChange={index => this.setConstraintIndex(i, index)} />
                </div>
            );
        });
    }

    render() {
        return (
            <div>
                <Modal
                    className="index-constraint-modal"
                    show={this.props.show}
                    onHide={() => this.props.onCancel()}
                    size="md">
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.isIncludeMode ? '選擇' : '排除'}篩選條件
                    </Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        {this.renderConstraintFilter()}
                        <Button
                            className="add-button"
                            appearance="subtle"
                            style={{ marginTop: 19 }}
                            onClick={() => this.addConstraint()}>
                            <Icon icon="plus-circle" /> 新增篩選條件
                    </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.onClose(this.state.constraintFilter)}>
                            確定
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}