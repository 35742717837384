import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import './style.scss';

const graphOptions = {
    chart: {
        backgroundColor: 'transparent',
        type: 'column',
        style: {
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif'
        },
        plotBorderColor: 'rgba(255, 255, 255, 0.7)',
        plotBorderWidth: 1
    },
    credits: {
        enabled: false
    },
    colors: [],
    plotOptions: {
        column: {
            borderColor: 'transparent',
            dataLabels: {
                enabled: true,
                connectorColor: 'transparent',
                distance: 5,
                color: 'white',
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    textOutline: null
                }
            },
            pointWidth: 17
        }
    },
    title: null,
    legend: {
        enabled: false
    },
    xAxis: {
        categories: [],
        labels: {
            style: { color: '#fff', fontSize: '12px' }
        },
    },
    yAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.3)',
        opposite: true,
        labels: {
            style: {
                color: '#fff',
                fontSize: '12px'
            },
            x: 7,
            y: 0
        },
        title: null
    },
    series: [{
        name: '',
        colorByPoint: true,
        data: []
    }]
};

let options = {};

const updateGraph = (ref, data) => {
    options.xAxis.categories = data ? Object.keys(data.data[0].data) : [];
    options.series[0].data = data ? Object.values(data.data[0].data): [];
    options.colors = getBarColor(options.xAxis.categories);

    if (!ref || !ref.current || !ref.current.chart) { return; }
    ref.current.chart.update(options);
}

const getBarColor = xAxisCategoryList => {
    let negativeColor = 'rgba(114, 207, 0, 0.8)';
    let positiveColor = 'rgba(255, 74, 104, 0.8)';

    return (
        xAxisCategoryList.map(category => {
            let value = category.replace('%', '');
            return parseInt(value) < 0 ? negativeColor : positiveColor;
        }));
}

const Component = props => {
    let ref = useRef();

    useEffect(() => {
        options = _.cloneDeep(graphOptions);
    }, []);

    useEffect(() => {
        updateGraph(ref, props.data);
    }, [props.data]);

    return (
        <div className="risk-return-graph">
            <div className="graph-title">{props.data?.name}</div>
            <HighchartsReact
                ref={ref}
                highcharts={Highcharts}
                options={options} />
        </div>
    )
}

export default Component;