import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Component from "./component.jsx"
import { getPanelData } from "Redux/actionCreators/dashboard"
import { isEqual } from "lodash";
function Container({ panel, rowHeight, parameters })
{
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === panel.id))
    // const panelData = null
    const trackingStock = useSelector(store=>store.stock.trackingStock)
    
    if(panelData)
    {
        panelData.rawData.main.left_table.columns.forEach(x => x.sortable = true);
        panelData.rawData.main.right_table.columns.forEach(x => x.sortable = true);
    }

    useEffect(() => {
        getPanelData(panel, parameters)
    },[panel, parameters])
    return panelData ? <Component 
        data={panelData.rawData}
        trackingStock={trackingStock}
    />: null
}

function areEqual(prevProps, nextProps)
{
    if(!isEqual(prevProps.panel, nextProps.panel))
    {
        return false;
    }else {
        return true;
    }
}
// export default Container;

export default React.memo(Container, areEqual);