export const getIndexUnit = index => {
    if (!index || !index.value) { return ''; }

    let unit = index.value.unit;

    if (
        index.dataType && (
            index.dataType.value === 'differentPercent' ||
            index.dataType.value === 'differentHeadTailPercent' ||
            index.dataType.value === 'growthRate' ||
            index.dataType.value === 'CAGrowthRate'
        )
    ) {
        unit = '%';
    }

    return unit;
}