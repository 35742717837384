import React from "react";
import { Pagination } from 'rsuite';
/**
 *
 * @memberof module:Pagination
 * @param {number} list - 資料。
 * @param {function} page - 選到的頁數
 * @param {number} displayLength - 單頁顯示比數。
 * @param {function} onChangePage - 換頁
 * @param {number} maxButtons - 最多按鈕數量。
 * @description 分頁UI
 */
function Component({list, page, displayLength, onChangePage, maxButtons})
{
    return <Pagination
        first prev next last ellipsis
        pages={Math.ceil(list.length / displayLength)}
        maxButtons={maxButtons}
        activePage={page}
        onSelect={onChangePage}
    />
}

export default Component;