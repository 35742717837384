import { showNotice } from 'Redux/actionCreators/notice';
import { StoreLink } from '../constant/link';
 
const showUpgradeNotice = () => showNotice(
    '此為付費版功能',
    '升級訂閱方案，立即建立您的選股策略，快速找出高勝率策略。',
    '我知道了',
    StoreLink
);

export default showUpgradeNotice;