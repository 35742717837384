import React, { Component } from 'react';
import KLineGraph from 'Components/kLineGraph/index';
import NumberHandler from 'Src/numberHandler';
import './style.scss';
import { isEqual } from "lodash"

class IndexChart extends Component {
    renderTWIndexInfo() {
        if (!this.props.data || !this.props.data.main) { return null; }

        let textColor = '';
        let priceSign = '';
        let percentageSign = '';

        if (this.props.data.main.name[2] > 0) {
            textColor = ' red';
            priceSign = '▲';
            percentageSign = '+';
        } else if (this.props.data.main.name[2] < 0) {
            textColor = ' green';
            priceSign = '▼';
        }

        return (
            <div className="info">
                <span className="name">{this.props.data.main.name[0]}</span>
                <span className={'name value' + textColor}>{this.props.data.main.name[1]}</span>
                <span className={'value' + textColor}>{priceSign + this.props.data.main.name[2]}</span>
                <span className={'value' + textColor}>{percentageSign + this.props.data.main.name[3]}</span>
                <span className="value">{NumberHandler.getUnitNumber(this.props.data.main.name[4])}</span>
            </div>
        );
    }

    renderIndex() {
        if (
            !this.props.data || !this.props.data.display ||
            !this.props.data.display.data
        ) {
            return null;
        }

        return this.props.data.display.data.map(data =>
            <div>
                <div className="index-row market_index_row">
                    <div className="index-name">{data.name}</div>
                    {
                        data.fontColor && data.data &&
                        data.data.map((value, i) => {
                            let className = 'index-value ' + data.fontColor[i];
                            return <div className={className}>{value}</div>;
                        })
                    }
                </div>
                <hr />
            </div>
        );
    }

    render() {
        // console.log('render1');
        // console.log('render2');
        // console.log('render3');
        return (
            <div className="index-chart">  
                <div className="k-line">
                    <div>{this.renderTWIndexInfo()}</div>
                    <KLineGraph
                        isTWIndex
                        data={this.props.data}
                        background="#353945" />
                </div>
                <div className="index">
                    <div className="title">{this.props.data.display.name}</div>
                    <hr />
                    {this.renderIndex()}
                </div>
            </div>
        );
    }
}

function areEqual(prevProps, nextProps)
{
    if(!isEqual(prevProps, nextProps))
    {
        return false;
    }else {
        return true;
    }
}

export default React.memo(IndexChart, areEqual);