export const colorDecidedByIndexAndValue = (i, value, withColorIndexes, colorMappingByRange) => {
    if(withColorIndexes.indexOf(i) !== -1)
    {
        const target = colorMappingByRange.find(o => {        
            if(
                (o.after !== undefined ? value > o.after: true ) &
                (o.before !== undefined ? value < o.before: true) &
                (o.equals !== undefined ? value === o.equals: true)
            )
            {
                return true
            }
            return false;
        })
        return target ? target.color : '';
    } else {
        return ''
    }
}

export const colorDecidedByValue = (value, colorMappingByRange) => {
    const target = colorMappingByRange.find(o => {        
        if(
            (o.after !== undefined ? value > o.after: true ) &
            (o.before !== undefined ? value < o.before: true) &
            (o.equals !== undefined ? value === o.equals: true)
        )
        {
            return true
        }
        return false;
    })
    if(target !== undefined)
    {
        return target.color;
    }
    return '';
}