import React from 'react';

import ArrowBlock from '../arrowBlock';
import ListItem from '../listItem';
import IndexUtil from 'Src/indexUtil';

import './style.scss';

const Component = props => {
    return (
        <ArrowBlock
            className="step-2"
            value="STEP2"
            title="幫好球帶的股票打分數，以下列條件評分">
            {
                props.indexScores &&
                props.indexScores.map((block, i) =>
                    block.rowList.map((row, j) =>
                        <ListItem key={i + '-' + j}>
                            {IndexUtil.getIndexRuleJSX(row)}，符合條件得
                            <span className="blue">{row.score}</span>分
                        </ListItem>
                    )
                )
            }
            {
                !props.indexScores &&
                <div className="rule-hidden">(分享者將評分條件設為不公開)</div>
            }
        </ArrowBlock>
    );
}

export default Component;