const formatDuration = duration => {
    if (duration == null) { return '-'; }

    const roundedDuration = Math.round(duration);
    const second = roundedDuration % 60;
    const minute = Math.floor(roundedDuration / 60 % 60);
    const hour = Math.floor(roundedDuration / 3600 % 24);
    const day = Math.floor(roundedDuration / 86400);

    let str = '';

    if (day > 0) {
        str += day + '日';
    }

    if (hour > 0) {
        str += hour + '小時';
    }

    if (minute > 0) {
        str += minute + '分';
    }

    if (second > 0 || str === '') {
        str += second + '秒';
    }

    return str;
};

export default formatDuration;