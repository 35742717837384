import React from "react"
import TrackStockToggle from "../trackStockToggle";
import { track } from "Redux/actionCreators/stock"
import { useSelector } from "react-redux";
import _ from 'lodash';

const setTrackStock = (groupId, checked, e) => {
    const stockId = e.currentTarget.name
    track(groupId, stockId, checked)
}

/**
 * @memberof module:TrackStockToggle
 * @param {string} id - 股票代號
 * @summary 資料處理 
 * @description 用id於trackingStock的各個group裡面找有沒有被追蹤
 * @returns {ReactElement}
 */

function Container({ id, style, inPopover }) {
    const trackingStock = useSelector(state => state.stock.trackingStock);
    const tracked =
        trackingStock ?
            trackingStock.some(group => group.items.includes(id.toString())) :
            false;

    return (
        <TrackStockToggle
            id={id}
            style={style}
            inPopover={inPopover}
            tracked={tracked}
            trackingStock={trackingStock ? trackingStock : []}
            setTrackStock={setTrackStock} />
    );
}

function areEqual(prevProps, nextProps) {
    return (
        prevProps.id === nextProps.id &&
        _.isEqual(prevProps.style, nextProps.style) &&
        prevProps.inPopover === nextProps.inPopover
    );
}

export default React.memo(Container, areEqual);