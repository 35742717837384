import DashboardPage from "Page/dashboardPage/index";
import StrategyOverviewPage from "Page/strategyOverviewPage/index";
import Market from "Page/market";
import StrategySharing from "Page/strategySharing/";
import Favorite from "Page/favorite/index";
import Login from "Page/loginPage";
import GraphPage from 'Page/graph/index';
// import GraphPage from 'Page/graphPage';
import EPS from "Components/eps";
import StockAnalysisPage from "Page/stockAnalysis/index";
import TablePage from 'Page/tablePage';
// import Strategy from 'Page/strategyPage';
import Strategy from 'Page/strategy/index';
import BackTestResult from 'Page/backtestResult/index';
import ViewSharedStrategy from 'Page/viewSharedStrategy';
import TutorialPage from 'Page/tutorial';
import ChangeLogPage from 'Page/changeLog';
import StrategyScore from 'Page/strategyScore';

import BackTest from 'Page/backtest/index';
export const routes = [
	{
		path: "/login",
		component: Login
	},
	{
		path: "/backtestResult/:id",
		component: BackTestResult
	},
	{
		path: "/dashboard",
		component: DashboardPage,
	},
	{
		path: "/StrategyOverview",
		component: StrategyOverviewPage,
	},
	{
		path: [
			'/strategy/:id',
			'/strategy'
		],
		component: Strategy,
	},
	{
		path: [
			'/strategyScore/:id',
			'/strategyScore'
		],
		component: StrategyScore,
	},
	{
		path: '/strategySharing/:id',
		component: ViewSharedStrategy
	},
	{
		path: "/strategySharing",
		component: StrategySharing,
	},
	{
		path: "/eps",
		component: EPS,
	},
	{
		path: "/graph",
		component: GraphPage,
	},
	{
		path: "/table",
		component: TablePage,
	},
	{
		path: "/market",
		component: Market,
	},
	{
		path: "/favorite",
		component: Favorite,
	},
	{
		path: [
			'/stockAnalysis/:id/:analysisId/:strategyId',
			'/stockAnalysis/:id/:analysisId',
			'/stockAnalysis/:id'
		],
		// path: '/stockAnalysis/:id/:analysisId/:strategyId',
		component: StockAnalysisPage,
	},
	{
		path: "/backtest/:id",
		component: BackTest
	},
	{
		path: '/tutorial',
		component: TutorialPage
	},
	{
		path: '/changeLog',
		component: ChangeLogPage
	}
	// {
	// 	path: "/tacos",
	// 	component: Tacos,
	// 	routes: [
	// 		{
	// 			path: "/tacos/bus",
	// 			component: Bus,
	// 		},
	// 		{
	// 			path: "/tacos/cart",
	// 			component: Cart,
	// 		},
	// 	],
	// },
	// {
	// 	path: "/error",
	// 	component: Error,
	// }
];
