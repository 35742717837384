import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AutoComplete,
    Button,
    Icon,
    InputGroup,
    Modal
} from 'rsuite';

import IconAdd from 'Image/svgIcon/dashboard/add.svg';
import IconDelete from 'Image/svgIcon/delete.svg';

import './style.scss';

const Component = ({
    selections,
    show,
    onConfirm,
    onClose
}) => {
    const stockpool = useSelector(store => store.stock.pool);
    const autoCompleteRef = useRef();
    const [text, setText] = useState();
    const [list, setList] = useState([]);
    const stockAutoCompleteList = useRef();

    useEffect(() => {
        stockAutoCompleteList.current =
            stockpool.map(x => ({
                label: x.stock_code + ' ' + x.stock_name,
                value: x.stock_code
            }));
    }, [stockpool]);

    const init = () => {
        setList(selections ?? []);
    };

    const onSelect = item => {
        if (list.some(x => x.value === item.value)) { return; }

        setList([...list, item.value]);
        setTimeout(() => setText(''));
    };

    const onKeyUp = e => {
        const found = autoCompleteRef.current.getFocusableMenuItems();

        // KeyCode 13 = Enter
        if (e.keyCode !== 13 || found.length !== 1) { return; }

        onSelect(...found);
        autoCompleteRef.current.close();
        setText('');
    };

    const deleteStock = value => {
        setList(list.filter(x => x !== value));
    };

    const renderList = () => {
        return (
            <div className="list">
                {
                    list.map(x => {
                        let stock = stockpool.find(s => s.stock_code === x);
                        let label = stock.stock_code + ' ' + stock.stock_name;
                        return (
                            <div key={x} className="item">
                                {
                                    stock &&
                                    <>
                                        <div className="label">{label}</div>
                                        <div
                                            className="clickable"
                                            onClick={() => deleteStock(x)}>
                                            <Icon icon={IconDelete} />
                                        </div>
                                    </>
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    return (
        <Modal
            className="stock-filter-modal"
            show={show}
            onEnter={init}
            onHide={onClose}>
            <Modal.Header>
                <Modal.Title>選擇個股</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <InputGroup>
                    <AutoComplete
                        ref={autoCompleteRef}
                        placeholder="搜尋代號/名稱"
                        data={stockAutoCompleteList.current}
                        value={text}
                        onChange={value => setText(value)}
                        onSelect={item => onSelect(item)}
                        onKeyUp={e => onKeyUp(e)} />
                    <InputGroup.Button>
                        <Icon icon={IconAdd} />
                    </InputGroup.Button>
                </InputGroup>
                <div className="hint">按Enter新增股票</div>
                {renderList()}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onConfirm(list)}>完成</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;