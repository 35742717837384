import React from 'react'
import { Link } from 'react-router-dom';
export default function Empty(){
    return <div className="strategy__list">
        <div className="strategy__list-empty">
            <div>
                您還沒有追蹤任何投資策略
            </div>
            <div>
                <Link to="/strategySharing">
                    立即追蹤
                </Link>
            </div>
        </div>
    </div>
}