import { SET_A_TABLIST, REMOVE_A_TABLIST, CHANGE_TAB } from "../actionTypes/tabList";
const initialState = {
    list: [],
}


// const tabObj = {
//     tabs: [],
//     currentTab: ''
// }

export default function reducer(state=initialState, action)
{

    switch (action.type) {
        case SET_A_TABLIST: {
            const exist = state.list.find(o => o.id === action.id);
            if(exist)
            {
                const list = state.list.map(o => {
                    if(o.id === action.id)
                    {
                        return {
                            ...o,
                            tabs: action.tabs
                        }
                    }else  {
                        return o;
                    }
                })
                return {
                    ...state,
                    list: list
                };
            } else {
                const currentTab = action.defaultTab ? action.defaultTab: (
                    action.tabs[0] ? (action.tabs[0].id ? action.tabs[0].id: action.tabs[0].value): null
                )
                return {
                    ...state,
                    list: [
                        ...state.list,
                        {
                            id: action.id,
                            currentTab: currentTab,
                            tabs: action.tabs
                        }
                    ]
                }
            }
        }
        case REMOVE_A_TABLIST: {
            const list = state.list.filter(o => o.id !== action.id)
            return {
                ...state,
                list: list
            }
        }
        case CHANGE_TAB:{
            // console.log('change');
            const list = state.list.map(o => {
                if(o.id === action.id)
                {
                    return {
                        ...o,
                        currentTab: action.currentTab
                    }
                } else {
                    return o;
                }
            })
            return {
                list: list
            }
        }
        default: {
            return state;
        }
    }
};