import React from 'react';
import {
    Button,
    Modal
} from 'rsuite';

const Component = props => {
    let text = [];

    let split =
        props && props.terms && props.terms.content ?
            props.terms.content.split('\n') : [];
    split.forEach((x, i) => text.push(<div key={i}>{x}<br /></div>));

    return (
        <Modal
            className="terms-modal"
            show={props.show}
            size="lg"
            onHide={props.onClose}>
            <Modal.Title>
                {props.terms?.title}
            </Modal.Title>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>
                    確定
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Component;