import React from 'react';
import { statusTranspile } from '../../../utils/statusTranspile';
import Api from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import CheckMark from '../../../image/svgIcon/message/checkmark-1.svg'
import { Icon } from 'rsuite';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link } from "react-router-dom"
import { READ_TASK, CHECK_ALERT } from '../../../redux/actionTypes/messages'
import { eventTypesToPage } from '../../../utils/eventTypesToPage';


export default function Tasks({ tasks })
{
    const dispatch = useDispatch()
    //
    const notifications = useSelector(store => store.messages.notifications)
    const notificationTasks = notifications.filter((o) => {
        return  o.category === 'task';
    })

    const readTask = async (notificationId, taskId) => {
        const result = await Api.readTask(notificationId)
        if(result) {
            dispatch({ type: READ_TASK, id: notificationId } )
            dispatch({ type: CHECK_ALERT })
        }
    }
    return <PerfectScrollbar style= { notificationTasks.length > 10 ? { height: "420px", width: '287px'}: {width: '287px'}}>
        <div className="messageBox_content_task">
            {/* {
                tasks ? tasks.map((o, i) => {
                    return <div key={i} className={o.read ? "messageBox_content_task_row": "messageBox_content_task_row messageBox_content_task_row-unread"}>
                        <div>
                            <div className = "messageBox_content_task_row_title">
                                    <div>
                                        <Icon icon={CheckMark}/>
                                    </div>
                                    <div>
                                        <Link to={`/strategy/${o.strategyId}`} onClick={() => {readTask(o.strategyId)}}>
                                            {o.msg}
                                        </Link>
                                    </div>
                            </div>
                            <div className = "messageBox_content_task_row_status">
                                {statusTranspile[o.status]}
                            </div>
                        </div>
                    </div>   
                }): '讀取中'
            } */}
            {
                notificationTasks ? notificationTasks.map((o, i) => {
                    return <div key={i} className={o.read ? "messageBox_content_task_row": "messageBox_content_task_row messageBox_content_task_row-unread"}>
                        <div>
                            <div className = "messageBox_content_task_row_title">
                                    <div>
                                        <Icon icon={CheckMark}/>
                                    </div>
                                    <div>
                                        <Link to={`/${eventTypesToPage[o.type]}/${o.data.id}`} onClick={() => {readTask(o.id)}}>
                                            {o.data.name}
                                        </Link>
                                    </div>
                            </div>
                            <div className = "messageBox_content_task_row_status">
                                {statusTranspile[o.data.status] ? statusTranspile[o.data.status] : ''}
                            </div>
                        </div>
                    </div>   
                }): '讀取中'
            }
        </div>

    </PerfectScrollbar>
};