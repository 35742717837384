import { call, put, takeLatest, select } from "redux-saga/effects";
import moment from 'moment';
import * as SagaActions from "./actionTypes/strategy";
import * as Actions from "../actionTypes/strategy/strategy";
import Api from "Api/strategy";
import { showNotice } from 'Redux/actionCreators/notice';
import { renameModalId } from "./selector/strategy/strategyOverviewPage";
import * as StrategyDesignActions from "../actionTypes/strategy/strategyDesign";
import { getStrategyObject } from  "Util/strategy";

import { prepareFormData } from "Util/formData";
import history from "Src/history"
const selectList = state => state.strategy.list;

function* _fetchStrategyScore(action)
{
    const strategyScore = yield call(() => Api('strategyScore',
    {
        params: {
            id: action.id
        }
    }));

    if(strategyScore)
    {
        yield put({
            type: Actions.INSERT_STRATEGYSCORE,
            id: action.id,
            strategyScore: strategyScore
        })

        yield put({
            type: Actions.SET_SIDEBAR_STRATEGY_SCORE_ID,
            id: action.id
        })
    }
}

function* _item(action)
{
    const item =  yield  call(() => Api("item",{  params: {id: action.id}}))
    // console.log('action');
    // console.log(action);
    if(item) {
        const indexLibrary = yield select(store => store.index.indexLibrary)
        // console.log('item');
        // console.log(item);
        const data = getStrategyObject(item, indexLibrary)

        yield put({
            type: StrategyDesignActions.SET_DESIGN_STRATEGY,
            payload: data
        })
    }
}

function* _revertToLastSave(action)
{
    const item =  yield  call(() => Api("item",{  params: {id: action.id}}))
    // console.log('action');
    // console.log(action);
    if(item) {
        const indexLibrary = yield select(store => store.index.indexLibrary)
        // console.log('item');
        // console.log(item);
        const data = getStrategyObject(item, indexLibrary)

        yield put({
            type: StrategyDesignActions.SET_DESIGN_STRATEGY,
            payload: data
        })

        showNotice("通知", "策略已還原至上次儲存狀態")
    }
}

function* list()
{
    const list = yield call(() => Api('list'));
    if(list)
    {
        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: list
        })
    }
}

function* _fetchStocks(action)
{
    const stockList = yield  call(() => Api('stockList', { params: {id:  action.id}}));
    if(stockList)
    {
        yield put({
            type: StrategyDesignActions.SET_STOCK_LIST,
            stockList: stockList
        })

        yield put({
            type: StrategyDesignActions.UPDATE_DESIGN_STRATEGY,
            payload: { status: stockList.status }
        });
    }
}

function* _sharedStrategyList()
{
    const list = yield call(() => Api('sharedList'))
    if(list)
    {
        yield put({
            type: Actions.SET_SHAREDLIST,
            sharedList: list
        })
    }
}

function* _trackSharedStrategy(action) {
    const tracked = action.tracked;

    const formData = new FormData();
    formData.append('_method', 'PUT');

    if (tracked != null) {
        formData.append('tracked', tracked ? '1' : '0');
    }

    const requestData = {
        params: { id: action.id },
        formData: formData
    }

    const result = yield call(() => Api('trackSharedStrategy', requestData));

    if (!result) { return; }

    let list = yield select(state => state.strategy.shared);
    list = [...list];

    const strategyIndex = list.findIndex(x => x.id === action.id);

    if (strategyIndex !== -1) {
        list[strategyIndex].tracked = tracked ? 1 : 0;

        yield put({
            type: Actions.SET_SHAREDLIST,
            sharedList: list
        });
    } else {
        yield put({ type: SagaActions.FETCH_SHARED_LIST });
    }
}

function* _startScore(action)
{
    const formData = prepareFormData(action.payload);
    const result = yield call(() => Api("startScore", {
        formData: formData
    }))

    if (!result) { return; }

    yield put({
        type: StrategyDesignActions.UPDATE_DESIGN_STRATEGY,
        payload: { status: result.status }
    });

    yield put({
        type: SagaActions.UPDATE_STRATEGY_IN_LIST,
        list: [{
            id: parseInt(action.payload.id),
            name: action.payload.name,
            status: result.status,
            status_text: result.status_text
        }]
    });

    if (action.payload.id !== result.id) {
        const location = { pathname: '/strategy/' + result.id };
        history.push(location);

        put({
            type: SagaActions.FETCH_STOCKS,
            id: action.payload.id
        });
    }
}

function* copy(action)
{
    const result = yield call(() => Api("copy", { params: { id: action.id } }));
    let list = yield select(selectList);
    list = [...list];

    if(result && !Array.isArray(result))
    {
        list.push(result);
        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: list
        })
        showNotice('通知', '複製策略成功')
    } else {
        showNotice('通知', '複製策略失敗')
    }
}

function* copyEdit(action)
{
    const result = yield call(() => Api('copy', { params: { id: action.id } }));
    let list = yield select(selectList);
    list = [...list];

    if (result && !Array.isArray(result)) {
        list.push(result);
        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: list
        });

        const location = { pathname: '/strategy/' + result.id };
        history.push(location);
    } else {
        showNotice('通知', '複製策略失敗');
    }
}

function* _resetCopiedStrategy(action)
{
    const result =  yield call(() => Api("resetCopiedStrategy", {
        params: { id: action.id }
    }))
    const indexLibrary = yield select(store => store.index.indexLibrary)
    const data =  getStrategyObject(result, indexLibrary)

    if(result)
    {
        yield put({
            type:  StrategyDesignActions.SET_DESIGN_STRATEGY,
            payload: data
        })
        showNotice('通知', '策略已還原至初始設定')
    } else {
        showNotice('通知', '還原策略至初始設定失敗')
    }
}

function* rename(action)
{
    const msg = '重新命名策略';
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('name', action.name);
    const id = yield select(renameModalId)
    const requestData = {
        params: {
            id: id
        },
        formData: formData
    }
    
    const res = yield call(() => Api('rename', requestData));
    
    let list = yield select(selectList);

    if (list && res) {
        list = [...list];

        let strategyIndex = list.findIndex(x => x.id.toString() === id);
        if (strategyIndex !== -1) {
            list[strategyIndex] = {
                ...list[strategyIndex],
                name: action.name
            };

            yield put({
                type: Actions.UPDATE_STRATEGY_LIST,
                list: list
            });
        }

        showNotice('通知', msg + '成功');
    } else {
        showNotice('通知', msg + '失敗');
    }
}

function* track(action) {
    const tracked = action.tracked;

    const formData = new FormData();
    formData.append('_method', 'PUT');

    if (tracked != null) {
        formData.append('tracked', tracked ? '1' : '0');
    }

    const requestData = {
        params: { id: action.id },
        formData: formData
    }

    const result = yield call(() => Api('update', requestData));

    if (result) {
        let list = yield select(selectList);
        list = [...list];

        let strategyIndex = list.findIndex(x => x.id === action.id);
        list[strategyIndex] = {
            ...list[strategyIndex],
            tracked: tracked ? 1 : 0
        };

        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: list
        });
    }
}
    
function* publish(action)
{
    const published = action.published;
    const msg = (published ? '' : '取消') + '分享策略';
    const formData = new FormData();
    formData.append('_method', 'PUT');
    
    if (published === true || published === false) {
        formData.append('published', published ? '1' : '0');
    }

    const requestData = {
        params: {
            id: action.id
        },
        formData: formData
    }

    const result = yield call(() => Api("update", requestData))
    
    if (result) {
        const list = yield select(selectList)
        const strategy = list.find(x => x.id === action.id);
        strategy.published = published;
        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: list
        })
    } else {
        showNotice("通知", msg + '失敗')
    }
    
    // if (this.whisperList[id]) {
        //     this.whisperList[id].hide();
        // }
}


function* _delete(action)
{
    const id = parseInt(action.id);
    const result = yield call(() => Api("delete", { params: {id: id }}));
    
    if (result) {
        const list = yield select(selectList);
        let strategyList = list.filter(x => x.id !== id);
        yield put({
            type: Actions.UPDATE_STRATEGY_LIST,
            list: strategyList
        })
        showNotice('通知', '刪除策略成功')
        // window.showNotice = () => showNotice('通知', '刪除策略成功')
    } else {
        showNotice('通知', '刪除策略失敗')
    }

    // if (this.whisperList[id]) {
    //     this.whisperList[id].hide();
    // }
}


// function* _fetchIndustry()
// {
//     const industries = yield call(() => Api('industries'));
//     if(industries)
//     {
//         yield put({
//             type: SET_INDUSTRIES,
//             industries: industries
//         })
//     }
// }

function* _updateStrategyInList(action) {
    if (!action.list) { return; }

    const updatedList = action.list;
    let list = yield select(selectList);
    list = [...list];

    // Find and replace the strategy in list
    for (let i = 0; i < updatedList.length; i++) {
        let updatedStrategy = updatedList[i];
        let strategyIndex = list.findIndex(y => y.id === updatedStrategy.id);

        if (strategyIndex !== -1) {
            list[strategyIndex] = {
                ...list[strategyIndex],
                ...updatedStrategy,
                updated_at: moment().format('YYYY-MM-DD HH:mm:ss')
            };
        } else {
            // Can't find the corresponding strategy in list
            // The list is not complete, force update
            yield put({ type: SagaActions.FETCH_LIST });
            return;
        }
    }

    yield put({ type: Actions.UPDATE_STRATEGY_LIST, list: list });
}

function* item()
{
    yield  takeLatest(SagaActions.FETCH_STRATEGY, _item);
}

function* revertToLastSave()
{
    yield takeLatest(SagaActions.REVERT_TO_LAST_SAVE, _revertToLastSave);    
}

function* fetchStocks()
{
    yield takeLatest(SagaActions.FETCH_STOCKS, _fetchStocks)
}
function* fetchStrategyScore()
{
    yield takeLatest(SagaActions.FETCH_STRATEGYSCORE, _fetchStrategyScore)
}

function* startScore()
{
    yield takeLatest(SagaActions.START_SCORE, _startScore)
}

function* strategyList()
{
    yield takeLatest(SagaActions.FETCH_LIST, list)
}

function* copyStategy()
{
    yield takeLatest(SagaActions.COPY_STRATEGY, copy)
}

function* copyEditStategy() {
    yield takeLatest(SagaActions.COPY_EDIT_STRATEGY, copyEdit);
}

function* resetCopiedStraty()
{
    yield  takeLatest(SagaActions.RESET_COPIED_STRATEGY_REMOTE, _resetCopiedStrategy);
}

function* sharedStrategyList()
{
    yield takeLatest(SagaActions.FETCH_SHARED_LIST, _sharedStrategyList)    
}

function* trackSharedStrategy() {
    yield takeLatest(SagaActions.TRACK_SHARED_STRATEGY, _trackSharedStrategy);
}

function* renameStrategy()
{
    yield takeLatest(SagaActions.RENAME_STRATEGY, rename);
}

function* trackStrategy()
{
    yield takeLatest(SagaActions.TRACK_STRATEGY, track);
}

function* publishStrategy()
{
    yield takeLatest(SagaActions.PUBLISH_STRATEGY, publish);
}

function* deleteStrategy()
{
    yield takeLatest(SagaActions.DELETE_STRATE, _delete);
}

function* updateStrategyInList() {
    yield takeLatest(SagaActions.UPDATE_STRATEGY_IN_LIST, _updateStrategyInList);
}

export default { item, strategyList, sharedStrategyList, trackSharedStrategy, copyStategy, copyEditStategy, renameStrategy, trackStrategy, publishStrategy, deleteStrategy,
    fetchStrategyScore, resetCopiedStraty, fetchStocks, revertToLastSave, startScore, updateStrategyInList
}