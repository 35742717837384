import store from '../store';
import { SHOW_NOTICE } from '../actionTypes/notice';

export const showNotice =
    (title, content, closeText, link, onExit) => {
        const obj = {
            type: SHOW_NOTICE,
            title: title ?? '通知',
            content: content ?? '發生了錯誤',
            closeText: closeText,
            link,
            onExit
        };

        store.dispatch(obj);
    };