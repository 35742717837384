import React, { useCallback, useEffect, useState } from 'react';
import Component from './component';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import IndexUtil from 'Src/indexUtil';
import showUpgradeNotice from '../../utils/showUpgradeNotice';
import {
    FETCH_STRATEGY,
    RESET_COPIED_STRATEGY_REMOTE,
    REVERT_TO_LAST_SAVE,
    START_SCORE
} from 'Redux/saga/actionTypes/strategy';
import * as Actions from 'Redux/actionTypes/strategy/strategyDesign';

const Container = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const profile = useSelector(store => store.profile);
    const { id } = useParams();
    const [prevStatus, setPrevStatus] = useState();
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

    const strategyDesign = useSelector(store => store.strategyDesign);
    const indexLibrary = useSelector(store => store.index.indexLibrary);
    const strategyList = useSelector(store => store.strategy.list);

    const strategy = strategyList.find(x => x.id.toString() === id);
    const status = strategy?.status;

    // Show upgrade notice for free users
    useEffect(() => {
        if (profile.pricing_plan !== 'free') { return; }

        showUpgradeNotice();
    }, [profile, id]);

    useEffect(() => {
        if (indexLibrary && id) {
            dispatch({
                type: FETCH_STRATEGY,
                id: id
            });
        }

        // Reset when id turns null
        // (Navigated from a previous strategy to a new strategy)
        if (!id) {
            dispatch({ type: Actions.RESET_STRATEGY_DESIGN });
        }
        /* eslint-disable-next-line */
    }, [indexLibrary, id]);

    useEffect(() => {
        // Navigate to scoring result when the result is available
        if ((prevStatus === 11 || prevStatus === 15) && status === 12) {
            history.push('/strategyScore/' + id);
        }

        setPrevStatus(status);
        // eslint-disable-next-line
    }, [status]);

    const resetCopiedStrategy = () => {
        dispatch({
            type: RESET_COPIED_STRATEGY_REMOTE,
            id: id
        });
    };

    const revertToLastSave = () => {
        dispatch({
            type: REVERT_TO_LAST_SAVE,
            id: id
        });
    };

    const onStrategyNameChange = useCallback((value) => {
        dispatch({
            type: Actions.SET_STRATEGY_NAME,
            strategyName: value
        });
        /* eslint-disable-next-line */
    }, []);

    const onStrategyDescriptionChange = useCallback((value) => {
        dispatch({
            type: Actions.SET_STRATEGY_DESCRIPTION,
            strategyDescription: value
        });
        /* eslint-disable-next-line */
    }, []);

    const onIndustryFilterChange = useCallback((list) => {
        dispatch({
            type: Actions.SET_INDUSTRY_FILTER,
            industryFilter: list
        });
        /* eslint-disable-next-line */
    }, []);

    const onConstraintFilterChange = useCallback((list) => {
        dispatch({
            type: Actions.SET_CONSTRAINT_FILTER,
            constraintFilter: list
        });
        /* eslint-disable-next-line */
    }, []);

    const startScore = () => {
        dispatch({
            type: START_SCORE,
            payload: {
                id: id,
                name: strategyDesign.strategyName,
                description: strategyDesign.strategyDescription,
                industryFilter: strategyDesign.industryFilter,
                constraintFilter: strategyDesign.constraintFilter,
                scoreList: strategyDesign.indexScoreList
            }
        });
    };

    const getTotalScore = () => {
        let totalScore = 0;

        strategyDesign.indexScoreList.forEach(block =>
            block.rowList.forEach(index => {
                if (!index.score) { return; }

                totalScore += index.score;
            })
        );

        return totalScore;
    };

    const getErrorMessages = () => {
        const errorMessages = [];

        if (getTotalScore() !== 100) {
            errorMessages.push('總分需等於100分方可進行運算');
        }

        if (strategyDesign) {
            if (strategyDesign.strategyName === '') {
                errorMessages.push('請輸入投資策略名稱');
            }

            let valueNotFilled =
                strategyDesign.indexScoreList.some(category =>
                    category.rowList.some(rule =>
                        rule.index && !IndexUtil.checkIndexRule(rule)
                    )
                );

            let scoreNotFilled =
                strategyDesign.indexScoreList.some(category =>
                    category.rowList.some(rule =>
                        rule.index && rule.score == null
                    )
                );

            if (valueNotFilled) {
                errorMessages.push('請輸入數值');
            }

            if (scoreNotFilled) {
                errorMessages.push('請輸入分數');
            }
        }

        return errorMessages;
    };

    const scored =
        status != null && status !== 0 && status !== 1 && status !== 10 &&
        status !== 11 && status !== 15;

    // Scoring progress Modal
    const isScoringProgressModalOpen = status === 11 || status === 15;
    const scoringPercentage = strategy?.percentage;
    const scoringRemainTime = strategy?.time;

    const showStrategyOverview = () => {
        history.push({ pathname: '/strategyOverview' });
    };

    // Two step tutorial modal
    const showTutorialModal = () => {
        setIsTutorialModalOpen(true);
    };

    const onTutorialModalClose = () => {
        setIsTutorialModalOpen(false);
    };

    return (
        <Component
            id={id}
            indexLibrary={indexLibrary ? indexLibrary : []}
            strategyDesign={strategyDesign}
            resetCopiedStrategy={resetCopiedStrategy}
            revertToLastSave={revertToLastSave}
            scored={scored}
            onStrategyNameChange={onStrategyNameChange}
            onStrategyDescriptionChange={onStrategyDescriptionChange}
            onIndustryFilterChange={onIndustryFilterChange}
            onConstraintFilterChange={onConstraintFilterChange}
            startScore={startScore}
            isScoringProgressModalOpen={isScoringProgressModalOpen}
            scoringPercentage={scoringPercentage}
            scoringRemainTime={scoringRemainTime}
            showStrategyOverview={showStrategyOverview}
            errorMessages={getErrorMessages()}
            isTutorialModalOpen={isTutorialModalOpen}
            showTutorialModal={showTutorialModal}
            onTutorialModalClose={onTutorialModalClose} />
    );
};

export default Container;