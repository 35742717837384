import React, { 
    // Component, 
    useState, useCallback, useEffect } from "react";
import{ useSelector } from "react-redux";
import Component from "./component"
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import {
//     Icon,
//     Pagination,
//     SelectPicker,
//     Table
// } from 'rsuite';
import TrackStockToggle from 'Components/trackStockToggle/index';
import './style.scss';
// import _ from 'lodash';
import { getData } from "Util/table"

function Container({ columnList, data })
{
    const trackingStock = useSelector(store => store.stock.trackingStock)
    const [state, setState] = useState({
        columnList: [],
        sortColumn: '',
        sortType: '',
        displayLength: 10,
        page: 1
    })

    useEffect(() => {
        setState(state => ({ ...state, columnList }));
    }, [columnList]);

    const onDisplayLengthChange = (length) => {
        setState({ ...state, page: 1, displayLength: length });
    }

    const sortColumnData = (sortColumn, sortType) => {
        setState({...state, sortColumn: sortColumn, sortType: sortType });
    }

    const handleDragColumn = (sourceId, targetId) => {
        const nextData = state.columnList.filter(x => x.id !== sourceId);
        const dragItem = state.columnList.find(x => x.id === sourceId);
        const index = nextData.findIndex(x => x.id === targetId);

        nextData.splice(index + 1, 0, dragItem);
        setState({ ...state, columnList: nextData });
    }

    const formatValue = useCallback((id, cellData) => {
        if (id === 'stock_code') {
            let content = <div dangerouslySetInnerHTML={{ __html: cellData.text }} />;
            let target = cellData.linkTarget ? cellData.linkTarget : '_blank';

            if (cellData.link) {
                content =
                    <a href={cellData.link}
                        target={target}
                        rel="noopener noreferrer">
                        {content}
                    </a>;
            }

            if (cellData.tracked !== undefined) {
                content =
                    <div style={{ display: 'flex' }}>
                        <TrackStockToggle
                            id={cellData.value}
                            trackingStock={trackingStock}
                        />
                        {content}
                    </div>;
            }

            return content;
        }

        if (!id.includes('ROI')) {
            return cellData;
        }

        if (cellData === '') { return '-'; }

        let sign = '';
        let className = '';

        if (cellData > 0) {
            sign = '+';
            className = 'positive';
        } else if (cellData < 0) {
            className = 'negative';
        }

        return <div className={className}>{sign + cellData + '%'}</div>;
        /* eslint-disable-next-line */
    }, [])


    const onChangePage = (page) => {
        setState({ ...state, page: page });
    }

    return <Component
        sortColumn={state.sortColumn}
        sortType={state.sortType}
        sortColumnData={sortColumnData}
        handleDragColumn={handleDragColumn}
        onDisplayLengthChange={onDisplayLengthChange}
        columnList={state.columnList}
        formatValue={formatValue}
        data={getData(
            data,
            state.sortColumn,
            state.sortType,
            state.displayLength,
            state.page
        )}
        displayLength={state.displayLength}
        dataLength={data.length}
        page={state.page}
        onChangePage={onChangePage}
    />
}


export default Container;
