import React from "react";
export const columnToContents = {
    stock_code: (cellData) => {
        let content = <div dangerouslySetInnerHTML={{ __html: cellData.text }} />
        return content
    },
    Score: (cellData, currentTab) => {
        const data = cellData.find(o => o.tab_id === currentTab);
        let content = <div dangerouslySetInnerHTML={{ __html: data.text }} />;
        return content;
    },
    ror: (cellData, currentTab) => {
        const data = cellData.find(o => o.tab_id === currentTab);
        let content = <div dangerouslySetInnerHTML={{ __html: data.text }} />;
        return content;
    },
    cost: (cellData, currentTab) => {
        const data = cellData.find(o => o.tab_id === currentTab);
        let content = <div dangerouslySetInnerHTML={{ __html: data.text }} />;
        return content;
    },
    weight: (cellData, currentTab) => {
        const data = cellData.find(o => o.tab_id === currentTab);
        let content = <div dangerouslySetInnerHTML={{ __html: data.text }} />;
        return content;
    },
    DailyPriceChange_Percent: (cellData, currentTab) => {
        
        let content = <div dangerouslySetInnerHTML={{ __html: cellData.text }} />;
        return content;
    },
    DailyClose: (cellData) => {
        let content = <div dangerouslySetInnerHTML={{ __html: cellData.text }} />;
        return content;
    },
    DailyClose1: (cellData) => {
        let content = <div dangerouslySetInnerHTML={{ __html: cellData.text }} />;
        return content;
    },
}
export const rowDataToContentsByColumnId = (id ,rowData, currentTab) => {
    return columnToContents[id](rowData[id], currentTab);
}