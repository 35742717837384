import history from "Src/history";
import store from "Redux/store"
import { COPY_STRATEGY } from 'Redux/saga/actionTypes/strategy';

export function toEditStrategy(eventKey, event){
    const id = event.currentTarget.dataset.id
    const location = { pathname: '/strategy/' + id };
    history.push(location);
}

export function copyStrategy(eventKey, event){
    const id = event.currentTarget.dataset.id
    store.dispatch({type: COPY_STRATEGY, id: id})
}

