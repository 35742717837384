export const ConstraintOperator = [
    { label: '>', value: 'greater' },
    { label: '≥', value: 'greaterEqual' },
    { label: '=', value: 'equal' },
    { label: '<', value: 'less' },
    { label: '≤', value: 'lessEqual' },
    { label: '介於', value: 'between' },
    { label: '> 另一指標', value: 'greaterIndex' },
    { label: '≥ 另一指標', value: 'greaterEqualIndex' },
    { label: '= 另一指標', value: 'equalIndex' },
    { label: '< 另一指標', value: 'lessIndex' },
    { label: '≤ 另一指標', value: 'lessEqualIndex' },
    { label: '百分位排名 >', value: 'rankGreater' },
    { label: '百分位排名(排除) >', value: 'rankExcludeGreater' },
    { label: '百分位排名 ≥', value: 'rankGreaterEqual' },
    { label: '百分位排名(排除) ≥', value: 'rankExcludeGreaterEqual' },
    { label: '百分位排名 <', value: 'rankLess' },
    { label: '百分位排名(排除) <', value: 'rankExcludeLess' },
    { label: '百分位排名 ≤', value: 'rankLessEqual' },
    { label: '百分位排名(排除) ≤', value: 'rankExcludeLessEqual' },
    { label: '百分位排名越高越高分', value: 'rankAscScore' },
    { label: '百分位排名越低越高分', value: 'rankDescScore' }
];

export const MultiValueOperator = [
    //{ label: '逐漸上升', value: 'increase' },
    //{ label: '逐漸下降', value: 'decrease' }
];

// Operator collections
export const Operator = [...ConstraintOperator, ...MultiValueOperator];