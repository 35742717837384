const floatFormatter = (number, exponent) => {
    let size = Math.pow(10, exponent);
    return Math.round(number * size) / size;
}
/*
    flaot number : number of input
    int decimal : specify decimal (false is auto detect)
    array<int> useUnits : use unit table to change number of input
*/
const getUnitNumber = (number, decimal = false, useUnits = [0, 3, 4, 6, 8, 10, 12, 14]) => {
    const unitMap = ['', '十', '百', '千', '萬', '十萬', '百萬', '千萬', '億', '十億', '百億', '千億', '兆', '十兆', '百兆', '千兆']
    //				  0, 	1,	 2,    3,	4,		5,	   6, 	  7, 	8,	   9, 	  10, 	 11,   12,	  13,	 14,	15
    let data = parseFloat(number);
    let result_number = data, result_decimal = 2;
    let i, pow = 1, precision = 3;
    for (i = 0; i < useUnits.length; i++) {
        pow = Math.pow(10, useUnits[i]);
        if (i !== 0) {
            precision = useUnits[i] - useUnits[i - 1];
        }
        result_number = data / pow;
        result_decimal = decimal !== false ? decimal : (useUnits[i] > 2 ? 1 : 2);
        if (Math.abs(result_number) < Math.pow(10, precision)) {
            return floatFormatter(result_number, result_decimal) + unitMap[useUnits[i]];
        }
    }
    return floatFormatter(result_number, result_decimal) + unitMap[useUnits[i - 1]];
}
export default {
    floatFormatter,
    getUnitNumber
}