import React from 'react';

import './style.scss';

const Component = props => {
    let className = 'value-highlight';

    if (props.highlight) {
        className += ' highlight';

        if (props.value > 0) {
            className += ' red';
        } else if (props.value < 0) {
            className += ' green';
        }
    }

    return (
        <div className={className}>
            <div className="title">{props.title}</div>
            <div className="value-container">
                <div className="value">{props.value}</div>
                <div className="unit">{props.unit}</div>
            </div>
        </div>
    );
}

export default Component;