import moment from 'moment';
import React, { Component } from 'react';
import {
    Button,
    Checkbox,
    Input,
    Modal,
} from 'rsuite';
import API from '../../api';

import './dataDateModal.css';

export default class DataDateModal extends Component {
    state = {};

    async componentDidMount() {
        if (!this.props.dataDate) { return };

        let dataDate = moment(this.props.dataDate);

        let day = dataDate.format('YYYY/MM/DD');
        let { quarter, month } = await API.getDataDate(null, null, day);

        this.state = {
            quarter,
            month,
            day,
            autoUpdateDate: this.props.autoUpdateDate
        };
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // Reset all input data when the modal opens
            let dataDate = moment(this.props.dataDate);

            let day = dataDate.format('YYYY/MM/DD');
            let { quarter, month } = await API.getDataDate(null, null, day);

            this.setState({
                quarter,
                month,
                day,
                autoUpdateDate: this.props.autoUpdateDate
            });
        }
    }

    setQuarter(value) {
        this.setState({ quarter: value });

        API.getDataDate(value, null, null)
            .then(result => {
                if (!result) { return; }

                let { month, day } = result;
                this.setState({ month, day });
            });
    }

    setMonth(value) {
        this.setState({ month: value });

        API.getDataDate(null, value, null)
            .then(result => {
                if (!result) { return; }

                let { quarter, day } = result;
                this.setState({ quarter, day });
            });
    }

    setDay(value) {
        this.setState({ day: value });

        API.getDataDate(null, null, value)
            .then(result => {
                if (!result) { return; }

                let { quarter, month } = result;
                this.setState({ quarter, month });
            });
    }

    onDayBlur() {
        API.getDataDate(null, null, this.state.day)
            .then(result => {
                if (!result) { return; }

                let { quarter, month, day } = result;
                this.setState({ quarter, month, day });
            });
    }

    setAutoUpdateDate(checked) {
        this.setState({ autoUpdateDate: checked });
    }

    onConfirmClick() {
        if (!this.props.onConfirmClick) { return; }

        this.props.onConfirmClick(this.state.day, this.state.autoUpdateDate);
    }

    render() {
        return (
            <Modal
                className="table-date-modal"
                show={this.props.show}
                onHide={() => this.props.onCancel()}>
                <Modal.Header>
                    <Modal.Title>資料日期</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="subtle">目前資料日期為</div>
                    <div className="input-container">
                        <Input
                            value={this.state.quarter}
                            onChange={value => this.setQuarter(value)} />
                        財報
                        <Input
                            value={this.state.month}
                            onChange={value => this.setMonth(value)} />
                        月營收
                        <Input
                            value={this.state.day}
                            onChange={value => this.setDay(value)}
                            onBlur={() => this.onDayBlur()} />
                        收盤資料
                    </div>
                    <div className="auto-update-date">
                        <Checkbox
                            checked={this.state.autoUpdateDate}
                            onChange={(_, checked) => this.setAutoUpdateDate(checked)}>
                            <div>自動更新表格中的數據，至最新收盤日數據</div>
                            <div className="subtle">
                                (勾選後，將每日更新表格至最新數據；若不勾，調出數據後不再變動)
                            </div>
                        </Checkbox>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onConfirmClick()}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}