import React from 'react';
import _ from 'lodash';

import PanelTypeList from './panelTypeList';
import store from 'Redux/store';
import {
    INSERT_ACTIVEPANELLIST,
    UPDATE_DASHBOARD_LAYOUT
} from 'Redux/actionTypes/dashboard/dashboard';

/**
 * 
 * @function getActivePanelList
 * @param {object} singles - 當前看板的singles
 * @param {*} layout - 戰情室排版
 * @param {*} rowHeight 
 * @description 設定戰情室排版，設定各面板使用元件，元件mounted之後各自去要資料然後處理。
 * @memberof module:Dashboard
 */
export default function getActivePanelList(
    singles, layout, rowHeight, isLoggedIn
) {
    if (!singles || !layout) { return []; }

    let activePanelList = singles.filter(x => layout.some(y => x.id === y.i));

    for (let i = 0; i < activePanelList.length; i++) {
        let panel = activePanelList[i];

        getActivePanel(panel, rowHeight, isLoggedIn);
    }

    store.dispatch({ type: UPDATE_DASHBOARD_LAYOUT, layout: layout });
};

async function getActivePanel(originPanel, rowHeight, isLoggedIn) {
    const panel = _.cloneDeep(originPanel);

    if (panel.need_auth && !isLoggedIn) {
        panel.type = 'needLogIn';
    }

    const Component = PanelTypeList[panel.type];

    if (Component) {
        panel.component = <Component rowHeight={rowHeight} panel={panel} />;
    } else {
        console.log('Invalid panel type: ' + panel.type);
    }

    store.dispatch({ type: INSERT_ACTIVEPANELLIST, activePanel: panel });
}