import UI from "../ui";
import { getPanelData } from "Redux/actionCreators/dashboard"
import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment"
import _ from "lodash";
import { getXAxisFormatter, getYAxisList } from "Util/highchart"
import { colorList, graphOption, fixedGraphHeight } from './constants'
import ColorUtil from 'Src/colorUtil';
import { deepEqual } from  "Util/deepEqual"

function Container(props)
{
    const panelData = useSelector(store => store.dashboard.panelDataList.find(o => o.id === props.panel.id), deepEqual)
    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        setCurrentTab(panelData?.rawData.main.tab[0].id);
    }, [panelData]);

    const chartDataProcess = useCallback((chart, source, periodType, i, showTimeRangePicker) => {
        let colorIndex = source.some(series => series.chart_type === 'column') ? 0 : 1;  
        graphOption.chart.height = props.panel.type !== 'chart' ? undefined : fixedGraphHeight;

        let option = _.cloneDeep(graphOption);

        if (chart[0] && chart[0].showText) {
            let chartName = chart[0].name;

            // Two line comparison
            if (
                chart.length === 2 &&
                chart[0].chart_type === 'line' &&
                chart[1].chart_type === 'line' && chart[1].dashLine
            ) {
                chartName = chart[0].name + ' vs ' + chart[1].name;
            }

            option.title.text = chartName;
            option.subtitle.text = chart[0].text;
            option.subtitle.style.color = chart[0].fontColor;
        }

        option.series = [];
        option.periodType = periodType;

        option.xAxis[0].labels.formatter = function () {
            return getXAxisFormatter(periodType, this.value);
        };

        let tickInterval = 0;
        const dayTotalMs = 1000 * 60 * 60 * 24;
        switch (periodType) {
            case 'year':
                tickInterval = dayTotalMs * 365;
                break;
            case 'quarter':
                tickInterval = dayTotalMs * 90;
                break;
            case 'month':
                tickInterval = dayTotalMs * 30;
                break;
            default:
                tickInterval = dayTotalMs;
        }
        option.xAxis[0].tickInterval = tickInterval;

        option.yAxis = getYAxisList(source);

        if (chart[0] && chart[0].showLegend === false) {
            option.legend.enabled = false;
            option.chart.marginTop = 1;
        }

        source.forEach(series => {
            let dateList = Object.keys(series.data);

            // Filter the date by time range picker's value
            if (showTimeRangePicker && panelData.rawData.main.currentTimeRange !== 'all') {
                let splited = this.state.currentTimeRange.split('_');
                let num = parseInt(splited[0]);
                let startDate =
                    moment().startOf('day').subtract(num, splited[1]);

                dateList =
                    dateList.filter(date => moment(date).isAfter(startDate));
            }

            let seriesData =
                dateList.map(date => [Date.parse(date), series.data[date]]);
            let color = colorList[colorIndex % colorList.length];

            let seriesObject = {
                name: series.name,
                type: series.chart_type,
                data: seriesData,
                color: color,
                yAxis:
                    !(series.chart_option && series.chart_option.stack) ? series.unit : 0
            };

            if (
                series.chart_type === 'column' &&
                !(series.chart_option && series.chart_option.stack)
            ) {
                seriesObject.color = ColorUtil.hexToRgba(color, 0.4);
                seriesObject.borderColor = ColorUtil.hexToRgba(color, 0.9);
                seriesObject.borderWidth = 1;
            }

            if (series.chart_option && series.chart_option.stack) {
                seriesObject.stack = 0;
                seriesObject.stacking = 'normal';
            }

            if (series.dashLine) {
                seriesObject.dashStyle = 'dash';
            }

            option.series.push(seriesObject);

            if (series.showYAxis === undefined || series.showYAxis) {
                option.chart.marginRight = 100;
            }

            colorIndex++;
        });

        return option;
        /* eslint-disable-next-line */
    },  [])

    const tabItemListProccess = (tabList, chartData) => {
        if (!chartData) { return; }
        // Initialize the chart list
        let tabItemList = {};
 
        if (tabList && tabList.length > 0) {
            tabList.forEach(tab => {
                tabItemList[tab.id] = [];
            });
        } else {
            tabItemList['default'] = [];
        }

        // Loop through the data and put every chart in their tab
        chartData.forEach((chart, i) => {
            let source = chart;
            source = _.cloneDeep(source);
            // Adjust dates to the first day of the period
            source.forEach(series => {
                if (!series.period_type || series.period_type === 'day') {
                    return;
                }

                let dateList = Object.keys(series.data);
                let newData = {};

                dateList.forEach(date => {
                    if (!series.period_type) { return; }

                    let adjustedDate = moment(date).startOf(series.period_type);
                    let adjustedDateStr = adjustedDate.format('YYYY-MM-DD');

                    newData[adjustedDateStr] = series.data[date];
                });

                series.data = newData;
            });

            // Set the smallest period type of the graph.
            let periodType = 'day';
            if (!source.some(series => series.period_type === 'day')) {
                if (source.some(series => series.period_type === 'month')) {
                    periodType = 'month';
                } else if (
                    source.some(series => series.period_type === 'quarter')
                ) {
                    periodType = 'quarter';
                } else if (
                    source.some(series => series.period_type === 'year')
                ) {
                    periodType = 'year';
                }
            }

            let showTimeRangePicker =
                !(chart[0] && chart[0].showTimeRangePicker === false);

            let chartData = chartDataProcess(chart, source, periodType, i, showTimeRangePicker);
            // // Get tab name
            let chartCode =
                chart.length >= 1 && chart[0] && chart[0].code ?
                    chart[0].code : '';
            let tab =
                tabList.find(tab => tab.items.some(item => item === chartCode));
            let tabName = tab.id;

            tabItemList[tabName].push(chartData);
        });

        return tabItemList;
    }

   
    useEffect(() => {
        getPanelData(props.panel, props.parameters)
        /* eslint-disable-next-line */
    },[])

    return (
        panelData ?
            <UI
                data={panelData.rawData.main.data}
                tabList={panelData.rawData.main.tab}
                tabItemList={tabItemListProccess(panelData.rawData.main.tab, panelData.rawData.main.data)}
                currentTab={currentTab}
                setCurrentTab={id => setCurrentTab(id)} /> :
            null
    );
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

export default React.memo(Container, areEqual);