import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    Button,
    Grid,
    Row,
    Icon,
    Pagination,
    Table
} from 'rsuite';
import StrategyRenameModal from 'Components/strategyRenameModal';
import './strategyOverviewPage.scss';
import { colorDecidedByValue } from "Util/colorDecider"
import { colorRangeMapping } from './container/constants/colorRange'
import MoreButton from "./components/moreButton"
import { isRunning } from "Util/isRunning"
import StrategyCard from "./components/strategyCard"
import HamburgerInActive from "Image/svgIcon/hamburger-2.svg";
import HamburgerActive from "Image/svgIcon/hamburger-3.svg";
import GridActive from "Image/svgIcon/grid-1.svg";
import GridInActive from "Image/svgIcon/grid-2.svg";
import IconAdd from 'Image/svgIcon/dashboard/add.svg';
import _ from "lodash"
const { Column, HeaderCell, Cell } = Table;

/**
 *
 * @memberof module:StrategyOverviewPage
 * @param {number} id - 被設定的看板id
 * @param {function} selectDashboard - 設定被選擇的看板
 * @description 投資策略UI
 */

function StrategyOverviewPage(props)
{
    const getData = () => {
        const { sortColumn, sortType, mode } = props.strategyOverviewPage;
        let data = _.cloneDeep(props.list);
        if(mode === 'table')
        {
            if (!props.list) { return []; }
            if (sortColumn && sortType) {
                data = data.sort((a, b) => {
                    let x = a[sortColumn];
                    let y = b[sortColumn];
    
                    if (typeof x === 'string' || typeof y === 'string') {
                        return (
                            sortType === 'asc' ?
                                ('' + x).localeCompare(y) :
                                ('' + y).localeCompare(x)
                        );
                    }
    
                    return sortType === 'asc' ? x - y : y - x;
                });
            }
            const start = props.strategyOverviewPage.displayLength * (props.strategyOverviewPage.page - 1);
            const end = start + props.strategyOverviewPage.displayLength;
            return data.slice(start, end);
        } else {
            return data;
        }

    }

    function renderTable(data) {
        return (
            <div className="block">
                <Table
                    autoHeight={true}
                    data={data}
                    shouldUpdateScroll={false}
                    sortColumn={props.strategyOverviewPage.sortColumn}
                    sortType={props.strategyOverviewPage.sortType}
                    onSortColumn={(sortColumn, sortType) => props.sortColumnData(sortColumn, sortType)}>
                    {props.columnList.map((column, i) => <Column
                        key={column.id}
                        width={column.width}
                        sortable={true}
                        fixed={i === 0}
                        align={i === 0 ? 'left' : 'center'}
                        verticalAlign="middle">
                        <HeaderCell>
                            {props.strategyOverviewPage.sortColumn === column.id ?
                                <div
                                    className="blue"
                                    style={{ textAlign: 'center' }}>
                                    {column.name}
                                </div> :
                                <div style={{ textAlign: 'center' }}>{column.name}</div>}
                        </HeaderCell>
                        <Cell dataKey={column.id}>
                            {
                                rowData => {
                                    if (column.id === 'name') {
                                        return (
                                            !isRunning(rowData.status) ?
                                                (
                                                    <div className="blue">
                                                        <Link to={'/strategy/' + rowData.id}>
                                                            {rowData[column.id]}
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div className="grey">
                                                        {rowData[column.id]}
                                                    </div>
                                                )
                                        );
                                    }

                                    if (column.id === 'created_at' || column.id === 'updated_at' || column.id === 'backtested_at') {
                                        return rowData[column.id] ? moment(rowData[column.id]).format('YYYY/MM/DD') : '';
                                    }

                                    if (column.id === 'status_text' && (
                                        rowData.status === 11 || rowData.status === 21 || rowData.status === 31
                                    )) {
                                        return <span className="orange">{rowData[column.id]}</span>;
                                    }

                                    if (column.id === 'published') {
                                        let className = rowData.published ? 'toggle on' : 'toggle off';
                                        let text = rowData.published ? '分享中' : '不分享';

                                        return (
                                            <Button
                                                className={className}
                                                onClick={props.publishStrategy}
                                                data-id={rowData.id}
                                                data-published={rowData.published}
                                            >
                                                {text}
                                            </Button>
                                        );
                                    }

                                    if (column.id === 'tracked') {
                                        let className = rowData.tracked ? 'toggle on' : 'toggle off';
                                        let content = rowData.tracked ? '追蹤中' : <><Icon icon={IconAdd} />追蹤</>;

                                        return (
                                            <Button
                                                className={className}
                                                onClick={props.trackStrategy}
                                                data-id={rowData.id}
                                                data-tracked={rowData.tracked}
                                            >
                                                {content}
                                            </Button>
                                        );
                                    }

                                    if (column.id === 'year_return_rate' ||
                                        column.id === 'price_return_rate_sum' ||
                                        column.id === 'dividend_return_rate_sum' ||
                                        column.id === 'stocks_win_rate' ||
                                        column.id === 'win_rate') {
                                        const value = rowData[column.id];
                                        const color = colorDecidedByValue(value, colorRangeMapping);
                                        return <span className={color}>{value}</span>;
                                    }

                                    if (column.id === 'backtest_buy_rule') {
                                        let buyRuleStr =
                                            rowData['backtest_buy_rule'] === '自訂權重買進' ?
                                                rowData['backtest_buy_rule'] :
                                                (rowData['backtest_buy_rule'].substr(0, 2) + '買進');

                                        return buyRuleStr + '前' + rowData['buy_rank'] + '名';
                                    }

                                    if (column.id === 'back_year') {
                                        return (
                                            rowData['back_year'] === 'custom' ?
                                                '自訂' :
                                                (rowData['back_year'] + '年')
                                        );
                                    }

                                    const indexYearReturnRateAcc =
                                        rowData['index_year_return_rate'];
                                    const ror = rowData['year_return_rate'];
                                    if (column.id === 'diff') {
                                        if (
                                            indexYearReturnRateAcc == null ||
                                            ror == null
                                        ) {
                                            return '';
                                        }

                                        const diff =
                                            Number((ror - indexYearReturnRateAcc).toFixed(2));

                                        if (diff === 0) { return '0%'; }

                                        return (
                                            diff > 0 ?
                                                <span className="red">+{diff}%</span> :
                                                <span className="green"> {diff}%</span>
                                        );
                                    }

                                    return rowData[column.id];
                                }}
                        </Cell>
                    </Column>
                    )}
                    <Column width={100} fixed="right" align="center">
                        <HeaderCell>
                            <div>功能</div>
                        </HeaderCell>
                        <Cell className="more-cell">
                            {rowData => <MoreButton rowData={rowData} />}
                        </Cell>
                    </Column>
                </Table>
                <div className="strategy-overview__pagination">
                    <Pagination
                        first prev next last ellipsis
                        pages={Math.ceil(props.list.length / props.strategyOverviewPage.displayLength)}
                        maxButtons={10}
                        activePage={props.strategyOverviewPage.page}
                        onSelect={props.onChangePage} />
                </div>
            </div>
        );
    }

    function renderCards(data) {
        return (
            <Grid fluid className="strategy_overview_list">
                <Row>
                    {
                        data.map((data, i) =>
                            <StrategyCard key={i} data={data} />)
                    }
                </Row>
            </Grid>
        );
    }

    function renderEmpty() {
        return (
            <div className="strategy-overview-empty">
                <div className="subtle">您尚未建立策略</div>
                <div className="text">
                    立即<Link to="/strategy">自建策略</Link>
                    、或前往<Link to="/strategySharing">知名策略</Link>挑選
                </div>
            </div>
        )
    }

    const data = getData();

    return (
        <div className="strategy-overview">
            <div>
                <div className="page-title">我的策略/回測</div>
                <Button
                    style={{ marginLeft: 10 }}
                    onClick={props.onNewStrategyClick}>
                    建立策略
                </Button>
            </div>
            <hr />
            <div className = "strategy_overview__sec_title">
                <div className="title">我的策略</div>
                <div className = "strategy_overview__sec_title__switcher">
                    <div></div>
                    <div>切換模式</div>
                    <div>
                        <div>
                            <Icon icon={props.strategyOverviewPage.mode === "table" ? HamburgerActive : HamburgerInActive} svgStyle={ {height: '17px'}} onClick={props.tableMode}/>
                        </div>
                        <div>
                            <Icon icon={props.strategyOverviewPage.mode === "cards" ? GridActive : GridInActive} svgStyle={{height: '17px'}} onClick={props.cardsMode}/>
                        </div>
                    </div>
                </div>
            </div>
            {
                data.length === 0 &&
                renderEmpty()
            }
            {
                data.length !== 0 &&
                props.strategyOverviewPage.mode === 'cards' &&
                renderCards(data)
            }
            {
                data.length !== 0 &&
                props.strategyOverviewPage.mode === 'table' &&
                renderTable(data)
            }
            <StrategyRenameModal
                show={props._strategyOverviewPage.showRenameModal}
                id={props._strategyOverviewPage.currentId}
                currentName={props._strategyOverviewPage.currentName}
                onConfirmClick={props.renameStrategy} 
            />
        </div>
    );
};

/**
 * @alias AreEqual_StrategyOverviewPage
 * @memberof StrategyOverviewPage
 * @param {object} prevProps 前狀態
 * @param {object} nextProps 後狀態
 * @description _strategyOverviewPage, strategyOverviewPage, list
 * @returns {boolean}
 */

 function areEqual(prevProps, nextProps) {
    // console.log(prevProps);
    if(
        !_.isEqual(prevProps._strategyOverviewPage, nextProps._strategyOverviewPage) ||
        !_.isEqual(prevProps.strategyOverviewPage, nextProps.strategyOverviewPage) ||
        !_.isEqual(prevProps.list, nextProps.list)
    )
    {
        return false
    } else {
        return true
    }
}

export default React.memo(StrategyOverviewPage, areEqual);
