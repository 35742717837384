import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import MailBox from './components/mailBox';
import Tasks from './components/tasks';
import { hot } from 'react-hot-loader/root';
// 選單內容
const TabContents = {
    emails: MailBox,
    tasks: Tasks
}
// 選單
const tab = [
    {
        type: 'tasks',
        text:'任務'
    }, 
    {
        type: 'emails',
        text: '信箱'
    }
];
const defaultTab = 'tasks'

function Notifications(){
    // const _notifications = useSelector(store => store.messages.messages)
    const [activeTab, setActiveTab] = useState(defaultTab);
    const getActiveTabElm = () => {
        const Tab = TabContents[activeTab]
        // const tabmessages = _notifications[activeTab]
        return <Tab />
    }
    // const _getActiveTabElm = () => {
    //     const Tab = TabContents[activeTab]
    //     const tabmessages = _notifications[activeTab]
    //     return <Tab { ...tabmessages}/>
    // }
    const onChangeTab = (e) => {
        e.stopPropagation();
        setActiveTab(e.target.value)
    }
    const stopClickPropagation = (e) => {
        e.stopPropagation()
    }
    return <div className="messageBox" onClick={stopClickPropagation}>
        <div className="messageBox_arrow">
        </div>
        <div className="messageBox_switcher switcher">
            {
                tab.map((o, i) => {
                    return <label className = "switcher_tab" key={i}>
                        <input type="radio" name="message" value={o.type} onChange={onChangeTab} defaultChecked={o.type === defaultTab}/>
                        <div>
                            {o.text}
                        </div>
                    </label>
                })
            }
        </div>
        <div className="messageBox_content">
            {getActiveTabElm()}
        </div>
    </div>
}

export default hot(Notifications);
// export default Notifications;