import * as Actions from "../actionTypes/singles"

const inititalState = {
    availableSingles: [],
    single: null
}

export default function reducer(state=inititalState, action)
{
    switch(action.type) {
        case Actions.UPDATE_SINGLES: {
            return {
                ...state,
                availableSingles: action.availableSingles
            }
        }
        default: {
            return state;
        }
    }
};