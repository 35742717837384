import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Icon } from 'rsuite';
import XLSX from 'xlsx';

import IconCSV from 'Image/svgIcon/table/csv.svg';
import IconXLS from 'Image/svgIcon/table/xls.svg';

import './uploadPanel.css';

export default class UploadPanel extends Component {
    state = {
        filename: ''
    };

    onFileDrop(acceptedFiles) {
        let filename = acceptedFiles[0] ? acceptedFiles[0].name : '';
        this.setState({ filename });

        let split = this.state.filename.toLocaleLowerCase().split('.');
        let fileExtension = split[split.length - 1];

        if (
            fileExtension !== 'csv' && fileExtension !== 'xls' &&
            fileExtension !== 'xlsx'
        ) {
            if (!this.props.onGetStocks) { return; }

            // Clear the preview list if the uploaded file is invalid
            this.props.onGetStocks([]);
            return;
        }

        // Only read the first file if multiple files are selected
        this.readFile(acceptedFiles[0]);
    }

    readFile(file) {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = e => {
            let stockList = [];

            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: 'array' });

            // Walkthrough all sheets
            const sheetsNameList = Object.keys(wb.Sheets);

            sheetsNameList.forEach(sheetsName => {
                let sheets = wb.Sheets[sheetsName];

                if (!sheets) { return; }

                // Walkthrough all cells
                // Object name that starts with ! is not a cell
                let cellNameList =
                    Object.keys(sheets).filter(x => !x.startsWith('!'));

                cellNameList.forEach(cellName => {
                    let cell = sheets[cellName];
                    if (!cell || !cell.v) { return; }

                    let value =
                        Number.isInteger(cell.v) ? cell.v.toString() : cell.v;
                    let stock =
                        this.props.stockPool.find(stock =>
                            stock.stock_code === value ||
                            stock.stock_name === value);

                    if (!stock || stockList.includes(stock.stock_code)) {
                        return;
                    }

                    stockList.push(stock.stock_code);
                });
            });

            if (!this.props.onGetStocks) { return; }
            this.props.onGetStocks(stockList);
        }

        reader.readAsArrayBuffer(file);
    }

    renderUpload() {
        return (
            <>
                <div className="icon-container">
                    <Icon icon={IconCSV} size="5x" />
                    <Icon icon={IconXLS} size="5x" />
                </div>
                <div className="blue">選擇上傳檔案</div>
            </>
        );
    }

    renderUploadComplete() {
        let split = this.state.filename.toLocaleLowerCase().split('.');
        let fileExtension = split[split.length - 1];
        let icon = null;

        if (fileExtension === 'csv') {
            icon = IconCSV;
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            icon = IconXLS;
        }

        return (
            icon ?
                <>
                    <Icon icon={icon} size="5x" />
                    {this.state.filename} 已上傳完成
                    <div className="blue">重新上傳檔案</div>
                </> :
                <>
                    {this.state.filename} 非CSV或XLS檔案
                    <div className="blue">重新上傳檔案</div>
                </>
        );
    }

    render() {
        return (
            <div className="upload-panel">
                <Dropzone onDrop={acceptedFiles => this.onFileDrop(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                this.state.filename === '' ?
                                    this.renderUpload() :
                                    this.renderUploadComplete()
                            }
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}