import React, { Component } from 'react';
import { Checkbox } from 'rsuite';

import PaginationTable from 'Components/paginationTable';

import './style.scss';

var _ = require('lodash');

const LeftFilterList = ['上市', '上櫃'];
const RightFilterList = ['上市', '上櫃'];

export default class DoubleTable extends Component {
    constructor(props) {
        super();

        this.state = {
            filter: {
                left: new Set(LeftFilterList),
                right: new Set(RightFilterList)
            }
        };

        this.state.data = {
            left:
                this.getFilteredData(
                    props.data.main.left_table.data, this.state.filter.left),
            right:
                this.getFilteredData(
                    props.data.main.right_table.data, this.state.filter.right)
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            let data =  {
                left:
                    this.getFilteredData(
                        this.props.data.main.left_table.data, this.state.filter.left),
                right:
                    this.getFilteredData(
                        this.props.data.main.right_table.data, this.state.filter.right)
            }

            this.setState({data: data});
        }

        if (!_.isEqual(prevProps.trackingStock, this.props.trackingStock)) {
            this.forceUpdate();
        }
    }

    updateFilter(table, filter, checked) {
        // Update filter list
        let filterList = _.cloneDeep(this.state.filter);

        if (checked) {
            filterList[table].add(filter);
        } else {
            filterList[table].delete(filter);
        }

        // Update table data
        let tableData =
            table === 'left' ?
                this.props.data.main.left_table.data :
                this.props.data.main.right_table.data;

        let data = _.cloneDeep(this.state.data);
        data[table] = this.getFilteredData(tableData, filterList[table]);

        this.setState({ filter: filterList, data: data });
    }

    getFilteredData(data, filterList) {
        return data.filter(x => x.type && filterList.has(x.type.value));
    }
    
    onTrackingStockChange(groupId, stockId, tracked) {
        if (!this.props.onTrackingStockChange) { return; }
        this.props.onTrackingStockChange(groupId, stockId, tracked);
    }

    renderFilter(table) {
        let list = table === 'left' ? LeftFilterList : RightFilterList;

        return list.map(x =>
            <Checkbox
                key={table + '-' + x}
                defaultChecked
                onChange={(_, checked) => this.updateFilter(table, x, checked)}>
                {x}
            </Checkbox>
        );
    }

    render() {
        // console.log('left left left');
        let leftTitle = this.props.data.main.left_table.name;
        let rightTitle = this.props.data.main.right_table.name;

        return (
            <div className="double-table">
                <div className="left-table">
                    <div className="table-title">
                        <span>{leftTitle} | </span>
                        {this.renderFilter('left')}
                    </div>
                    <PaginationTable
                        height={513}
                        rowHeight={36}
                        columns={this.props.data.main.left_table.columns}
                        data={this.state.data.left}
                        displayLength={13}
                        sortColumn={this.props.data.main.left_table.sortBy.column}
                        sortType={this.props.data.main.left_table.sortBy.order}
                        trackingStock={this.props.trackingStock}
                        onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                </div>
                <div className="right-table">
                    <div className="table-title">
                        <span>{rightTitle} | </span>
                        {this.renderFilter('right')}
                    </div>
                    <PaginationTable
                        height={513}
                        rowHeight={36}
                        columns={this.props.data.main.right_table.columns}
                        data={this.state.data.right}
                        displayLength={13}
                        sortColumn={this.props.data.main.right_table.sortBy.column}
                        sortType={this.props.data.main.right_table.sortBy.order}
                        trackingStock={this.props.trackingStock}
                        onTrackingStockChange={(groupId, stockId, tracked) => this.onTrackingStockChange(groupId, stockId, tracked)} />
                </div>
            </div>
        );
    }
}