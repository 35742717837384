import { colorList, 
    // graphOption
 } from  "Page/graph/constants"
// import { INSERT_SELECTED_STOCK, REMOVE_SELECTED_STOCK, INSERT_SELECTED_INDEX,  REMOVE_SELECTED_INDEX, SET_DATE_RANGE, SET_LINE_VISIBLE } from "../actionTypes/graph";
// import * as Actions from "../actionTypes/graph";
import * as Actions from "../actionTypes/graph";
const initialStat = {
    originSeletectStock: [],
    originSelectedIndex: [],
    selectedStock: [],
    selectedIndex: [],
    lineList: [],
    chartList: [],
    graphStart: 'normal',
    graphType: 'one',
    dateRange: '10Y',
    startDate: null,
    endDate: null,
}

const getLineList = (selectedStock, selectedIndex) => {
    let list = [];
    for (let i = 0; i < selectedStock.length; i++) {
        for (let j = 0; j < selectedIndex.length; j++) {
            let visible =
                selectedStock[i].visible &&
                selectedIndex[j].visible;

            list.push({
                stock: selectedStock[i],
                index: selectedIndex[j],
                color: colorList[list.length],
                visible: visible,
            });
        }
    }
    return list;
}

export default function reducer(state=initialStat, action)
{
    switch(action.type) {
        case Actions.INSERT_SELECTED_STOCK: {
            const { stock } = action;
            if (
                state.selectedStock.some(x =>
                    x.stock_code === stock.stock_code)
            ) {
                alert('此個股已在清單中，請選取其他個股。');
                return state;
            } else {
                
                const entry = { ...stock };
                entry.visible = true;
                const list =
                    state.selectedStock ?
                        [...state.selectedStock, entry] : [entry];
    
                const lineList = getLineList(
                    [
                        ...state.selectedStock, entry
                    ],
                    state.selectedIndex
                );
                return {
                    ...state,
                    originSeletectStock: state.selectedStock,
                    originSeletectIndex: state.selectedIndex,
                    selectedStock: list,
                    lineList: lineList
                }
            }
        }


        case Actions.REMOVE_SELECTED_STOCK: {
            const list = [...state.selectedStock];
            list.splice(action.index, 1);
            const lineList = getLineList(list, state.selectedIndex);
            return {
                ...state, selectedStock: list, lineList: lineList
            }
        }

        
        case Actions.INSERT_SELECTED_INDEX: {
            const { index } = action
            if (
                state.selectedIndex.some(x =>
                    x.value.value === index.value.value &&
                    x.range.value === index.range.value)
            ) {
                alert('此指標已在清單中，請選取其他指標。');
                return state;
            } else {

                let entry = {...index};
                console.log(index);
                entry.visible = true;
    
                let list =
                    state.selectedIndex ?
                        [...state.selectedIndex, entry] : [entry];
    
                const lineList = getLineList(
                    state.selectedStock,
                    [
                        ...state.selectedIndex,
                        entry
                    ]
                );
                return {
                    ...state,
                    originSeletectStock: state.selectedStock,
                    originSeletectIndex: state.selectedIndex,
                    selectedIndex: list,
                    lineList: lineList
                }
            }
            
        }

        case Actions.REMOVE_SELECTED_INDEX: {
            const { index } = action;
            let list = state.selectedIndex.filter(x => x !== index);
            const lineList = getLineList(state.selectedStock, list);
            return {
                ...state,
                selectedIndex: list, lineList: lineList
            }
        }

        case Actions.SET_DATE_RANGE: {
            const { option } = action;
            let now = new Date();

            // Ignore time, only keep date
            let endDate = new Date(now.toDateString()); // Set today as end date
            let startDate = new Date(now.toDateString());

            if (option === '7D') {
                startDate.setDate(endDate.getDate() - 7);
            } else if (option === '15D') {
                startDate.setDate(endDate.getDate() - 15);
            } else if (option === '1M') {
                startDate.setMonth(endDate.getMonth() - 1);
            } else if (option === '3M') {
                startDate.setMonth(endDate.getMonth() - 3);
            } else if (option === '6M') {
                startDate.setMonth(endDate.getMonth() - 6);
            } else if (option === 'start') {
                startDate.setMonth(0);
                startDate.setDate(1);
            } else if (option === '1Y') {
                startDate.setFullYear(endDate.getFullYear() - 1);
            } else if (option === '3Y') {
                startDate.setFullYear(endDate.getFullYear() - 3);
            } else if (option === '5Y') {
                startDate.setFullYear(endDate.getFullYear() - 5);
            } else if (option === '10Y') {
                startDate.setFullYear(endDate.getFullYear() - 10);
            } else if (option === 'all') {
                startDate = null;
                endDate = null;
            }
            return {
                ...state,
                dateRange: option,
                startDate: startDate,
                endDate: endDate
            }
        }
        case Actions.SET_LINE_VISIBLE: {
            const { index, visible } = action;
            // console.log('visible');
            // console.log(visible);
            const list = state.lineList.map((o, i) => {
                if(i === index)
                {
                    o.visible = visible
                    return o
                } else {
                    return o
                }
            })
            return {
                ...state, lineList: list
            }
        }
        case Actions.SET_GRAPH_START: {
            // console.log(action);
            return {
                ...state,
                graphStart: action.graphStart
            }
        }
        
        case Actions.SET_GRAPH_TYPE: {
            return {
                ...state,
                graphType: action.graphType
            }
        }

        case Actions.SET_INDEX_VISIBLE: {
            const { index, value } = action;
            const list = state.selectedIndex.map((o, i) => {
                if(i === index)
                {
                    o.visible = value
                    return o
                } else {
                    return o
                }
            })
            const lineList = getLineList(state.selectedStock, list);
            return {
                ...state,
                selectedIndex: list, lineList: lineList
            }
        }
        
        case Actions.SET_STOCK_VISIBLE: {
            const { index, value } = action;
            const list = state.selectedStock.map((o, i) => {
                if(i === index)
                {
                    o.visible = value
                    return o
                } else {
                    return o
                }
            })
            
            const lineList = getLineList(list, state.selectedIndex);
            return {
                ...state,
                selectedStock: list, lineList: lineList
            }
        }
        case Actions.SET_END_DATE:  {
            const { date } = action;
            let startDate = state.startDate;

            if (date < startDate) {
                startDate = null;
            }

            return {
                ...state,
                startDate: startDate,
                endDate: date
            }
        }
        case Actions.SET_START_DATE: {
            const { date } = action;
            let endDate = state.endDate;
    
            if (date > endDate) {
                endDate = null;
            }
    
            return {
                ...state,
                startDate: date,
                endDate: endDate
            }
        }
        case Actions.RESET_GRAPH: {
            return initialStat;
        }
        default: {
            return state
        }        
    }
};