import React from 'react';
import { Button } from 'rsuite';

const LoginButton = props => {
    const login = () => {
        if (props.onClick) { props.onClick(); }

        window.location.href = '/login';
    }

    return <Button onClick={login}>登入/註冊</Button>;
}

export default LoginButton;